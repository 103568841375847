// Copyright (C) 2023 by Posit Software, PBC.

import { createRouter, createWebHashHistory } from 'vue-router';
import { store } from '@/store';
import {
  loadUserAndSettingsFilter,
  updateTitleFilter,
  requireAuthFilter,
  requireRoleFilter,
  contentListFilter,
  enabledFilter,
  userCompletionFilter,
  dirtyParamsFilter,
} from './helpers';

// Routes
import welcome from './welcome';
import authentication from './authentication';
import misc from './misc';
import contentList from './contentList';
import admin from './admin';
import people from './people';
import content from './content';
import NotFoundView from '@/views/NotFoundView';
import AppHeader from '@/components/AppHeader';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: () => {
        const { isAuthenticated } = store.state.currentUser;

        if (isAuthenticated) {
          return '/content/listing';
        }

        return '/welcome';
      },
    },
    ...welcome,
    ...authentication,
    ...misc,
    ...contentList,
    ...admin,
    ...people,
    ...content,
    {
      path: '/:pathMatch(.*)*',
      name: 'not_found',
      meta: {
        title: 'Not Found',
      },
      components: {
        default: NotFoundView,
        header: AppHeader,
      },
    }
  ],
});
// Global navigation guards
router.beforeEach(loadUserAndSettingsFilter);
router.beforeEach(enabledFilter);
router.beforeEach(requireAuthFilter);
router.beforeEach(requireRoleFilter);
router.beforeEach(userCompletionFilter);
router.beforeEach(contentListFilter);
router.beforeEach(dirtyParamsFilter);
router.afterEach(updateTitleFilter);

export function routeWithRedirect(routeName) {
  const route = {
    name: routeName,
  };

  if (router.currentRoute.value.query.url) {
    route.query = {
      url: router.currentRoute.value.query.url,
    };
  }

  return route;
}

export function navigateWithRedirect(routeName) {
  router.push(routeWithRedirect(routeName));
}

export default router;

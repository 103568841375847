<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <div
      v-if="!filterActive"
      class="mt-3rem"
    >
      <div class="message">
        No environments have been added yet.,
      </div>
      <div class="message">
        Content will not be available until an administrator has added
        appropriate environments to the system.
      </div>
    </div>
    <div
      v-if="filterActive"
      class="mt-3rem message"
    >
      <div class="mb-1rem">
        The search string you have entered was not found
        in any of the known environments.
      </div>
      <div class="mb-1rem">
        Please update or clear your search string.
      </div>
      <a
        href="#"
        data-automation="empty-environment-reset-filter"
        @click.prevent="resetAllFilters"
        @keydown.prevent.space="resetAllFilters"
        @keydown.prevent.enter="resetAllFilters"
      >
        Clear search string
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyEnvironmentsList',
  props: {
    filter: {
      type: String,
      required: true,
    },
  },
  emits: ['reset'],
  computed: {
    filterActive() {
      return this.filter.length > 0;
    }
  },
  methods: {
    resetAllFilters() {
      this.$emit('reset');
    },
  }
};
</script>

<style scoped>
.mt-3rem {
  margin-top: 3rem;
}
.mb-1rem {
  margin-bottom: 1rem;
}
.message {
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!-- Generic action button to be used across the app -->
<template>
  <button
    ref="button"
    :class="['action', buttonClass]"
    :disabled="disabled"
    :aria-label="ariaLabel"
    type="button"
    @click="handler"
    @keydown.space="handler"
  >
    <span
      v-if="hasLabel"
      class="actionTitle"
    >{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    label: {
      type: String,
      default: null,
    },
    buttonClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: null,
    }
  },
  emits: ['clicked'],
  computed: {
    hasLabel() {
      return Boolean(this.label);
    },
  },
  methods: {
    handler(e) {
      this.$emit('clicked', e);
    },
    focus() {
      this.$nextTick().then(() => this.$refs.button.focus());
    },
  },
};
</script>

<style lang="scss" scoped>
  button.action:disabled {
    cursor: not-allowed;
    &:hover * {
      background: transparent;
    }
  }
</style>

// Copyright (C) 2024 by Posit Software, PBC.

import { SKIP_TO_MAIN_URL } from '@/store/modules/navigation';
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

/**
 * Composable to handle the skip to main content navigation.
 *
 * @param {Function} onSkip Function to call when the skip to main content is triggered.
 *
 *
 * When loading the page represented by the template belowe, the user might wish to navigate with
 * the keyboard but skip the header and set * focus directly to the search input.
 * This can be achieved with the following code:
 *
 * @example
 * <template>
 *   <input ref="contentSearch" />
 *   <div id="content-list">
 *     ...
 *   </div>
 * </template>
 *
 * <script setup>
 * import { ref } from 'vue';
 * import { useSkipToMainContent } from '@/composables/skipToMainContent';
 *
 * const searchInput = ref(null);
 * useSkipToMainContent(() => {
 *   searchInput.value.focus();
 * });
 * </script>
 *
 * When the user presses the skip to main content key, the focus will be set to the search input.
 */
export const useSkipToMainContent = onSkip => {
  const store = useStore();
  const skipToMainContent = computed(
    () => store.state.navigation.skipToMainContent
  );
  const previousUrl = computed(() => store.state.navigation.skipToMainUrl);
  const route = useRoute();

  if (!route) { return; }

  // Special case: when the user visits some content, then navigates back to the Content List,
  // by Back button or clicking the Content link, the focus should reset so a Tab key press
  // reveals the "Skip to main content" button. I don't know of other similar cases where this 
  // would be necessary. So, this is a simple special-case for now.
  if (
    route.name === 'contentList' &&
    previousUrl.value?.match(/\/apps\/[0-9a-fA-F-]+\/access/)
  ) {
    setTimeout(() => {
      document.querySelector('#app').focus();
    }, 100);
  }
  store.commit(SKIP_TO_MAIN_URL, route.path);

  watch(skipToMainContent, value => {
    if (value) {
      onSkip();
    }
  });
};

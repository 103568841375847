<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { addExtension } from '@/api/app';
import { safeAPIErrorMessage } from '@/api/error';
import { taskToPromise } from '@/api/tasks';
import FileInput, { FileType } from '@/components/FileInput.vue';
import IndeterminateProgressBar from '@/components/IndeterminateProgressBar.vue';
import MessageBox from '@/components/MessageBox.vue';
import TabSelector, { TabShape } from '@/components/TabSelector.vue';
import RSButton from '@/elements/RSButton';
import RSInputText from '@/elements/RSInputText.vue';
import RSModal from '@/elements/RSModal';
import { docsPath } from '@/utils/paths';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

defineProps({
  showModal: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['close', 'refreshContentList']);
const router = useRouter();

const localState = reactive({
  activeTabId: 'url',
  appGuid: null,
  bundleUrl: '',
  isDeploymentComplete: false,
  errorMessage: null,
  invalidUrlMessage: '',
  isDeploying: false,
  selectedBundle: null,
});

const tabs = [
  { id: 'url', label: 'URL' },
  { id: 'bundle', label: 'Bundle' },
];

const friendlyServerError = (error) => {
  if (!error) {return null;}

  const missingFileMatch = error.match(/\/([a-zA-Z0-9-_]+.[a-zA-Z0-9]+):/);
  if (missingFileMatch) {
    return {
      text: 'Error: This bundle does not contain the required file:',
      filename: missingFileMatch[1],
    };
  }

  return { text: error };
};

const onSelectBundle = ({ file }) => {
  localState.selectedBundle = file;
};

const onDeploymentProgress = (task) => {
  if (task.finished) {
    localState.isDeploying = false;
    localState.isDeploymentComplete = true;
    emit('refreshContentList');
  }
};

const isAddDisabled = () => {
  if (localState.isDeploying) {
    return true;
  }

  if (localState.activeTabId === 'url') {
    return !localState.bundleUrl;
  }

  return !localState.selectedBundle;
};

const isValidUrl = (url) => 
  url?.match(/^(https?):\/\/[^\s/$.?#].[^\s]*(.tar.gz|.tgz)$/);

const onUpdateTab = (tabId) => {
  localState.activeTabId = tabId;
  localState.errorMessage = null;
};

const onAdd = async() => {
  localState.errorMessage = null;

  if (localState.activeTabId === 'url') {
    if (!isValidUrl(localState.bundleUrl)) {
      localState.invalidUrlMessage = `
        Please provide a bundle URL that begins with "http" or "https"
        and ends with either a ".tgz" or ".tar.gz" extension.
        For example: https://company.example.com/extension.tar.gz
      `;
      return;
    }
    localState.selectedBundle = { url: localState.bundleUrl };
  }

  if (localState.selectedBundle === null) {
    return;
  }

  localState.isDeploying = true;
  try {
    const { guid, taskId } = await addExtension(localState.selectedBundle);
    localState.appGuid = guid;

    await taskToPromise(taskId, onDeploymentProgress);
  } catch (error) {
    localState.errorMessage = friendlyServerError(safeAPIErrorMessage(error));
    localState.isDeploying = false;
  }
};

const onOpen = () => {
  emit('close');

  if (!localState.appGuid) {
    return;
  }
  router.push({ name: 'apps.access', params: { idOrGuid: localState.appGuid } });
};

const reset = () => {
  localState.activeTabId = 'url';
  localState.bundleUrl = '';
  localState.errorMessage = null;
  localState.isDeploymentComplete = false;
  localState.isDeploying = false;
  localState.selectedBundle = null;
};

const onClose = () => {
  reset();
  emit('close');
};

const onDragOver = () => {
  if (localState.activeTabId === 'url') {
    localState.activeTabId = 'bundle';
  }
};
</script>

<template>
  <RSModal
    v-if="showModal"
    data-automation="add-extension-dialog"
    subject="Add a Connect Extension"
    close-button-label="Close"
    :active="showModal"
    @close="onClose"
  >
    <template #content>
      <div
        role="button"
        tabindex="-1"
        data-automation="add-extension-content"
        @dragover="onDragOver"
      >
        <MessageBox small>
          <div 
            class="extension-message"
            data-automation="add-extension-message"
          >
            <p class="description">
              Connect Extensions provide a way for you to deploy content that adds features to Connect without requiring server upgrades.
              These features might be interfaces to capabilities which are only currently supported via the Connect Server API.
            </p>
            <p class="description">
              <span class="emphasize">To add a Connect Extension, you can:</span>
              <ul class="add-extension-list">
                <li><span class="emphasize">Provide a URL</span> to a bundle containing deployable content, or</li>
                <li><span class="emphasize">Upload a bundle file</span> from your local filesystem.</li>
              </ul>
            </p>

            <p class="description">
              <span class="emphasize">Bundles must:</span>
              <ul class="add-extension-list">
                <li>Have an extension of either <code>.tgz</code> or <code>.tar.gz</code></li>
                <li>
                  Follow the guidelines outlined in the <a
                    class="help-link"
                    :href="docsPath('user/extensions')"
                    target="_blank"
                  >Extensions documentation</a>.
                </li>
              </ul>
            </p>
          </div>
        </MessageBox>

        <TabSelector
          :active-tab-id="localState.activeTabId"
          :disabled="localState.isDeploying || localState.isDeploymentComplete"
          :tabs="tabs"
          :shape="TabShape.SQUARE"
          name="add-tab-selector"
          data-automation="add-extension-tab-selector"
          @update:active-tab-id="onUpdateTab"
        />

        <section class="deploy-section">
          <div
            v-if="localState.activeTabId === 'url'"
            class="tab-url"
          >
            <RSInputText
              v-model.trim="localState.bundleUrl"
              label="Bundle URL"
              name="bundle-url"
              data-automation="bundle-url-input"
              :disabled="localState.isDeploying || localState.isDeploymentComplete"
              :message="localState.invalidUrlMessage"
              @change="localState.invalidUrlMessage = ''"
            />
          </div>

          <div
            v-if="localState.activeTabId === 'bundle'"
          >
            <div class="add-extension-form">
              <label for="bundle-input">Select a bundle</label>
              <FileInput
                id="bundle-input"
                name="bundle-input"
                :read-only="localState.isDeploying || localState.isDeploymentComplete"
                :file-type="FileType.BUNDLE"
                :show-image-preview="false"
                data-automation="bundle-image-input"
                @input="onSelectBundle"
              />
            </div>
          </div>
        </section>

        <div
          v-if="localState.errorMessage"
          class="error-message"
          data-automation="extension-error-message"
        >
          {{ localState.errorMessage.text }}

          <code
            v-if="localState.errorMessage.filename"
            class="error-message__filename"
            data-automation="extension-error-filename"
          >
            {{ localState.errorMessage.filename }}
          </code>
        </div>
        <div
          v-else-if="(localState.isDeploying && localState.selectedBundle)
            || localState.isDeploymentComplete
          "
          class="deployment-progress"
        >
          <div
            class="deployment-progress__text"
            role="status"
          >
            <p
              v-if="localState.isDeploymentComplete"
              data-automation="extension-deployed-message"
            >
              Deployed {{ localState.selectedBundle.name }}
            </p>
            <p v-else>
              Deploying {{ localState.selectedBundle.name }}
            </p>
          </div>
          <div class="deployment-progress__bar">
            <div v-if="localState.isDeploymentComplete">
              ✔
            </div>
            <div v-else>
              <IndeterminateProgressBar />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #controls>
      <div class="controls">
        <RSButton
          data-automation="add-extension-cancel-btn"
          :label="localState.isDeploymentComplete ? 'Close' : 'Cancel'"
          type="secondary"
          @click="onClose"
        />

        <RSButton
          data-automation="add-extension-submit-btn"
          :label="localState.isDeploymentComplete ? 'Open' : 'Add'"
          :disabled="isAddDisabled()"
          @click="localState.isDeploymentComplete ? onOpen() : onAdd()"
        />
      </div>
    </template>
  </RSModal>
</template>

<style lang="scss">
@import 'Styles/shared/_colors';

.rsmodal__header {
  margin-bottom: 1rem;
}
.deploy-section {
  margin-top: 1.5rem;
}

.extension-message {
  p:not(:last-child) {
    margin-bottom: 1rem;
  }
  code {
    background-color: transparent;
    font-size: 0.7rem;
  }
  a.help-link {
    color: $color-primary-dark;
    text-decoration: underline;
  }
}

.add-extension-list {
  list-style-type: disc;
  margin-left: 1em;

  li {
    margin-left: 1rem;
    margin-bottom: 0;
  }
}

.tab-url {
  margin-bottom: 1rem;
}

.add-extension-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  label {
    margin-bottom: 0.5rem;
    font-weight: 600;

    &.image-label.image-label.image-label {
      text-align: center;
      font-weight: 500;
    }
  }

  input {
    padding: 0.5rem;
  }
}

.controls {
  display: flex;
  justify-content: flex-end;

  button:last-child {
    margin-left: 1em;
  }
}

.deployment-progress {
  background-color: $color-info-background;
  border-radius: 3px;
  border: 1px solid $color-info-border;
  color: $color-info;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;

  &__label {
    font-weight: 600;
  }

  &__bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2rem;
  }
}

.error-message {
  background-color: $color-error-background;
  border-radius: 3px;
  border: 1px solid $color-error-border;
  color: $color-error;
  padding: 1rem;
  line-height: 1.2;

  &__filename {
    background-color: inherit;
    color: inherit;
    font-size: 0.8rem;
  }
}
</style>

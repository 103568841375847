// Copyright (C) 2022 by Posit Software, PBC.

// mapping of UI-level interval groupings to their actual groupings
const frequencyGroups = {
  minute: ['minute'],
  hour: ['hour'],
  day: ['day'],
  week: ['dayofweek', 'weekday', 'week'],
  'semi-month': ['semimonth'],
  month: ['dayofmonth', 'dayweekofmonth'],
  year: ['year'],
};

// map of UI-level column names to their respective scheduled item values
const propertyMap = {
  name: i => i.title,
  frequency: i => i.numberOfRuns,
  nextRun: i => i.nextRun,
  author: i => i.authorName,
  email: i => i.email,
};

// filterSort filters and sorts scheduled items according to parameters
// provided.
export function filterSort(items, frequency, column, sortDescending) {
  // construct a filter based on frequency
  let frequencyFilterFn = () => true;
  if (frequency !== 'all') {
    frequencyFilterFn = item => frequencyGroups[frequency].includes(item.scheduleType);
  }

  const filteredItems = items.filter(frequencyFilterFn);

  // sort items based on column and requested sort order
  filteredItems.sort(function(a, b) {
    const aval = propertyMap[column](a);
    const bval = propertyMap[column](b);

    if (aval === bval) {
      return 0;
    } else if (sortDescending) {
      return aval > bval ? -1 : 1;
    }
    return aval < bval ? -1 : 1;
  });

  return filteredItems;
}

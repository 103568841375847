<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<!--
  Component to show more information for a component on hover or focus.
  Intended to be more verbose and accessible than HTML "title" atribute.
  Wrap the component for hovering within <Tooltip> (as the default slot)
  and tip content goes in #tip slot. Position with T/R/B/L props as needed.

  <Tooltip
  data-automation='tooltip'
  top='1em'
  right='1em'
  >
  <TooltippedComponent />
  <template #tip>
  You've been tipped!
  </template>
  </Tooltip>
-->

<script setup>
import { reactive } from 'vue';

const props = defineProps({
  dataAutomation: {
    type: String,
    default: ''
  },
  top: {
    type: String,
    default: ''
  },
  right: {
    type: String,
    default: ''
  },
  bottom: {
    type: String,
    default: ''
  },
  left: {
    type: String,
    default: ''
  }
});

const localState = reactive({
  showingTooltip: false,
});
</script>

<template>
  <span class="container">
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <span
      @mouseover="localState.showingTooltip = true"
      @mouseout="localState.showingTooltip = false"
      @focus="localState.showingTooltip = true"
      @focusout="localState.showingTooltip = false"
    >
      <slot />
    </span>
    <div
      v-if="localState.showingTooltip"
      class="tooltip"
      aria-live="polite"
      :data-automation="props.dataAutomation"
    >
      <slot name="tip" />
    </div>
  </span>
</template>

<style lang="scss" scoped>
  @import 'Styles/shared/_colors';
  @import 'Styles/shared/_mixins';

  .container {
    position: relative;
  }

  .tooltip {
    @include normal-drop-shadow;
    display: flex;
    position: absolute;
    top: v-bind(top);
    right: v-bind(right);
    bottom: v-bind(bottom);
    left: v-bind(left);
    width: max-content;
    background-color: $color-info-background;
    border: 1px solid $color-info-border;
    color: $color-info;
    padding: 5px;
    z-index: 1;
    line-height: 12px;
    animation: delayed-fade-out 5s;
  }

  @keyframes delayed-fade-out {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>

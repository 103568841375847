<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__publish-rconsole wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Publish
      </StepHeading>
      <p>
        Use the rsconnect package to deploy the unzipped project.
      </p>

      <p>
        If this is your first time publishing from RStudio, you need to
        <a
          :href="publishingDocs"
          target="_blank"
        >
          link the IDE to a Connect account
        </a>
        before deploying your code.
      </p>

      <p>
        Publish your content by using the R console to run the following command:
      </p>

      <div class="wizard-panel__copy">
        <code class="wizard-panel__copy-code">
          {{ publishCode }}
        </code>

        <CopyButton
          class="wizard-panel__copy-button"
          :copy-text="publishCode"
        />
      </div>

      <p class="wizard-panel__notice">
        <strong>Note:</strong>
        Publishing for the first time can often take a while,
        since {{ systemDisplayName }} will download and build all dependent packages.
      </p>

      <div class="wizard-panel__learn-more">
        <ul class="wizard-panel__links">
          <li
            v-for="link in links"
            :key="link.title"
            class="wizard-panel__link"
          >
            <a
              :href="link.target"
              target="_blank"
            >
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CopyButton from '@/components/CopyButton';
import { docsPath, serverURL } from '@/utils/paths';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'PublishRConsole',
  components: {
    CopyButton,
    StepHeading,
  },
  mixins: [PanelMixin],
  data() {
    return {
      links: [
        { title: 'Learn more about how to publish', target: docsPath('user/publishing-overview') },
        { title: 'Learn more about the rsconnect package', target: 'https://rstudio.github.io/rsconnect/' },
      ],
    };
  },
  computed: {
    publishingDocs() {
      return docsPath('user/publishing-overview');
    },
    serverAddress() {
      return serverURL('/');
    },
    publishCode() {
      return `rsconnect::deployApp()`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.wizard-panel {
  &__copy {
    display: flex;

    &-code {
      flex-grow: 1;
       margin-bottom: 0;
    }

    &-button {
      align-self: center;
      margin-left: 0.5rem;
    }
  }

  &__learn-more {
    margin-top: 1rem;
  }
}
</style>


// Copyright (C) 2023 by Posit Software, PBC.

import AppHeader from '@/components/AppHeader';
import { store } from '@/store';
import GroupEdit from '@/views/groups/GroupEdit';
import GroupsView from '@/views/groups/GroupsView';
import UserKeysView from '@/views/users/apikeys/UserKeysView';
import UserProfileView from '@/views/users/profile/UserProfileView';
import UsersView from '../views/users/UsersView.vue';

const isGroupsEnabled = () => {
  return store.state.server.settings.authentication.groupsEnabled;
};

export default [
  {
    path: '/people',
    name: 'people',
    redirect: '/people/users',
  },
  {
    path: '/people/users',
    name: 'people.users',
    components: {
      peopleContainer: UsersView,
      header: AppHeader,
    },
    meta: {
      requireAuth: true,
      title: 'Users',
    }
  },
  {
    path: '/people/users/:guid',
    name: 'people.users.profile',
    components: {
      userContainer: UserProfileView,
      header: AppHeader,
    },
    props: {
      userContainer: true,
    },
    meta: {
      requireAuth: true,
      title: 'User Profile',
    },
  },
  {
    path: '/people/users/:guid/apikeys',
    name: 'people.users.api_keys',
    components: {
      userContainer: UserKeysView,
      header: AppHeader,
    },
    props: {
      userContainer: true,
    },
    meta: {
      requireAuth: true,
      title: 'API Keys',
    }
  },
  {
    path: '/people/groups',
    name: 'people.groups',
    components: {
      peopleContainer: GroupsView,
      header: AppHeader,
    },
    meta: {
      enabled: isGroupsEnabled,
      requireAuth: true,
      title: 'Groups',
    },
  },
  {
    path: '/people/groups/:groupId',
    name: 'people.groups.edit',
    components: {
      peopleContainer: GroupEdit,
      header: AppHeader,
    },
    props: {
      peopleContainer: true,
    },
    meta: {
      enabled: isGroupsEnabled,
      requireAuth: true,
      title: 'Groups',
    },
  },
];

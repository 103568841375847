// Copyright (C) 2022 by Posit Software, PBC.

/**
 * copyToClipboard copies a value to the clipboard in a backwards-compatible manner.
 * @param {Object} object
 * @param {string} object.value string value to copy to clipboard
 * @param {Element} object.triggerTarget (optional) element to attach temp element to
 * required if button is within a modal
 */
export async function copyToClipboard({ value, triggerTarget }) {
  try {
    return await navigator.clipboard.writeText(value);
  } catch {
    const tmpEl = document.createElement('textarea');
    tmpEl.style.opacity = '0';
    tmpEl.style.height = '0';
    tmpEl.style.width = '0';
    tmpEl.value = value;

    if (triggerTarget) {
      triggerTarget.parentNode.insertBefore(tmpEl, triggerTarget);
      tmpEl.select();
      document.execCommand('copy');
      triggerTarget.parentNode.removeChild(tmpEl);
    } else {
      document.body.appendChild(tmpEl);
      tmpEl.select();
      document.execCommand('copy');
      document.body.removeChild(tmpEl);
    }
  }
}

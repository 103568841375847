<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__publish-ide wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Publish
      </StepHeading>
      <p>
        In the IDE, press the
        <span class="emphasize">Publish</span>
        button in the upper-right corner of
        the Source pane or the Output pane and follow the on-screen instructions.
      </p>
      <p class="wizard-panel__publish-ide-screenshot">
        <img
          alt="Publish button in RStudio IDE"
          src="./screenshot.png"
        >
      </p>
      <p class="wizard-panel__publish-ide-server-address-notice">
        If you're publishing for the first time, enter the URL for {{ systemDisplayName }}:
      </p>

      <div class="wizard-panel__copy">
        <code class="wizard-panel__copy-code">
          {{ serverAddress }}
        </code>
        <CopyButton
          class="wizard-panel__copy-button"
          :copy-text="serverAddress"
        />
      </div>

      <p class="wizard-panel__notice">
        <strong>Note:</strong>
        Publishing for the first time can often take a while,
        since {{ systemDisplayName }} will download and build all dependent packages.
      </p>

      <p class="wizard-panel__learn-more">
        <a
          :href="learnMoreLink"
          target="_blank"
        >Learn more about how to publish</a>
      </p>
    </div>
  </div>
</template>

<script>
import CopyButton from '@/components/CopyButton';
import { docsPath, serverURL } from '@/utils/paths';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'PublishIDE',
  components: {
    CopyButton,
    StepHeading,
  },
  mixins: [PanelMixin],
  computed: {
    serverAddress() {
      return serverURL('/');
    },
    learnMoreLink() {
      return docsPath('user/publishing-overview');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.wizard-panel {
  &__publish-ide {
    &-screenshot {
      margin-bottom: 0.5rem;
    }
  }

  &__copy {
    display: flex;

    &-code {
      flex-grow: 1;
       margin-bottom: 0;
    }

    &-button {
      align-self: center;
      margin-left: 0.5rem;
    }
  }

  &__learn-more {
    margin-top: 1rem;
  }
}

@include for-small-screens() {
  .wizard-panel__publish-ide-server-address-copy {
    min-width: auto !important;
  }
}
</style>

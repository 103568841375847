// Copyright (C) 2022 by Posit Software, PBC.

export const ACCESS_SETTINGS_UPDATE_TYPE = 'ACCESS_SETTINGS_UPDATE_TYPE';
export const ACCESS_SETTINGS_UPDATE_MODE = 'ACCESS_SETTINGS_UPDATE_MODE';
export const ACCESS_SETTINGS_UPDATE_PRIMARY_MODE = 'ACCESS_SETTINGS_UPDATE_PRIMARY_MODE';
export const ACCESS_SETTINGS_UPDATE_SECONDARY_MODE = 'ACCESS_SETTINGS_UPDATE_SECONDARY_MODE';
export const ACCESS_SETTINGS_UPDATE_ALTERNATE_USER = 'ACCESS_SETTINGS_UPDATE_ALTERNATE_USER';

export const ModeType = {
  VIEWER: 'viewer',
  OWNER: 'owner',
  EDITOR: 'editor',
};

export const RunAsModes = {
  DEFAULT: 'default',
  ALTERNATE: 'alternate',
  CURRENT: 'current',
};

export default {
  state: {
    type: null,
    mode: null,
    runAs: {
      primaryMode: null,
      secondaryMode: null,
      alternateUser: null,
    },
  },
  mutations: {
    [ACCESS_SETTINGS_UPDATE_TYPE](state, value) {
      state.type = value;
    },
    [ACCESS_SETTINGS_UPDATE_MODE](state, value) {
      state.mode = value;
    },
    [ACCESS_SETTINGS_UPDATE_PRIMARY_MODE](state, value) {
      state.runAs.primaryMode = value;
    },
    [ACCESS_SETTINGS_UPDATE_SECONDARY_MODE](state, value) {
      state.runAs.secondaryMode = value;
    },
    [ACCESS_SETTINGS_UPDATE_ALTERNATE_USER](state, value) {
      state.runAs.alternateUser = value;
    },
  },
};

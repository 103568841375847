// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiPath } from '@/utils/paths';
import { keysToCamel } from './transform';

/**
 * API calls for connect api queue
 */

/**
 * Fetches queue and transforms the response from api
 * @returns {Promise} A Promise that resolves with an array containing the processes queue.
 */
const getQueue = () => {
  const path = apiPath('queue');
  return axios.get(path).then(response => {
    return keysToCamel(response.data).map(record => {
      return {
        ...record,
        id: String(record.id),
        active: !!(record.hostname && record.heartbeat),
        payload: atob(record.item),
      };
    });
  });
};

/**
 * Deletes a queue process
 * @param {(string|number)} processId - The process id for the queue record to be deleted.
 * @returns {Promise} A Promise for the delete call.
 */
const deleteQueueProcess = processId => {
  const path = apiPath(`queue/${processId}`);
  return axios.delete(path);
};

export { getQueue, deleteQueueProcess };

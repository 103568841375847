// Copyright (C) 2022 by Posit Software, PBC.

import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

/*
 * Transforms an object's keys to camel case.
 * @param object
 * @param exclude - An array of strings or regular expressions matching keys that
 *                  will be excluded from transformation.
 * @returns object
 */
export function keysToCamel(object, exclude = [], deep = true) {
  if (object !== Object(object)) {
    // pass back all primitives (undefined, null, boolean, number, string, bigint, symbol)
    return object;
  }
  return camelcaseKeys(object, { exclude, deep });
}

/*
 * Transforms an object's keys to snake case.
 * @param object
 * @param exclude - An array of strings or regular expressions matching keys that
 *                  will be excluded from transformation.
 * @returns object
 */
export function keysToSnake(object, exclude = [], deep = true) {
  if (object !== Object(object)) {
    // pass back all primitives (undefined, null, boolean, number, string, bigint, symbol)
    return object;
  }
  return snakeCaseKeys(object, { exclude, deep });
}

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <OutroPanel
    :learn-more-title="`Introduction to Python APIs in ${systemDisplayName}`"
    learn-more-text="Learn more in the User Guide"
    learn-more-target="user/flask"
  >
    <template #body>
      <p>
        Once your Python API is published, your code can be accessed from
        other systems. You control who can access it and more via its
        settings panels.
      </p>
    </template>
  </OutroPanel>
</template>

<script>
import { mapState } from 'vuex';
import OutroPanel from '../OutroPanel';

export default {
  name: 'PythonAPIOutro',
  components: { OutroPanel },
  computed: {
    ...mapState({
      systemDisplayName: state => state.server.settings.systemDisplayName,
    }),
  },
};
</script>

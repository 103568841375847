<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div
    :class="componentClass"
    :data-automation="dataAutomation"
  >
    <FocusLock>
      <div
        :class="dialogClasses"
        role="dialog"
        :aria-label="subject"
        @click.stop
        @keydown.stop
      >
        <div class="rs-modal__header">
          <div class="rs-modal__header-left">
            <h1
              ref="title"
              class="rs-modal__title"
              tabindex="-1"
            >
              {{ subject }}
            </h1>
            <div
              v-if="hasTopControls"
              class="rs-modal__top-controls"
            >
              <slot name="topControls" />
            </div>
          </div>
          <button
            v-if="closeable"
            :aria-label="closeButtonLabel"
            :tabindex="active ? 0 : -1"
            :disabled="!active"
            class="rs-modal__close"
            @click="$emit('close')"
          />
        </div>
        <section class="rs-modal__content">
          <slot name="content" />
        </section>
        <section
          v-if="hasControls"
          class="rs-modal__actions"
        >
          <slot name="controls" />
        </section>
      </div>
    </FocusLock>
  </div>
</template>

<script>
import FocusLock from 'vue-focus-lock';

const escKeyCode = 27;
const defaultCloseLabel = 'Close modal';
const modalClass = {
  base: 'rs-modal',
  active: 'rs-modal--active',
};
const defaultDialogClass = {
  base: 'rs-modal__dialog',
  medium: 'rs-modal__dialog--medium',
  wide: 'rs-modal__dialog--wide',
};
const dialogWidth = {
  default: 'default',
  medium: 'medium',
  wide: 'wide',
};

export default {
  name: 'RSModal',
  components: {
    FocusLock,
  },
  props: {
    dataAutomation: {
      type: String,
      default: null,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    subject: {
      type: String,
      default: '',
    },
    closeButtonLabel: {
      type: String,
      default: defaultCloseLabel,
    },
    width: {
      type: String,
      default: dialogWidth.default,
    },
    dialogClass: {
      type: String,
      default: null,
    }
  },
  emits: ['close'],
  computed: {
    trapActive() {
      return !this.active;
    },
    hasControls() {
      return Boolean(this.$slots.controls);
    },
    hasTopControls() {
      return Boolean(this.$slots.topControls);
    },
    componentClass() {
      const classMap = [modalClass.base];
      if (this.active) {
        classMap.push(modalClass.active);
      }
      return classMap;
    },
    dialogClasses() {
      const classes = [defaultDialogClass.base];

      if (this.width === dialogWidth.medium) {
        classes.push(defaultDialogClass.medium);
      }

      if (this.width === dialogWidth.wide) {
        classes.push(defaultDialogClass.wide);
      }

      if (this.dialogClass) {
        classes.push(this.dialogClass);
      }

      return classes;
    },
  },
  mounted() {
    document.addEventListener('keydown', this.closeModalOnEsc, true);
    this.$nextTick().then(() => {
      this.$refs.title?.focus();
    });
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.closeModalOnEsc, true);
  },
  methods: {
    closeModalOnEsc(e) {
      if (this.active && e.keyCode === escKeyCode) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-modal {
  @include position-cover-screen;
  background: rgba(0, 0, 0, 0.05);
  opacity: 0;
  overflow-y: auto;
  pointer-events: none;
  transition: opacity 0.2s ease;
  z-index: $rs-overcover-z-element;

  &__dialog {
    @include strong-drop-shadow;
    background: $color-dialog-background;
    border: 1px solid $color-dialog-border;
    box-sizing: border-box;
    margin: 3.7rem auto 0 auto;
    max-width: 34rem;
    padding: 2rem;
    transform: translateY(-2rem);
    transition: transform 0.2s ease;
    width: 100%;

    &--wide {
      max-width: 95%;

      @include for-small-screens() {
        max-width: none;
      }
    }

    &--medium {
      max-width: 75%;

      @include for-small-screens() {
        max-width: none;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    &-left {
      display: flex;
    }
  }

  &__title {
    @include control-visible-focus;

    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    margin-left: -4px;
    padding: 4px;
    word-wrap: break-word;
    line-height: 1.5;
  }

  &__close {
    @include control-visible-focus;

    background: url(/images/elements/close.svg) no-repeat center center;
    border: 0;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    &:hover {
      background-color: $color-light-grey-2;
    }
  }

  &__content {
    font-size: 0.9rem;
    word-wrap: break-word;
  }

  &__top-controls {
    padding-left: 1rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    padding-top: 1rem;

    & > * {
      margin-left: 1rem;
      word-wrap: break-word;
    }

    &--centered {
      justify-content: center;

      & > *:first-child {
        margin-left: 0;
      }
    }
  }

  &--active {
    opacity: 1;
    pointer-events: auto;

    .rs-modal__dialog {
      transform: translateY(0);
    }
  }
}
</style>

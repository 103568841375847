<!-- Copyright (C) 2024 by Posit Software, PBC. -->
<script setup>
import {
  SET_BUNDLES_DISPLAYED_ID,
  TOGGLE_BUNDLES_HISTORY,
} from '@/store/modules/bundles';
import { computed } from 'vue';
import { useStore } from 'vuex';
import BaseHistory, { BUNDLE_HISTORY } from './BaseHistory';

const store = useStore();

const items = computed(() => store.state.bundles.items);
const displayedId = computed(() => store.state.bundles.displayedId);
</script>

<template>
  <BaseHistory
    :type="BUNDLE_HISTORY"
    :items="items"
    :displayed-id="displayedId"
    sort-by="createdTime"
    @display-item="item => store.commit(SET_BUNDLES_DISPLAYED_ID, item.id)"
    @toggle-history="store.commit(TOGGLE_BUNDLES_HISTORY)"
  />
</template>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <OutroPanel
    learn-more-title="Introduction to Shiny"
    learn-more-text="Learn more in the User Guide"
    learn-more-target="user/shiny"
  >
    <template #body>
      <p>
        Once your Shiny App is published, you control who can access it,
        its runtime settings, and much more via its settings panels.
      </p>
    </template>
  </OutroPanel>
</template>

<script>
import OutroPanel from '../OutroPanel';

export default {
  name: 'ShinyAppOutro',
  components: { OutroPanel }
};
</script>

// Copyright (C) 2024 by Posit Software, PBC.

import axios from 'axios';

import { SystemCheckRun, SystemCheckRunResults } from './dto/systemChecks';
import { keysToCamel } from '@/api/transform';
import { apiV1Path } from '@/utils/paths';

export function getSystemCheckRuns() {
  return axios
    .get(apiV1Path('system/checks'))
    .then(({ data }) => {
      return data.map(r => new SystemCheckRun(keysToCamel(r)));
    });
}

export function getSystemCheckRun(id) {
  return axios
    .get(apiV1Path(`system/checks/${encodeURIComponent(id)}`))
    .then(({ data }) => new SystemCheckRun(keysToCamel(data)));
}

export function getSystemCheckRunResults(id) {
  return axios
    .get(apiV1Path(`system/checks/${encodeURIComponent(id)}/results`))
    .then(({ data }) => {
      const run = keysToCamel(data.run);  
      const results = data.results.map(r => keysToCamel(r));
      return new SystemCheckRunResults(run, results);
    });
}

export function runSystemChecks(hostname) {
  const postData = {};
  if (hostname !== undefined) {
    postData.hostname = hostname;
  }

  return axios
    .post(apiV1Path(`system/checks`), postData)
    .then(({ data }) => new SystemCheckRun(keysToCamel(data)));
}

export function deleteSystemCheckRun(id) {
  return axios
    .delete(apiV1Path(`system/checks/${encodeURIComponent(id)}`));
}

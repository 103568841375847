<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <td
    :data-automation="dataAutomation"
    :class="componentClass"
  >
    <a
      v-if="clickable"
      :href="link.href"
      :title="link.title"
      :target="link.target"
      @click.stop="clickHandler"
      @keydown.enter.stop="clickHandler"
    >
      <slot />
    </a>
    <slot v-else />
  </td>
</template>

<script>
const errorMsgRequiredCellId = 'RSTableCell - cell-id prop is required when cell is clickable';
const tableClass = {
  base: 'rs-tablecell',
  clickable: 'rs-tablecell--clickable',
  hasIcon: 'rs-tablecell--icon',
  fill: 'rs-tablecell--fill',
};

export default {
  name: 'RSTableCell',
  props: {
    dataAutomation: {
      type: String,
      default: null,
    },
    cellId: {
      type: [String, Number],
      default: '',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      default: () => ({
        href: '#',
        title: '',
      }),
    },
    hasIcon: {
      // set to true if an icon is contained in the cell - this reduces excessive padding introduced by the icon
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    componentClass() {
      const classMap = [tableClass.base];
      if (this.clickable) {
        classMap.push(tableClass.clickable);
      }
      if (this.hasIcon) {
        classMap.push(tableClass.hasIcon);
      }
      if (this.fill) {
        classMap.push(tableClass.fill);
      }
      return classMap;
    }
  },
  created() {
    if (this.clickable && !this.cellId) {
      throw new Error(errorMsgRequiredCellId);
    }
  },
  methods: {
    clickHandler(e) {
      if (this.link.href === '#') {
        e.preventDefault();
      }
      this.$emit('click', this.cellId, e.currentTarget);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-tablecell {
  @include cell-padding;
  font-size: $rs-font-size-smaller;
  white-space: nowrap;
  vertical-align: middle;

  &--fill {
    width: 100%;
    min-width: 200px;
  }

  &--clickable {
    @include clickable-table-cell($color-light-grey);
    padding: 0.4rem;

    & a {
      @include cell-padding(0.6);
      display: block;

      &:focus {
        @include control-focus();
      }
    }

    & a:hover {
      color: $color-link-hover-cell;
    }
  }

  &--icon {
    @include cell-padding(0.6);
  }
}

.rs-tablecell--clickable.rs-tablecell--icon {
  // override clickable + icon styling
  padding: 0 3px;
}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';

// Set the default XSRF properties for our app
axios.defaults.xsrfCookieName = 'RSC-XSRF';
axios.defaults.xsrfHeaderName = 'X-RSC-XSRF';

// Gets the value for a cookie by name and returns the value if the cookie exists, null otherwise
const getCookieValue = cookieName => {
  const match = document.cookie.match(new RegExp(`(^|;\\s*)(${cookieName})=([^;]*)`));
  return match ? decodeURIComponent(match[3]) : null;
};

// Request interceptor to handle SameSite cookie for incompatible browsers (i.e. Safari 12).
// The XSRF cookie may have been swallowed by the browser if it is not SameSite compatible.
// Finds the `-legacy` version of the XSRF cookie and adds it to the request.
axios.interceptors.request.use(config => {
  const xsrfCookieValue = getCookieValue(`${config.xsrfCookieName}-legacy`);
  if (xsrfCookieValue) {
    config.headers[config.xsrfHeaderName] = xsrfCookieValue;
  }
  return config;
});

// Copyright (C) 2023 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import PythonDependencies from './panels/PythonDependencies';
import PythonDeploy from './panels/PythonDeploy';
import PythonRsconnect from './panels/PythonRsconnect';
import InstallQuarto from './panels/InstallQuarto';
import QuartoOutro from './panels/QuartoOutro';

export default {
  copyFrom: [
    DownloadExample,
    PythonDependencies,
    InstallQuarto,
    PythonRsconnect,
    PythonDeploy,
    QuartoOutro,
  ],
};

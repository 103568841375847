// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiPath, apiExperimentalPath, apiV1Path } from '@/utils/paths';
import { Schedule } from './dto/schedule';
import { keysToSnake, keysToCamel } from './transform';

/**
 * Get a list of the available timezones that can be used to schedule content runs.
 * @returns {Array} The list of available timezones.
 */
export function getTimezones() {
  return axios
    .get(apiV1Path('timezones'))
    .then(({ data }) => {
      const parse = ({ offset, timezone }) => {
        const humanReadable = timezone.replace(/\//g, ' - ').replace(/_/g, ' ');
        return {
          text: `(GMT${offset}) ${humanReadable}`,
          value: timezone,
        };
      };
      return data.map(parse);
    });
}

/**
 * Create a new content scheduled run.
 * @param {Object} createPayload - Object with the schedule data to create the record.
 * @returns {Promise} - The promise of the request.
 */
export function createSchedule(createPayload) {
  return axios
    .post(apiPath(`schedules`), keysToSnake(createPayload))
    .then(({ data }) => new Schedule(keysToCamel(data)));
}

/**
 * Update a content scheduled run.
 * @param {Object} updatePayload - Object with the schedule data to update the record.
 * @returns {Promise} - The promise of the request.
 */
export function updateSchedule(updatePayload) {
  return axios
    .post(apiPath(`schedules/${updatePayload.id}`), keysToSnake(updatePayload))
    .then(({ data }) => new Schedule(keysToCamel(data)));
}

/**
 * Delete a content schedule.
 * @param {Number} scheduleId - The schedule id to be deleted.
 * @returns {Promise} - The promise of the request.
 */
export function deleteSchedule(scheduleId) {
  return axios
    .delete(apiPath(`schedules/${scheduleId}`));
}

/**
 * Get a list of the scheduled runs for a variant.
 * @param {number} variantId - The variant id.
 * @returns {Array} The list of scheduled runs.
 */
export function getVariantSchedules(variantId) {
  return axios
    .get(apiPath(`variants/${variantId}/schedules`))
    .then(({ data }) => data.map(s => new Schedule(keysToCamel(s))));
}

/**
 * Get a list of the scheduled content between the given start and end dates.
 * @param {Object} search - The schedules search parameters.
 * @param {boolean} search.detailed - Get details about the scheduled entries.
 * @param {string} search.start - Start date string (ISO string) to be used in the search.
 * @param {string} search.end - End date string (ISO string) to be used in the search.
 * @returns {Array} The list of the scheduled content entries found.
 */
export function getSchedules({ detailed, start, end }) {
  return axios
    .get(apiExperimentalPath('schedules'), { params: { detailed, start, end } })
    .then(({ data }) => keysToCamel(data));
}

// Copyright (C) 2023 by Posit Software, PBC.

import AppRoles from '@/api/dto/appRole';
import UserRoles from '@/api/dto/userRole';
import { store } from '@/store';
import { CONTENT_LIST_UPDATE_FILTER, FilterType } from '@/store/modules/contentList';
import {
  LOAD_CONTENT_VIEW,
  SET_LOGS_PANEL_VISIBILITY,
  SET_SETTINGS_PANEL_VISIBILITY,
} from '@/store/modules/contentView';
import { CURRENT_USER_LOAD } from '@/store/modules/currentUser';
import { LEGACY_PARAMS_SET_UNSAVED_MODAL } from '@/store/modules/legacyParams';
import { SERVER_SETTINGS_LOAD } from '@/store/modules/server';
import { loginPath } from '@/utils/paths';
import ContentTypes from '@/views/content/contentList/contentType';
import { isEmpty } from 'lodash';

// If the route requires authentication (meta.requireAuth), redirect to login if
// not already authenticated.
export const requireAuthFilter = (to, from, next) => {
  if (!to.meta.requireAuth) {
    next();
    return;
  }

  const { isAuthenticated } = store.state.currentUser;

  if (!isAuthenticated) {
    window.location.href = loginPath({
      redirect: window.location.href,
    });
    next(false);
  } else {
    next();
  }
};

// If the route requires specific role(s)
export const requireRoleFilter = (to, from, next) => {
  if (!to.meta.requireRole) {
    next();
    return;
  }

  const { isAuthenticated, user } = store.state.currentUser;
  const roles = [to.meta.requireRole].flat().map(r => UserRoles.of(r));

  if (!isAuthenticated) {
    window.location.href = loginPath({
      redirect: window.location.href,
    });
    next(false);
  } else if (!roles.includes(user.userRole)) {
    next({ name: 'unauthorized' });
  } else {
    next();
  }
};

export const enabledFilter = (to, from, next) => {
  const { enabled } = to.meta;

  if (enabled === undefined) {
    next();
    return;
  }

  switch (typeof enabled) {
    case 'function':
      if (enabled()) {
        next();
      } else {
        // TODO: 404 more appropriate?
        next(false);
      }
      break;
    default:
      if (enabled) {
        next();
      } else {
        next(false);
      }
  }
};

// Temporary navigation guard to ensure that currentUser and server settings are
// loaded before handling routing. Once we have migrated to Vue entirely, this
// should be dropped as it is ensured the App root.
export const loadUserAndSettingsFilter = (to, from, next) => {
  const { loaded: serverLoaded } = store.state.server;
  const { loaded: currentUserLoaded } = store.state.currentUser;

  if (serverLoaded && currentUserLoaded) {
    next();
    return;
  }

  const serverSettings = store.dispatch(SERVER_SETTINGS_LOAD);
  const currentUser = store.dispatch(CURRENT_USER_LOAD);

  Promise.all([serverSettings, currentUser]).then(() => {
    next();
  });
};

// Handle page titles if provided in meta.title
export const updateTitleFilter = to => {
  const { title } = to.meta;

  if (title) {
    const { systemDisplayName } = store.state.server.settings;
    document.title = `${title} - ${systemDisplayName}`;
  }
};

// NOTE: We handle app routes via id or guid.
// - GUID is desired over id due to security implications,
// - ID is easy to guess since it is a serialized number.
// More details on /store/modules/contentView.
export const resolveContentFilter = (to, from, next) => {
  if (to.meta.resolveContent) {
    const { idOrGuid: appIdOrGuid, id: variantId } = to.params;
    store.dispatch(LOAD_CONTENT_VIEW, { appIdOrGuid, variantId })
      .then(() => {
        const { requiresAuth, app } = store.state.contentView;

        if (requiresAuth) {
          window.location.href = loginPath({
            redirect: window.location.href,
          });
          next(false);
          return;
        }

        // TODO: The logs and settings panel visibility would be better in their own helper
        if (to.name.includes('apps.logs')) {
          store.commit(SET_LOGS_PANEL_VISIBILITY, true);
        }

        if (to.name.includes('apps.') && !to.name.includes('apps.logs') && to.name !== 'apps.variant') {
          store.commit(SET_SETTINGS_PANEL_VISIBILITY, true);
        } else {
          // We can't just set this in the defaultState in store/modules/contentView
          // because that will make the settings disappear after sving a setting and reloading
          // instead, we do it here based on the route
          store.commit(SET_SETTINGS_PANEL_VISIBILITY, false);
        }

        if (app) {
          to.meta.title = `${app.displayName}`;
        }

        if (store.state.currentUser.user.isAdmin() && ['apps', 'apps.variant'].includes(to.name)) {
          store.commit(SET_SETTINGS_PANEL_VISIBILITY, true);
          return next({
            name: `apps.access`,
            params: { idOrGuid: appIdOrGuid },
          });
        }

        if (to.meta.variantRedirectCheck) {
          variantRedirectFilter(to, from, next);
        } else {
          next();
        }
      });
  } else {
    next();
  }
};

export const variantRedirectFilter = (to, from, next) => {
  const { app } = store.state.contentView;

  // No variant redirect check if:
  // - route does not demand it
  // - app did not loaded, is a viewer user,
  //   we don't load variant specifics for viewers
  //   but allow them to go to the content view to request access.
  if (!to.meta.variantRedirectCheck || !app) {
    next();
    return;
  }

  const { currentVariant } = store.state.parameterization;
  const { idOrGuid } = to.params;
  if (app.isRenderable()) {
    next({
      name: `${to.name}.variant`,
      params: { idOrGuid, id: currentVariant.id },
      query: to.query,
    });
  } else {
    next();
  }
};

export const contentListFilter = (to, _from, next) => {
  if (!to.meta.isContentList) {
    next();
    return;
  }

  let minRoleFilter = AppRoles.Viewer;
  let contentFilter = to.meta.defaultContentType || ContentTypes.All;

  if (!isEmpty(to.query.filter)) {
    to.query.filter.forEach(f => {
      const [fname, value] = f.split(':');
      if (fname === 'min_role') {
        minRoleFilter = AppRoles.of(value);
      } else if (fname === 'content_type') {
        contentFilter = value;
      }
    });
  }

  store.commit(CONTENT_LIST_UPDATE_FILTER, {
    type: FilterType.CONTENT_TYPE,
    value: contentFilter,
  });

  store.commit(CONTENT_LIST_UPDATE_FILTER, {
    type: FilterType.VISIBILITY,
    value: minRoleFilter,
  });

  next();
};

export const userCompletionFilter = (to, _from, next) => {
  const user = store.state.currentUser.user;

  if (!user.isAnonymous()) {
    // Make locked users log out
    if (user.locked) {
      window.location.href = store.state.server.settings.logoutUrl;
      return;
    }

    // We direct users through the login page for confirmation and other
    // things. Therefore, we need to let a route change happen if we're
    // trying to go to the login page even if their record is not
    // complete/confirmed. It's not clear why we don't just send them to the
    // right page directly.
    if (to.name !== 'login_view') {
      const authentication = store.state.server.settings.authentication;
      if (user.isUnconfirmed()) {
        if (to.name !== 'confirm_user') {
          return next({
            name: 'confirm_user',
            query: { target: to.fullPath },
          });
        }
      } else if (user.isIncomplete(authentication)) {
        if (to.name !== 'user_completion') {
          return next({
            name: 'user_completion',
            query: { target: to.fullPath },
          });
        }
      }
    }
  }

  next();
};

export const dirtyParamsFilter = (to, _, next) => {
  // If legacy params has changes, we prompt the user to confirm
  // proceed with unsaved changes. After confirming, the navigation will continue.
  const { form: paramsForm } = store.state.legacyParams;
  if (paramsForm.loaded && paramsForm.dirty) {
    store.commit(LEGACY_PARAMS_SET_UNSAVED_MODAL, {
      show: true,
      ignoreCallback: () => next(to),
    });
  } else {
    next();
  }
};

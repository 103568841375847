<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <div
      v-if="!suppressTitle"
      class="flex"
    >
      <h1
        ref="title"
        tabindex="-1"
        class="sectionTitle focusedTitle"
        data-automation="environment-list-title"
      >
        Execution Environments
      </h1>
      <div
        class="controls"
      >
        <div class="actionBar inline showTitles">
          <BaseButton
            v-if="!readonly"
            ref="addEnvironmentButton"
            v-bind="{
              label: 'Add Environment',
              buttonClass: 'action new'
            }"
            title="Add Environment"
            aria-label="Add Environment"
            data-automation="add-environment"
            @clicked="onAddEnvironment"
          />
        </div>
      </div>
    </div>
    <div class="search-container">
      <RSInputSearch
        ref="search"
        v-model="searchString"
        :show-label="false"
        label="Search for environments"
        placeholder="Search for environments..."
        :small="false"
        :show-clear="searchString.length > 0"
        name="environment-search"
        icon="search"
        @change="onSearchChange"
        @clear="clearTextSearch"
      />
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton';
import { useSkipToMainContent } from '@/composables/skipToMainContent';
import RSInputSearch from '@/elements/RSInputSearch';
import { debounce } from '@/utils/debounce';

export default {
  name: 'EnvironmentsListHeader',
  components: {
    RSInputSearch,
    BaseButton,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    suppressTitle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['addEnvironment', 'filter'],
  data() {
    return {
      searchString: '',
    };
  },
  created() {
    useSkipToMainContent(this.onSkipToMainContent);
  },
  mounted() {
    if (!this.suppressTitle) {
      this.$nextTick().then(() => this.$refs.title.focus());
    }
  },
  methods: {
    onSearchChange: debounce(300, function() {
      this.$emit('filter', this.searchString);
    }),
    clearTextSearch() {
      this.searchString = '';
      this.$emit('filter', this.searchString);
    },
    onAddEnvironment() {
      this.$emit('addEnvironment');
    },
    onSkipToMainContent() {
      if (this.$refs.addEnvironmentButton) {
        this.$refs.addEnvironmentButton.focus();
        return;
      }
      this.$refs.search.focus();
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'Styles/shared/_mixins';

.controls {
  display: flex;
  flex: 0 0 3rem;
}
.search-container {
  margin: 0 0 0.3rem 0;
  width: 100%;
}
</style>

<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    class="table"
    :class="{ dialogTable: dialogMode }"
  >
    <div class="table__header">
      <div class="column__blank">
        &nbsp;&nbsp;
      </div>
      <button
        :class="titleColumnClasses"
        data-automation="sort-title"
        @click="onClickSortTitle"
      >
        Title
      </button>
      <button
        :class="pythonColumnClasses"
        data-automation="sort-python"
        @click="onClickSortPython"
      >
        Python
      </button>
      <button
        :class="quartoColumnClasses"
        data-automation="sort-quarto"
        @click="onClickSortQuarto"
      >
        Quarto
      </button>
      <button
        :class="rColumnClasses"
        data-automation="sort-r"
        @click="onClickSortR"
      >
        R
      </button>
      <button
        :class="tensorflowColumnClasses"
        data-automation="sort-tensorflow"
        @click="onClickSortTensorFlow"
      >
        TensorFlow
      </button>
      <div
        v-if="!readonly"
        class="column__action"
        data-automation="action-column"
      >
        &nbsp;
      </div>
    </div>
    <div>
      <!-- eslint-disable vue/no-v-for-template-key -->
      <template
        v-for="environment in environments"
        :key="environment.guid"
      >
        <!-- eslint-enable vue/no-v-for-template-key -->
        <!-- eslint-disable-next-line vue/valid-v-for -->
        <EnvironmentsTableRow
          :environment="environment"
          :active-sort="activeSort"
          :readonly="readonly"
          :dialog-mode="dialogMode"
          :currently-selected-guid="currentlySelectedGuid"
          @edit="onEdit"
          @delete="onDelete"
          @click="onRowClick"
        />
      </template>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import EnvironmentsTableRow from './EnvironmentsTableRow';

const SortDirection = {
  notSorted: 0,
  ascending: 1,
  descending: 2,
};

export default {
  name: 'EnvironmentsTable',
  components: {
    EnvironmentsTableRow,
  },
  props: {
    environments: {
      type: Array,
      default: () => [],
    },
    activeSort: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dialogMode: {
      type: Boolean,
      default: false,
    },
    currentlySelectedGuid: {
      type: String,
      default: '',
    },
  },
  emits: ['click', 'delete', 'edit', 'sort'],
  data() {
    return {
      currentEnvironment: '',
      columnSort: {
        Title: SortDirection.notSorted,
        Python: SortDirection.notSorted,
        Quarto: SortDirection.notSorted,
        R: SortDirection.notSorted,
        TensorFlow: SortDirection.notSorted,
      },
    };
  },
  computed: {
    titleColumnClasses() {
      const classes = ['column__sortable', 'column__title'];

      if (this.columnSort.Title === SortDirection.ascending) {
        classes.push('sort--ascending');
      } else if (this.columnSort.Title === SortDirection.descending) {
        classes.push('sort--descending');
      } else {
        classes.push('sort--none');
      }
      return classes;
    },
    pythonColumnClasses() {
      const classes = ['column__sortable'];

      if (this.columnSort.Python === SortDirection.ascending) {
        classes.push('sort--ascending');
      } else if (this.columnSort.Python === SortDirection.descending) {
        classes.push('sort--descending');
      } else {
        classes.push('sort--none');
      }
      if (this.dialogMode) {
        classes.push('column__python--small');
      } else {
        classes.push('column__python--normal');
      }
      return classes;
    },
    quartoColumnClasses() {
      const classes = ['column__sortable'];

      if (this.columnSort.Quarto === SortDirection.ascending) {
        classes.push('sort--ascending');
      } else if (this.columnSort.Quarto === SortDirection.descending) {
        classes.push('sort--descending');
      } else {
        classes.push('sort--none');
      }
      if (this.dialogMode) {
        classes.push('column__quarto--small');
      } else {
        classes.push('column__quarto--normal');
      }
      return classes;
    },
    rColumnClasses() {
      const classes = ['column__sortable'];

      if (this.columnSort.R === SortDirection.ascending) {
        classes.push('sort--ascending');
      } else if (this.columnSort.R === SortDirection.descending) {
        classes.push('sort--descending');
      } else {
        classes.push('sort--none');
      }
      if (this.dialogMode) {
        classes.push('column__r--small');
      } else {
        classes.push('column__r--normal');
      }
      return classes;
    },
    tensorflowColumnClasses() {
      const classes = ['column__sortable'];

      if (this.columnSort.TensorFlow === SortDirection.ascending) {
        classes.push('sort--ascending');
      } else if (this.columnSort.TensorFlow === SortDirection.descending) {
        classes.push('sort--descending');
      } else {
        classes.push('sort--none');
      }
      if (this.dialogMode) {
        classes.push('column__tensorflow--small');
      } else {
        classes.push('column__tensorflow--normal');
      }
      return classes;
    },
  },
  mounted() {
    this.columnSort[this.activeSort.key] =
      (this.activeSort.direction === 'asc')
        ? SortDirection.ascending
        : SortDirection.descending;
  },
  methods: {
    onClickEdit(environment) {
      this.$emit('edit', environment);
    },
    onClickDelete(environment) {
      this.$emit('delete', environment);
    },
    formatDate(date) {
      if (!date) {
        return '';
      }

      return dayjs(date).format('MMM D, YYYY, h:mm:ss a');
    },
    onEdit(environment) {
      this.$emit('edit', environment);
    },
    onDelete(environment) {
      this.$emit('delete', environment);
    },
    adjustSort(attr) {
      const currValue = this.columnSort[attr];
      Object.keys(this.columnSort).forEach(key => {
        this.columnSort[key] = 0;
      });
      this.columnSort[attr] =
        (currValue === SortDirection.descending)
          ? SortDirection.ascending
          : SortDirection.descending;

      this.$emit('sort', {
        key: attr,
        direction: this.columnSort[attr] === SortDirection.ascending ? 'asc' : 'desc',
      });
    },
    onClickSortTitle() {
      this.adjustSort('Title');
    },
    onClickSortPython() {
      this.adjustSort('Python');
    },
    onClickSortQuarto() {
      this.adjustSort('Quarto');
    },
    onClickSortR() {
      this.adjustSort('R');
    },
    onClickSortTensorFlow() {
      this.adjustSort('TensorFlow');
    },
    onRowClick(environment) {
      if (this.dialogMode) {
        this.$emit('click', environment);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.table {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    background-color: $color-light-grey;
  }
}
.column {
  &__blank {
    flex: 0 0 1rem;
    line-height: 2.9rem;
    background-color: $color-light-grey;
    border-top: 1px solid $color-light-grey-2;
    border-left: 1px solid $color-light-grey-2;
    border-bottom: 1px solid $color-light-grey-2;
    font-size: 0.9rem;
  }
  &__sortable {
    line-height: 2.9rem;
    cursor: pointer;
    border-top: 1px solid $color-light-grey-2;
    border-bottom: 1px solid $color-light-grey-2;
    font-size: 0.9rem;
    margin-right: 2px;
    padding: 0;
    text-align: left;
    @include control-visible-focus;
  }
  &__title {
    flex: 1 0 10rem;
    padding-left: 0.5rem;
  }
  &__python {
    &--normal {
      flex: 0 0 8rem;
      padding-left: 1rem;
    }
    &--small {
      flex: 0 0 6rem;
      padding-left: 1rem;
    }
  }
  &__quarto {
    &--normal {
      flex: 0 0 8rem;
      padding-left: 1rem;
    }
    &--small {
      flex: 0 0 6rem;
      padding-left: 1rem;
    }
  }
  &__r {
    &--normal {
      flex: 0 0 8rem;
      padding-left: 1rem;
    }
    &--small {
      flex: 0 0 6rem;
      padding-left: 1rem;
    }
  }
  &__tensorflow {
    &--normal {
      flex: 0 0 8rem;
      padding-left: 1rem;
    }
    &--small {
      flex: 0 0 6rem;
      padding-left: 1rem;
    }
  }
  &__action {
    flex: 0 0 4rem;
    background-color: $color-light-grey;
    border-top: 1px solid $color-light-grey-2;
    border-right: 1px solid $color-light-grey-2;
    border-bottom: 1px solid $color-light-grey-2;
    font-size: 0.9rem;
    padding: 1rem;
  }
}
.sort {
  &--ascending {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMCAzMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzAgMzA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojN0I3QjdCO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPGxpbmUgY2xhc3M9InN0MCIgeDE9IjguNSIgeTE9IjE3LjUiIHgyPSIyMS41IiB5Mj0iMTcuNSIvPgo8bGluZSBjbGFzcz0ic3QwIiB4MT0iMTAuNSIgeTE9IjE1LjUiIHgyPSIxOS41IiB5Mj0iMTUuNSIvPgo8bGluZSBjbGFzcz0ic3QwIiB4MT0iMTIuNSIgeTE9IjEzLjUiIHgyPSIxNy41IiB5Mj0iMTMuNSIvPgo8bGluZSBjbGFzcz0ic3QwIiB4MT0iMTQuNSIgeTE9IjExLjUiIHgyPSIxNS41IiB5Mj0iMTEuNSIvPgo8L3N2Zz4K);
    background-position-x: right;
    background-position-y: 50%;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-color: $color-light-grey;

    &:hover {
      background-color: $color-light-grey-2;
    }
  }
  &--descending {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMCAzMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzAgMzA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojN0I3QjdCO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPGxpbmUgY2xhc3M9InN0MCIgeDE9IjIxLjUiIHkxPSIxMS41IiB4Mj0iOC41IiB5Mj0iMTEuNSIvPgo8bGluZSBjbGFzcz0ic3QwIiB4MT0iMTkuNSIgeTE9IjEzLjUiIHgyPSIxMC41IiB5Mj0iMTMuNSIvPgo8bGluZSBjbGFzcz0ic3QwIiB4MT0iMTcuNSIgeTE9IjE1LjUiIHgyPSIxMi41IiB5Mj0iMTUuNSIvPgo8bGluZSBjbGFzcz0ic3QwIiB4MT0iMTUuNSIgeTE9IjE3LjUiIHgyPSIxNC41IiB5Mj0iMTcuNSIvPgo8L3N2Zz4K);
    background-position-x: right;
    background-position-y: 50%;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-color: $color-light-grey;

    &:hover {
      background-color: $color-light-grey-2;
    }
  }

  &--none {
    background-color: $color-light-grey;

    &:hover {
      background-color: $color-light-grey-2;
    }
  }
}
.dialogTable {
  max-height: 400px;
  overflow-y: scroll;
  .table__header {
    margin-top: 2px;
  }
}
</style>

<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<script setup>
import {
  DaysOfWeek,
  OrdinalMonthWeeks,
  Schedule,
  ScheduleTypes,
  SemiMonthOptions,
} from '@/api/dto/schedule';
import { updateVariant } from '@/api/parameterization';
import {
  createSchedule,
  deleteSchedule,
  getTimezones,
  getVariantSchedules,
  updateSchedule,
} from '@/api/scheduledContent';
import {
  getSubscribers,
  removeSubscriber,
  subscribeUser,
} from '@/api/subscriptions';
import DateTimeInput from '@/components/DateTimeInput';
import MessageBox from '@/components/MessageBox.vue';
import DailySchedule from '@/components/Schedule/DailySchedule';
import IntervalScheduleInput from '@/components/Schedule/IntervalScheduleInput';
import MonthlySchedule from '@/components/Schedule/MonthlySchedule';
import SemimonthlySchedule from '@/components/Schedule/SemimonthlySchedule';
import WeeklySchedule from '@/components/Schedule/WeeklySchedule';
import TimezoneSelector from '@/components/TimezoneSelector';
import RSInputCheckbox from '@/elements/RSInputCheckbox';
import RSInputSelect from '@/elements/RSInputSelect';
import {
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import pluralize from '@/utils/pluralize';
import { browserTimezone } from '@/utils/timezone';
import ConfirmationPanel from '@/views/content/settings/ConfirmationPanel';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { computed, onBeforeMount, reactive, watch } from 'vue';
import { RouterLink } from 'vue-router';
import { useStore } from 'vuex';
import ScheduleEmail from './ScheduleEmail';

const store = useStore();

const defaultSchedType = ScheduleTypes.Day;
const simpleIntervalTypes = [
  ScheduleTypes.Minute,
  ScheduleTypes.Hour,
  ScheduleTypes.Year,
];

// Create the schedule type options here, since those won't change
// and there's no need to have them reactive. (Leaner reset of data too)
const schedOptions = [
  { label: 'By Minute', value: ScheduleTypes.Minute },
  { label: 'Hourly', value: ScheduleTypes.Hour },
  { label: 'Daily', value: ScheduleTypes.Day },
  { label: 'Weekly', value: ScheduleTypes.Week },
  { label: 'Semimonthly', value: ScheduleTypes.SemiMonth },
  { label: 'Monthly', value: ScheduleTypes.DayOfMonth },
  { label: 'Yearly', value: ScheduleTypes.Year },
];

const initialData = () => ({
  currentSchedule: null,
  email: {
    all: false,
    collabs: true, // When enabling email, we want collabs to be set by default.
    viewers: false,
    additionalRecipients: [],
  },
  initialized: false,
  invalid: false,
  isDirty: false,
  isSaving: false,
  nextRun: null,
  publishOutput: true,
  scheduleEnabled: false,
  sendEmail: false,
  startTime: dayjs(),
  schedInterval: 1,
  // The difference between schedTypeCategory and schedTypeSelected
  // is that the first one tracks the category selected in the dropdown
  // and the later tracks the actual schedule type to use.
  // This because there are more than one schedule type values for some of this.
  // E.g: By Week has "every X weeks" but also "every x-y-z days of each week".
  schedTypeCategory: defaultSchedType,
  schedTypeSelected: defaultSchedType,
  specificOptions: {
    day: 1,
    days: [],
    week: 1,
    semimonth: SemiMonthOptions.First,
  },
  timezone: {
    selected: null,
    list: [],
  },
});

const localState = reactive(initialData());

const app = computed(() => store.state.contentView.app);
const currentUser = computed(() => store.state.currentUser.user);
const serverSettings = computed(() => store.state.server.settings);
const currentVariant = computed(() => store.state.parameterization.currentVariant);
const variants = computed(() => store.state.parameterization.variants);
const canSchedule = computed(() =>
  currentUser.value.canScheduleVariant(app.value));
const checkboxVariantName = computed(() => currentVariant.value.name === 'default' ? '' : currentVariant.value.name);
const appAccessList = computed(() => app.value.users || []);
const appAccessGroups = computed(() => app.value.groups || []);
const isSimpleInterval = computed(() =>
  simpleIntervalTypes.includes(localState.schedTypeCategory));
const isDaily = computed(
  () => localState.schedTypeCategory === ScheduleTypes.Day
);
const isWeekly = computed(
  () => localState.schedTypeCategory === ScheduleTypes.Week
);
const isSemimonthly = computed(
  () => localState.schedTypeCategory === ScheduleTypes.SemiMonth
);
const isMonthly = computed(
  () => localState.schedTypeCategory === ScheduleTypes.DayOfMonth
);
const intervalTerm = computed(() => {
  const intervals = {
    minute: 'minute',
    hour: 'hour',
    day: 'day',
    week: 'week',
    dayofmonth: 'month',
    year: 'year',
  };

  return pluralize(
    localState.schedInterval,
    intervals[localState.schedTypeCategory],
    `${intervals[localState.schedTypeCategory]}s`
  );
});
const enableConfirmation = computed(
  () => localState.isDirty && !localState.isSaving && !localState.invalid
);
const manageSubscriptionsLabel = computed(() =>
  pluralize(
    variants.value.length,
    'Manage email subscription',
    'Manage email subscriptions'
  ));
const showConfirmation = computed(() => {
  // We show confirmation panel when...
  // A) There is an existing schedule but the user disables scheduling. (scheduleEnabled = false)
  if (disabledCurrentSchedule.value) {
    return true;
  }

  // B) There was no current schedule and it is now enabled
  // C) When there is an existing schedule and any input changes.
  // ^ both cases above can be handled with isDirty
  return localState.isDirty;
});
const disabledCurrentSchedule = computed(
  () => localState.currentSchedule && !localState.scheduleEnabled
);

const reset = () => {
  Object.assign(localState, initialData());
  init();
};

watch(() => currentVariant.value?.id, reset);

onBeforeMount(() => {
  init();
});

const setErrorMessageFromAPI = error => {
  store.commit(SET_ERROR_MESSAGE_FROM_API, error);
};

const setInfoMessage = message => {
  store.dispatch(SHOW_INFO_MESSAGE, message);
};

const init = () => {
  getVariantSchedules(currentVariant.value.id)
    .then(currentSched => {
      if (currentSched.length) {
        localState.currentSchedule = currentSched[0];
        localState.scheduleEnabled = true;
        return loadScheduleForm();
      }
    })
    .catch(setErrorMessageFromAPI);
};

const loadScheduleForm = () => {
  return Promise.all([getTimezones(), getSubscribers(currentVariant.value.id)])
    .then(([tzList, subs]) => {
      localState.timezone.list = tzList;
      localState.originalSubs = [...subs.subscriptions];
      resolveTimezone();
      reflectCurrentSchedule(subs.subscriptions);
      localState.initialized = true;
    })
    .catch(setErrorMessageFromAPI);
};

const reflectCurrentSchedule = subs => {
  if (!localState.currentSchedule) {
    // Allow saving automatically if there's no existing schedule
    // we should allow immediate saving with the default values in this case.
    localState.isDirty = true;
    return;
  }

  let schedJson;
  const { email, type, activate, timezone, startTime, nextRun } =
    localState.currentSchedule;

  updateTimezone(timezone, false);
  // The start time comes as ISO string (UTC) from the backend,
  // but we must handle dates here as if they were local dates,
  // remove the "Z" so dayjs picks it up as a local date.
  localState.startTime = forceLocalAsISO(startTime);
  localState.nextRun = forceLocalAsISO(nextRun);
  localState.publishOutput = activate;
  localState.schedTypeCategory = ScheduleTypes.categoryOf(type);
  localState.schedTypeSelected = type;

  try {
    schedJson = JSON.parse(localState.currentSchedule.schedule);
  } catch {
    schedJson = {};
  }

  if (schedJson.N) {
    localState.schedInterval = schedJson.N;
  }

  localState.specificOptions.day = schedJson.Day || 1;
  localState.specificOptions.days = schedJson.Days ? [...schedJson.Days] : [];
  localState.specificOptions.week = schedJson.Week || 1;
  localState.specificOptions.semimonth = schedJson.First
    ? SemiMonthOptions.First
    : SemiMonthOptions.Last;

  if (email) {
    updateEmailRecipients(
      {
        sendEmail: email,
        collaborators: currentVariant.value.emailCollaborators,
        viewers: currentVariant.value.emailViewers,
        mailAll: currentVariant.value.emailAll,
        additionalRecipients: subs,
      },
      false
    );
  }
};

const scheduleFlagChange = show => {
  if (show) {
    // Load data and form
    loadScheduleForm();
  } else if (!localState.currentSchedule) {
    // If no current schedule and disabled,
    // no need to consider it dirty.
    localState.isDirty = false;
  } else {
    localState.isDirty = true;
  }
};

const resetSpecifics = () => {
  localState.schedInterval = 1;
  localState.specificOptions.day = 1;
  localState.specificOptions.days = [];
  localState.specificOptions.week = 1;
  localState.specificOptions.semimonth = SemiMonthOptions.First;
};

const resetStartTime = () => {
  localState.startTime = dayjs();
  resolveTimezone();
  localState.isDirty = true;
};

const tzFind = tz => localState.timezone.list.find(i => i.value === tz);

const resolveTimezone = () => {
  const localTimezone = tzFind(browserTimezone());
  const defaultTimezone = tzFind('UTC');
  localState.timezone.selected = localTimezone || defaultTimezone;
};
const updateTimezone = (value, dirty = true) => {
  localState.isDirty = dirty;
  localState.timezone.selected = tzFind(value);
};
const updateStartTime = date => {
  localState.isDirty = true;
  localState.startTime = date;
};
const updateSchedSelected = value => {
  localState.invalid = false;
  localState.isDirty = true;
  resetSpecifics();
  localState.schedTypeSelected = value;
  localState.schedTypeCategory = ScheduleTypes.categoryOf(value);
};

const updateSingleInterval = interval => {
  localState.isDirty = true;
  localState.schedInterval = interval;
};

const updateScheduleValues = ({
  type,
  interval,
  days,
  nday,
  nthweek,
  weekday,
}) => {
  localState.invalid = false;
  localState.isDirty = true;
  localState.schedTypeSelected = type;
  localState.schedInterval = interval;

  if (type === ScheduleTypes.DayOfWeek) {
    localState.specificOptions.days = [...days];
  }

  if (type === ScheduleTypes.DayOfMonth) {
    localState.specificOptions.day = nday;
  }

  if (type === ScheduleTypes.DayweekOfMonth) {
    localState.specificOptions.day = DaysOfWeek.valueOf(weekday);
    localState.specificOptions.week = OrdinalMonthWeeks.valueOf(nthweek);
  }
};

const updateSemimonthSet = value => {
  localState.isDirty = true;
  localState.specificOptions.semimonth = value;
};

const updateEmailRecipients = (
  { sendEmail, collaborators, viewers, mailAll, additionalRecipients },
  dirty = true
) => {
  localState.isDirty = dirty;
  localState.sendEmail = sendEmail;
  localState.email.all = mailAll;
  localState.email.collabs = collaborators;
  localState.email.viewers = mailAll ? false : viewers;
  localState.email.additionalRecipients = additionalRecipients;
};

const invalidWeeklySet = () => {
  // This only happens when no day is choosen for weekly - weekdays
  localState.invalid = true;
};

const handleSubscriptions = () => {
  const newSubs = localState.email.additionalRecipients.map(sub => sub.guid);
  const removeSubs = localState.originalSubs.reduce((toRemove, sub) => {
    const subscIndex = newSubs.indexOf(sub.guid);
    if (subscIndex === -1) {
      // Subscriber not in the list anymore, pull it in to be removed.
      toRemove.push(sub.guid);
    } else {
      // Subscriber still in the list, ignore it as it is not new.
      newSubs.splice(subscIndex, 1);
    }
    return toRemove;
  }, []);

  return [
    // Requests to subscribe users
    ...newSubs.map(sub => subscribeUser(currentVariant.value.id, sub)),
    // Requests to un-subscribe users
    ...removeSubs.map(sub => removeSubscriber(currentVariant.value.id, sub)),
  ];
};
const save = () => {
  if (disabledCurrentSchedule.value) {
    // Delete the current schedule due to the user disabling it.
    const { id } = localState.currentSchedule;
    return deleteSchedule(id)
      .then(() => {
        localState.isDirty = false;
        localState.currentSchedule = null;
        setInfoMessage({ message: 'Schedule saved successfully.' });
      })
      .catch(setErrorMessageFromAPI);
  }

  const allRequests = [];
  const schedule = new Schedule({
    appId: app.value.id,
    variantId: currentVariant.value.id,
    activate: localState.publishOutput,
    email: localState.sendEmail,
    type: localState.schedTypeSelected,
    timezone: localState.timezone.selected.value,
    startTime: forceISOAsLocal(localState.startTime),
    nextRun: forceISOAsLocal(localState.nextRun || localState.startTime),
  });
  schedule.setSpecifics({
    interval: localState.schedInterval,
    ...localState.specificOptions,
  });

  if (localState.sendEmail) {
    const variantClone = cloneDeep(currentVariant.value);
    variantClone.setEmailSettings(localState.email);
    allRequests.push(updateVariant(variantClone.toJSON()));
    allRequests.push(...handleSubscriptions());
  }

  if (localState.currentSchedule) {
    // If current schedule, update it
    schedule.id = localState.currentSchedule.id;
    allRequests.push(updateSchedule(schedule.toJSON()));
  } else {
    // else, create a new one
    allRequests.push(createSchedule(schedule.toJSON()));
  }

  return Promise.all(allRequests)
    .then(responses => {
      // The last request in the list is the update/create schedule
      const newSchedule = responses.pop();
      localState.isDirty = false;
      localState.currentSchedule = newSchedule;
      setInfoMessage({ message: 'Schedule saved successfully.' });
    })
    .catch(setErrorMessageFromAPI);
};
// Method to create forced strings of local dates as ISO strings
// ignoring the timezone or local offset.
// The datetime the user sees in the UI,
// is what is sent to the database but forced as UTC string.
// E.g '2023-10-30T09:30:20 GMT -0400' -> '2023-10-30T09:30:20Z'
const forceISOAsLocal = d => `${d.format('YYYY-MM-DDTHH:mm:ss')}Z`;

// Method to create a forced dayjs instance from a date
// which comes as ISO string (UTC) from the backend,
// but we must handle dates here as if they were local dates,
// remove the "Z" so dayjs picks it up as a local date.
const forceLocalAsISO = d => dayjs(d.toISOString().slice(0, -1));
</script>

<template>
  <div>
    <ConfirmationPanel
      :enabled="enableConfirmation"
      :visible="showConfirmation"
      @save="save"
      @discard="reset"
    />

    <MessageBox
      v-if="app.locked"
      alert
      small
      data-automation="content-locked-schedule-warning"
    >
      This content is locked. Locked content cannot be run on a schedule. Any
      changes made to the Schedule settings will have no effect.
    </MessageBox>

    <RSInputCheckbox
      v-model="localState.scheduleEnabled"
      name="enable-schedule-output"
      data-automation="schedule__output-checkbox"
      :disabled="!canSchedule"
      @change="scheduleFlagChange"
    >
      Render <span
        v-if="checkboxVariantName"
        class="emphasize"
      >{{ checkboxVariantName }}</span> on a schedule
    </RSInputCheckbox>

    <div
      v-if="localState.scheduleEnabled"
      data-automation="schedule__full-form"
    >
      <div v-if="!localState.initialized">
        Loading...
      </div>
      <div v-else>
        <div class="formSection">
          <!-- Timezone -->
          <div
            v-if="localState.timezone.list.length > 0"
            class="rs-field"
            data-automation="schedule__timezone"
          >
            <span class="rs-field__help-label">Timezone</span>
            <TimezoneSelector
              :selected="localState.timezone.selected"
              :options="localState.timezone.list"
              :disabled="!canSchedule"
              @change="updateTimezone"
            />
          </div>

          <!-- Start date/time -->
          <div
            class="rs-field vertical-space"
            data-automation="schedule__datetime"
          >
            <span class="rs-field__help-label">Start date & time</span>
            <DateTimeInput
              :date="localState.startTime"
              :disabled="!canSchedule"
              @change="updateStartTime"
            />
          </div>

          <div
            v-if="canSchedule"
            class="reset-time vertical-space"
          >
            <button
              class="link-like-button"
              @click="resetStartTime"
            >
              Reset to local time
            </button>
          </div>
        </div>

        <div class="formSection">
          <RSInputSelect
            v-model="localState.schedTypeCategory"
            label="Schedule type"
            :options="schedOptions"
            :disabled="!canSchedule"
            data-automation="schedule__frequency"
            name="schedule-type"
            @change="updateSchedSelected"
          />

          <!-- By Minute, Hours, Years -->
          <IntervalScheduleInput
            v-if="isSimpleInterval"
            v-model="localState.schedInterval"
            :term="intervalTerm"
            :disabled="!canSchedule"
            @change="updateSingleInterval"
          />

          <DailySchedule
            v-if="isDaily"
            :type="localState.schedTypeSelected"
            :interval="localState.schedInterval"
            :disabled="!canSchedule"
            @change="updateScheduleValues"
          />

          <WeeklySchedule
            v-if="isWeekly"
            :type="localState.schedTypeSelected"
            :interval="localState.schedInterval"
            :days="localState.specificOptions.days"
            :disabled="!canSchedule"
            @change="updateScheduleValues"
            @invalid="invalidWeeklySet"
          />

          <SemimonthlySchedule
            v-if="isSemimonthly"
            :value="localState.specificOptions.semimonth"
            :disabled="!canSchedule"
            @change="updateSemimonthSet"
          />

          <MonthlySchedule
            v-if="isMonthly"
            :type="localState.schedTypeSelected"
            :interval="localState.schedInterval"
            :monthday="localState.specificOptions.day"
            :nthweek="localState.specificOptions.week"
            :weekday="localState.specificOptions.day"
            :disabled="!canSchedule"
            @change="updateScheduleValues"
          />
        </div>

        <!-- Publish output -->
        <div class="formSection">
          <RSInputCheckbox
            v-model="localState.publishOutput"
            name="publication-enabled"
            data-automation="schedule__publish-output"
            :disabled="!canSchedule"
            label="Publish output after it is generated"
          />
        </div>

        <ScheduleEmail
          :enabled="localState.sendEmail"
          :app="app"
          :current-user="currentUser"
          :server-settings="serverSettings"
          :access-list="appAccessList"
          :access-groups="appAccessGroups"
          :all="localState.email.all"
          :collabs="localState.email.collabs"
          :viewers="localState.email.viewers"
          :subscribers="localState.email.additionalRecipients"
          @change="updateEmailRecipients"
        />
      </div>

      <RouterLink
        :to="{ name: 'apps.subscriptions' }"
        class="manage-subscriptions-link"
        data-automation="manage-subscriptions-link"
      >
        {{ manageSubscriptionsLabel }}
      </RouterLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
.reset-time {
  text-align: right;
}

input.interval-check[type='radio'] {
  vertical-align: middle;
}

.manage-subscriptions-link {
  display: block;
  margin-top: 1rem;
  font-size: 0.9rem;
}
</style>

// Copyright (C) 2024 by Posit Software, PBC.

import { isEmpty } from 'lodash';
import { watch, reactive, toRefs, unref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { searchContent } from '@/api/content';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';

export const defaultQuery = 'is:published';

/**
 * @typedef {Object} ComposeContentSearch
 * @property {String} query - Reactive Ref string of the query used to search content items.
 * @property {String} lastQueryUsed - Reactive Ref string of the last query used to search content items.
 * @property {String} sortBy - Reactive Ref string of the sort instruction used to search.
 * @property {String} order - Reactive Ref string of the order instruction used to search.
 * @property {Number} page - Reactive Ref integer of the page used to search, undefined is equivalent to page 1.
 * @property {Boolean} isFetching - Reactive Ref boolean indicating if there is a search request in progress.
 * @property {Function} search - The search function that retreives content items from the server.
 */

/**
 * Composable that handles searching content items via reactive "query", "sortBy" and "order" Refs.
 * @example
 * const { query, sortBy, order, page, search } = useContentSearch(onResults);
 *
 * // Below reactive refs can be used as the v-model of components.
 * query.value = '2020 reports owner:hisaishi';
 * sortBy.value = 'created';
 * order.value = 'asc';
 *
 * // Search is triggered with above values
 * search();
 *
 * // Browser's URL query params are updated automatically after search too
 * // ?q=2020+reports+owner:hisaishi&sort=created&order=asc
 *
 * @example
 * // Initial values are picked from URL if applicable
 * // E.g: ?q=fastest+cars+2023&sort=created&order=asc&page=3
 * const { query, sortBy, order, page, search } = useContentSearch(onResults);
 * // Refs values are automatically picked up to:
 * // query.value === 'fastest cars 2023';
 * // sortBy.value === 'created';
 * // order.value === 'asc';
 * // page.value === 3;
 *
 * The initial value of "query" is pulled from the route "?q=" query parameter.
 * @param {Function} onResults Callback function to be run when search results are fetched.
 * @returns {ComposeContentSearch} search function and the reactive Refs query and isFetching
 */
export const useContentSearch = ({
  onResults = () => {},
} = {}) => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const routeQueryChangeSubscriptions = [];
  const subscribeToRouteQueryChange = sub => routeQueryChangeSubscriptions.push(sub);

  const state = reactive({
    query: undefined,
    lastQueryUsed: '',
    sortBy: undefined,
    order: undefined,
    page: undefined,
    perPage: 10, // This is static, but may change in the future
    isFetching: false,
    syncLockURL: false,
  });

  const syncQueryToURL = () => {
    // Prevent URL watcher to trigger a second search request
    state.syncLockURL = true;

    const query = {};
    if (state.query !== undefined) {
      query.q = state.query;
    }
    if (state.sortBy) {
      query.sort = state.sortBy;
      query.order = state.order;
    }
    if (state.page) {
      query.page = state.page;
    }
    router.push({ query });
  };

  const syncURLToStateQuery = routeQuery => {
    state.query = routeQuery.q === undefined ? defaultQuery : routeQuery.q; // An empty string for the search query is valid
    state.sortBy = routeQuery.sort;
    state.order = routeQuery.order;
    state.page = routeQuery.page ? parseInt(routeQuery.page, 10) : undefined; // Leave it undefined, as "no page" === "page 1"
  };

  const searchFn = async() => {
    if (state.isFetching) {
      return;
    }
    state.isFetching = true;

    try {
      const results = await searchContent({
        query: state.query,
        sortBy: state.sortBy,
        order: state.order,
        page: state.page,
        perPage: state.perPage,
      });
      onResults(results);
    } catch (err) {
      store.commit(SET_ERROR_MESSAGE_FROM_API, err);
    }

    state.lastQueryUsed = unref(state.query);
    state.isFetching = false;
  };

  watch(
    () => route.query,
    params => {
      // If route changed to an empty root. Sync default query to it.
      if (isEmpty(route.query)) {
        router.push({ query: { q: defaultQuery } });
      }

      // No need to propagate to URL if it is already fetching data.
      // If there is a lock, it means search was triggered from a search input update,
      // propagation to the URL will take place and syncing from the URL here is pointless.
      if (state.syncLockURL) {
        state.syncLockURL = false;
        return;
      }
      syncURLToStateQuery(params);
      searchFn();
      routeQueryChangeSubscriptions.forEach(sub => sub());
    },
  );

  // Initial sync between URL and search
  if (isEmpty(route.query)) {
    state.query = defaultQuery;
    router.push({ query: { q: defaultQuery } });
  } else {
    syncURLToStateQuery(route.query);
  }
  searchFn();

  return {
    search: () => {
      searchFn();
      syncQueryToURL();
    },
    subscribeToRouteQueryChange,
    ...toRefs(state),
  };
};

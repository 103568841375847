<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <p
      class="rsc-lead"
      data-automation="no-assigned-tags-message"
    >
      No tags can be assigned because none have been created yet.
    </p>
    <p
      v-if="isAdmin"
      data-automation="create-tags-message"
    >
      To create some tags, please use the
      <RouterLink
        data-automation="tag-editor-link"
        :to="{ name: 'admin.tag_editor' }"
      >
        Tag Editor
      </RouterLink>.
      Only an administrator can create tags.
    </p>
    <p v-else>
      Contact an administrator for more information.
    </p>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  name: 'EmptyTagsMessage',
  components: {
    RouterLink
  },
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

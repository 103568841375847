// Copyright (C) 2024 by Posit Software, PBC.

import { serverURL } from '@/utils/paths';

export class Share {
  constructor({
    contentGuid,
    token,
  } = {}) {
    this.token = token;
    this.contentGuid = contentGuid;
  }

  url() {
    return serverURL(`/share/${this.token}`);
  }
}

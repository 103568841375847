<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="band">
    <div class="bandContent mainPage">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppContainer',
};
</script>


<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Common option panel functionality handles dynamic sizing of options window
  according to its content and the size of the overall browser window.
-->
<template>
  <div
    ref="optionsPanel"
    :style="style"
    class="optionsPanel"
  >
    <slot />
  </div>
</template>

<script>
import { debounce } from '@/utils/debounce';

export default {
  name: 'OptionsPanel',
  data() {
    return {
      lastOptionsPanelVisibility: false,
      maxHeight: 0,
    };
  },
  computed: {
    style() {
      // Update our style dynamically
      return { maxHeight: `${this.maxHeight}px` };
    },
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    this.destroy();
  },
  updated() {
    this.update();
  },
  methods: {
    debouncedUpdateMaxHeight: debounce(300, function() {
      return this.updateMaxHeight();
    }),
    destroy() {
      window.removeEventListener('resize', this.debouncedUpdateMaxHeight);
    },
    hasVisibilityChanged() {
      if (this.lastOptionsPanelVisibility !== this.isVisible()) {
        this.lastOptionsPanelVisibility = this.isVisible();
        return true;
      }
      return false;
    },
    init() {
      window.addEventListener('resize', this.debouncedUpdateMaxHeight);
      this.debouncedUpdateMaxHeight();
    },
    isVisible() {
      // This is guaranteed to be correct reflection of visiblity
      if (this.optionsPanelRef()) {
        return this.optionsPanelRef().offsetHeight > 0;
      }
      return false;
    },
    optionsPanelRef() {
      return this.$refs.optionsPanel;
    },
    update() {
      if (this.hasVisibilityChanged()) {
        this.debouncedUpdateMaxHeight();
      }
    },
    updateMaxHeight() {
      let result = 0;
      if (this.optionsPanelRef()) {
        const t = this.optionsPanelRef().getBoundingClientRect().top;
        // window.innerHeight is not a reactive data element
        // so we need to handle reactivity a little differently.
        result = this.windowInnerHeight() - (t ? t : 0) - 10;
      }
      if (result !== this.maxHeight) {
        // assign and cause reactive data chain updates.
        this.maxHeight = result;
      }
    },
    windowInnerHeight() {
      return window.innerHeight;
    },
  },
};
</script>

<style lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

.optionsPanel {
  background-color: $color-white;
  float: right;
  height: calc(100vh - 50px); // 50px = height of main menu
  position: relative;
  width: $minor-column-width;
  min-width: $min-minor-column-width;
  overflow: auto;

  @media only screen and (min-width: 1024px) {
    padding: 0 1rem;
    width: 27%; // The width of rsc-content-list__main is set to 73% and 100 - 73 = 27
  }

  .section {
    margin-top: 20px;
  }
}
  .rsc-options-panel {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.25rem 0 1.35rem 0;

      &-title {
        font-size: 1.25rem;
        font-weight: 300;
        margin: 0;
      }
    }

    &__input-group .rs-field:not(:last-of-type) {
      margin-bottom: 1.8rem;
    }
  }
</style>

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { copyToClipboard } from '@/utils/clipboard';
import copyImage from 'Images/copy.svg';
import checkImage from 'Images/elements/check.svg';
import { ref } from 'vue';

defineProps({
  copyText: {
    type: String,
    required: true,
  },
});

const isCopied = ref(false);
const triggerTarget = ref(null);

const onCopy = async text => {
  await copyToClipboard({
    triggerTarget: triggerTarget.value,
    value: text,
  });

  isCopied.value = true;
  setTimeout(() => {
    isCopied.value = false;
  }, 2_000);
};
</script>

<template>
  <div
    ref="triggerTarget"
    class="copy-to-clipboard"
  >
    <img
      :alt="isCopied ? 'Copied!' : 'Copy to clipboard'"
      :src="isCopied ? checkImage : copyImage"
      class="copy-to-clipboard__image"
      data-automation="copy-icon-button"
      role="button"
      tabindex="0"
      @click="onCopy(copyText)"
      @keydown.space="onCopy(copyText)"
    >
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.copy-to-clipboard {
  display: flex;
  margin-left: 0.1rem;

  &__image {
    @include control-visible-focus;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 2px;
    padding: 4px;
    width: 22px;

    &:hover {
      background: $color-light-grey-3;
    }
  }
}
</style>

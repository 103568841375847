<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    aria-label="Delete"
    title="Delete"
    class="action delete content-actions__delete-button"
    data-automation="menuitem-delete--dropdown"
    @click="$emit('deleteModal')"
  >
    <span class="actionTitle">Delete</span>
  </button>
</template>

<script>
export default {
  name: 'DeleteButton',
  emits: ['deleteModal']
};
</script>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    :class="{ active }"
    class="time-picker rsc-time-picker"
  >
    <input
      ref="input"
      v-model="text"
      v-focus-if="focus"
      :aria-label="type"
      type="text"
      class="time-picker__input"
      :disabled="disabled"
      @input="setDirty"
      @focus="activate"
      @blur="deactivate"
    >
    <div class="time-picker__clock">
      <div class="time-picker__dial">
        <div class="time-picker__center" />
        <div
          :style="handRotation"
          class="time-picker__hand"
        />
        <!-- eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
        <span
          v-for="(label, index) in labels"
          :key="index"
          role="button"
          tabindex="0"
          :class="labelClasses(label)"
          class="time-picker__label"
          @mousedown.prevent="update(label)"
        >
          {{ label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { FocusIf } from '@/utils/focus';

export default {
  name: 'TimePicker',
  directives: {
    'focus-if': FocusIf
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: true,
      validator: function(value) {
        return ['hour', 'minute', 'second'].includes(value);
      }
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['change', 'focus'],
  data() {
    return {
      active: false,
      dirty: false,
      text: null
    };
  },
  computed: {
    labels() {
      switch (this.type) {
        case 'hour':
          return [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        default:
          return [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
      }
    },
    max() {
      switch (this.type) {
        case 'hour':
          return 12;
        default:
          return 60;
      }
    },
    handRotation() {
      let angle;

      if (!this.value) {
        angle = 0;
      } else {
        angle = ((this.value / this.max) * 360).toFixed(0);
      }

      return { transform: `rotate(${angle}deg)` };
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function() {
        this.text = this.formatValue(this.value);
      }
    }
  },
  methods: {
    activate() {
      this.$refs.input.select();
      this.active = true;
      this.$emit('focus', this.type);
    },
    deactivate() {
      if (this.dirty) {
        const newValue = this.parseValue(this.text);

        if (this.isValid(newValue)) {
          this.update(newValue);
        } else {
          this.text = this.formatValue(this.value);
        }
      }

      this.active = false;
      this.dirty = false;
    },
    isValid(value) {
      if (!Number.isInteger(value)) {
        return false;
      }

      if (this.type === 'hour') {
        return value >= 1 && value <= 12;
      }

      return value >= 0 && value <= 59;
    },
    parseValue(value) {
      return parseInt(value, 10);
    },
    formatValue(value) {
      if (value === null) {
        return '';
      }

      if (this.type === 'hour') {
        return value.toString();
      }

      return value.toString().padStart(2, '0');
    },
    update(value) {
      this.$emit('change', value);
    },
    setDirty() {
      this.dirty = true;
    },
    labelClasses(label) {
      return {
        selected: label === this.value,
        [`time-picker__label-${this.type}`]: true,
      };
    },
  }
};
</script>

<style scoped lang="scss">
@use 'sass:math';
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

$time-input-picker-size: 160px;
$time-input-picker-center-size: 8px;
$time-input-picker-label-size: 30px;
$time-input-picker-label-margin: 2px;
.rsc-time-picker {
  /* This is needed so a input[type=number]'s spin buttons do not bleed through the calendar */
  z-index: 1;
}

.time-picker {
  position: relative;

  &:not(.active) {
    .time-picker__clock {
      display: none;
    }
  }

  &.active {
    .time-picker__clock {
      display: block;
    }
  }
}

  .time-picker__input {
    max-width: 100%;
    text-align: center;
  }

  .time-picker__clock {
    position: absolute;
    left: calc(50% - #{math.div($time-input-picker-size, 2)});
    height: $time-input-picker-size;
    width: $time-input-picker-size;
    border-radius: 50%;
    background-color: $color-white;

    @include small-drop-shadow();
  }

  .time-picker__dial {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .time-picker__center {
    position: absolute;
    left: calc(50% - #{math.div($time-input-picker-center-size, 2)});
    top: calc(50% - #{math.div($time-input-picker-center-size, 2)});
    height: $time-input-picker-center-size;
    width: $time-input-picker-center-size;
    border-radius: 50%;
    background-color: $color-primary;
  }

  .time-picker__hand {
      position: absolute;
      top: math.div($time-input-picker-label-size, 2);
      left: 50%;
      height: calc(50% - #{math.div($time-input-picker-label-size, 2)});
      width: 1px;
      -webkit-transform-origin: center bottom;
      transform-origin: center bottom;
      background-color: $color-primary;
      will-change: transform;
  }

  .time-picker__label {
    position: absolute;
    top: calc(50% - #{math.div($time-input-picker-label-size, 2)});
    left: calc(50% - #{math.div($time-input-picker-label-size, 2)});
    line-height: $time-input-picker-label-size;
    width: $time-input-picker-label-size;
    height: $time-input-picker-label-size;
    border-radius: 50%;
    font-weight: bold;
    text-align: center;
    background-color: $color-white;
    cursor: pointer;

    @include transition-property(background-color);
    @include slow-transition-duration();

    &:hover {
      background-color: $color-light-grey-2;
    }

    &.selected {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  .time-picker__label-hour, .time-picker__label-minute {
    $angle: math.div(360, 12);
    $rot: 0;

    @for $i from 1 through 12 {
      &:nth-of-type(#{$i}) {
        transform:
          rotate(-90 + $rot * 1deg)
          translate(math.div($time-input-picker-size, 2) - math.div($time-input-picker-label-size, 2) - $time-input-picker-label-margin)
          rotate(90 + $rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
</style>

// Copyright (C) 2022 by Posit Software, PBC.

// safeAPIErrorMessage attempts to return the error message contained in the response.
// If it fails to find the error message, it returns a generic message.
export const safeAPIErrorMessage = (err) =>
  err?.response?.data?.error
    ? `Error: ${err.response.data.error}`
    : 'An error has occurred';

// safeAPIErrorCode attempts to return the error code contained in the response.
export const safeAPIErrorCode = (err) => err?.response?.data?.code || null;

// isAPIErrorMessage checks whether the response meets the criteria for
// safeAPIErrorMessage. If that function would return a generic error message,
// this function returns `false`, so you can do something else with that response
// and avoid a generic error message.
export const isAPIErrorMessage = (err) => !!err?.response?.data?.error;

// Copyright (C) 2022 by Posit Software, PBC.

export const sampleSchema = [
  {
    id: 1481670970641,
    name: 'Research Area',
    version: 0,
    children: [
      {
        id: 1481670984979,
        name: 'Population Ecology',
        version: 0,
        children: [],
      },
      {
        id: 1481680268301,
        name: 'Basic Physiology',
        version: 0,
        children: [],
      },
      {
        id: 1481680273533,
        name: 'Bioacoustics',
        version: 0,
        children: [],
      },
      {
        id: 1481680286196,
        name: 'Behavioral Ecology',
        version: 0,
        children: [],
      },
      {
        id: 1481680290905,
        name: 'Human Impacts',
        version: 0,
        children: [],
      },
    ],
  },
  {
    id: 1481680303456,
    name: 'Project Status',
    version: 0,
    children: [
      {
        id: 1481680319379,
        name: 'Planning',
        version: 0,
        children: [],
      },
      {
        id: 1481680323723,
        name: 'Grant Pending',
        version: 0,
        children: [],
      },
      {
        id: 1481680330731,
        name: 'Funded',
        version: 0,
        children: [],
      },
      {
        id: 1481680334693,
        name: 'Underway',
        version: 0,
        children: [],
      },
      {
        id: 1481680343362,
        name: 'Ongoing',
        version: 0,
        children: [],
      },
      {
        id: 1481680345620,
        name: 'Complete',
        version: 0,
        children: [],
      },
    ],
  },
  {
    id: 1481680353373,
    name: 'Grant',
    version: 0,
    children: [
      {
        id: 1481680370761,
        name: 'National Geographic Socitey',
        version: 0,
        children: [
          {
            id: 1481680399385,
            name: 'NG 134-234-134',
            version: 0,
            children: [],
          },
          {
            id: 1481680414303,
            name: 'NG 235-983-872',
            version: 0,
            children: [],
          },
        ],
      },
      {
        id: 1481680431040,
        name: 'Leonardo DiCaprio Foundation',
        version: 0,
        children: [
          {
            id: 1481680451319,
            name: 'LD 2342-2341',
            version: 0,
            children: [],
          },
          {
            id: 1481680463078,
            name: 'LD 1820-0992',
            version: 0,
            children: [],
          },
          {
            id: 1481680472471,
            name: 'LD 9232-3241',
            version: 0,
            children: [],
          },
        ],
      },
      {
        id: 1481680487620,
        name: 'Lenfest Ocean Program',
        version: 0,
        children: [
          {
            id: 1481680501148,
            name: 'LOP 23-234',
            version: 0,
            children: [],
          },
          {
            id: 1481680509252,
            name: 'LOP 24-094',
            version: 0,
            children: [],
          },
        ],
      },
      {
        id: 1481680519980,
        name: 'Patagonia',
        version: 0,
        children: [
          {
            id: 1481680532843,
            name: 'P 9324-0234',
            version: 0,
            children: [],
          },
          {
            id: 1481680542530,
            name: 'P 1246-3423',
            version: 0,
            children: [],
          },
          {
            id: 1481680552123,
            name: 'P 5934-3453',
            version: 0,
            children: [],
          },
          {
            id: 1481681653852,
            name: 'P 7234-1093',
            version: 0,
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 1481680570096,
    name: 'Species',
    version: 0,
    children: [
      {
        id: 1481680809834,
        name: 'Baleen Whales',
        version: 0,
        children: [
          {
            id: 1481680914485,
            name: 'Blue',
            version: 0,
            children: [],
          },
          {
            id: 1481680925733,
            name: 'Fin',
            version: 0,
            children: [],
          },
          {
            id: 1481680935845,
            name: 'Minke',
            version: 0,
            children: [],
          },
          {
            id: 1481680982867,
            name: 'Humpback',
            version: 0,
            children: [],
          },
          {
            id: 1481680984827,
            name: 'Sei',
            version: 0,
            children: [],
          },
        ],
      },
      {
        id: 1481680824728,
        name: 'Toothed Whales',
        version: 0,
        children: [
          {
            id: 1481681002218,
            name: 'Orca',
            version: 0,
            children: [],
          },
          {
            id: 1481681007420,
            name: 'Sperm',
            version: 0,
            children: [],
          },
          {
            id: 1481681037537,
            name: `Baird's Beaked`,
            version: 0,
            children: [],
          },
          {
            id: 1481681052904,
            name: 'Strap-toothed',
            version: 0,
            children: [],
          },
          {
            id: 1481681072458,
            name: 'Pygmy Beaked',
            version: 0,
            children: [],
          },
        ],
      },
      {
        id: 1481681083464,
        name: 'Dolphins',
        version: 0,
        children: [
          {
            id: 1481681134751,
            name: 'Atlantic Spotted',
            version: 0,
            children: [],
          },
          {
            id: 1481681146037,
            name: 'Atlantic White-sided',
            version: 0,
            children: [],
          },
          {
            id: 1481681169724,
            name: 'Chilean',
            version: 0,
            children: [],
          },
          {
            id: 1481681187285,
            name: 'Common Bottlenose',
            version: 0,
            children: [],
          },
        ],
      },
      {
        id: 1481681209830,
        name: 'Porpoises',
        version: 0,
        children: [
          {
            id: 1481681217980,
            name: `Dall's`,
            version: 0,
            children: [],
          },
          {
            id: 1481681229819,
            name: 'Harbour',
            version: 0,
            children: [],
          },
          {
            id: 1481681280121,
            name: 'Spectacled',
            version: 0,
            children: [],
          },
        ],
      },
      {
        id: 1481681316648,
        name: 'Manatees',
        version: 0,
        children: [
          {
            id: 1481681328888,
            name: 'Amazonian',
            version: 0,
            children: [],
          },
          {
            id: 1481681342860,
            name: 'West Indian',
            version: 0,
            children: [],
          },
          {
            id: 1481681405052,
            name: 'African',
            version: 0,
            children: [],
          },
        ],
      },
      {
        id: 1481681424179,
        name: 'Dugong',
        version: 0,
        children: [],
      },
      {
        id: 1481681470706,
        name: 'Seals',
        version: 0,
        children: [
          {
            id: 1481681495284,
            name: 'Hooded',
            version: 0,
            children: [],
          },
          {
            id: 1481681504761,
            name: 'Bearded',
            version: 0,
            children: [],
          },
          {
            id: 1481681538152,
            name: 'Grey',
            version: 0,
            children: [],
          },
          {
            id: 1481681540495,
            name: 'Leopard',
            version: 0,
            children: [],
          },
          {
            id: 1481681548383,
            name: 'Ross',
            version: 0,
            children: [],
          },
        ],
      },
      {
        id: 1481681561760,
        name: 'Walrus',
        version: 0,
        children: [],
      },
    ],
  },
];

<!-- Copyright (C) 2024 by Posit Software, PBC. -->
<script setup>
import {
  SET_RENDERINGS_DISPLAYED_ID,
  TOGGLE_RENDERINGS_HISTORY,
} from '@/store/modules/contentView';
import { computed } from 'vue';
import { useStore } from 'vuex';
import BaseHistory, { RENDERING_HISTORY } from './BaseHistory';

const store = useStore();

const items = computed(() => store.state.contentView.renderingHistory.items);
const displayedId = computed(
  () => store.state.contentView.renderingHistory.displayedId
);
</script>

<template>
  <BaseHistory
    :type="RENDERING_HISTORY"
    :items="items"
    :displayed-id="displayedId"
    sort-by="renderTime"
    @display-item="item => store.commit(SET_RENDERINGS_DISPLAYED_ID, item.id)"
    @toggle-history="store.commit(TOGGLE_RENDERINGS_HISTORY)"
  />
</template>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    class="rsc-metrics__section"
    data-automation="metrics-process-table"
  >
    <h1 class="sectionTitle focusedTitle">
      Processes
    </h1>
    <RSTable
      :columns="tableHeaders"
      @sort="sortChanged"
    >
      <RSTableRow
        v-for="process in processes"
        :key="process.jobKey"
        :row-id="process.jobKey"
        :row-label="getRowLabel(process.appId, process.appName)"
        :class="{ disabled: isProcessDisabled(process) }"
        class="process-list__process-row"
      >
        <RSTableCell class="hideOnMobile">
          {{ process.hostname }}
        </RSTableCell>
        <RSTableCell
          :cell-id="process.jobKey"
        >
          <component
            :is="getRowClickable(process.appId) ? 'RouterLink' : 'span'"
            :to="{ name: 'apps', params: { idOrGuid: process.appGuid } }"
            :title="process.appName"
          >
            {{ process.appName }}
          </component>
        </RSTableCell>
        <RSTableCell class="hideOnMobile">
          {{ jobDescription(process.type) }}
        </RSTableCell>
        <RSTableCell class="hideOnMobile">
          {{ process.appRunAs }}
        </RSTableCell>
        <RSTableCell>
          {{ cpu(process.cpuCurrent) }}
        </RSTableCell>
        <RSTableCell>
          {{ humanizeBytesBinary(process.ram) }}
        </RSTableCell>
        <RSTableCell class="hideOnMobile">
          {{ fromNow(process.startTime) }}
        </RSTableCell>
        <RSTableCell>
          <div class="process-list__actions">
            <RouterLink
              v-if="getRowClickable(process.appId)"
              :to="{ name: 'apps.logs', params: { idOrGuid: process.appGuid }, query: { logKey: process.jobKey } }"
              title="View Log"
              target="_blank"
            >
              <i
                aria-hidden="true"
                class="rs-icon logIcon"
              />
              <span class="sr-only">View Log</span>
            </RouterLink>
            <button
              v-if="canKillProcess(process)"
              title="Kill Process"
              :disabled="isProcessDisabled(process)"
              class="process-list__kill-button"
              data-automation="process-list__kill-button"
              @click="confirmKillProcess(process)"
            >
              <i
                aria-hidden="true"
                class="rs-icon killIcon"
              />
              <span class="sr-only">Kill Process</span>
            </button>
          </div>
        </RSTableCell>
      </RSTableRow>
    </RSTable>
    <div
      v-if="!hasProcesses"
      class="rsc-metrics__process-list--empty"
    >
      No processes running
    </div>
    <ConfirmModal
      v-if="confirm.show"
      confirmation="Yes, I want to kill the process"
      @confirm="killProcess(confirm.process)"
      @cancel="clearConfirm"
    >
      <EmbeddedStatusMessage
        type="warning"
        class="confirm-details__warning"
        message="Killing a process can cause data loss and negatively impact users that are viewing related content."
        :show-close="false"
      />

      <p>
        You are about to kill the process
        <code class="confirm-details__process-type">{{ jobDescription(confirm.process.type) }}</code>
        for the content <span class="confirm-details__process-app-name">{{ confirm.process.appName }}</span>.
        Are you sure that you want to proceed?
      </p>
    </ConfirmModal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import ConfirmModal from '@/components/ConfirmModal';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage.vue';
import RSTable from '@/elements/RSTable';
import RSTableCell from '@/elements/RSTableCell';
import RSTableRow from '@/elements/RSTableRow';

import { JobTag } from '@/api/dto/job';
import { killJob } from '@/api/jobs';
import { getProcesses } from '@/api/metrics';
import {
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { humanizeBytesBinary } from '@/utils/bytes.filter';
import { cancelableInterval } from '@/utils/promiseInterval';
import { RouterLink } from 'vue-router';
import { mapActions, mapMutations } from 'vuex';

dayjs.extend(relativeTime);

export default {
  name: 'ProcessList',
  components: {
    RSTable,
    RSTableCell,
    RSTableRow,
    ConfirmModal,
    EmbeddedStatusMessage,
    RouterLink,
  },
  props: {
    // Testing flag to keep network calls from being made
    shouldInit: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      api: {
        processes: [],
      },
      fetchProcessesAborter: new AbortController(),
      processes: [],
      disabledProcesses: [],
      sortingState: {
        column: 'cpuCurrent',
        direction: 'desc',
      },
      tableHeaders: [
        {
          name: 'hostname',
          label: 'Hostname',
          class: 'hideOnMobile',
          sortable: true
        },
        {
          name: 'appName',
          label: 'Content Name',
          sortable: true,
        },
        {
          name: 'type',
          label: 'Type',
          class: 'hideOnMobile',
          sortable: true,
        },
        {
          name: 'appRunAs',
          label: 'User',
          class: 'hideOnMobile',
          sortable: true,
        },
        {
          name: 'cpuCurrent',
          label: 'CPU',
          sortable: true, direction: 'desc'
        },
        {
          name: 'ram',
          label: 'RAM',
          sortable: true
        },
        {
          name: 'startTime',
          label: 'Started',
          class: 'hideOnMobile',
          sortable: true
        },
        {
          name: 'actions',
          label: 'Actions',
          sortable: false,
          width: '50px',
        },
      ],
      confirm: {
        show: false,
        process: null,
      }
    };
  },
  computed: {
    hasProcesses() {
      return this.processes.length !== 0;
    },
  },
  created() {
    if (this.shouldInit) {
      this.init();
    }
  },
  beforeUnmount() {
    this.fetchProcessesAborter.abort();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    init() {
      cancelableInterval(this.fetchProcesses, this.fetchProcessesAborter.signal, 6000);
    },
    async fetchProcesses() {
      return getProcesses()
        .then(processes => {
          this.api.processes = processes;
          this.processes = this.sortAndFilter(this.api.processes);
        })
        .catch(this.setErrorMessageFromAPI);
    },
    humanizeBytesBinary,
    fromNow(time) {
      return dayjs(time).fromNow();
    },
    cpu(cpuCurrent) {
      if (isNaN(parseFloat(cpuCurrent)) || !isFinite(cpuCurrent)) {
        return '-';
      }
      return cpuCurrent.toFixed(2);
    },
    sortAndFilter(processes) {
      // TODO: Filter
      const { column, direction } = this.sortingState;
      function compare(process1, process2) {
        const invert = direction === 'desc' ? -1 : 1;
        // Time fields need to be sorted using dayjs
        if (column === 'startTime') {
          return invert *
            (dayjs(process1[column]) - dayjs(process2[column]));
        }
        // Type fields need to be sorted by their linguistic representation
        if (column === 'type') {
          return invert * (
            JobTag.description(JobTag.of(process1[column]))
              .localeCompare(JobTag.description(JobTag.of(process2[column])))
          );
        }
        // Assume that everything but cpu and ram are string sorted
        if (column !== 'cpuCurrent' && column !== 'ram') {
          return invert * process1[column].localeCompare(process2[column]);
        }
        // Assume numeric sort for everything else
        return invert * (process1[column] - process2[column]);
      }
      return processes.sort(compare);
    },
    sortChanged({ index, direction }) {
      if (direction === null) {
        direction = 'desc';
      }
      this.sortingState = {
        column: this.tableHeaders[index].name,
        direction: direction,
      };
      for (const index2 in this.tableHeaders) {
        this.tableHeaders[+index2].direction = (+index === +index2) ? direction : null;
      }
      this.processes = this.sortAndFilter(this.api.processes);
    },
    // eslint-disable-next-line no-shadow
    getRowLabel(appId, name) {
      return appId === '0'
        ? ''
        : `Go to content: ${name}`;
    },
    getRowClickable(appId) {
      return appId !== '0';
    },
    // For testing
    columnNameToIndex(columnName) {
      return this.tableHeaders.findIndex(column => column.name === columnName);
    },
    jobDescription(tag) {
      return JobTag.description(JobTag.of(tag));
    },
    // eslint-disable-next-line no-shadow
    isProcessDisabled(process) {
      return this.disabledProcesses.includes(process.jobKey);
    },
    // eslint-disable-next-line no-shadow
    disableProcess(process) {
      this.disabledProcesses.push(process.jobKey);
    },
    // eslint-disable-next-line no-shadow
    canKillProcess(process) {
      // Can only kill processes that belong to a content item and are not
      // environment restores.
      const processJobTag = JobTag.of(process.type);

      return process.appId !== '0' &&
        ![
          JobTag.PackratRestoreTag,
          JobTag.PythonRestoreTag,
        ].includes(processJobTag);
    },
    // eslint-disable-next-line no-shadow
    confirmKillProcess(process) {
      this.confirm = {
        show: true,
        process: process,
      };
    },
    clearConfirm() {
      this.confirm = {
        show: false,
        process: null,
      };
    },
    // eslint-disable-next-line no-shadow
    killProcess(process) {
      this.clearConfirm();

      killJob(process.appGuid, process.jobKey)
        .then(() => {
          this.disableProcess(process);
          this.setInfoMessage({
            message: `The process "${this.jobDescription(process.type)}" has been queued to be killed.`
          });
        })
        .catch(this.setErrorMessageFromAPI);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.process-list__process-row {
  &.disabled {
    opacity: 0.5;

    .process-list__kill-button {
      cursor: not-allowed;
    }
  }
}

.process-list__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 3px;

  a, button {
    padding: 0;
    background-color: inherit;

    &:hover {
      background-color: $color-light-grey-2;
    }

    .rs-icon {
      margin-right: 0;
    }
  }
}

.confirm-details__process-type {
  display: inline-block;
  padding: 0 3px;
}

.confirm-details__process-app-name {
  font-weight: bold;
}

.confirm-details__warning {
  margin-bottom: 1.0rem;
}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

import { apiPath } from '@/utils/paths';
import axios from 'axios';
import { keysToCamel } from './transform';

/**
 * API calls for content permissions
 */

/**
 * Get the existing request access for the given content, if any.
 * @param {string} contentGUID Content's guid
 * @returns {Promise} Resolution with the pending request access, if any.
 */
export const getPendingRequestAccess = contentGUID => {
  return axios.get(apiPath(`content/${contentGUID}/permissions-request`))
    .then(({ data }) => keysToCamel(data));
};

/**
 * Get request access by token
 * @param {string} token Request's token
 * @returns {Promise} Resolution with the request access, if any.
 */
export const getRequestAccessByToken = ({ contentGUID, requestToken }) => {
  return axios.get(
    apiPath(`content/${contentGUID}/permissions-request?token=${requestToken}`)
  ).then(({ data }) => keysToCamel(data));
};

/**
 * Request permissions to access the given content.
 * @param {string} [guid] Content's guid
 * @param {string} [permissionType] Permission to be obtained
 * @returns {Promise} Empty result. Permission created on a succesfull resolution.
 */
export const requestContentPermissions = ({ guid, permissionType }) => {
  return axios.post(
    apiPath(`content/${guid}/permissions-request`),
    { role: permissionType }
  );
};

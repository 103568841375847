<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <SearchSelect
    label="Timezone"
    :selected="selected"
    :options="options"
    :disabled="disabled"
    title="Select a timezone"
    class="timezone-selector"
    @select="selectHandler"
  />
</template>

<script>
import SearchSelect from './SearchSelect';

export default {
  name: 'TimezoneSelector',
  components: {
    SearchSelect
  },
  props: {
    selected: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  emits: ['change'],
  methods: {
    selectHandler(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss">
.search-select.timezone-selector {
  display: block;

  .search-select__input {
    width: 100%;
    font-size: 0.825rem;
  }

  .search-select__options {
    z-index: 1000;
    font-size: 1rem;
  }
}
</style>

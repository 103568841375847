<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    id="rStudioHeader"
    class="connect-header contentHeader"
    :class="{ logsActive, historyActive }"
  >
    <div class="band contentHeader">
      <div class="innards">
        <div id="leftSide">
          <template v-if="!isAuthenticated">
            <div>
              <RouterLink
                :to="{ name: 'welcome' }"
                data-automation="content-header-logo-link"
              >
                <!-- eslint-disable vue/no-useless-v-bind -->
                <img
                  class="connect-header__logo"
                  :src="'__logo__'"
                  alt="Return to Welcome"
                >
                <!-- eslint-enable vue/no-useless-v-bind -->
              </RouterLink>
            </div>
          </template>
          <template v-else>
            <button
              id="menuToggler"
              aria-label="Menu Toggle"
              class="connect-header__menu-button"
              @click="openNavPanel"
            />
            <NotificationBadge
              v-if="showFeedNotification"
              class="whats-new-notification"
            />
            <button
              id="overlayNavToggler"
              aria-label="Overlay Toggle"
              @click="showOverlayNav = !showOverlayNav"
            />
          </template>

          <ContentPath />
        </div>

        <div id="rightSide">
          <ServerSettingsKeeper />
          <ContentActions
            v-if="app && currentUser"
          />
          <CurrentUser id="currentUser" />
        </div>
      </div>
    </div>

    <BundleHistory v-if="isAppHistoryVisible" />
    <RenderingHistory v-if="isVariantHistoryVisible" />

    <div
      v-if="showOverlayNav"
      class="overlayNav"
    >
      <ContentPath
        :is-authenticated="isAuthenticated"
      />
    </div>

    <StatusMessage />
  </div>
</template>

<script>
import CurrentUser from '@/components/CurrentUser';
import { BundleHistory, RenderingHistory } from '@/components/History';
import ServerSettingsKeeper from '@/components/ServerSettingsKeeper.vue';
import StatusMessage from '@/components/StatusMessage';
import NotificationBadge from '@/components/NotificationBadge.vue';
import { SHOW_NAV_PANEL } from '@/store/modules/navigation';
import { RouterLink } from 'vue-router';
import { mapMutations, mapState } from 'vuex';
import ContentActions from './ContentActions';
import ContentPath from './ContentPath';

export default {
  name: 'ContentHeader',
  components: {
    BundleHistory,
    ContentActions,
    ContentPath,
    CurrentUser,
    RenderingHistory,
    ServerSettingsKeeper,
    StatusMessage,
    NotificationBadge,
    RouterLink,
  },
  data() {
    return {
      showOverlayNav: false,
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentUser: state => state.currentUser.user,
      isAuthenticated: state => state.currentUser.isAuthenticated,
      isAppHistoryVisible: state => state.bundles.showHistoryPane,
      isVariantHistoryVisible: state => state.contentView.renderingHistory.showHistoryPane,
      showLogs: state => state.contentView.showLogs,
      showFeedNotification: state => state.whatsNew.showNotification,
    }),
    logsActive() {
      return this.showLogs;
    },
    historyActive() {
      return this.isAppHistoryVisible || this.isVariantHistoryVisible;
    },
  },
  methods: {
    ...mapMutations({
      showingNavPanel: SHOW_NAV_PANEL,
    }),
    openNavPanel() {
      this.showingNavPanel(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.historyActive {
  height: auto!important;
}
.whats-new-notification {
  top: 2px;
  position: relative;
  right: 12px;
}
</style>

// Copyright (C) 2023 by Posit Software, PBC.

import { store } from '@/store';
import { loginPath } from '@/utils/paths';
import LoginView from '@/views/authentication/LoginView.vue';
import RegisterView from '@/views/authentication/RegisterView.vue';
import ResetPasswordView from '@/views/authentication/ResetPasswordView.vue';
import SetPasswordView from '@/views/authentication/SetPasswordView.vue';
import UnauthorizedView from '@/views/authentication/UnauthorizedView';
import ConfirmUser from '@/components/ConfirmUser';
import UserProfileCompletion from '@/views/authentication/UserProfileCompletion';
import TokenSync from '@/views/users/connect/TokenSync';
import AppHeader from '@/components/AppHeader';

export default [
  {
    path: '/login',
    name: 'login_view',
    components: {
      default: LoginView,
      header: AppHeader,
    },
    beforeEnter: (to, _from, next) => {
      // We don't have access the server settings in the `meta`, so we need to set the title here.
      to.meta.title = `Sign in to ${store.state.server.settings.systemDisplayName}`;

      // If the server does not handle credentials, then the provider handles credentials.
      // Go to the __login__ path so Connect handles a proper redirect.
      if (!store.state.server.settings.authentication.handlesCredentials) {
        window.location = loginPath();
        return;
      }

      next();
    },
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    components: {
      default: UnauthorizedView,
      header: AppHeader,
    },
    meta: {
      requireAuth: true,
      title: 'Unauthorized',
    },
  },
  {
    path: '/register',
    name: 'register_view',
    components: {
      default: RegisterView,
      header: AppHeader,
    },
    meta: {
      title: 'Register',
    },
    beforeEnter: (_to, _from, next) => {
      // If self registration is disabled, redirect to welcome page.
      if (!store.state.server.settings.selfRegistration) {
        next('/welcome');
        return;
      }
      next();
    },
  },
  {
    path: '/resetpassword',
    name: 'reset_password',
    components: {
      default: ResetPasswordView,
      header: AppHeader,
    },
    meta: {
      title: 'Reset Password',
    }
  },
  {
    path: '/confirm',
    name: 'confirm_user',
    components: {
      default: ConfirmUser,
      header: AppHeader,
    },
    meta: {
      title: 'Account confirmation',
      requireAuth: true,
    },
  },
  {
    path: '/user-completion',
    name: 'user_completion',
    components: {
      default: UserProfileCompletion,
      header: AppHeader,
    },
    meta: {
      title: 'Account setup',
    }
  },
  {
    path: '/setpassword',
    name: 'set_password',
    components: {
      default: SetPasswordView,
      header: AppHeader,
    },
    meta: {
      title: 'Reset Password',
    }
  },
  {
    path: '/tokens/:token/activate',
    name: 'token_activate',
    components: {
      default: TokenSync,
      header: AppHeader,
    },
    meta: {
      requireAuth: true,
    },
  },
];

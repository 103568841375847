<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
const props = defineProps({
  activeAuth: { type: Object, default: () => {} }
});

const emit = defineEmits(['openModal', 'integrationChange']);

import RSButton from '@/elements/RSButton';

const removeActiveIntegration = () => emit('integrationChange', []);
const openIntegrationModal = () => emit('openModal');
</script>

<template>
  <div
    class="active-integration"
    data-automation="active-integration"
    :integration-guid="props.activeAuth.value"
  >
    <div
      class="active-integration-controls"
      data-automation="active-integration-controls"
    >
      <RSButton
        class="change-active-integration"
        data-automation="change-active-integration"
        :icon-only="true"
        icon="images/elements/actionEdit.svg"
        type="link"
        label="Change Active Integration"
        size="normal"
        @click="openIntegrationModal"
      />
      <RSButton
        class="remove-active-integration"
        data-automation="remove-active-integration"
        :icon-only="true"
        icon="images/elements/actionDelete.svg"
        type="link"
        label="Remove Active Integration"
        size="normal"
        @click="removeActiveIntegration"
      />
    </div>
    <div
      class="active-integration-content"
      data-automation="active-integration-content"
    >
      <img
        :src="`images/oauthintegrations/${props.activeAuth.template}.png`"
        :alt="`${props.activeAuth.template} integration`"
        class="active-integration-logo"
      >
      <p
        class="active-integration-name"
        data-automation="active-integration-name"
      >
        {{ props.activeAuth.label }}
      </p>
      <p
        class="active-integration-description"
        data-automation="active-integration-description"
      >
        {{ props.activeAuth.sub }}
      </p>
    </div>
    <a
      :href="props.activeAuth.href"
      data-automation="active-integration-login"
      class="active-integration-login"
    >
      {{ props.activeAuth.linkName }}
    </a>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.active-integration {
  background: $color-white;
  border: 1px solid $color-medium-grey;
  display: flex;
  flex-flow: column;
  position: relative;
}

.active-integration-controls {
  position: absolute;
  right: 0;
}

.active-integration-content {
  margin: 1.25rem 0 1.625rem 1.25rem;
}

.active-integration-logo {
  height: 14px;
  position: relative;
  margin-right: 0.25rem;
}

.active-integration-name {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 0.875rem;
}

.active-integration-description {
  color: $color-dark-grey-2;
  line-height: 0.875rem;
  margin-left: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}

.active-integration-login {
  background-color: $color-dark-blue;
  color: $color-white;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0.375rem;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
</style>

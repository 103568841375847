<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { RouterLink, useRoute } from 'vue-router';
import upperFirst from 'lodash/upperFirst';
import UserRoles from '@/api/dto/userRole';
import { SHOW_USER_MENU } from '@/store/modules/navigation';

const store = useStore();
const route = useRoute();

const apiKeys = ref(null);
const profile = ref(null);

const serverSettings = computed(() => store.state.server.settings);
const currentUser = computed(() => store.state.currentUser.user);
const hasLogout = computed(() => serverSettings.value.logoutUrl !== '');

const roleName = computed(() => {
  const userRole = UserRoles.stringOf(currentUser.value.userRole);
  return upperFirst(userRole);
});

const isActiveTab = computed(() => { 
  const currentPath = route.name;
  return prefix => currentPath?.startsWith(prefix);
});

const onLogout = () => {
  window.location.href = serverSettings.value.logoutUrl;
};

const closeUserMenu = () => {
  store.commit(SHOW_USER_MENU, false);
  setTimeout(() => {
    const profileButton = document.querySelector('button.profileButton');
    profileButton?.focus();
  }, 200);
};

onMounted(() => {
  const initialFocus = route.name.startsWith('people.users.api_keys') ?
    apiKeys.value.$el :
    profile.value.$el;
  setTimeout(() => initialFocus.focus(), 200);
});
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
  <div
    id="user-dropdown"
    class="user-dropdown"
    data-automation="user-dropdown"
    tabindex="-1"
    @click="closeUserMenu"
    @keyup.esc="closeUserMenu"
  >
    <menu
      class="user-dropdown-menu"
      data-automation="user-menu"
      aria-label="User Menu"
    >
      <ul>
        <li>
          <div class="menu-section-title">
            <span
              class="role"
              data-automation="user-role"
            >{{ roleName }}</span>
          </div>
        </li>
        <li>
          <RouterLink
            ref="profile"
            :to="{ name: 'people.users.profile', params: { guid: currentUser.guid } }"
            :class="{ active: isActiveTab('people.users.profile') }"
            class="menu-item profile"
            data-automation="profile"
            @click="closeUserMenu"
          >
            <span>View Profile</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink
            ref="apiKeys"
            class="menu-item apiKeys"
            data-automation="api-keys"
            :to="{ name: 'people.users.api_keys', params: { guid: currentUser.guid } }"
            :class="{ active: isActiveTab('people.users.api_keys') }"
            @click="closeUserMenu"
          >
            <span>Manage Your API Keys</span>
          </RouterLink>
        </li>
        <li>
          <div v-if="hasLogout">
            <button
              class="menu-item logout"
              data-automation="logout"
              @click="onLogout"
            >
              <span>Log Out</span>
            </button>
          </div>
        </li>
      </ul>
    </menu>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.contentView + .user-dropdown {
  top: 50px;
}

.user-dropdown {
  position: absolute;
  top: 57px;
  right: 10px;
  z-index: 1001;
  
  @include normal-drop-shadow();
}

.user-dropdown-menu {
  background: $color-white;
  border: 1px solid $color-default-border;
  border-radius: 0.3rem;
  color: $color-button-text;
  font-size: 0.8125rem;
  
  li {
    margin-bottom: 0;
  }
}

.menu-section-title {
  background: $color-light-grey-2;
  line-height: 30px;
  text-align: center;

  .role {
    font-weight: bold;
  }
}

.menu-item {
  color: inherit;
  cursor: pointer;
  display: block;
  border-radius: 0;
  margin-right: 0;
  line-height: 40px;
  padding: 0 16px 0 37px;
  font-size: 13px;
  background-repeat: no-repeat;
  background-size: 15px 80px;
  background-color: transparent;
  background-position: 13px -40px;
  @include control-visible-focus;

  &:hover {
    background-color: $color-options-focus-background;
    text-decoration: none;
  }

  &.active {
    background-color: $color-options-focus-background;
  }

  &.apiKeys {
    background-image: url(/images/elements/navAPIKeys.svg);
  }

  &.login {
    background-image: url(/images/elements/navLogin.svg);
  }

  &.signup {
    background-image: url(/images/elements/navSignup.svg);
  }

  &.profile {
    background-image: url(/images/elements/navProfile.svg);
  }

  &.logout {
    background-image: url(/images/elements/navLogout.svg);
    width: 100%;
    text-align: left;
  }
}
</style>

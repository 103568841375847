<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import CopyIconButton from '@/components/CopyIconButton.vue';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';

const store = useStore();

const created = computed(() => {
  const createdTime = store.state.infoSettings.app.createdTime;
  return dayjs(createdTime).format('MMM D, YYYY h:mma ([GMT]Z)');
});
const lastDeployed = computed(() => {
  const lastDeployedTime = store.state.infoSettings.app.lastDeployedTime;
  return dayjs(lastDeployedTime).format('MMM D, YYYY h:mma ([GMT]Z)');
});
const typeDescription = computed(() => store.state.infoSettings.app.typeDescription);
const appGuid = computed(() => store.state.infoSettings.app.guid);
const appId = computed(() => store.state.infoSettings.app.id);
const rVersion = computed(() => store.state.infoSettings.app.rVersion);
const pyVersion = computed(() => store.state.infoSettings.app.pyVersion);
const quartoVersion = computed(() => store.state.infoSettings.app.quartoVersion);
const imageName = computed(() => store.state.infoSettings.app.imageName);
const clusterName = computed(() => store.state.infoSettings.app.clusterName);
const loading = computed(() => created.value === null);
</script>

<template>
  <div>
    <EmbeddedStatusMessage
      v-if="loading"
      message="Loading access settings..."
      :show-close="false"
      type="activity"
      data-automation="loading"
    />
    <dl
      v-if="!loading"
      class="itemDate"
    >
      <div
        class="item created"
        data-automation="settings-info-created"
      >
        <dt class="emphasize">
          Created:
        </dt><dd>{{ created }}</dd>
      </div>
      <div
        class="item updated"
        data-automation="settings-info-last-deployed"
      >
        <dt class="emphasize">
          Last Deployed:
        </dt><dd>{{ lastDeployed }}</dd>
      </div>
      <div
        class="item type"
        data-automation="settings-info-type"
      >
        <dt class="emphasize">
          Content Type:
        </dt><dd>{{ typeDescription }}</dd>
      </div>
      <div
        class="item guid"
        data-automation="settings-info-app-guid"
      >
        <dt class="emphasize">
          GUID:
        </dt>
        <dd>
          {{ appGuid }} 
          <CopyIconButton
            :copy-text="appGuid"
            class="inline-copy-button"
          />
        </dd>
      </div>
      <div
        class="item app-id"
        data-automation="settings-info-app-id"
      >
        <dt class="emphasize">
          Content ID:
        </dt><dd>{{ appId }}</dd>
      </div>
      <div
        v-if="rVersion"
        class="item rversion"
        data-automation="settings-info-rversion"
      >
        <dt class="emphasize">
          R Version:
        </dt><dd>{{ rVersion }}</dd>
      </div>
      <div
        v-if="pyVersion"
        class="item pyversion"
        data-automation="settings-info-pyversion"
      >
        <dt class="emphasize">
          Python Version:
        </dt><dd>{{ pyVersion }}</dd>
      </div>
      <div
        v-if="quartoVersion"
        class="item quartoversion"
        data-automation="settings-info-quartoversion"
      >
        <dt class="emphasize">
          Quarto Version:
        </dt><dd>{{ quartoVersion }}</dd>
      </div>
      <div
        v-if="imageName && clusterName"
        class="item imageNameWithCluster"
        data-automation="settings-info-imageNameWithCluster"
      >
        <dt class="emphasize">
          Last Execution:
        </dt>
        <dd>{{ `Used the ${imageName} image within the ${clusterName} cluster` }}</dd>
      </div>
    </dl>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.itemDate {
  font-size: 12px;
  color: $color-dark-grey-2;
  font-style: italic;
  word-break: break-all;
  line-height: 18px;

  dt, dd {
    display: inline;
  }

  .item:not(.type) {
    // type needs 0 margin-bottom to allow for copy button space
    margin-bottom: 0.5rem;
  }

  .inline-copy-button {
    display: inline-block;
    width: 20px;
    height: 18px;
    top: 3px;
    position: relative;
  }

  .type, .imageNameWithCluster {
    word-break: break-word;
  }
}
</style>

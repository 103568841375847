<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="content-url">
    <div class="formSection">
      <div
        v-if="canCustomize"
        class="textInputContainer content-url__custom-url"
      >
        <div class="content-url__custom-url-label mb">
          Path
        </div>
        <RSInputText
          :model-value="modelValue"
          :disabled="!canEditSettings"
          label="custom-content-url"
          :show-label="false"
          placeholder="Customize"
          name="custom-content-url"
          data-automation="content-vanity-path"
          @change="handleInput"
        />
      </div>
      <div class="content-url__custom-url-label">
        URL
        <span
          v-if="disableCopy"
          class="content-url__custom-url-label__disabled-tip"
        >
          copying disabled until saved
        </span>
      </div>
      <div
        class="textInputContainer content-url__full-url"
      >
        <div
          role="link"
          :full-url="fullUrl"
          class="content-url__full-url-text"
          :class="disableCopy ? 'disabled' : ''"
          aria-label="content-url"
          tabindex="0"
          data-automation="content-vanity-url"
          @click="selectText"
          @keyup.space="selectText"
        >
          {{ fullUrl }}
        </div>
        <CopyButton
          class="smallTextButton content-url__copy-url"
          data-automation="copy-vanity-url"
          :copy-text="fullUrl"
          :disabled="disableCopy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CopyButton from '@/components/CopyButton.vue';
import RSInputText from '@/elements/RSInputText';
import { contentURL, serverURL } from '@/utils/paths';

export default {
  name: 'ContentUrl',
  components: {
    RSInputText,
    CopyButton
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    appGuid: {
      type: String,
      required: true,
    },
    canCustomize: {
      type: Boolean,
      default: false,
    },
    canEditSettings: {
      type: Boolean,
      default: false,
    },
    disableCopy: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showCopied: false,
    };
  },
  computed: {
    fullUrl() {
      return this.modelValue === '' ? contentURL(this.appGuid) : serverURL(`${this.modelValue }/`);
    },
  },
  methods: {
    selectText(e) {
      const input = e.target;
      if (!this.disableCopy) { window.getSelection().selectAllChildren(input); }
    },
    handleInput(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.content-url {
  &__custom-url {
    padding-top: 0;

    &-label {
      font-size: 0.9rem;
      line-height: 1.5rem;
      color: #333;
      margin-top: 0.4rem;

      &__disabled-tip {
        font-style: italic;
        font-size: 0.85em;
        padding-left: 0.5rem;
        color: $color-dark-grey;
      }
    }
  }

  &__full-url {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid #c8c8c8;
    width: 100%;
    max-width: 100%;
    padding: 0 0.6rem 0.4rem 0.6rem;
    background-color: #fff;
    color: rgb(89, 88, 88);
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    margin-top: 0.2rem;

    &-text {
      font-size: 13px;
      line-height: 16px;
      margin-top: 0.5rem;
      min-width: 0;
      overflow-wrap: break-word;
      @include control-visible-focus();

      &.disabled {
        user-select: none;
        -webkit-user-select: none;
      }
    }
  }

  &__copy-url {
    margin: 5px 0 0 5px;
    padding: 2px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: $color-button-background;
    transition-property: background-color;
    transition-duration: 0.25s;
    color: $color-button-text;
    font-size: 13px;
    line-height: 30px;
    min-width: unset;

    &:hover {
      background-color: $color-button-background-hover;
      text-decoration: none;
    }
  }
}
.mb {
  margin-bottom: 0.25rem;
}
</style>

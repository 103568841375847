<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div
    :data-automation="dataAutomation"
    class="rs-field"
  >
    <div
      v-if="showLabel"
      :class="{ small }"
      class="rs-field__help"
    >
      <label
        :for="name"
        :class="{ small }"
        class="rs-field__help-label"
      >{{
        label
      }}</label>
      <i
        v-if="hasHelp"
        :class="{ active: helpShown }"
        tabindex="0"
        class="rs-field__help-icon"
        role="button"
        aria-label="More Information"
        @click="toggleHelp"
        @keypress="toggleHelp"
      />
    </div>

    <!-- help text -->
    <div
      v-if="helpShown"
      class="rs-field__text"
    >
      <span v-if="$slots.help">
        <slot name="help" />
      </span>
      <span v-else>{{ help }}</span>
    </div>
    <div
      :class="controlClass"
      class="rs-field__control"
    >
      <div class="rs-field__control__box">
        <input
          v-bind="$attrs"
          :id="name"
          ref="input"
          :aria-label="label"
          :class="{
            error: hasError,
            warning: hasWarning,
            info: hasInfo,
            small,
            clear,
            mode: $slots.mode
          }"
          :name="name"
          class="rs-input"
          type="text"
          autocomplete="off"
          autocorrect="off"
          :value="modelValue"
          :aria-describedby="`${ name }-message`"
          :aria-invalid="hasError"
          @focus="setFocused(true)"
          @blur="setFocused(false)"
          @input="handleValue"
          @keydown.esc="blur"
          @keydown.up.prevent="moveHighlight('up')"
          @keydown.down.prevent="moveHighlight('down')"
          @keydown.enter.prevent="onPressEnter"
        >
        <div
          v-if="icon && !loading"
          :class="iconClass"
        />
        <RSButton
          v-show="showClear && !loading"
          :id="`${name}-clear`"
          class="rs-search-clear"
          icon-only
          label="Clear"
          type="link"
          :icon="iconClose"
          @click="clearSearch"
        />
        <div
          v-if="loading"
          class="rs-icon spinner"
        >
          <div class="dot one" />
          <div class="dot two" />
          <div class="dot three" />
          <div class="dot four" />
        </div>
        <slot name="mode" />
      </div>
      <div
        v-if="showResults"
        class="rs-select-options"
      >
        <div
          v-for="(result, index) in searchResults"
          :key="index"
          :class="optionClasses(result)"
          tabindex="0"
          role="menuitem"
          @focus="setHighlighted(result)"
          @mouseover="setHighlighted(result)"
          @mousedown.prevent="select(result)"
        >
          <slot
            v-bind="result"
            name="result"
          />
        </div>
      </div>
    </div>

    <!-- message text -->
    <div
      v-if="hasMessage"
      :id="`${ name }-message`"
      :class="{ 'rs-field__error': hasError, 'rs-field__warning': hasWarning, 'rs-field__info': hasInfo }"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
import RSInputText from './RSInputText';
import RSButton from './RSButton.vue';
import iconClose from '../../images/elements/close.svg';

export default {
  name: 'RSInputSearch',
  components: { RSButton },
  extends: RSInputText,
  props: {
    modelValue: {
      type: String,
      default: null
    },
    searchResults: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    showClear: {
      type: Boolean,
      default: false
    },
    pressEnter: {
      type: Function,
      default: null,
    },
  },
  emits: [
    'change',
    'clear',
    'select',
    'emptySelect',
    'focus',
    'blur',
    'update:modelValue',
  ],
  data() {
    return {
      focused: null,
      highlighted: null,
      iconClose,
    };
  },
  computed: {
    showResults() {
      return this.searchResults.length > 0 && this.focused;
    },
    clear() {
      return this.showClear;
    },
  },
  watch: {
    searchResults: {
      handler() {
        if (this.searchResults.length > 0) {
          this.highlighted = this.searchResults[0];
        } else {
          this.highlighted = null;
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleValue(ev) {
      this.$emit('change', ev.target.value);
      this.$emit('update:modelValue', ev.target.value);
    },
    optionClasses(result) {
      if (this.highlighted === result) {
        return 'rs-select-option rs-select-option--highlight';
      }
      return 'rs-select-option';
    },
    clearSearch() {
      this.$emit('clear');
      this.focusElement();
    },
    focusElement() {
      this.$refs.input.focus();
    },
    setHighlighted(result) {
      this.highlighted = result;
    },
    select(result) {
      this.$emit('select', result);
      this.blur();
    },
    onPressEnter(ev) {
      // If a press enter handler exsits, use that
      if (this.pressEnter) {
        this.pressEnter(ev);
        return;
      }

      if (this.highlighted) {
        this.$emit('select', this.highlighted);
        this.blur();
      } else {
        this.$emit('emptySelect');
      }
    },
    setFocused(value) {
      this.focused = value;
      if (value) {
        this.$emit('focus');
      } else {
        this.$emit('blur');
      }
    },
    blur() {
      // Verify if input still exists before blur
      // due to destroyed parent/component might show errors
      if (this.$refs.input) {
        this.$refs.input.blur();
        this.$emit('blur');
      }
    },
    moveHighlight(direction) {
      const options = this.searchResults;
      let index = this.highlighted ? options.indexOf(this.highlighted) : -1;
      switch (direction) {
        case 'up':
          index--;
          if (index >= 0) {
            this.setHighlighted(options[index]);
          }
          break;
        case 'down':
          index++;
          if (index < options.length) {
            this.setHighlighted(options[index]);
          }
          break;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

input {
  box-sizing: border-box;
  padding: 5px 10px;
  border: 1px solid $color-medium-grey;
  &:disabled, &:read-only {
    @include control-disabled-input;
  }
}

  .rs-field__control {
    position: relative;

    &__box {
      background-color: $color-white;
      display: flex;
      border: 1px solid $color-medium-grey;
    }

    & .rs-input {
      flex: 1;

      &.mode {
        border: 0;
        margin-right: 3px;
      }
    }

    &--icon {
      & .rs-input {
        padding-left: 2rem;

        &.small {
          padding-left: 1.5rem;
          padding-top: 0.35rem;
        }

        &.clear {
          padding-right: ($rs-icon-size + 4px);

          &.small {
            padding-right: ($rs-icon-size);
          }
        }
      }

      & .rs-icon {
        position: absolute;
        top: 4px;
        left: 4px;
        bottom: 0;
      }
    }
    & .rs-search-clear {
      border-radius: 0;
      padding: 0.18rem;
      position: absolute;
      right: 2px;
      top: 2px;
    }

    .small ~ .rs-search-clear {
      padding: 0.065rem;
    }
  }

.rs-input {
  width: 100%;
  max-width: 100%;
  font-size: $rs-font-size-normal;
  margin: 0;
  padding: 0.4rem 0.6rem;
  background-color: #fff;
  color: $color-secondary-inverse;

  &:focus {
    @include control-focus;
  }

  &.small {
    font-size: $rs-font-size-small;
    padding: 0.25rem 0.4rem;
  }

  @include message-state;

  &.range {
    padding: 0;
  }
}

  .rs-select {
    &-options {
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
      background-color: $color-white;
      border: 1px solid $color-medium-grey;
      border-top: none;
    }

    &-option {
      padding: 0.4rem 0.6rem;
      font-size: $rs-font-size-normal;
      cursor: pointer;

      &--highlight {
        background-color: $color-light-grey-2;
      }
    }
  }
</style>

// Copyright (C) 2022 by Posit Software, PBC.

// Host reflects the backend struct `HostDTO` in the hosts API
export class Host {
  constructor({
    hostname,
    version,
    lastSeen,
  } = {}) {
    this.hostname = hostname;
    this.version = version;
    this.lastSeen = new Date(lastSeen);
  }

  toJSON() {
    return {
      hostname: this.hostname,
      version: this.version,
      lastSeen: this.lastSeen,
    };
  }
}

// Copyright (C) 2023 by Posit Software, PBC.

// Iframe bus util to communicate with iframes when needed.
import { Bus } from '@/utils/iframeBus';

// Vue plugin to expose methods for a single iframeBus in components.
const VueIframeBus = {
  install(app) {
    app.mixin({
      beforeCreate() {
        const routeNameProvider = () => (this.$route.name || '');
        Bus.Start(routeNameProvider);
        this.$onAdminSelfGrantAccessDo = Bus.OnAdminContentAccessDo.bind(Bus);
      }
    });
  }
};

export default VueIframeBus;

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    class="tags-toggle-info toggleableInfo"
  >
    <ul>
      <li>
        Tags are used to organize your content to make it easy for your users to
        find the content they're interested in.
      </li>
      <li>
        As an administrator, you define the tagging schema that your data scientists
        then use to categorize the content they publish.
      </li>
    </ul>
    <h4 class="tags-toggle-info__description-title">
      Defining Your Schema
    </h4>
    <p>
      The definition of the tagging schema for the content in your
      organization starts with categories. Within each category, you define tags,
      which can be nested any number of levels deep.
      <span
        v-if="exampleCategories.length > 1"
      >
        {{ `Some current categories in your organization are "${exampleCategories[0]}" and "${exampleCategories[1]}".` }}
      </span>
      <span
        v-else
      >
        {{ `The only category in your organization at this moment is "${exampleCategories[0]}".` }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'TagsHelpInfo',
  props: {
    exampleCategories: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
.toggleableInfo {
  font-size: 13px;
  border: 1px solid $color-light-grey-3;
  margin-bottom: 10px;
  padding: 10px;
  background-color: $color-light-grey;
}
</style>

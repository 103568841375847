<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSModalForm
    :active="true"
    :subject="`Remove ${category}`"
    @close="$emit('deleteModal')"
    @submit="onConfirm"
  >
    <template #content>
      <p class="details">
        {{ `Are you sure you want to remove this ${category}? This operation cannot be undone.` }}
      </p>
    </template>
    <template #controls>
      <RSButton
        type="secondary"
        label="No"
        title="No"
        data-automation="confirm-dialog-cancel"
        @click="$emit('deleteModal')"
      />
      <RSButton
        type="primary"
        label="Yes"
        title="Yes"
        data-automation="confirm-dialog-submit"
      />
    </template>
  </RSModalForm>
</template>

<script>
import { terminateApplication } from '@/api/app';
import { ContentTypeLabel } from '@/api/dto/app';
import RSButton from '@/elements/RSButton';
import RSModalForm from '@/elements/RSModalForm';
import { SHOW_ERROR_MESSAGE, SHOW_INFO_MESSAGE } from '@/store/modules/messages';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'DeleteModal',
  components: { RSModalForm, RSButton },
  emits: ['deleteModal'],
  computed: {
    ...mapState({
      app: state => state.contentView.app,
    }),
    category() {
      return ContentTypeLabel[this.app.contentType()];
    },
    appTitle() {
      return this.app.displayName;
    },
  },
  methods: {
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    onConfirm() {
      return terminateApplication(this.app.id)
        .then(() => {
          this.setInfoMessage({
            message: `Deleted ${this.category}: ${this.appTitle}`,
            autoHide: false,
          });

          return this.$router.push({ name: 'contentList' });
        })
        .catch(() => {
          this.setErrorMessage({
            message: `Unable to delete ${this.category}: ${this.appTitle}`,
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.details {
  white-space: normal;
  text-align: left;
}

button.primary {
  margin-left: 1rem;
}
</style>

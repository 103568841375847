<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
const { activeTabId, tabs } = defineProps({
  tabs: { type: Array, required: true },
  activeTabId: { type: String, required: true },
});

const emit = defineEmits(['update:activeTabId']);
</script>

<template>
  <div
    class="tab-selector"
    data-automation="app-settings-tablist"
    role="radiogroup"
  >
    <label
      v-for="tab in tabs"
      :key="tab.id"
      :for="tab.id"
      :class="[
        'tab',
        { active: tab.id === activeTabId, disabled: tab.disabled }
      ]"
      :data-automation="tab.testId"
    >
      <span :class="['icon', tab.id]" />
      <input
        :id="tab.id"
        name="content-settings-tabs"
        :disabled="tab.disabled"
        :value="tab.id"
        :checked="tab.id === activeTabId"
        type="radio"
        @change="emit('update:activeTabId', $event.target.value)"
      >
      {{ tab.name }}
    </label>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.tab-selector {
  &:has(:focus-visible) {
    @include control-focus();
  }

  display: flex;
  justify-content: space-between;

  .tab {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    padding: 5px;
    text-align: center;

    input {
      appearance: none;
      border: none;
      margin: 0;
      opacity: 0;
      outline: none;
      padding: 0;
    }

    &:hover {
      background-color: $color-white;
    }

    &.active {
      background-color: $color-primary;
      color: $color-white;

      .icon {
        background-position: center -60px;
      }
    }

    .icon {
      cursor: pointer;
      display: inline-block;
      height: 35px;
      margin-right: 1px;
      min-width: 40px;
      padding: 25px 5px 0;
      width: 35px;

      background-color: transparent;
      background-position: center 0px;
      background-repeat: no-repeat;
      background-size: 30px 90px;

      &.info {
        background-image: url(Images/elements/tabInfo.svg);
      }
      &.access {
        background-image: url(Images/elements/tabAccess.svg);
      }
      &.runtime {
        background-image: url(Images/elements/tabPerformance.svg);
      }
      &.tags {
        background-image: url(Images/elements/tabTags.svg);
      }
      &.schedule {
        background-image: url(Images/elements/tabSchedule.svg);
      }
      &.environmentVariables {
        background-image: url(Images/elements/tabEnvironmentVariables.svg);
      }
    }
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.active {
  background-color: $color-primary;
  color: $color-white;
}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

// Set the focus if value is true (not only initial)
export const FocusIf = {
  update: function(el, binding) {
    if (binding.value && binding.value !== binding.oldValue) {
      el.focus();
    }
  },
};

// Set the initial focus on an element
const Focus = {
  inserted: function(el) {
    el.focus();
  },
};

export default Focus;

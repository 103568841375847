// Copyright (C) 2023 by Posit Software, PBC.

export class Bundle {
  constructor({
    id,
    contentGuid,
    createdTime,
    clusterName,
    imageName,
    rVersion,
    pyVersion,
    quartoVersion,
    active,
    size,
    metadata = {},
  } = {}) {
    this.id = id;
    this.contentGuid = contentGuid;
    this.clusterName = clusterName;
    this.imageName = imageName;
    this.rVersion = rVersion;
    this.pyVersion = pyVersion;
    this.quartoVersion = quartoVersion;
    this.active = active;
    this.size = size;
    this.metadata = metadata;
    this.createdTime = new Date(createdTime);
  }

  toJSON() {
    return {
      id: this.id,
      contentGuid: this.contentGuid,
      clusterName: this.clusterName,
      imageName: this.imageName,
      rVersion: this.rVersion,
      pyVersion: this.pyVersion,
      quartoVersion: this.quartoVersion,
      active: this.active,
      size: this.size,
      metadata: this.metadata,
      createdTime: this.createdTime,
    };
  }

  toString() {
    return `Bundle(${this.id})`;
  }
}

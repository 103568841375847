<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <p>Override this, please.</p>
</template>

<script>
import { ContentTypeDescriptions } from '@/constants/contentTypes';
import { mapState } from 'vuex';

export const validateStep = value => {
  return [null, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(value);
};

export const sourceFromState = state => {
  return state.publish.copyFrom.files.filter(
    f => !/(^readme\..+|\.(rproj|db|yml|gz|txt))$/i.test(f)
  )[0];
};

export const rprojFromState = state => {
  return state.publish.copyFrom.files.filter(
    f => /\.rproj$/i.test(f)
  )[0];
};

/* eslint-disable vue/no-unused-properties */

export default {
  name: 'PanelMixin',
  props: {
    step: {
      default: null,
      validator: validateStep,
    },
  },
  computed: {
    ...mapState({
      copyFrom: state => state.publish.copyFrom,
      zip: state => `${state.publish.copyFrom.name}.zip`,
      dir: state => state.publish.copyFrom.name,
      rproj: rprojFromState,
      source: sourceFromState,
      systemDisplayName: state => state.server.settings.systemDisplayName,
    }),
    typeString() {
      return ContentTypeDescriptions[this.copyFrom.type];
    },
  },
};
</script>

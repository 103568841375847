// Copyright (C) 2022 by Posit Software, PBC.

// Initialize Vue.js runtime environment
import { createApp } from 'vue';
import flatPickr from 'vue-flatpickr-component';

// Initialize Axios for our Vue.js app
import '@/api/axiosSetup';

// Redux and angular router plugins
import VueIframeBus from '@/plugins/iframeBus';

// Vuex store
import { store } from '@/store';

// Vue router
import router from '@/router';

import App from '@/App';

const app = createApp(App);
app
  .use(VueIframeBus)
  .use(router)
  .use(store)
  .component('flat-pickr', flatPickr)
  .mount('#connectui');

// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';

import { apiPath, serverURL } from '@/utils/paths';
import { keysToCamel, keysToSnake } from '@/api/transform';

export function setPassword(utoken, newPassword) {
  return axios.post(
    apiPath('me/password'),
    keysToSnake({ utoken, newPassword })
  );
}

/**
 * Change the user's password.
 *
 * @param {string} currentPassword - existing password
 * @param {string} newPassword - new password
 * @returns {Promise<{}>}
 */
export function changePassword(currentPassword, newPassword) {
  return axios.post(
    apiPath('me/password'),
    keysToSnake({ password: currentPassword, newPassword })
  );
}

/**
 * Gets data required to display a captcha challenge.
 *
 * @returns {Promise<{challengeId: string, challengeData:Array<{mimeType: string, payload: string}>}>}
 */
export function getCaptcha() {
  return axios
    .get(serverURL('__captcha__'))
    .then(({ data }) => keysToCamel(data));
}

/**
 * Authenticates a user with the system so they are in a logged-in state.
 *
 * @param {string} username
 * @param {string} password
 * @param {string} [invalidatePassword] - Invalidate token/password used to confirm an account
 * @param {string} [challengeId] - needed when `serverSettings.authentication.challengeResponseEnabled = true`
 * @param {string} [challengeResponse] - needed when `serverSettings.authentication.challengeResponseEnabled = true`
 * @returns {Promise<{passwordExpired: boolean}>}
 */
export function login({
  username,
  password,
  challengeId,
  challengeResponse,
  invalidatePassword = false,
} = {}) {
  const params = keysToSnake({ username, password, invalidatePassword });
  if (challengeId && challengeResponse) {
    params.challenge = challengeId;
    params.response = challengeResponse;
  }
  return axios
    .post(serverURL('__login__'), params)
    .then(({ data }) => keysToCamel(data));
}

/**
 * Checks if the current user is in restricted access mode.
 *
 * @returns {Promise<boolean>}
 */
export function getRestrictedAccessMode() {
  return axios
    .get(apiPath('me/restricted_access_mode'))
    .then(() => false)
    .catch(() => true);
}

/**
 * Send or get confirmation link for an account.
 *
 * @param {string} userGuid - guid of the user
 * @returns {Promise<null | {url: string}>} - null if sending email is not configured on the server,
 *     otherwise object containing the account confirmation link.
 */
export function sendOrGetAccountConfirmationLink(userGuid) {
  return axios
    .post(`${apiPath('users')}/${encodeURIComponent(userGuid)}/confirm/resend`)
    .then(({ data }) => data);
}

/**
 * Send or get reset password for a user.
 *
 * @param {string} username - username of the user
 * @returns {Promise<null | {url: string}>} - null if sending email is not configured on the server,
 *     otherwise object containing the reset link.
 */
export function sendOrGetResetPasswordLink(username) {
  return axios
    .post(`${apiPath('users')}/${encodeURIComponent(username)}/password`)
    .then(({ data }) => data);
}

<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<template>
  <div>
    <div
      class="formSection"
      data-automation="params-actions"
    >
      <div class="actions flex">
        <button
          v-if="canEdit"
          class="auxAction"
          :class="{ disabled: !isSaveEnabled }"
          :disabled="!isSaveEnabled"
          data-automation="params-save-btn"
          @click="onSave"
        >
          Save
        </button>
        <button
          v-if="canEdit"
          class="auxAction"
          data-automation="params-save-as-btn"
          @click="onSaveAs"
        >
          Save As
        </button>
        <button
          :class="{ disabled: !isRunEnabled }"
          :disabled="!isRunEnabled"
          data-automation="params-run-btn"
          @click="onRun"
        >
          Run
        </button>
      </div>
    </div>

    <div class="formSection tight">
      <div class="actionBar panelActionBar">
        <button
          v-if="isRenameEnabled && canEdit"
          class="action rename"
          data-automation="params-rename-btn"
          @click="onRename"
        >
          Rename
        </button>
        <button
          v-if="isDeleteEnabled && canEdit"
          class="action delete"
          data-automation="params-delete-btn"
          @click="confirmDelete = true"
        >
          Delete
        </button>
        <button
          v-if="isRevertEnabled"
          class="action revert"
          data-automation="params-revert-btn"
          @click="onRevert"
        >
          Revert
        </button>
      </div>
    </div>

    <ConfirmModal
      v-if="confirmDelete"
      subject="Delete Report"
      confirmation="Delete"
      data-automation="legacy-params__confirm-delete"
      @confirm="onDelete"
      @cancel="confirmDelete = false"
    >
      <EmbeddedStatusMessage
        type="warning"
        class="legacy-params__delete-warning"
        message="This action cannot be undone and will delete all parameter values and renderings associated with this report configuration."
        :show-close="false"
      />

      <p>
        Are you sure that you want to delete the <strong>{{ currentVariantName }}</strong> report configuration?
      </p>
    </ConfirmModal>
  </div>
</template>

<script>
import ConfirmModal from '@/components/ConfirmModal';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage.vue';
import {
  CLOSE_PANEL,
  LOAD_RENDERINGS_HISTORY,
  PARAMETERS_PANEL,
  TOGGLE_PANELS,
} from '@/store/modules/contentView';
import {
  LEGACY_PARAMS_DELETE_VARIANT,
  LEGACY_PARAMS_REVERT_CHANGES,
  LEGACY_PARAMS_RUN_REPORT,
  LEGACY_PARAMS_SAVE_VARIANT,
  LEGACY_PARAMS_SET_RENAMING_VARIANT,
  LEGACY_PARAMS_SET_SAVING_VARIANT,
} from '@/store/modules/legacyParams';
import { SHOW_ERROR_MESSAGE, SHOW_INFO_MESSAGE } from '@/store/modules/messages';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'ParametersActions',
  components: {
    ConfirmModal,
    EmbeddedStatusMessage,
  },
  emits: ['deleteVariant'],
  data: () => ({
    confirmDelete: false,
  }),
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
      app: state => state.contentView.app,
      currentVariant: state => state.parameterization.currentVariant,
      isDirty: state => state.legacyParams.form.dirty,
      isBusy: state => state.legacyParams.isBusy,
      isRendered: state => state.legacyParams.isRendered,
    }),
    isDefault() {
      return this.currentVariant.isDefault;
    },
    canEdit() {
      return this.currentUser.isAppEditor(this.app);
    },
    isRenameEnabled() {
      return !this.isDirty;
    },
    isDeleteEnabled() {
      return !this.isDefault && !this.isDirty;
    },
    isRevertEnabled() {
      return this.isDirty || this.isRendered;
    },
    isSaveEnabled() {
      if (this.isDefault) {
        // Parameter updates (dirty) cannot be saved back to default.
        // Renderings can be saved back to default.
        if (this.isDirty) {
          return false;
        } else if (this.isRendered) {
          return true;
        } 
        return false;
      }

      // non-default variants can have parameter updates and renderings saved.
      return this.isDirty || this.isRendered;
    },
    isRunEnabled() {
      return !this.isBusy;
    },
    currentVariantName() {
      return this.currentVariant.name;
    },
  },
  methods: {
    ...mapMutations({
      openRenameVariant: LEGACY_PARAMS_SET_RENAMING_VARIANT,
      openSaveVariant: LEGACY_PARAMS_SET_SAVING_VARIANT,
    }),
    ...mapActions({
      run: LEGACY_PARAMS_RUN_REPORT,
      save: LEGACY_PARAMS_SAVE_VARIANT,
      revertChanges: LEGACY_PARAMS_REVERT_CHANGES,
      deleteVariant: LEGACY_PARAMS_DELETE_VARIANT,
      togglePanels: TOGGLE_PANELS,
      loadRenderings: LOAD_RENDERINGS_HISTORY,
      setInfoMessage: SHOW_INFO_MESSAGE,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    onDelete() {
      this.confirmDelete = false;
      this.$emit('deleteVariant');

      return this.deleteVariant()
        .then(() => {
          return this.loadRenderings(this.currentVariant.id);
        })
        .then(() => {
          this.$router.push({ params: { id: this.currentVariant.id } });
          this.setInfoMessage({ message: 'Report has been deleted.' });
          return this.togglePanels({
            panel: PARAMETERS_PANEL,
            action: CLOSE_PANEL,
          });
        })
        .catch(() => {
          this.setErrorMessage({ message: 'Unable to delete report.' });
        });
    },
    onRename() {
      this.openRenameVariant(true);
    },
    onRevert() {
      this.revertChanges();
    },
    onSave() {
      this.save()
        .then(() => {
          this.setInfoMessage({ message: 'Parameters have been saved.' });
        })
        .catch(err => {
          console.error(err);
          this.setErrorMessage({ message: 'Unable to save parameters.' });
        });
    },
    onSaveAs() {
      this.openSaveVariant(true);
    },
    onRun() {
      this.run(this.currentVariant.id);
    },
  }
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_mixins';
.legacy-params__delete-warning {
  margin-bottom: 1rem;
}

.panelActionBar {
  @include flex-space-between();
  margin-right: 0px;

  .action {
    display: block;
    background-size: 30px 30px;

    padding: 0 5px 0 30px;
    background-position: 0px center;
    width: auto;
    margin-left: 0px;

    .actionTitle {
      display: inline-block;
      white-space: nowrap;
    }
  }
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <OutroPanel
    learn-more-title="Learn more about Quarto"
    learn-more-text="Learn more about Quarto"
    learn-more-target="https://quarto.org/docs/guide"
  >
    <template #body>
      <p>
        Once your content is published, you control who can access it,
        when it gets updated, and much more via its settings panels.
      </p>
    </template>
  </OutroPanel>
</template>

<script>
import OutroPanel from '../OutroPanel';

export default {
  name: 'QuartoOutro',
  components: { OutroPanel }
};
</script>

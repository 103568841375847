<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <CopyTextDialog
    :title="`API Key: ${apiKey.name}`"
    description="Here's your new key. Copy it now! This is the only time we'll show it to you."
    copied-message="Your key has been copied."
    :text-label="`Your new ${apiKey.name} API key value`"
    :text="apiKey.key"
    data-automation="new-api-key"
  />
</template>

<script>
import CopyTextDialog from '@/components/CopyTextDialog';

export default {
  name: 'NewKeyConfirmation',
  components: {
    CopyTextDialog,
  },
  props: {
    apiKey: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showKeyCopied: false,
      copiedTimeout: null,
    };
  },
};
</script>

<style lang="scss">
  #ctd-text {
    margin: 0.8rem 0;
    text-align: center;
    width: 100%;
  }
</style>

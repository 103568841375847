<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <tr
    :class="componentClass"
    :data-automation="dataAutomation"
    :role="clickable ? 'button' : 'row'"
    :tabindex="clickable ? '0' : null"
    :aria-label="rowLabel"
    @click.stop="clickHandler"
    @keydown.enter.stop="clickHandler"
    @keydown.space.stop="clickHandler"
  >
    <slot />
    <td
      v-if="deletable"
      data-automation="rs-tablerow__delete"
      class="rs-tablerow__delete"
    >
      <button
        :aria-label="deleteButtonLabel"
        data-automation="rs-tablerow__button—delete"
        @click.stop="({ currentTarget }) => $emit('delete', rowId, currentTarget)"
      />
    </td>
  </tr>
</template>

<script>
const defaultDeleteLabel = 'Delete item';
const errorMsgRequiredRowLabel = 'RSTableRow - row-label prop is required when row is clickable';
const errorMsgRequiredRowId = 'RSTableRow - row-id prop is required when row is clickable';
const rowClass = {
  base: 'rs-tablerow',
  clickable: 'rs-tablerow--clickable',
};

export default {
  name: 'RSTableRow',
  props: {
    dataAutomation: {
      type: String,
      default: null,
    },
    rowId: {
      type: [String, Number],
      default: '',
    },
    rowLabel: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    deleteButtonLabel: {
      type: String,
      default: defaultDeleteLabel,
    },
  },
  emits: ['click', 'delete'],
  computed: {
    componentClass() {
      const classMap = [rowClass.base];
      if (this.clickable) {
        classMap.push(rowClass.clickable);
      }
      return classMap;
    }
  },
  created() {
    if (this.clickable) {
      if (!this.rowLabel) {
        throw new Error(errorMsgRequiredRowLabel);
      }
      if (!this.rowId) {
        throw new Error(errorMsgRequiredRowId);
      }
    }
  },
  methods: {
    clickHandler() {
      if (this.clickable) {
        this.$emit('click', this.rowId);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-tablerow {
  border-bottom: 1px solid $color-light-grey;

  &--clickable {
    @include clickable-table-cell(darken($color-light-grey, 3%), 250ms);

    &:hover {
      color: $color-link-hover-cell;
    }

    &:focus {
      @include control-focus();
      outline-offset: -3px;
    }
  }

  &__delete {
    padding: 0 3px;
    vertical-align: middle;
    width: 50px;

    button {
      background-color: transparent;
      background-image: url(/images/elements/actionDelete.svg);
      background-size: $rs-icon-size $rs-icon-size;
      background-repeat: no-repeat;
      background-position: center center;
      border: none;
      cursor: pointer;
      display: inline-block;
      height: $rs-icon-size;
      width: $rs-icon-size;

      @include control-visible-focus;
    }
  }
}
</style>

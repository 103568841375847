// Copyright (C) 2022 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import RunExample from './panels/RunExample';
import PythonDependencies from './panels/PythonDependencies';
import VetiverOutro from './panels/VetiverOutro';

export default {
  copyFrom: [
    DownloadExample,
    PythonDependencies,
    RunExample,
    VetiverOutro,
  ],
};

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import TabSelector, { TabShape } from '@/components/TabSelector.vue';
import { useSkipToMainContent } from '@/composables/skipToMainContent';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const router = useRouter();
const store = useStore();

useSkipToMainContent(() => tabSelector.value?.focusTab(route.name));

const showNavigationLinks = computed(() => (
  (!store.state.server.settings.authentication.externalGroupId ||
    store.state.server.settings.authentication.externalGroupSearch) &&
  store.state.server.settings.authentication.groupsEnabled && 
  route.params?.groupId === undefined
));

const selectedPage = ref(route.name);

const tabs = [
  {
    id: 'people.users',
    testId: 'people-nav-users',
    label: 'Users',
  },
  {
    id: 'people.groups',
    label: 'Groups',
    testId: 'people-nav-groups',
  },
];

const tabSelector = ref(null);

const onTabSelect = (tabId) => {
  selectedPage.value = tabId;
  router.replace({ name: tabId });
};
</script>

<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div class="tab-bar">
        <TabSelector
          v-if="showNavigationLinks"
          ref="tabSelector"
          name="admin-tabs"
          :active-tab-id="route.name"
          :shape="TabShape.SQUARE"
          :tabs="tabs"
          @update:active-tab-id="onTabSelect"
        />
      </div>

      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.tab-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

// ContentTypes is used to apply a filter for fetching the list of content for a user,
// i.e. shiny, document, plot, etc. For the purpose of fetching content on the dashboard.
// Holds singleton values and a construction function for raw content type
// values.
const ContentTypes = {
  Application: 'application',
  Document: 'document',
  Plot: 'plot',
  Pin: 'pin',
  Api: 'api',
  All: 'all',

  isAll(value) {
    return value === ContentTypes.All || !value;
  }
};

export default Object.freeze(ContentTypes);

// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';

import { apiV1Path } from '@/utils/paths';
import { keysToCamel } from '@/api/transform';
import { Job, JobKillOrder } from '@/api/dto/job';

export const getJob = (guid, key) => {
  const url = apiV1Path(`content/${guid}/jobs/${key}`);
  return axios.get(url).then(({ data }) => new Job(keysToCamel(data)));
};

export const getJobs = guid => {
  const url = apiV1Path(`content/${guid}/jobs`);
  return axios
    .get(url)
    .then(({ data }) => keysToCamel(data).map(job => new Job(job)));
};

export const getJobLog = (guid, key) => {
  const url = apiV1Path(`content/${guid}/jobs/${key}/log`);
  return axios.get(url).then(({ data }) => keysToCamel(data.entries));
};

export const getJobError = (guid, key) => {
  const url = apiV1Path(`content/${guid}/jobs/${key}/error`);
  return axios.get(url).then(({ data }) => keysToCamel(data));
};

export const killJob = (guid, key) => {
  const url = apiV1Path(`content/${guid}/jobs/${key}`);
  return axios.delete(url).then(({ data }) => new JobKillOrder(keysToCamel(data)));
};

export const tailJobPath = (guid, key) => apiV1Path(`content/${guid}/jobs/${key}/tail`);

export const downloadJobLogPath = (guid, key) => apiV1Path(`content/${guid}/jobs/${key}/download`);

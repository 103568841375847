// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiV1Path } from '@/utils/paths';
import { keysToCamel, keysToSnake } from './transform';

/**
 * API calls for custom URLs (vanities)
 */

/**
 * Sets a new custom URL
 * @param {number} appGuid Target content identifier.
 * @param {string} path The custom URL path.
 * @returns {Promise} A Promise that resolves with the created custom URL.
 */
export function setCustomUrl(appGuid, path) {
  return axios.put(
    apiV1Path(`content/${ encodeURIComponent(appGuid) }/vanity`),
    keysToSnake({ path }),
  ).then(response => {
    return keysToCamel(response.data);
  });
}

/**
 * Deletes a custom URL
 * @param {number} appGuid Target content identifier.
 * @returns {Promise} The executed Promise for the deletion.
 */
export function deleteCustomUrl(appGuid) {
  return axios.delete(apiV1Path(`content/${ encodeURIComponent(appGuid) }/vanity`));
}

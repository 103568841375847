<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<script setup>
import TabSelector, { TabShape } from '@/components/TabSelector.vue';
import { useSkipToMainContent } from '@/composables/skipToMainContent';
import RSInputSelect from '@/elements/RSInputSelect.vue';
import { useWindowSize } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const { width } = useWindowSize();

useSkipToMainContent(() => {
  tabSelector.value?.focusTab(route.name);
  dropDownSelector.value?.focusElement();
});

const selectedPage = ref(route.name);

const tabs = [
  {
    id: 'admin.metrics',
    testId: 'admin-nav-metrics',
    label: 'Metrics',
  }, {
    id: 'admin.mail_settings',
    testId: 'admin-nav-settings',
    label: 'Mail Settings',
  }, {
    id: 'admin.tag_editor',
    testId: 'admin-nav-tags',
    label: 'Tags',
  }, {
    id: 'admin.audit_logs',
    testId: 'admin-nav-logs',
    label: 'Audit Logs',
  }, {
    id: 'admin.scheduled_content',
    testId: 'admin-nav-scheduled',
    label: 'Scheduled Content',
  }, {
    id: 'admin.system_checks',
    testId: 'admin-nav-systemchecks',
    label: 'System Checks',
  }, {
    id: 'admin.processes',
    testId: 'admin-nav-processes',
    label: 'Processes',
  }, {
    id: 'admin.queue',
    testId: 'admin-nav-queue',
    label: 'Queue',
  },
];

const options = tabs.map(({ id, label }) => ({
  value: id,
  label,
}));

const tabSelector = ref(null);
const dropDownSelector = ref(null);

const onTabSelect = (tabId) => {
  selectedPage.value = tabId;
  router.replace({ name: tabId });
};

const isLargeWindow = computed(() => width.value > 1023);
</script>

<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div
        v-if="isLargeWindow"
        class="tab-bar"
      >
        <TabSelector
          ref="tabSelector"
          name="admin-tabs"
          :active-tab-id="route.name"
          :shape="TabShape.SQUARE"
          :tabs="tabs"
          @update:active-tab-id="onTabSelect"
        />
      </div>
      <div
        v-else
        class="select-box"
      >
        <RSInputSelect
          ref="dropDownSelector"
          v-model="selectedPage"
          label="Admin Pages"
          name="admin-tabs"
          :show-label="false"
          :options="options"
          @change="onTabSelect"
        />
      </div>
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.tab-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.select-box {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
</style>

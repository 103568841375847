<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__jupyter-open wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Open the Jupyter Notebook
      </StepHeading>
      <p>
        Unzip <code>{{ zip }}</code>, then navigate to the <code>{{ dir }}</code>
        directory and upload <code>{{ source }}</code> from within Jupyter:
      </p>
      <p>
        <a
          href="https://jupyter.org/"
          target="_blank"
        >
          Learn more about Jupyter
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'JupyterNotebookOpen',
  components: { StepHeading },
  mixins: [PanelMixin],
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__jupyter-open {
    background-image: url('./background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

import { updateUserPreferences } from '@/api/users';

export const JUMP_START_CLOSE = 'JUMP_START_CLOSE';
export const JUMP_START_HIDE = 'JUMP_START_HIDE';
export const JUMP_START_INIT = 'JUMP_START_INIT';
export const JUMP_START_OPEN = 'JUMP_START_OPEN';
export const JUMP_START_UNHIDE = 'JUMP_START_UNHIDE';
export const JUMP_START_UPDATE_HIDDEN = 'JUMP_START_UPDATE_HIDDEN';
export const JUMP_START_RESET_BACK_NAVIGATION = 'JUMP_START_RESET_BACK_NAVIGATION';

export default {
  state: {
    active: false,
    hide: true,
    seen: false,
    backNavigation: false
  },
  mutations: {
    [JUMP_START_OPEN](state, payload) {
      state.active = true;
      state.seen = true;
      state.backNavigation = payload && payload.backNavigation;
    },
    [JUMP_START_CLOSE](state) {
      state.active = false;
    },
    [JUMP_START_HIDE](state) {
      state.hide = true;
    },
    [JUMP_START_UNHIDE](state) {
      state.hide = false;
    },
    [JUMP_START_RESET_BACK_NAVIGATION](state) {
      state.backNavigation = false;
    }
  },
  actions: {
    [JUMP_START_INIT]({ state, commit, rootState: { currentUser: { user } } }) {
      if (user.preferences) {
        if (user.preferences.hideJumpStart) {
          commit(JUMP_START_HIDE);
          return;
        }
      }
      commit(JUMP_START_UNHIDE);
      if (state.seen) {
        return;
      }
      commit(JUMP_START_OPEN);
    },
    [JUMP_START_UPDATE_HIDDEN]({ commit, rootState: { currentUser: { user } } }, value) {
      return updateUserPreferences(user.guid, { hideJumpStart: value })
        .then(() => {
          if (value) {
            commit(JUMP_START_HIDE);
            return;
          }
          commit(JUMP_START_UNHIDE);
        })
        .catch(e => {
          return e;
          // NOTE: this error is silently ignored because we de not currently
          // have a good way to surface such errors or otherwise allow the user
          // to *do something* in response.
        });
    },
  },
};

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="rsc-search-box">
    <RSInputSearch
      v-model="form.search"
      :data-automation="dataAutomation"
      :icon="icon"
      :label="label"
      :loading="loading"
      :name="name"
      :search-results="searchResults"
      :show-label="false"
      :show-clear="showClear"
      @blur="onBlur"
      @focus="onFocus"
      @clear="clearSearchExt"
      @change="onInput"
      @select="onSelect"
      @empty-select="onEmptySelect"
    >
      <template #result="principal">
        <RSPrincipalInfo
          :is-group="isGroup(principal)"
          :initials="principal.displayInitials"
          :name="principal.displayName"
          :additional-icon="viewerIcon(principal)"
          :tooltip="viewerTooltip(principal)"
          :status="principal.username"
        />
      </template>
      <template #mode>
        <ModeSelector />
      </template>
    </RSInputSearch>
    <!-- eslint-disable vue/no-unused-refs -->
    <MessageBox
      v-show="showHelp"
      ref="helpBox"
      small
      closeable
      @close="closeSearchHelp"
    >
      <!-- eslint-disable vue/no-unused-refs -->
      <slot name="help" />
    </MessageBox>
  </div>
</template>

<script>
import UserRoles from '@/api/dto/userRole';
import MessageBox from '@/components/MessageBox';
import PrincipalSearchSelect from '@/components/PrincipalSearchSelect';
import RSInputSearch from '@/elements/RSInputSearch';
import RSPrincipalInfo from '@/elements/RSPrincipalInfo';
import { ModeType } from '@/store/modules/accessSettings';
import { debounce } from '@/utils/debounce';
import { mapState } from 'vuex';
import ModeSelector from './ModeSelector';

export default {
  name: 'SearchBox',
  components: {
    MessageBox,
    ModeSelector,
    RSInputSearch,
    RSPrincipalInfo,
  },
  extends: PrincipalSearchSelect,
  props: {
    exclusionSet: {
      type: Set,
      default() { return new Set(); },
    },
  },
  emits: ['input', 'select'],
  data() {
    return {
      findingTrackTimeout: null,
      unsuccessfulFind: false,
    };
  },
  computed: {
    ...mapState({
      mode: state => state.accessSettings.mode,
    }),
    isCollaborator() {
      return this.mode === ModeType.OWNER || this.mode === ModeType.EDITOR;
    },
    showHelp() {
      return this.unsuccessfulFind && Boolean(this.$slots.help);
    },
    searchResults() {
      return this.api.principals.filter(user => !this.exclusionSet.has(user.guid));
    },
  },
  watch: {
    searchResults() {
      this.adjustHelpTop();
    },
  },
  methods: {
    isViewer(principal) {
      return this.isUser(principal)
        ? principal.userRole === UserRoles.Viewer
        : false;
    },
    adjustHelpTop() {
      const { searchResults, $refs: { helpBox } } = this;
      const helpMarginTop = (searchResults.length * 43) + 16;
      helpBox.$el.style.marginTop = `${helpMarginTop}px`;
    },
    closeSearchHelp() {
      this.unsuccessfulFind = false;
    },
    addAsViewer(principal) {
      return this.isCollaborator && this.isViewer(principal);
    },
    viewerIcon(principal) {
      return this.addAsViewer(principal) ? 'viewer' : null;
    },
    viewerTooltip(principal) {
      return this.addAsViewer(principal) ? 'Viewer' : '';
    },
    setPrincipals(principals, prefix) {
      // verify search criteria didn't changed while performing the search
      if (prefix === this.form.search) {
        this.api.principals = principals;
        this.adjustHelpTop();
      }
    },
    clearSearchExt() {
      this.clearSearch(); // original method
      this.adjustHelpTop();
    },
    onFocus() {
      this.adjustHelpTop();
    },
    onBlur() {
      this.adjustHelpTop();
    },
    inputDebounce: debounce(300, function() {
      if (this.form.search !== '') {
        this.search(this.form.search);
      }
      this.$emit('input', this.form.search);
      this.startSearchTrack();
    }),
    onSelect(principal) {
      const mode = this.addAsViewer(principal) ? ModeType.VIEWER : this.mode;
      this.clearStartSearchTrack();
      this.$emit('select', { principal, mode });
      this.api.principals = [];
      this.form.search = '';
      this.unsuccessfulFind = false;
    },
    onEmptySelect() {
      if (!this.loading && this.findingTrackTimeout) {
        this.unsuccessfulFind = true;
        this.clearStartSearchTrack();
      }
    },
    startSearchTrack() {
      // Track if a user searched for something
      // but didn't select anything (maybe couldn't find something)
      this.clearStartSearchTrack();
      if (this.form.search.length >= 2) {
        this.findingTrackTimeout = setTimeout(() => {
          this.unsuccessfulFind = true;
        }, 1500);
      }
    },
    clearStartSearchTrack() {
      clearTimeout(this.findingTrackTimeout);
      this.findingTrackTimeout = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.rsc-search-box {
  margin-bottom: 0.9rem;
  :deep(.rs-input) {
    border: 0px;
  }
}
</style>

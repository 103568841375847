<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <RSInputSelect
      label="Who runs this content on the server"
      :options="primaryOptions"
      :disabled="!isAdmin"
      :model-value="runAs.primaryMode"
      name="runtime-runas-primary"
      data-automation="runtime-runas-primary"
      @change="onPrimaryChange"
    >
      <template #help>
        <p v-if="isAdmin">
          By default, this content will run on the server as the user
          <span class="inlineUserName">{{ defaultRunAsUser }}</span>.
          You may need to use an alternate user depending on resource permissions and constraints.
          If you specify a user, they must be a member of the Unix group that is the
          primary group of <span class="inlineUserName">{{ defaultRunAsUser }}</span>.
        </p>
        <p v-else>
          By default, this content will run on the server as the default server
          <span class="inlineUserName">RunAs</span> user.
          Administrators can specify an alternate user depending
          on resource permissions and constraints. Please contact an administrator if you need
          to change this setting.
        </p>
      </template>
    </RSInputSelect>

    <RSInputSelect
      v-if="showSecondaryOptions"
      label="The content will execute as the current user when viewed by
        a logged in user. When scheduled or when viewed by someone who is not logged in,
        it will run as:"
      :options="secondaryOptions"
      :disabled="!isAdmin"
      :model-value="runAs.secondaryMode"
      name="runtime-runas-secondary"
      data-automation="runtime-runas-secondary"
      @change="onSecondaryChange"
    />

    <div class="textInputContainer noTopPadding">
      <RSInputText
        v-if="showRunAsInput"
        label="Unix username to run content as"
        :show-label="false"
        :model-value="runAs.alternateUser"
        placeholder="Enter the Unix username"
        name="runtime-runas-alternate-user"
        data-automation="runtime-runas-alternate-user"
        @change="onAlternateUserChange"
      />
    </div>
  </div>
</template>

<script>
import RSInputSelect from '@/elements/RSInputSelect';
import RSInputText from '@/elements/RSInputText';
import {
  ACCESS_SETTINGS_UPDATE_ALTERNATE_USER,
  ACCESS_SETTINGS_UPDATE_PRIMARY_MODE,
  ACCESS_SETTINGS_UPDATE_SECONDARY_MODE,
  RunAsModes,
} from '@/store/modules/accessSettings';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'RunAs',
  components: {
    RSInputSelect,
    RSInputText,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
    defaultRunAsUser: {
      type: String,
      default: '',
    },
    runAsCurrentAllowed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      runAs: state => state.accessSettings.runAs,
    }),
    altUserText() {
      // The alt username is displayed in the option for non-admins,
      // because the text input is not visible to them.
      return this.isAdmin ? '' : this.runAs.alternateUser;
    },
    secondaryOptions() {
      return [
        { value: RunAsModes.DEFAULT, label: `The default user ${this.defaultRunAsUser}` },
        { value: RunAsModes.ALTERNATE, label: `An alternate user ${this.altUserText}` },
      ];
    },
    primaryOptions() {
      const options = this.secondaryOptions.slice();

      if (this.runAsCurrentAllowed) {
        options.push({
          value: RunAsModes.CURRENT,
          label: 'The Unix account of the current user',
        });
      }
      return options;
    },
    showRunAsInput() {
      return (
        this.runAs.primaryMode === RunAsModes.ALTERNATE ||
        (
          this.runAs.primaryMode === RunAsModes.CURRENT &&
          this.runAs.secondaryMode === RunAsModes.ALTERNATE
        )
      ) && this.isAdmin;
    },
    showSecondaryOptions() {
      return this.runAs.primaryMode === RunAsModes.CURRENT;
    },
  },
  methods: {
    ...mapMutations({
      updatePrimary: ACCESS_SETTINGS_UPDATE_PRIMARY_MODE,
      updateSecondary: ACCESS_SETTINGS_UPDATE_SECONDARY_MODE,
      updateAlternateUser: ACCESS_SETTINGS_UPDATE_ALTERNATE_USER,
    }),
    onPrimaryChange(value) {
      this.updatePrimary(value);
    },
    onSecondaryChange(value) {
      this.updateSecondary(value);
    },
    onAlternateUserChange(value) {
      this.updateAlternateUser(value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.inlineUserName {
  font-style: italic;
}
.groupHeadings {
  color: $color-heading;
  letter-spacing: .1em;
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}
.spaceAfter {
  margin-bottom: 0.5rem;
}
.noTopPadding {
  padding-top: 0;
}
</style>

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import FocusLock from 'vue-focus-lock';
import { ExecutionTypeK8S } from '@/api/serverSettings';
import { SHOW_NAV_PANEL } from '@/store/modules/navigation';
import { docsPath } from '@/utils/paths';
import NavPanelMenu from './NavPanelMenu.vue';

const standardDocMenuItems = [
  {
    title: 'User Guide',
    href: docsPath('user'),
    class: 'userGuide',
    dataAutomation: 'left-nav-userguide',
    target: '_blank',
  }, {
    title: 'Admin Guide',
    href: docsPath('admin'),
    class: 'adminGuide',
    dataAutomation: 'left-nav-adminguide',
    target: '_blank',
  }, {
    title: 'FAQ',
    href: 'https://support.posit.co/hc/en-us/sections/205987508-RStudio-Connect',
    class: 'faq',
    dataAutomation: 'left-nav-faq',
    target: '_blank',
  }, {
    title: 'Articles',
    href: 'https://support.posit.co/hc/en-us/sections/204688508-RStudio-Connect',
    class: 'articles',
    dataAutomation: 'left-nav-articles',
    target: '_blank',
  }, {
    title: 'Release Notes',
    href: docsPath('news'),
    class: 'releaseNotes',
    dataAutomation: 'left-nav-releasenotes',
    target: '_blank',
  }, {
    title: 'Server API Reference',
    href: docsPath('api'),
    class: 'apiDocs',
    dataAutomation: 'left-nav-apidocs',
    target: '_blank',
  }, {
    title: 'Cookbook',
    href: docsPath('cookbook'),
    class: 'apiDocs',
    dataAutomation: 'left-nav-apidocs-cookbook',
    target: '_blank',
  }
];

const getAdminMenuItems = [
  {
    title: 'Metrics',
    name: 'admin.metrics',
    class: 'metrics',
    dataAutomation: 'left-nav-metrics',
  }, {
    title: 'Mail Settings',
    name: 'admin.mail_settings',
    class: 'settings',
    dataAutomation: 'left-nav-settings',
  }, {
    title: 'Tags',
    name: 'admin.tag_editor',
    class: 'tags',
    dataAutomation: 'left-nav-tags',
  }, {
    title: 'Audit Logs',
    name: 'admin.audit_logs',
    class: 'auditLogs',
    dataAutomation: 'left-nav-logs',
  }, {
    title: 'Scheduled Content',
    name: 'admin.scheduled_content',
    class: 'scheduled',
    dataAutomation: 'left-nav-scheduled',
  }, {
    title: 'Processes',
    name: 'admin.processes',
    class: 'processes',
    dataAutomation: 'left-nav-processes',
  }, {
    title: 'System Checks',
    name: 'admin.system_checks',
    class: 'systemChecks',
    dataAutomation: 'left-nav-systemchecks',
  }, {
    title: 'Queue',
    name: 'admin.queue',
    class: 'queue',
    dataAutomation: 'left-nav-queue',
  },
];

const whatsNewMenuItem = {
  title: `What's New`,
  name: 'whatsnew',
  class: 'whatsNew',
  dataAutomation: 'left-nav-whats-new',
};

const store = useStore();
const currentUser = computed(() => store.state.currentUser.user);
const isAuthenticated = computed(() => store.state.currentUser.isAuthenticated);
const serverSettings = computed(() => store.state.server.settings);
const showNotification = computed(() => store.state.whatsNew.showNotification);
const isAdmin = computed(() => currentUser.value.isAdmin());
const isPublisher = computed(() => currentUser.value.isPublisher());
const viewerHideDocs = computed(() => !currentUser.value.canPublish() &&
  serverSettings.value.hideViewerDocumentation);
const shouldShowNav = computed(() =>
  Boolean(
    isAuthenticated.value && Object.keys(serverSettings.value).length
  ));
const shouldShowIntegrations = computed(() =>
  (serverSettings.value.oauthIntegrationsEnabled &&
    (isPublisher.value || isAdmin.value)));
const shouldShowEnvironmentsMenu = computed(() =>
  Boolean(
    serverSettings.value.executionType === ExecutionTypeK8S &&
      (isPublisher.value || isAdmin.value)
  ));
const navNodeList = computed(() => document.querySelectorAll('.panelContents a'));

const handleKeydown = (e) => {
  // JSdom in vue-test-utils is unable to find the e.target at times which causes tests to fail.
  if (!e) { return; }
  let index = Array.prototype.indexOf.call(navNodeList.value, e.target);
  if (e.key === 'ArrowUp') {
    index--;
    if (index >= 0) {
      navNodeList.value[index].focus();
    }
  } else if (e.key === 'ArrowDown') {
    index++;
    if (index < navNodeList.value.length) {
      navNodeList.value[index].focus();
    }
  }
};
const closePanel = async() => {
  store.commit(SHOW_NAV_PANEL, false);
  setTimeout(() => {
    const menuToggler = document.querySelector('#menuToggler');
    menuToggler?.focus();
  }, 200);
};
const getPeopleMenuItems = () => {
  if ((!serverSettings.value.authentication.externalGroupId
    || serverSettings.value.authentication.externalGroupSearch)
    && serverSettings.value.authentication.groupsEnabled) {
    return [
      {
        title: 'Users',
        name: 'people.users',
        class: 'users',
        dataAutomation: 'left-nav-users'
      },
      {
        title: 'Groups',
        name: 'people.groups',
        class: 'groups',
        dataAutomation: 'left-nav-groups'
      },
    ];
  }

  return [];
};
const getDocumentationMenuItems = () => {
  const menuItems = [...standardDocMenuItems];

  if (serverSettings.value.whatsNewEnabled) {
    menuItems.unshift(whatsNewMenuItem);
  };

  return menuItems;
};

onMounted(() => document.addEventListener('keydown', handleKeydown(event)));
onUnmounted(() => document.removeEventListener('keydown', handleKeydown(event)));
</script>

<template>
  <div
    id="navPanel"
    class="sidePanel"
  >
    <div class="panelContents">
      <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
      <nav
        v-if="shouldShowNav"
        aria-label="Site Navigation"
        class="navMenu"
        tabindex="-1"
        @click="closePanel"
        @keydown="handleKeydown($event)"
        @keydown.esc="closePanel"
      >
        <FocusLock group="sidePanel">
          <NavPanelMenu
            title="Content"
            data-automation="left-nav-content"
            name="contentList"
          />

          <NavPanelMenu
            title="People"
            data-automation="left-nav-people"
            name="people"
            :children="getPeopleMenuItems()"
          />

          <NavPanelMenu
            v-if="shouldShowEnvironmentsMenu"
            title="Environments"
            data-automation="left-nav-image"
            name="environments"
          />

          <NavPanelMenu
            v-if="shouldShowIntegrations"
            title="Integrations"
            data-automation="left-nav-integrations"
            name="integrations"
          />

          <div v-if="isAdmin">
            <NavPanelMenu
              title="Admin"
              data-automation="left-nav-admin"
              name="admin"
              :children="getAdminMenuItems"
            />
          </div>
          <div
            v-if="!viewerHideDocs"
            :class="{ 'show-notification': showNotification }"
          >
            <NavPanelMenu
              title="Documentation"
              data-automation="left-nav-doc"
              name="help"
              :children="getDocumentationMenuItems()"
            />
          </div>
        </FocusLock>
      </nav>
    </div>

    <footer class="panelFooter">
      <div class="finePrint">
        <div
          v-if="serverSettings.about"
          data-automation="about"
        >
          {{ serverSettings.about }}
        </div>
        <div
          v-if="serverSettings.build"
          data-automation="build"
        >
          Build {{ serverSettings.build }}
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

#navPanel {
  left: 0px;
  width: $nav-panel-width;
  background-color: $color-light-grey;

  .show-notification {
    position: relative;
  }

  .navMenu {
    color: $color-dark-grey-3;
    font-size: 13px;
    overflow-x: hidden;
  }

  .panelContents {
    min-height: 100%;
    margin-bottom: -$nav-panel-footer-height;
  }

  .panelContents:after {
    content: "";
    display: block;
  }

  .panelFooter, .panelContents:after {
    height: $nav-panel-footer-height;
  }

  .finePrint {
    color: $color-dark-grey-3;
    line-height: 20px;
    font-size: 11px;
    padding: 0 $side-panel-padding;
  }
}
</style>

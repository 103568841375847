// Copyright (C) 2022 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import OpenExample from './panels/OpenExample';
import OpenIDE from './panels/OpenIDE';
import PublishIDE from './panels/PublishIDE';
import RMarkdownParamsOutro from './panels/RMarkdownParamsOutro';
import RunExample from './panels/RunExample';

export default {
  copyFrom: [
    DownloadExample,
    OpenIDE,
    OpenExample,
    RunExample,
    PublishIDE,
    RMarkdownParamsOutro,
  ],
};

// Copyright (C) 2022 by Posit Software, PBC.

export const SERVER_NOTICES_LICENSING_CLOSE = 'SERVER_NOTICES_LICENSING_CLOSE';
export const SERVER_NOTICES_SUPPORT_CLOSE = 'SERVER_NOTICES_SUPPORT_CLOSE';
export const SERVER_NOTICES_DEPRECATED_CLOSE = 'SERVER_NOTICES_DEPRECATED_CLOSE';

// The server-notices component (Angular and Vue) are reconstructed on each
// route change. The open/close state for each server-notices sub-component is
// tracked state in order to avoid redisplaying each notice whenever the route
// changes.
export default {
  state: {
    licensing: true,
    support: true,
    deprecated: true,
  },
  mutations: {
    [SERVER_NOTICES_LICENSING_CLOSE](state) {
      state.licensing = false;
    },
    [SERVER_NOTICES_SUPPORT_CLOSE](state) {
      state.support = false;
    },
    [SERVER_NOTICES_DEPRECATED_CLOSE](state) {
      state.deprecated = false;
    },
  },
};

// Copyright (C) 2023 by Posit Software, PBC.

import axios from 'axios';
import { apiPath } from '@/utils/paths';
import { keysToCamel, keysToSnake } from './transform';
import { Variant, VariantRendering } from './dto/variant';

/**
 *
 * @param {string} appGuid application guid
 * @returns {Promise} parameter definition for the application
 */

export const getParameterControls = appGuid => axios
  .get(apiPath(`content/${appGuid}/controls`))
  // TODO: replace this stubbed code with the actual API response.
  //       Currently, "options" are not returned for "select" params.
  .then(() => keysToCamel(MOCK_VARIANT_CONTROLS))
  .catch(() => keysToCamel(MOCK_VARIANT_CONTROLS));

export const getVariant = async variantId => axios
  .get(apiPath(`variants/${variantId}`))
  .then(({ data }) => new Variant(keysToCamel(data)));

export const getVariants = async appId => axios
  .get(apiPath(`applications/${appId}/variants`))
  .then(({ data }) => {
    if (!data) {
      return [];
    }
    return data.map(variant => new Variant(keysToCamel(variant)));
  })
  // TODO: handle the error correctly once the API is in place.
  .catch(error => console.error('FETCH ERROR:', error));

export const getVariantParameters = async(appGuid, variantId) => axios
  .get(apiPath(`content/${appGuid}/parameters/${variantId}`))
  .then(({ data }) => data.parameters)
  .then(() => getStubbedVariantValues(variantId)) // TODO: remove
  .catch(err => {
    // TODO: remove the stubbed values when using valid API request.
    console.error(`FETCH ERROR: [${err.response.status}]`, 'returning mock data');
    return getStubbedVariantValues(variantId); // TODO: remove
  });

export const createVariant = async(variantId, variantName) => axios
  .post(apiPath(`variants/${variantId}/duplicate?variant_name=${encodeURIComponent(variantName)}&visibity=ad-hoc`))
  .then(({ data }) => new Variant(keysToCamel(data)));

export const updateVariant = async variant => axios
  .post(apiPath(`variants/${variant.id}`), keysToSnake(variant))
  .then(({ data }) => new Variant(keysToCamel(data)));

export const deleteVariant = async variantId => axios
  .delete(apiPath(`variants/${variantId}`))
  .then(({ data }) => keysToCamel(data));

export const fetchRenderings = variantId => axios
  .get(apiPath(`variants/${variantId}/renderings`))
  .then(({ data }) => data.map(v => new VariantRendering(keysToCamel(v))));

export const refreshReport = async variantId => axios
  .post(apiPath(`variants/${variantId}/render?activate=true`))
  .then(({ data }) => keysToCamel(data));

// TODO: Update when API is available and returning valid responses.
export const renderVariantValues = async(variantId, values) => axios
  .post(apiPath(`variant/${variantId}/run`), values)
  .then(() => variantRunValues(variantId, values))
  .catch(error => {
    console.error(`POST ERROR: [${error.response.status}]`, 'returning post mocked run');
    return variantRunValues(variantId, values);
  });

export const getVariantRenderings = variantId => axios
  .get(apiPath(`variants/${variantId}/renderings`))
  .then(({ data }) => data.map(v => new VariantRendering(keysToCamel(v))));

// TODO: Update when API is available and returning valid responses.
export const deleteRun = async variantRunId => axios
  .delete(apiPath(`variant/run/${variantRunId}`))
  .catch(error => {
    console.error(`DELETE ERROR: [${error.response.status}]`);
    return null;
  });

// TODO: remove this section once the api is returning valid responses.
const MOCK_VARIANT_CONTROLS = [
  {
    name: 'dateStart',
    type: 'datepicker',
    label: 'Start Date',
    value: new Date('2023/01/31 15:15:00'),
  },
  {
    name: 'dateEnd',
    type: 'datetimepicker',
    label: 'End Date and Time',
    value: new Date('2023/02/14 15:15:00'),
  },
  {
    name: 'city',
    type: 'radio',
    label: 'City',
    value: 'Worcester',
    choices: ['Springfield', 'Worcester', 'Boston'],
  },
  {
    name: 'ridecount',
    type: 'slider',
    label: 'Ride Count',
    min: 0,
    max: 10,
    step: 1,
    value: 7,
  },
  {
    name: 'mode',
    type: 'select',
    label: 'Mode',
    value: 'Rail',
    choices: ['Bus', 'Subway', 'Rail']
  },
  {
    name: 'note',
    type: 'text',
    label: 'Note',
    value: 'zebra burglar',
  },
  {
    name: 'turboMode',
    type: 'checkbox',
    label: 'Turbo Mode',
    value: true,
  },
  {
    name: 'turboModePassword',
    type: 'password',
    label: 'Turbo Mode Password',
    value: ''
  },
  {
    name: 'activeTrains',
    type: 'numeric',
    label: 'Active Trains',
    value: '3',
  },
];

const variantRunValues = (id, values) => ({
  active: true,
  appId: 8,
  bundleId: 7,
  // To prevent duplicate ids for the runs
  id: Math.floor(Math.random() * (100 - 1) + 1),
  jobKey: 'VBA9HaIslma9Vsjn',
  renderDuration: 2525673043,
  renderTime: new Date(),
  variantId: id,
  values,
});

const getStubbedVariantValues = () => ({
  city: 'Worcester',
  dateStart: new Date('2023/12/01'),
  dateEnd: new Date('2023/12/15 15:15:00'),
  mode: 'Subway',
  note: 'Zebra Dogfish',
  ridecount: 3,
  turboMode: true,
  turboModePassword: 'mnemonic turtle',
  activeTrains: 3,
});

// Legacy endpoints
//
// Many of these are duplicated from above, but the goal is to be able to mimic
// the legacy workflows as closely as possible.  All this should be able to be
// removed once we migrate to new p14n APIs.

export const duplicateVariant = async(id, variantName, visibility) => axios
  .post(apiPath(`variants/${id}/duplicate?variant_name=${encodeURIComponent(variantName)}&visibility=${encodeURIComponent(visibility)}`))
  .then(({ data }) => new Variant(keysToCamel(data)));

export const renderVariant = async id => axios
  .post(apiPath(`variants/${id}/render?activate=true`))
  .then(({ data }) => keysToCamel(data));

export const promoteVariant = async(id, sourceKey) => axios
  .post(apiPath(`variants/${id}/promote?source_key=${encodeURIComponent(sourceKey)}`))
  .then(({ data }) => keysToCamel(data));

export const getVariantOverrides = async(variantId) => axios
  .get(apiPath(`variants/${variantId}/overrides`))
  .then(({ data }) => keysToCamel(data));

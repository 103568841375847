<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { docsPath } from '@/utils/paths';
import RSButton from '@/elements/RSButton';
import { computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  isSearching: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(['requestPermissions']);
const store = useStore();
const viewerWithoutSearch = computed(() =>
  !props.isSearching && store.state.currentUser.user.isViewer());
const publisherWithoutSearch = computed(() =>
  !props.isSearching && store.state.currentUser.user.canPublish());
const contentSearchDocs = docsPath('user/viewing-content/#searching-and-filtering-content');

const requestPermissions = () => {
  emit('requestPermissions');
};
</script>

<template>
  <div
    class="empty-results"
    data-automation="empty-results"
  >
    <div
      v-if="viewerWithoutSearch"
      class="empty-results__viewer"
      data-automation="empty-results-viewer-no-search"
    >
      <p class="emphasize">
        Need permissions to publish content?
      </p>
      <p>
        No content has been published, or none is visible to you. You are currently a Viewer.
        If you think you should have publisher permissions, you can submit a request.
      </p>
      <RSButton
        label="Request permissions"
        icon="images/empty-inbox.svg"
        data-automation="empty-results-request-permissions-btn"
        @click="requestPermissions"
      />
    </div>
    <div
      v-else-if="publisherWithoutSearch"
      class="empty-results__publisher"
      data-automation="empty-results-publisher-no-search"
    >
      <p class="emphasize">
        Start publishing and sharing!
      </p>
      <p>
        You have no visible published content. You can get started with
        publishing new content by clicking the Publish button.
      </p>
    </div>
    <div
      v-else
      class="empty-results__search"
      data-automation="empty-results-with-search"
    >
      <p class="emphasize">
        Oops, no luck with that search.
      </p>
      <p>
        Try using a different search query or refer to the
        <a
          target="_blank"
          :href="contentSearchDocs"
        >Connect Search</a>
        documentation to learn more about acceptable search terms.
      </p>
      <figure
        class="empty-results__search-img"
      >
        <img
          src="/images/empty-search.svg"
          alt="Empty search image"
        >
      </figure>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.empty-results {
  display: flex;
  justify-content: center;
  font-size: 1.44rem;
  text-align: center;
  padding: 4rem 0;

  & > div {
    max-width: 720px;
  }

  p {
    margin-bottom: 1.44rem;

    &:not(.emphasize) {
      color: $color-dark-grey;
    }
  }
}
</style>

<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <h2
    ref="pageHeading"
    :class="headingClass"
    class="wizard-panel__title"
    data-automation="wizard-panel-title"
    tabindex="-1"
  >
    <slot />
  </h2>
</template>

<script>
export default {
  name: 'StepHeading',
  props: {
    step: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    headingClass() {
      return this.step === 'done' ?
        'wizard-panel__title--done' :
        `wizard-panel__title--step wizard-panel__title--step-${this.step}`;
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.$refs.pageHeading.focus();
    });
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.wizard-panel__title {
  padding: .4rem 0;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    @include control-visible-focus();
  }

  &--done {
    padding-left: 3rem;
    background-size: 4rem 3rem;

    &:focus-visible {
      @include control-visible-focus();
    }
  }
}
</style>

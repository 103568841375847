<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    subject="Don't Show Examples"
    close-button-label="Close Modal"
    :active="active"
    class="dont-show"
    @close="close"
  >
    <template #content>
      <p class="help-text">
        We will not show you these examples next time you login.
      </p>

      <p class="help-text">
        To view them any time, just pick Jump Start Examples from the Publish menu.
      </p>

      <div class="dont-show__screenshot">
        <img
          alt=""
          class="dont-show__screenshot-img"
          src="/images/jumpstart/newContentMenu.png"
        >
      </div>
    </template>
    <template #controls>
      <RSButton
        id="okButton"
        label="OK"
        @click="close"
      />
    </template>
  </RSModal>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';

export default {
  name: 'DontShowDialog',
  components: {
    RSModal,
    RSButton,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  mounted() {
    const okButton = document.getElementById('okButton');
    okButton && okButton.focus();
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.dont-show {
  /* The DontShowDialog is a modal on top of another modal, so
     we need a higher z-index than the base rs-modal class (which is 1000).  */
  z-index: 1001 !important;

  .help-text {
    margin-bottom: 1rem;
  }

  &__screenshot {
    text-align: right;
    padding: 10px;
    margin-bottom: 0;
    background-color: $color-light-blue;
    border-radius: 10px;
    line-height: 0; /* so no extra space appears below the screen shot */
  }

  &__screenshot-img {
    width: 273px;
  }
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Open & Install Dependencies
      </StepHeading>

      <p>
        Unzip <code>{{ zip }}</code> locally, and open a command prompt to the unzipped
        project directory.
      </p>

      <div class="wizard-panel__section">
        <h3 class="wizard-panel__subtitle">
          Install required packages using pip:
        </h3>

        <div class="python-dependencies__install">
          <code class="python-dependencies__install-code">
            {{ installCode }}
          </code>

          <CopyButton
            class="python-dependencies__install-copy"
            :copy-text="installCode"
          />
        </div>
      </div>

      <ul class="wizard-panel__links">
        <li>
          <a
            href="https://pip.pypa.io/en/stable/"
            target="_blank"
          >
            Learn more about pip - the Python Package Installer
          </a>
        </li>
        <li>
          <a
            href="https://pip.pypa.io/en/stable/user_guide/#requirements-files"
            target="_blank"
          >
            Learn more about using requirements.txt with pip
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CopyButton from '@/components/CopyButton';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'PythonDependencies',
  components: {
    CopyButton,
    StepHeading,
  },
  mixins: [PanelMixin],
  computed: {
    installCode() {
      return 'pip install -r requirements.txt';
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
.python-dependencies {

  &__install {
    display: flex;
    margin-top: .5rem;

    &-code {
      align-content: center;
      background-color: $color-terminal-background;
      border: 1px solid #aaa;
      border-radius: 0;
      color: $color-terminal;
      flex-grow: 1;
      font-size: 0.8rem;
      line-height: 1.3;
      margin-bottom: 0;
      padding: 0.3rem;
    }

    &-copy {
      margin-left: 0.25rem;
    }
  }
}
</style>

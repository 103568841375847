<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { isEmpty } from 'lodash';
import { computed, onBeforeUnmount, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import DeleteButton from './DeleteButton';
import DeleteModal from './DeleteModal';
import EmailReportButton from './EmailReportButton';
import HistoryButton from './HistoryButton';
import LogsButton from './LogsButton';
import OpenSoloButton from './OpenSoloButton';
import PrintButton from './PrintButton';
import RefreshButton from './RefreshButton';
import SettingsButton from './SettingsButton';
import SourceVersions from './SourceVersions';
import SourceVersionsButton from './SourceVersionsButton';

const store = useStore();

const localState = reactive({
  isMoreOpen: false,
  deleteModalActive: false,
  sourceVersionsActive: false,
});

const app = computed(() => store.state.contentView.app);
const hasAppError = computed(() => store.state.contentView.appError);
const showParamsPanel = computed(
  () => store.state.contentView.showParametersPanel
);
const currentUser = computed(() => store.state.currentUser.user);
const hasVariantError = computed(
  () => store.state.parameterization.currentVariant?.error
);
const variant = computed(() => store.state.parameterization.currentVariant);
const paramsLoaded = computed(() => store.state.legacyParams.form.loaded);
const dirtyParams = computed(() => store.state.legacyParams.form.dirty);
const isVariantBusy = computed(() => store.state.legacyParams.isBusy);

const isLoadingParamsPanel = computed(
  () => showParamsPanel.value && !paramsLoaded.value
);
const showRefreshReport = computed(() => {
  if (isEmpty(variant.value) || hasVariantError.value) {
    return false;
  }

  // Do not show if app is locked
  if (app.value.locked) {
    return false;
  }

  // must be an app editor, must be a report with a variant (because then you also have read
  // access), and must be deployed.
  return app.value.isDeployed() && currentUser.value.isAppEditor(app.value);
});
const showOpenSolo = computed(() => {
  if (hasAppError.value || !app.value.isDeployed()) {
    return false; // no app in state, load error, or not deployed, no solo.
  }

  if (!isEmpty(variant.value) && !variant.value.renderDuration) {
    return false; // no rendering; no solo.
  }

  return true;
});
const showEmailReport = computed(() => {
  if (hasAppError.value || !app.value.isDeployed() || app.value.locked) {
    return false; // load error or not deployed or locked
  }

  if (!currentUser.value.canViewApp(app.value)) {
    return false; // not permitted to view, no email.
  }

  if (isEmpty(variant.value)) {
    return false; // no variant, no email.
  }

  if (!variant.value.renderDuration) {
    return false; // never rendered, no email.
  }

  if (app.value.isSite()) {
    return false; // multi-page sites, no email.
  }

  return true;
});
const showLogs = computed(() => currentUser.value.canViewAppSettings(app.value));
const showPrint = computed(() => {
  if (hasAppError.value || !app.value.isDeployed() || app.value.locked) {
    return false;
  }

  if (!currentUser.value.canViewApp(app.value)) {
    return false;
  }

  if (!isEmpty(variant.value)) {
    if (isVariantBusy.value) {
      return false;
    }

    if (!variant.value.renderDuration) {
      return false;
    }

    if (app.value.isSite()) {
      return false;
    }
  }

  return true;
});
const showHistory = computed(() => {
  if (hasAppError.value || hasVariantError.value || app.value.locked) {
    return false;
  }
  return (
    currentUser.value.canViewApp(app.value) &&
    (app.value.isStatic() || app.value.isRenderable())
  );
});
const showSourceVersions = computed(() =>
  !hasAppError.value && currentUser.value.isAppEditor(app.value));

const showDelete = computed(() =>
  !hasAppError.value && currentUser.value.canDeleteApp(app.value));

const showSettings = computed(() => !hasAppError.value);

onMounted(() => {
  document.addEventListener('click', closeMore);
});
onBeforeUnmount(() => {
  document.removeEventListener('click', closeMore);
});

const closeMore = e => {
  if (localState.isMoreOpen) {
    localState.isMoreOpen = false;
    e.stopPropagation();
    e.preventDefault();
  }
};
</script>

<template>
  <div class="content-actions actionBar">
    <RefreshButton
      v-show="showRefreshReport"
      :disabled="dirtyParams || isVariantBusy || isLoadingParamsPanel"
    />
    <OpenSoloButton
      v-show="showOpenSolo"
      :dropdown="false"
    />
    <EmailReportButton
      v-show="showEmailReport"
      :disabled="dirtyParams || isVariantBusy || isLoadingParamsPanel"
    />
    <LogsButton
      v-show="showLogs"
      :dropdown="false"
    />

    <div class="popupButtonAndMenuContainer content-actions__more-container">
      <button
        aria-label="More Options"
        title="More Options"
        class="action moreActions menuButton content-actions__more-button"
        :class="{ current: localState.isMoreOpen }"
        data-automation="menuitem-more"
        @click.stop.prevent="localState.isMoreOpen = !localState.isMoreOpen"
      />

      <div
        class="popupMenu content-actions__more-menu"
        :class="{ open: localState.isMoreOpen }"
        data-automation="menuitem-popup-container"
      >
        <OpenSoloButton
          v-show="showOpenSolo"
          :dropdown="true"
        />
        <PrintButton
          v-show="showPrint"
          :dropdown="true"
        />
        <LogsButton
          v-show="showLogs"
          :dropdown="true"
        />
        <HistoryButton v-show="showHistory" />
        <SourceVersionsButton
          v-show="showSourceVersions"
          @source-versions="localState.sourceVersionsActive = true"
        />
        <DeleteButton
          v-show="showDelete"
          @delete-modal="localState.deleteModalActive = true"
        />
      </div>
    </div>

    <SettingsButton v-show="showSettings" />
    <DeleteModal
      v-if="localState.deleteModalActive"
      @delete-modal="localState.deleteModalActive = false"
    />
    <SourceVersions
      v-if="localState.sourceVersionsActive"
      @source-versions="localState.sourceVersionsActive = false"
    />
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.popupButtonAndMenuContainer {
  display: inline-block;
  position: relative;
}

.content-actions__more-container:has(.content-actions__more-menu:empty) {
  display: none;
}

.content-actions__more-menu {
  button:focus-visible {
    outline: none;
    box-shadow: 0px 0px 0px 2px $color-posit-teal inset;
  }
}
</style>

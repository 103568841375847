// Copyright (C) 2024 by Posit Software, PBC.

import dayjs from 'dayjs';
import arraySupport from 'dayjs/plugin/arraySupport';

dayjs.extend(arraySupport);

// Returns the product version YYYY.MM.V without unreleased or
// development suffixes.
//
// Assumes a valid input product version.
export const normalizedVersion = (rawVersion) => {
  // Incoming raw version may resemble the following:
  // * 2024.09.0
  // * 2024.09.0-dev+26-g51b853f70e
  // * 2024.09.0-dev+26-dirty-g51b853f70e
  const version = rawVersion;
  const idx = version.indexOf('-');
  if (idx !== -1) {
    return version.slice(0, idx);
  }
  return version;
};

// Returns the date when the product is out-of-support.
//
// Assumes an input version without any unreleased or development suffixes.
export const endOfSupport = (normalized) => {
  const parts = normalized.split('.');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // dayjs months are 0-indexed.
  // Shift to the end of the month after adding 18mos to avoid having the
  // result normalized when the end month has fewer days than the start month.
  const start = dayjs([year, month, 1]);
  const end = start.add(18, 'month').endOf('month');
  return end;
};

// Compares two given version strings
export const versionCompare = (v1, v2) => {
  const normalizedV1 = normalizedVersion(v1);
  const normalizedV2 = normalizedVersion(v2);
  // Splits the version string into an array of numbers
  const version1 = normalizedV1.split('.').map(Number);
  const version2 = normalizedV2.split('.').map(Number);
  const len = Math.max(version1.length, version2.length);

  for (let i = 0; i < len; i++) {
    // Checks the left-most number first to see if it's higher
    // It loops through the array to do the comparison
    const num1 = i < version1.length ? version1[i] : 0;
    const num2 = i < version2.length ? version2[i] : 0;

    if (num1 > num2) { return 1; }
    if (num2 > num1) { return -1; }
  }

  return 0;
};

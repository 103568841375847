<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<!--
  TabSelector presents a set of tabs that a user can select from. The selected tab is highlighted.

  The tabs are defined with an `id` and a `label`:

  const tabs = [
  { id: 'modified', label: 'Modified', testId: 'tab-modified' },
  { id: 'all', label: 'All', testId: 'tab-all' },
  ];

  <TabSelector
  name="server-settings"
  :active-tab-id="activeTabId"
  :tabs="tabs"
  @update:activeTabId="setActiveTab"
  />

  When a tab is selected, `update:activeTabId` is emitted with the `id` of the selected tab.
-->

<script>
export const TabShape = {
  ROUND: 'round',
  SQUARE: 'square',
};
</script>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  activeTabId: { type: String, required: true },
  disabled: { type: Boolean, default: false },
  name: { type: String, required: true },
  shape: { type: String, default: TabShape.ROUND },
  tabs: { type: Array, required: true },
});

const emit = defineEmits(['update:activeTabId']);
const tabRef = ref(null);

const focusTab = (tabName) => {
  const tab = tabRef.value?.querySelector(`input[value="${tabName}"]`);
  tab?.focus();
};

defineExpose({ focusTab });
</script>

<template>
  <div class="container">
    <div
      ref="tabRef"
      :class="[
        'tab-selector',
        { square: shape === TabShape.SQUARE, disabled: props.disabled }
      ]"
      data-automation="tab-selector"
    >
      <label
        v-for="tab in tabs"
        :key="tab.id"
        :for="tab.id"
        class="tab"
        :class="{ active: tab.id === props.activeTabId }"
      >
        <input
          :id="tab.id"
          :checked="tab.id === props.activeTabId"
          :data-automation="tab.testId"
          :name="props.name"
          :value="tab.id"
          :disabled="props.disabled"
          type="radio"
          @change="emit('update:activeTabId', $event.target.value)"
        >
        {{ tab.label }}
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.container {
  display: flex;
  justify-content: center;
}

.tab-selector {
  display: flex;
  justify-content: center;

  &:has(:focus-visible) {
    @include control-focus(25px);
  }
  &.disabled {
    opacity: 0.7;
  }

  .tab {
    align-content: center;
    background-color: $color-white;
    border: 1px solid $color-rounded-button-border;
    border-right: none;
    color: $color-button-text;
    height: 1.7rem;
    min-width: 5rem;
    padding: 0 1rem;
    text-align: center;
    text-wrap: nowrap;

    input[type='radio'] {
      appearance: none;
      outline: none;
      position: absolute;
      opacity: 0;
    }

    &.active {
      background-color: $color-posit-blue;
      color: $color-white;
    }
    &:first-of-type {
      border-radius: 25px 0 0 25px;
    }
    &:last-of-type {
      border-right: 1px solid $color-rounded-button-border;
      border-radius: 0 25px 25px 0;
    }
  }

  &.square {
    &:has(:focus-visible) {
      @include control-focus(5px);
    }

    .tab {
      &:first-of-type {
        border-radius: 5px 0 0 5px;
      }
      &:last-of-type {
        border-radius: 0 5px 5px 0;
      }
    }
  }

}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

// AppRoles holds singleton values and a construction function for raw app role
// values.
const AppRoles = {
  Owner: 3,
  Editor: 2,
  Viewer: 1,
  None: 0,

  of(value) {
    switch (value) {
      case 'owner':
        return AppRoles.Owner;
      case 'editor':
        return AppRoles.Editor;
      case 'viewer':
        return AppRoles.Viewer;
      case 'none':
        return AppRoles.None;
      default:
        return AppRoles.None;
    }
  },

  stringOf(role) {
    switch (role) {
      case AppRoles.Owner:
        return 'owner';
      case AppRoles.Editor:
        return 'editor';
      case AppRoles.Viewer:
        return 'viewer';
      default:
        return 'none';
    }
  },

  // Whether owners or editors have access.
  isCollaborator(role) {
    return role > AppRoles.Viewer;
  },

  isOwner(role) {
    return role === AppRoles.Owner;
  },

  isEditor(role) {
    return role === AppRoles.Editor;
  },

  isViewer(role) {
    return role === AppRoles.Viewer;
  },
};

export default Object.freeze(AppRoles);

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    subject="Environment Variable Changes"
    @close="$emit('close')"
  >
    <template #content>
      <div>
        <p>
          Changing the environment variables for processes related to this
          {{ ContentTypeLabel[contentType] }} will result in the termination
          of any currently running processes for this {{ ContentTypeLabel[contentType] }}.
          Are you sure you want to continue?
        </p>
        <br>
        <p>
          The environment variable(s) that will be changed are:
        </p>
        <pre>{{ variables }} </pre>
      </div>
    </template>

    <template #controls>
      <RSButton
        label="OK"
        data-automation="confirm-dialog-submit"
        @click="$emit('save')"
      />
    </template>
  </RSModal>
</template>

<script>
import { ContentTypeLabel } from '@/api/dto/app';
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';

export default {
  name: 'ReloadDialog',
  components: { RSButton, RSModal },
  props: {
    contentType: {
      type: String,
      required: true,
    },
    variables: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'save'],
  data: () => ({
    ContentTypeLabel
  })
};
</script>

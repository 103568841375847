// Copyright (C) 2024 by Posit Software, PBC.

export const SHRINK_HEADER = 'SHRINK_HEADER';
export const SHOW_NAV_PANEL = 'SHOW_NAV_PANEL';
export const SHOW_USER_MENU = 'SHOW_USER_MENU';
export const SKIP_TO_MAIN_CONTENT = 'SKIP_TO_MAIN_CONTENT';
export const SKIP_TO_MAIN_URL = 'SKIP_TO_MAIN_URL';
export const TOGGLE_SKIP_TO_MAIN_CONTENT = 'TOGGLE_SKIP_TO_MAIN_CONTENT';

export const defaultState = () => ({
  shrinkHeader: true,
  showNavPanel: false,
  showUserMenu: false,
  skipToMainContent: false,
  skipToMainUrl: null,
});

export default {
  state: defaultState(),
  mutations: {
    [SHRINK_HEADER](state, shrink) {
      state.shrinkHeader = shrink;
    },
    [SHOW_NAV_PANEL](state, show) {
      state.showNavPanel = show;
    },
    [SHOW_USER_MENU](state, show) {
      state.showUserMenu = show;
    },
    [TOGGLE_SKIP_TO_MAIN_CONTENT]: (state) => {
      state.skipToMainContent = !state.skipToMainContent;
    },
    [SKIP_TO_MAIN_URL]: (state, url) => {
      state.skipToMainUrl = url;
    },
  },
  actions: {
    [SKIP_TO_MAIN_CONTENT]: async({ commit }) => {
      commit(TOGGLE_SKIP_TO_MAIN_CONTENT);
      await new Promise(resolve => setTimeout(resolve, 0));

      commit(TOGGLE_SKIP_TO_MAIN_CONTENT);
    }
  }
};

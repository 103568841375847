// Copyright (C) 2022 by Posit Software, PBC.

// UserRoles holds singleton values and a construction function for raw user role
// values.
const UserRoles = {
  Admin: 3,
  Publisher: 2,
  Viewer: 1,
  Anonymous: 0,

  of(value) {
    switch (value) {
      case 'administrator':
        return UserRoles.Admin;
      case 'publisher':
        return UserRoles.Publisher;
      case 'viewer':
        return UserRoles.Viewer;
      default:
        // all other values are defaulted to anonymous
        return UserRoles.Anonymous;
    }
  },

  stringOf(value) {
    switch (value) {
      case UserRoles.Admin:
        return 'administrator';
      case UserRoles.Publisher:
        return 'publisher';
      case UserRoles.Viewer:
        return 'viewer';
      default:
        // all other values are defaulted to anonymous
        return 'anonymous';
    }
  },
};

export default Object.freeze(UserRoles);

// Copyright (C) 2023 by Posit Software, PBC.

// See: http://en.wikipedia.org/wiki/Binary_prefix
// See src/connect/util/bytesize.go
export const DECIMAL_PREFIXES = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];
export const BINARY_PREFIXES = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB'];

export const B = 1;

// Decimal
export const KB = 1000;
export const MB = 1000 * KB;
export const GB = 1000 * MB;
export const TB = 1000 * GB;
export const PB = 1000 * TB;
export const EB = 1000 * PB;

// Binary
export const KiB = 1024;
export const MiB = 1024 * KiB;
export const GiB = 1024 * MiB;
export const TiB = 1024 * GiB;
export const PiB = 1024 * TiB;
export const EiB = 1024 * PiB;

/**
 * humanizeBytesDecimal makes bytes readable to humans, using decimal
 * (1000-based) units. Values are rewritten in terms of the greatest unit
 * multiple that is less than or equal to the value.
 *
 * @example
 * // returns "10.5 KB"
 * humanizeBytesDecimal(10500);
 *
 * @param {Number} bytes - number of bytes
 * @param {Number} [precision=1] - number of decimal places.
 *
 * Precision is forced to 0 if `bytes` is below one kilobyte (1000).
 *
 * @returns {string} Translated size. Erroneous arguments produce '-'. Values less than 1 produce '0 B'.
 */
export function humanizeBytesDecimal(
  bytes,
  precision = 1,
) {
  return humanizeBytes(bytes, precision, 1000, DECIMAL_PREFIXES);
}

/**
 * humanizeBytesBinary makes bytes readable to humans, using binary
 * (1024-based) units. Values are rewritten in terms of the greatest unit
 * multiple that is less than or equal to the value.
 *
 * @example
 * // returns "10.5 KiB"
 * humanizeBytesDecimal(10752);
 *
 * @param {Number} bytes - number of bytes
 * @param {Number} [precision=1] - number of decimal places.
 *
 * Precision is forced to 0 if `bytes` is below one kibibyte (1024).
 *
 * @returns {string} Translated size. Erroneous arguments produce '-'. Values less than 1 produce '0 B'.
 */
export function humanizeBytesBinary(
  bytes,
  precision = 1,
) {
  return humanizeBytes(bytes, precision, 1024, BINARY_PREFIXES);
}

function humanizeBytes(
  bytes,
  precision,
  base,
  units
) {
  if (bytes < base) {
    // For values less than the base (1024 or 1000), force precision=0 because
    // it makes no sense to say "10.00 B".
    precision = 0;
  }
  if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
    return '-';
  }
  if (bytes < 1) {
    const unit = units[0];
    return `0 ${unit}`;
  }
  const exponent = Math.floor(Math.log(bytes) / Math.log(base));
  const scaled = (bytes / Math.pow(base, exponent)).toFixed(precision);
  const unit = units[exponent];
  return `${scaled} ${unit}`;
}

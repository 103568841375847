<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__open-example wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Open the Example
      </StepHeading>
      <div class="wizard-panel__open-example-content">
        <h3 class="wizard-panel__subtitle">
          Using Posit Workbench:
        </h3>
        <div class="wizard-panel__open-example-description">
          <p>
            Use the <span class="emphasize">Upload</span> command in the Files pane
            to upload <code>{{ zip }}</code>, and then open
            <code>{{ rprojLegend }}</code> from within the IDE.
          </p>
          <img
            alt="Posit Workbench Files pane with arrow pointing at Upload button"
            class="wizard-panel__open-example-description-thumb"
            src="./thumbnail.png"
          >
        </div>

        <hr class="wizard-panel__separator">

        <h3 class="wizard-panel__subtitle">
          Using RStudio Desktop:
        </h3>
        <div class="wizard-panel__open-example-description">
          <p>
            Unzip <code>{{ zip }}</code> locally, then open
            <code>{{ rprojLegend }}</code> from within the IDE.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'OpenExample',
  components: { StepHeading },
  mixins: [PanelMixin],
  computed: {
    rprojLegend() {
      return this.rproj || 'a project file';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.wizard-panel {
  &__open-example {
    background-position: right bottom;
    background-repeat: no-repeat;

    &-content {
      margin-top: 1.5rem;
    }

    &-description {
      margin-top: 0.5rem;
      margin-left: 1rem;
      font-size: 0.9rem;

      &-thumb {
        display: block;
        margin: 1rem 0;
      }
    }
  }

  &__separator {
    height: 1px;
    color: $color-divider;
    background-color: $color-divider;
    border: none;
    margin: 1.5rem 0;
  }
}
</style>

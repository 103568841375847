// Copyright (C) 2024 by Posit Software, PBC.

import { safeAPIErrorMessage } from '@/api/error';

export const SET_STATUS_MESSAGE = 'SET_STATUS_MESSAGE';
export const SET_ACTIVITY_MESSAGE = 'SET_ACTIVITY_MESSAGE';
export const SET_ERROR_MESSAGE_FROM_API = 'SET_ERROR_MESSAGE_FROM_API';
export const SHOW_INFO_MESSAGE = 'SHOW_INFO_MESSAGE';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SHOW_WARNING_MESSAGE = 'SHOW_WARNING_MESSAGE';
export const CLEAR_STATUS_MESSAGE = 'CLEAR_STATUS_MESSAGE';
export const CLEAR_ACTIVITY_MESSAGE = 'CLEAR_ACTIVITY_MESSAGE';

export const StatusTypes = {
  INFO_MESSAGE: 'INFO_MESSAGE',
  ERROR_MESSAGE: 'ERROR_MESSAGE',
  WARNING_MESSAGE: 'WARNING_MESSAGE',
  ACTIVITY_MESSAGE: 'ACTIVITY_MESSAGE',
};

export const defaultState = () => ({
  statusMessage: {
    message: null,
    statusType: null,
  },
  activityMessage: {
    page: null,
    message: null,
  },
});

export default {
  state: defaultState(),
  mutations: {
    [SET_STATUS_MESSAGE](state, { message, statusType }) {
      state.statusMessage.message = message;
      state.statusMessage.statusType = statusType;
    },
    [SET_ERROR_MESSAGE_FROM_API](state, apiError) {
      state.statusMessage.message = safeAPIErrorMessage(apiError);
      state.statusMessage.statusType = StatusTypes.ERROR_MESSAGE;
    },
    [CLEAR_STATUS_MESSAGE](state) {
      state.statusMessage.message = null;
      state.statusMessage.statusType = null;
    },
    [SET_ACTIVITY_MESSAGE](state, { message, page }) {
      state.activityMessage.page = page;
      state.activityMessage.message = message;
    },
    [CLEAR_ACTIVITY_MESSAGE](state) {
      state.activityMessage.page = null;
      state.activityMessage.message = null;
    },
  },
  actions: {
    [SHOW_INFO_MESSAGE]({ commit }, { message, autoHide = true, timeout = 2000 }) {
      commit(SET_STATUS_MESSAGE, {
        message,
        statusType: StatusTypes.INFO_MESSAGE,
      });
      if (autoHide) {
        setTimeout(() => commit(CLEAR_STATUS_MESSAGE), timeout);
      }
    },
    [SHOW_ERROR_MESSAGE]({ commit }, { message, autoHide = false, timeout = 2000 }) {
      commit(SET_STATUS_MESSAGE, {
        message,
        statusType: StatusTypes.ERROR_MESSAGE,
      });
      if (autoHide) {
        setTimeout(() => commit(CLEAR_STATUS_MESSAGE), timeout);
      }
    },
    [SHOW_WARNING_MESSAGE]({ commit }, { message, autoHide = false, timeout = 2000 }) {
      commit(SET_STATUS_MESSAGE, {
        message,
        statusType: StatusTypes.WARNING_MESSAGE,
      });
      if (autoHide) {
        setTimeout(() => commit(CLEAR_STATUS_MESSAGE), timeout);
      }
    },
  },
};

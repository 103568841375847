// Copyright (C) 2023 by Posit Software, PBC.
//
import { store } from '@/store';

import WelcomeView from '@/views/welcome/WelcomeView';
import AppHeader from '@/components/AppHeader';

export default [
  {
    path: '/welcome',
    name: 'welcome',
    components: {
      default: WelcomeView,
      header: AppHeader,
      // NOTE: The Welcome page will render it's own footer since it handles
      // the custom landing page logic as well. When we upgrade to Vue 3, we
      // could migrate this into a function that resolves the component based
      // on state.
    },
    meta: {
      title: 'Welcome',
    },
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.currentUser;

      if (isAuthenticated) {
        next('/content/listing');
      } else {
        next();
      }
    }
  }
];

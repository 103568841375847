<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<template>
  <RSModal
    :active="true"
    :subject="subject"
    class="subscriptions-modal"
    data-automation="subscriptions-modal"
    @close="hideSubscriptionsModal"
  >
    <template #content>
      <div v-if="variants.length === 1">
        <p
          v-if="subscriptions[0]?.checked"
          data-automation="subscribe-text"
        >
          You are subscribed to email notifications for this document. If you no longer wish
          to receive these notifications, click the <strong>Unsubscribe</strong> button below.
        </p>
        <p
          v-else
          data-automation="unsubscribe-text"
        >
          You are not subscribed to email notifications for this document. If you would like
          to receive these notifications, click the <strong>Subscribe</strong> button below.
        </p>
      </div>
      <div v-else>
        <p class="subscriptions-prompt">
          Select the variants for which you would like to receive email notifications
          when they are rendered according to schedule.
        </p>
        <RSCheckboxGroup
          :name="subject"
          :options="subscriptions"
          class="subscription-list"
        />
      </div>
    </template>
    <template #controls>
      <RSButton
        class="cancel-button"
        type="secondary"
        label="Cancel"
        @click="hideSubscriptionsModal"
      />
      <RSButton
        v-if="variants.length > 1"
        type="primary"
        label="Save"
        data-automation="subscriptions-modal__save-button"
        @click="onSave"
      />
      <RSButton
        v-else
        type="primary"
        :label="toggleSubscriptionLabel"
        data-automation="subscriptions-modal__toggle-subscription-button"
        @click="onToggleSubscription"
      />
    </template>
  </RSModal>
</template>

<script>
import { getSubscribers, subscribeUser, unsubscribeUser } from '@/api/subscriptions';
import RSButton from '@/elements/RSButton';
import RSCheckboxGroup from '@/elements/RSCheckboxGroup';
import RSModal from '@/elements/RSModal';
import pluralize from '@/utils/pluralize';
import isEmpty from 'lodash/isEmpty';
import { mapState } from 'vuex';

export default {
  name: 'SubscriptionsModal',
  components: { RSModal, RSButton, RSCheckboxGroup },
  data() {
    return {
      subscriptions: [],
    };
  },
  computed: {
    subject() {
      return `${this.app.title} ${pluralize(this.variants.length, 'Subscription', 'Subscriptions')}`;
    },
    toggleSubscriptionLabel() {
      return this.subscriptions[0]?.checked ? 'Unsubscribe' : 'Subscribe';
    },
    ...mapState({
      app: state => state.contentView.app,
      currentUser: state => state.currentUser.user,
      variants: state => state.parameterization.variants,
      currentVariant: state => state.parameterization.currentVariant,
    }),
  },
  async created() {
    for (const variant of this.variants) {
      const subscribers = await getSubscribers(variant.id);
      this.subscriptions.push({
        checked: !subscribers.optOut,
        label: variant.name,
        id: variant.id
      });
    }
  },
  methods: {
    async onSave() {
      const { guid: userGuid } = this.currentUser;
      for (const subscription of this.subscriptions) {
        if (subscription.checked) {
          await subscribeUser(subscription.id, userGuid);
        } else {
          await unsubscribeUser(subscription.id, userGuid);
        }
      }
      this.hideSubscriptionsModal();
    },
    onToggleSubscription() {
      const subscription = this.subscriptions[0];
      subscription.checked = !subscription.checked;
      this.onSave();
    },
    hideSubscriptionsModal() {
      const params = {};
      let routeName = 'apps.output';
      const guid = this.app.guid;
      params.idOrGuid = guid;

      if (!isEmpty(this.currentVariant)) {
        params.id = this.currentVariant.id;
        routeName += '.variant';
      }

      this.$router.push({
        name: routeName,
        params,
        replace: true,
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  .subscriptions-prompt {
    margin-bottom: 1rem;
  }

  .subscription-list {
    margin-left: 1rem;
  }

  .cancel-button {
    margin-right: 1rem;
  }
</style>

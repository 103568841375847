// Copyright (C) 2023 by Posit Software, PBC.

const DefaultK8SAccount = 'default';

export class ServiceAccounts {
  constructor(accounts) {
    this.enabled = accounts.enabled;
    this.default = accounts.default;
    this.accounts = accounts.accounts.reduce(this.buildOptionsFn(accounts.default), []);
  }

  // Returns a func to be used as the reducer
  // for building the service account options
  buildOptionsFn(defaultSa) {
    return (opts, sa) => {
      const opt = {
        label: this.label(sa, false),
        value: sa,
      };

      // Use "namespace default" as the label for the "default" account
      // to be more specific on instances configured with a custom service account
      if (sa === DefaultK8SAccount) {
        opt.label = 'namespace default';
      }

      if (defaultSa === sa) {
        // Add a "(default)" label to the default service account
        // when it is configured to use a different default account
        if (defaultSa !== DefaultK8SAccount) {
          opt.label = this.label(sa, true);
        }

        // Place the default account as the first option in the list
        opts.unshift(opt);
      } else {
        opts.push(opt);
      }

      return opts;
    };
  }

  // Returns the service account label with the (default) postfix if applicable
  // and adds ellipsis to long service account names
  label(sa, isDefault) {
    let label = sa;
    // These numbers come from trying out long service account names
    // in the UI service accounts dropdown. This UI doesn't change in size.
    // It is safe to have fixed numbers to limit and trim long named service accounts.
    const limit = isDefault ? 34 : 44;
    const trimSize = isDefault ? 30 : 40;
    if (sa.length > limit) {
      label = `${sa.slice(0, trimSize)}...`;
    }
    if (isDefault) {
      label = `${label} (default)`;
    }
    return label;
  }

  // Returns the service account label with the (not recognized) postfix if applicable
  notRecognizedLabel(sa) {
    let label = sa;
    // These numbers come from trying out long service account names
    // in the UI service accounts dropdown. This UI doesn't change in size.
    // It is safe to have fixed numbers to limit and trim long named service accounts.
    const limit = 29;
    const trimSize = 26;
    if (sa.length > limit) {
      label = `${sa.slice(0, trimSize)}...`;
    }
    return `${label} (not recognized)`;
  }
}

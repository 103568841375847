// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiPath } from '@/utils/paths';
import removeMultipleSpaces from '@/utils/removeMultipleSpaces';
import { keysToCamel, keysToSnake } from './transform';

/**
 * API calls for connect api tags
 */

/**
 * Fetch and transform the tags tree from api.
 * @returns {Promise} A Promise that resolves with an array containing the tags tree.
 */
const getTagsTree = () => {
  const path = apiPath('tag-tree');
  return axios.get(path).then(response => {
    return keysToCamel(response.data);
  });
};

/**
 * Creates a new tag via api
 * @param {Object} payload The tag's properties.
 * @param {string} payload.name Required. The name for the new tag.
 * @param {number} payload.parentId The parent id for this tag. Required only for tags, not categories.
 * @returns {Promise} A Promise that resolves with the created tag.
 */
const createTag = payload => {
  payload.name = removeMultipleSpaces(payload.name);
  const path = apiPath('tags');
  return axios.post(path, keysToSnake(payload)).then(response => {
    return keysToCamel(response.data);
  });
};

/**
 * Deletes a tag via api
 * @param {Object} payload Properties of the tag to be deleted.
 * @param {(string|number)} payload.id Required. The tag's id.
 * @param {(string|number)} payload.version Required. The tag's version.
 * @returns {Promise} The executed Promise for the deletion.
 */
const deleteTag = ({ id, version }) => {
  const [eId, eVersion] = [id, version].map(param => encodeURIComponent(param));
  const path = apiPath(`tags/${eId}?version=${eVersion}`);
  return axios.delete(path);
};

/**
 * Creates a new version for a given tag
 * @param {Object} payload The tag's properties
 * @param {string} payload.name Required. The name for the new tag.
 * @param {(string|number)} payload.id Required. The tag's id.
 * @param {(string|number)} payload.version Required. The tag's version.
 * @returns {Promise} A Promise that resolves with the new tag's version.
 */
// eslint-disable-next-line no-shadow
const createTagVersion = ({ name, id, version }) => {
  // shadowing window.name
  const [eId, eVersion] = [id, version].map(param => encodeURIComponent(param));
  const path = apiPath(`tags/${eId}/name?version=${eVersion}`);
  return axios.post(path, { name }).then(response => {
    return keysToCamel(response.data);
  });
};

export { getTagsTree, createTag, createTagVersion, deleteTag };

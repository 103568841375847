<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <OutroPanel
    learn-more-title="Parameterized R Markdown"
    learn-more-text="Learn more in the User Guide"
    learn-more-target="user/param-rmarkdown"
  >
    <template #body>
      <p>
        Once published, anyone with access can change the input parameters
        via the Input panel in {{ systemDisplayName }}.
      </p>
      <p>
        The resulting customized reports can be saved and shared with
        others, or kept private.
      </p>
    </template>
  </OutroPanel>
</template>

<script>
import { mapState } from 'vuex';
import OutroPanel from '../OutroPanel';

export default {
  name: 'RMarkdownParamsOutro',
  components: { OutroPanel },
  computed: {
    ...mapState({
      systemDisplayName: state => state.server.settings.systemDisplayName,
    })

  }
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__outro {
    background-image: url('./background.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>

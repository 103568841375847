// Copyright (C) 2022 by Posit Software, PBC.

import AppRoles from '@/api/dto/appRole';
import ContentTypes from '@/views/content/contentList/contentType';

export const CONTENT_LIST_UPDATE_FILTER = 'CONTENT_LIST_UPDATE_FILTER';
export const CONTENT_LIST_UPDATE_VIEW_TYPE = 'CONTENT_LIST_UPDATE_VIEW_TYPE';
export const CONTENT_LIST_SET_DESELECT_TAG = 'CONTENT_LIST_SET_DESELECT_TAG';

export const FilterType = {
  SEARCH: 'search',
  VISIBILITY: 'visibility',
  CONTENT_TYPE: 'contentType',
  TAGS: 'tags',
};

export const ViewType = {
  TABLE: 'compact',
  BLOG: 'expanded',
};

const filterDefaults = {
  search: '',
  visibility: AppRoles.Viewer,
  contentType: ContentTypes.All,
  tags: {},
};

export default {
  state: {
    filters: { ...filterDefaults },
    startPage: 0,
    viewType: null,
    isOptionsVisible: true,
    deselectTag: {
      tagId: null,
      categoryId: null,
    },
    tagsTree: [],
  },
  mutations: {
    [CONTENT_LIST_UPDATE_FILTER](state, { type, value }) {
      state.filters[type] = value;
    },
    [CONTENT_LIST_UPDATE_VIEW_TYPE](state, value) {
      state.viewType = value;
    },
    [CONTENT_LIST_SET_DESELECT_TAG](state, { tagId, categoryId }) {
      state.deselectTag.tagId = tagId;
      state.deselectTag.categoryId = categoryId;
    },
  },
};

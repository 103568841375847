// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiV1Path } from '@/utils/paths';
import { keysToCamel } from './transform';

export const getExamples = () => axios.get(apiV1Path('examples')).then(({ data }) => {
  const examples = keysToCamel(data);
  examples.forEach(example => {
    example.thumbnailUrl = apiV1Path(`examples/${example.name}/thumbnail`);
    example.downloadUrl = apiV1Path(`examples/${example.name}/zip`);
  });
  return examples;
});

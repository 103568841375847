// Copyright (C) 2024 by Posit Software, PBC.

import axios from 'axios';
import { apiV1Path } from '@/utils/paths';
import { Share } from './dto/share';
import { keysToCamel } from './transform';

export function getShare(guid) {
  // eslint-disable-next-line no-shadow
  return axios.get(apiV1Path(`content/${guid}/share`)).then(({ status, data }) => {
    let share;

    if (status === 200) {
      share = new Share(keysToCamel(data));
    }

    return {
      status,
      share,
    };
  });
}

export function createShare(guid) {
  // eslint-disable-next-line no-shadow
  return axios.post(apiV1Path(`content/${guid}/share`)).then(({ status, data }) => {
    let share;

    if (status === 201) {
      share = new Share(keysToCamel(data));
    }

    return {
      status,
      share,
    };
  });
}

export function removeShare(guid) {
  // eslint-disable-next-line no-shadow
  return axios.delete(apiV1Path(`content/${guid}/share`)).then(({ status }) => {
    // We could just return the full axios response object, but this stays
    // consistent with the other share API functions (and there is no share
    // object)
    return {
      status,
    };
  });
}

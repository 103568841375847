<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <RSButton
      label="Confirm Account"
      data-automation="rp-trigger"
      @click="confirmAccount"
    />
    <CopyTextDialog
      v-if="copyUrlDialog.show"
      title="Account Confirmation Link"
      description="
        Users cannot confirm their own accounts without an email server configured in Posit Connect.
        Administrators will need to copy the account confirmation link to the clipboard and pass it to
        the user via other means. This should be done without visiting the link since administrators must
        have no knowledge of the users' passwords."
      copied-message="The account confirmation link has been copied to the clipboard."
      text-label="Copy Account Confirmation Link"
      :text="copyUrlDialog.url"
      @close="copyUrlDialog.show = false"
    />

    <RSModal
      v-if="sendEmailDialog.show"
      :active="true"
      subject="Confirm Account"
      @close="sendEmailDialog.show = false"
    >
      <template #content>
        Send account confirmation link to {{ userProfile.email }}?
      </template>
      <template #controls>
        <RSButton
          label="Send"
          data-automation="rp-send"
          @click="sendEmail"
        />
      </template>
    </RSModal>
  </div>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';

import { sendOrGetAccountConfirmationLink } from '@/api/authentication';
import CopyTextDialog from '@/components/CopyTextDialog';
import {
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'ConfirmAccount',
  components: {
    CopyTextDialog,
    RSButton,
    RSModal,
  },
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
    canEmail: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      copyUrlDialog: {
        show: false,
        url: '',
      },
      sendEmailDialog: {
        show: false,
      },
    };
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    confirmAccount() {
      if (this.canEmail) {
        this.sendEmailDialog.show = true;
      } else {
        // show copyable 'reset password' link
        return sendOrGetAccountConfirmationLink(this.userProfile.guid)
          .then(({ url }) => {
            this.copyUrlDialog.url = url;
            this.copyUrlDialog.show = true;
          })
          .catch(e => {
            this.copyUrlDialog.show = false;
            this.setErrorMessageFromAPI(e);
          });
      }
    },
    sendEmail() {
      return sendOrGetAccountConfirmationLink(this.userProfile.guid)
        .then(() => {
          this.setInfoMessage({ message: `An account confirmation link has been emailed to ${this.userProfile.email}.` });
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          this.sendEmailDialog.show = false;
        });
    },
  },
};
</script>

// Copyright (C) 2024 by Posit Software, PBC.

import { ref } from 'vue';
import { useRoute } from 'vue-router';

/**
 * @typedef {Object} ComposeContentFilter
 * @property {String|Array} filterRef - Reactive Ref string or array with this filter value(s).
 * @property {Function} syncFrom - Looks for this filter's <key>:<value> match from external string source and syncs the filter value.
 * @property {Function} syncFilterToRef - Syncs the filter value to another string based reactive ref. Replacing any existing <key>:<value> match with the current filter value.
 */

/**
 * Composable to create and handle a new content filter and its values.
 * On creation, it syncs any value that could be present in the route "?q=" query parameter.
 * @example
 * const { filterRef, syncFrom, syncFilterToRef } = useContentFilter('owner');
 * // This filter will be in the form of 'owner:<value>'
 *
 * filterRef.value = 'hisaishi';
 *
 * const externalRef = ref('lint my life');
 * syncFilterToRef(filterRef); // externalRef value is now 'lint my life owner:hisaishi'
 *
 * syncFrom('owner:stanley woodcraft without nails'); // filterRef value is now 'stanley'
 *
 * @example
 * const { filterRef, syncFrom, syncFilterToRef } = useContentFilter('country', 'japan');
 * // Initial value of filterRef is 'japan'
 * // This filter will be in the form of 'country:<value>'
 *
 * const externalRef = ref('lint my life');
 * syncFilterToRef(filterRef); // externalRef value is now 'lint my life country:japan'
 *
 * syncFrom('country:norway woodcraft without nails'); // filterRef value is now 'norway'
 *
 * @example
 * // Filter  with multiple values
 * const { filterRef, syncFrom, syncFilterToRef } = useContentFilter('tags', []);
 * // This filter will be in the form of 'tags:<value>,<value>,<value>...'
 *
 * filterRef.value = ['backpack', 'cars', 'movies'];
 *
 * const externalRef = ref('lint my life');
 * syncFilterToRef(filterRef); // externalRef value is now 'lint my life tags:backpack,cars,movies'
 *
 * syncFrom('tags:countries,economics woodcraft without nails'); // filterRef value is now ['countries', 'economics']
 *
 * @param {String} id Content filter id. Used to generate, find and update the filter <key>:<value>. E.g: 'owner' -> owner:value
 * @param {String|Array} initialValue The initial value. Defaults to empty string. Any value present in ?q param has precedence.
 * An Array must be passed (can be empty) for the filter to handle multiple values.
 * @returns {ComposeContentFilter} Reactive ref of the filter and sync methods to update to and from the filter value.
 */
export const useContentFilter = (id, initialValue = '') => {
  const route = useRoute();
  const { q: initialQuery } = route.query;

  const filterRef = ref(initialValue);
  const refIsArray = Array.isArray(initialValue);
  const findIndexAt = q => q.indexOf(`${id}:`);
  const findFilterRep = q => {
    const matchTarget = q.slice(findIndexAt(q)).split(' ');
    // Values without "double-quoted" values, return right away
    if (!matchTarget[0].includes('"')) {
      return matchTarget[0];
    }

    // For values with "double-quoted" values (values with spaces in between)
    // a bit more work is needed to retrieve the full filter representation.
    let allRep = matchTarget[0];
    for (let index = 1; index < matchTarget.length; index++) {
      allRep += ` ${matchTarget[index]}`;

      const quoteMatch = (matchTarget[index].match(/"/g) || []).length;
      if (quoteMatch === 1) {
        return allRep;
      }
    }
    return allRep;
  };

  const unquote = str => str.replace(/"/g, '');
  const setQuotes = values => [...values].map(f => {
    return f.includes(' ') ? `"${f}"` : f;
  });

  const syncFrom = (q = '') => {
    if (findIndexAt(q) > -1) {
      const filterRep = findFilterRep(q);
      const [ , filterValues ] = filterRep.split(':');
      filterRef.value = refIsArray ? unquote(filterValues).split(',') : filterValues;
    } else {
      filterRef.value = refIsArray ? [] : '';
    }
  };

  const syncFilterToRef = queryRef => {
    let newQueryValue = queryRef.value;

    // If current filter <id>: is present, remove it
    if (findIndexAt(newQueryValue) > -1) {
      newQueryValue = newQueryValue.replace(findFilterRep(newQueryValue), '');
    }

    // If current filter is not empty, append the value to query
    if (filterRef.value && filterRef.value.length) {
      const filterStringValue = refIsArray ? setQuotes(filterRef.value).join(',') : filterRef.value;
      newQueryValue += ` ${id}:${filterStringValue}`;
    }

    // Normalize spacing on query
    newQueryValue = newQueryValue.trim();
    newQueryValue = filterUnquotedValues(newQueryValue);

    queryRef.value = newQueryValue;
  };

  const filterUnquotedValues = (str) => {
    const sections = str.split(/("[^"]*")/);
    // Goes through each section and replaces multiple spaces outside quotes
    // Anything inside double quotes are not changed
    const replacedSections = sections.map(section => {
      if (section.startsWith('"')) {
        return section;
      }
      return section.replace(/  +/g, ' ');
    });

    // Joins to create the full filter value
    return replacedSections.join('');
  };

  if (initialQuery) {
    syncFrom(initialQuery);
  }

  return {
    filterRef,
    syncFrom,
    syncFilterToRef,
  };
};

<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div class="band">
    <table
      class="vertical-space local-about-table"
      data-automation="documentation-about-local__table"
    >
      <tr>
        <td class="emphasize-no-wrap right-space">
          Product:
        </td>
        <td>
          Posit Connect
        </td>
      </tr>
      <tr v-if="version">
        <td class="emphasize-no-wrap right-space">
          Version:
        </td>
        <td>
          {{ version }}
        </td>
      </tr>
      <tr v-if="build">
        <td class="emphasize-no-wrap right-space">
          Build:
        </td>
        <td>
          {{ build }}
        </td>
      </tr>
      <tr v-if="endOfSupport">
        <td class="emphasize-no-wrap right-space">
          End of Support:
        </td>
        <td>
          {{ endOfSupport }}
        </td>
      </tr>
      <template v-if="showVersionData">
        <tr>
          <td class="emphasize-no-wrap right-space">
            R Versions:
          </td>
          <td class="r-versions">
            {{ rInstalls }}
          </td>
        </tr>
        <tr>
          <td class="emphasize-no-wrap right-space">
            Python Versions:
          </td>
          <td class="python-versions">
            {{ pythonInstalls }}
          </td>
        </tr>
        <tr>
          <td class="emphasize-no-wrap right-space">
            Quarto Versions:
          </td>
          <td class="quarto-versions">
            {{ quartoInstalls }}
          </td>
        </tr>
        <tr>
          <td class="emphasize-no-wrap right-space">
            TensorFlow Versions:
          </td>
          <td class="tensorflow-versions">
            {{ tensorflowInstalls }}
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import { normalizedVersion, endOfSupport } from '@/utils/version';
import {
  getServerSettings,
  getRInstallations,
  getPythonInstallations,
  getQuartoInstallations,
  getTensorFlowInstallations,
  ExecutionTypeNative,
} from '@/api/serverSettings';
import { mapState, mapMutations } from 'vuex';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import UserRoles from '@/api/dto/userRole';

dayjs.extend(localizedFormat);

export default {
  name: 'About',
  data() {
    return {
      about: '',
      build: '',
      version: '',
      endOfSupport: '',
      rInstalls: [],
      pythonInstalls: [],
      quartoInstalls: [],
      tensorflowInstalls: [],
      initialized: false,
      showVersionData: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
    }),
    canSeeVersions() {
      return this.currentUser?.userRole >= UserRoles.Publisher;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    async initialize() {
      try {
        const serverSettings = await getServerSettings();
        const { about, build, version, executionType } = serverSettings;
        this.about = about;
        this.build = build;
        this.version = version;
        if (version !== '') {
          const normalized = normalizedVersion(version);
          const eos = endOfSupport(normalized);
          const formatted = eos.format('LL');
          this.endOfSupport = formatted;
        }

        this.showVersionData = executionType === ExecutionTypeNative && this.canSeeVersions;

        if (this.showVersionData) {
          await this.getInstallations();
        }
        this.initialized = true;
      } catch (e) {
        this.setErrorMessageFromAPI(e);
      }
    },
    async getInstallations() {
      try {
        const rInstalls = await getRInstallations();
        this.rInstalls = this.buildVersionString(
          rInstalls.map(install => install.version)
        );

        const pythonInstalls = await getPythonInstallations();
        this.pythonInstalls = this.buildVersionString(
          pythonInstalls.map(install => install.version)
        );

        const quartoInstalls = await getQuartoInstallations();
        this.quartoInstalls = this.buildVersionString(
          quartoInstalls.map(install => install.version)
        );

        const tensorflowInstalls = await getTensorFlowInstallations();
        this.tensorflowInstalls = this.buildVersionString(
          tensorflowInstalls.map(install => install.version)
        );
      } catch (e) {
        this.setErrorMessageFromAPI(e);
      }
    },
    buildVersionString(versions) {
      if (versions === undefined) {
        // Likely to be unreachable with real data.
        return '(unknown)';
      }

      if (versions.length === 0) {
        return '(none)';
      }

      return versions.join(', ');
    },
  },
};
</script>

<style scoped lang="scss">
.local-about-table {
  max-width: 650px;
  border-collapse: separate;
  border-spacing: 0 15px;
}
</style>

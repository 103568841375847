// Copyright (C) 2022 by Posit Software, PBC.

import { apiPath, apiV1Path } from '@/utils/paths';
import axios from 'axios';
import { Bundle } from './dto/bundle';
import { Job } from './dto/job';
import { keysToCamel } from './transform';

/**
 * API calls for bundle endpoints
 *
 * Makes the request to the endpoint, gets the data back
 * and transforms said data as needed.
 */

export const createBundle = (appGuid, bundleData) => {
  if (bundleData.url) {
    const url = `${apiV1Path(`content/${appGuid}/bundles`)  }?url=${bundleData.url}`;
    return axios.post(url).then(res => res.data);
  }

  return axios.post(apiV1Path(`content/${appGuid}/bundles`), bundleData).then(res => res.data);
};

export const getBundleData = (appGuid, bundleId) => {
  const url = apiV1Path(`content/${appGuid}/bundles/${bundleId}`);
  return axios.get(url).then(res => keysToCamel(res.data));
};

export const getAppBundles = (appGuid, pageSize = 500) => {
  const url = apiV1Path(`content/${appGuid}/bundles?page_size=${pageSize}`);
  return axios.get(url).then(({ data }) => data.map(b => new Bundle(keysToCamel(b))));
};

export const getBundleDownloadUrl = (appGuid, bundleId) => apiV1Path(`content/${appGuid}/bundles/${bundleId}/download`);

export const deleteBundle = (appGuid, bundleId) => {
  const url = apiV1Path(`content/${appGuid}/bundles/${bundleId}`);
  return axios.delete(url).then(res => res.data);
};

export const deployBundle = (appId, bundleId) => {
  const url = apiPath(`applications/${appId}/deploy`);
  return axios.post(url, { bundle: Number(bundleId) }).then(res => res.data);
};

export const getBundleJobs = (appId, bundleId, jobTag) => {
  const url = apiPath(`applications/${appId}/jobs?filter=tag:${jobTag}&filter=bundle_id:${bundleId}`);
  return axios.get(url).then(res => res.data.map(j => new Job(keysToCamel(j))));
};

// Copyright (C) 2024 by Posit Software, PBC.

/**
* @param {Object} errorField
// errorField = {
//   field: null,
//   code: responseData.code,
//   msg: responseData.error,
//   args: responseData.payload,
// };
 * @returns {Boolean}  returns true if handled, false if not.
 */

export const ENVIRONMENT_ADD_OR_EDIT_DIALOG_FIELDS_ENUM = {
  ENVIRONMENT_NAME: 'environment_name',
};

export function onEnvServerError(errorField) {
  return ENVIRONMENT_ADD_OR_EDIT_DIALOG_FIELDS_ENUM.ENVIRONMENT_NAME === errorField.field;
}

// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiV1Path } from '@/utils/paths';

export function getFeatureUsage() {
  return axios
    .get(apiV1Path('feature-usage'))
    .then(({ data }) => data);
}

export const Features = {
  OpenSolo: 'open_solo',
};

export function markFeatureUsed(feature) {
  // failures here are acceptable and may be safely ignored
  return axios
    .post(apiV1Path(`feature-usage/${encodeURIComponent(feature)}`))
    .catch(() => {});
}

// Copyright (C) 2023 by Posit Software, PBC.

import { getCurrentUser } from '@/api/users';
import { CurrentUser } from '@/api/dto/user';
import UserRoles from '@/api/dto/userRole';

export const CURRENT_USER_LOAD = 'CURRENT_USER_LOAD';
export const CURRENT_USER_SET = 'CURRENT_USER_SET';
export const CURRENT_USER_ERROR = 'CURRENT_USER_ERROR';
export const CURRENT_USER_CLEAR = 'CURRENT_USER_CLEAR';

export default {
  state: {
    loaded: false,
    error: null,
    isAuthenticated: false,
    user: {},
    lastKnownSession: null,
  },
  mutations: {
    [CURRENT_USER_SET](state, { isAuthenticated, user }) {
      state.loaded = true;
      state.error = null;
      state.isAuthenticated = isAuthenticated;
      state.user = user;
      state.lastKnownSession = isAuthenticated ? new Date() : null;
    },

    [CURRENT_USER_ERROR](state, err) {
      state.loaded = false;
      state.error = err;
      state.isAuthenticated = false;
      state.user = {};
    },

    [CURRENT_USER_CLEAR](state) {
      state.loaded = false;
      state.error = null;
      state.isAuthenticated = false;
      state.user = {};
      state.lastKnownSession = null;
    },
  },
  actions: {
    [CURRENT_USER_LOAD]({ commit }, clearUser = true) {
      return new Promise((resolve, reject) => {
        // There are times when clearing user is not desired,
        // e.g: checking for current session,
        // we don't want to trigger re-rendering everywhere.
        if (clearUser) {
          commit(CURRENT_USER_CLEAR);
        }

        getCurrentUser()
          .then(user => {
            commit(CURRENT_USER_SET, { isAuthenticated: true, user });
            resolve({ isAuthenticated: true, user });
          })
          .catch(err => {
            if (err?.response?.status === 401) {
              const anonymousUser = new CurrentUser({ userRole: UserRoles.Anonymous });
              commit(CURRENT_USER_SET, {
                isAuthenticated: false,
                user: anonymousUser,
              });
              resolve({ isAuthenticated: false, user: anonymousUser });
            } else {
              commit(CURRENT_USER_ERROR, err);
              reject(err);
            }
          });
      });
    },
  },
};

// Copyright (C) 2022 by Posit Software, PBC.

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(calendar);
export function activeTime(datetime) {
  if (!datetime) {
    return 'Never logged in';
  }
  return dayjs(datetime).calendar(null, {
    lastDay: `[Yesterday]`,
    sameDay: `[Today]`,
    nextDay: `[Tomorrow]`,
    nextWeek: 'YYYY-MM-DD',
    lastWeek: 'YYYY-MM-DD',
    sameElse: 'YYYY-MM-DD',
  });
}

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div
    :data-automation="dataAutomation"
    class="rs-field"
  >
    <div
      :class="{small}"
      class="rs-field__help"
    >
      <label
        :for="name"
        :class="{small}"
        class="rs-field__help-label"
      >{{ label }}
        <span
          v-if="required"
          aria-hidden="true"
        >*</span>
      </label>
      <div class="rs-field__help-controls">
        <i
          v-if="hasHelp"
          :class="{active: helpShown}"
          tabindex="0"
          class="rs-field__help-icon"
          aria-label="More Information"
          role="button"
          @click="toggleHelp"
          @keypress="toggleHelp"
        />
      </div>
    </div>

    <!-- help text -->
    <div
      v-if="helpShown"
      class="rs-field__text"
    >
      <span v-if="$slots.help">
        <slot name="help" />
      </span>
      <span v-else>{{ help }}</span>
    </div>
    <div class="rs-field__control">
      <input
        v-bind="$attrs"
        :id="name"
        ref="input"
        :aria-label="label"
        :class="{ error: hasError, warning: hasWarning, info: hasInfo, small }"
        :name="name"
        class="rs-input"
        :value="modelValue"
        :type="inputType"
        :aria-describedby="`${name}-message`"
        :aria-invalid="hasError"
        :aria-required="required"
        @input="handleValue"
      >
      <button
        v-if="toggleVisibility"
        :aria-label="`toggle visibility for ${label}`"
        :class="viewValueClass"
        type="button"
        @click.prevent="toggleView"
      />
    </div>

    <!-- message text -->
    <div
      v-if="hasMessage"
      :id="`${ name }-message`"
      :class="{ 'rs-field__error': hasError, 'rs-field__warning': hasWarning, 'rs-field__info': hasInfo }"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
const ErrorMessage = 'error';
const WarningMessage = 'warning';
const InfoMessage = 'info';

export default {
  name: 'RSInputPassword',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: null
    },
    // Please do not remove this prop, although it seems like it is not used.
    // Without this prop, the modelModifers attribute is erroneously added to the input element.
    // See: https://github.com/rstudio/connect/pull/27082
    // eslint-disable-next-line vue/no-unused-properties
    modelModifiers: {
      type: Object,
      default: () => ({})
    },
    dataAutomation: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    messageType: {
      type: String,
      default: ErrorMessage,
    },
    small: {
      type: Boolean,
      default: false,
    },
    toggleVisibility: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: true,
    }
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      inputType: 'password',
      helpShown: false,
    };
  },
  computed: {
    hasMessage() {
      return Boolean(this.message);
    },
    hasHelp() {
      return Boolean(this.help) || Boolean(this.$slots.help);
    },
    hasError() {
      return this.hasMessage && this.messageType === ErrorMessage;
    },
    hasWarning() {
      return this.hasMessage && this.messageType === WarningMessage;
    },
    hasInfo() {
      return this.hasMessage && this.messageType === InfoMessage;
    },
    viewValueClass() {
      const className = ['rs-field__control-see-value'];
      if (this.inputType === 'text') {
        className.push('rs-field__control-see-value--showing');
      }
      return className;
    },
  },
  methods: {
    handleValue(ev) {
      this.$emit('change', ev.target.value);
      this.$emit('update:modelValue', ev.target.value);
    },
    focusElement() {
      this.$nextTick().then(() => this.$refs.input.focus());
    },
    toggleView(ev) {
      ev.preventDefault();
      if (this.inputType === 'password') {
        this.inputType = 'text';
      } else {
        this.inputType = 'password';
      }
    },
    toggleHelp() {
      this.helpShown = !this.helpShown;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

input {
  box-sizing: border-box;
  padding: 5px 10px;
}

input {
  border: 1px solid $color-medium-grey;
  &:disabled, &:read-only {
    @include control-disabled-input;
  }
}

.rs-field {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 0.9rem;
  }

  &__text {
    font-size: $rs-font-size-small;
    border: 1px solid $color-light-grey-3;
    background-color: $color-light-grey-2;
    margin: 3px 0 5px 0;
    padding: 0.5rem;
    line-height: 1.5;

    p, ul, ol, li {
      margin: 0.3rem 0;
      padding: 0;
    }

    ul, ol {
      padding-left: 1rem;
    }
  }

  &__error {
    @include message;
    color: $color-error;
  }

  &__warning {
    @include message;
    color: $color-warning;
  }

  &__info {
    @include message;

    &-label {
      font-size: 0.9rem;
    }
  }

  &__help {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;

    &-label {
      @include label;

      &.small {
        @include label(true);
      }
    }

    &-controls {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      & > * {
        margin-left: 0.5rem;
      }
    }

    &-icon {
      width: $rs-icon-size-smaller;
      height: $rs-icon-size-smaller;
      margin: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      background-repeat: no-repeat;
      background-size: $rs-icon-size-smaller $rs-icon-size-smaller*2;
      background-position: 0 0;
      background-image: url(/images/elements/actionToggleInfo.svg);
      cursor: pointer;

      &.active {
        background-position: 0 (-$rs-icon-size-smaller);
      }

      &:focus {
        @include control-focus;
        border-radius: 50%;
      }
    }
  }

  &__control {
    position: relative;
    .rs-input {
      width: 100%;
      max-width: 100%;
      font-size: $rs-font-size-normal;
      margin: 0;
      padding: 0.4rem 0.6rem;
      background-color: #fff;
      color: $color-secondary-inverse;

      &:focus {
        @include control-focus;
      }

      &.small {
        font-size: $rs-font-size-small;
        padding: 0.25rem 0.4rem;
      }

      @include message-state;
    }

    &-see-value {
      background-color: transparent;
      background-image: url(/images/elements/actionView.svg);
      background-position: center;
      background-size: contain;
      border: 0;
      position: absolute;
      padding: 0;
      margin: 4px;
      height: 1.5rem;
      width: 1.5rem;
      top: 0;
      right: 0;

      &:focus {
        @include control-focus;
      }

      &--showing::after {
        background: $color-dark-grey;
        content: " ";
        height: 2px;
        left: 0;
        position: absolute;
        top: 0.7rem;
        width: 1.4rem;
        transform: rotate(-45deg);
      }
    }

  }
}
</style>

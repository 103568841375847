// Copyright (C) 2023 by Posit Software, PBC.

export const DEPLOY_WIZARD_OPEN = 'DEPLOY_WIZARD_OPEN';
export const DEPLOY_WIZARD_CLOSE = 'DEPLOY_WIZARD_CLOSE';

export default {
  state: {
    active: false,
  },
  mutations: {
    [DEPLOY_WIZARD_OPEN](state) {
      state.active = true;
    },

    [DEPLOY_WIZARD_CLOSE](state) {
      state.active = false;
    },
  },
};

<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<template>
  <div class="formSection">
    <iframe
      v-if="url"
      v-show="frameLoaded"
      id="parameters-iframe"
      ref="iframe"
      title="Parameters Form"
      data-automation="parameters-iframe"
      :src="url"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { LEGACY_PARAMS_UPDATE_FORM } from '@/store/modules/legacyParams';

// https://stackoverflow.com/a/15935330/555493
export function maxIframeHeight(iframeDocument) {
  return Math.max(
    iframeDocument.body.scrollHeight,
    iframeDocument.documentElement.scrollHeight,
    iframeDocument.body.offsetHeight,
    iframeDocument.documentElement.offsetHeight,
    iframeDocument.body.clientHeight,
    iframeDocument.documentElement.clientHeight
  );
}

export default {
  name: 'ParametersEditor',
  props: {
    url: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState({
      frameLoaded: state => state.legacyParams.form.loaded,
    }),
  },
  mounted() {
    window.addEventListener('message', this.handleMessage);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleMessage);
  },
  methods: {
    ...mapMutations({
      updateForm: LEGACY_PARAMS_UPDATE_FORM,
    }),
    handleMessage(msg) {
      switch (msg.data.type) {
        case 'customshiny:onchange':
          this.updateForm({ dirty: true });
          break;
        case 'customshiny:resize':
          this.resize();
          break;
        case 'customshiny:ready':
          this.updateForm({ loaded: true });
          break;
      }
    },
    resize() {
      const { iframe } = this.$refs;

      if (
        iframe &&
        iframe.contentWindow &&
        iframe.contentWindow.document &&
        iframe.contentWindow.document.body &&
        iframe.contentWindow.document.body.children
      ) {
        const doc = iframe.contentWindow.document;
        const height = maxIframeHeight(doc);
        iframe.style.height = `${height}px`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#parameters-iframe {
  overflow: scroll;
  width: 100%;
  height: 100%;
  border: 0px;
}
</style>

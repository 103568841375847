<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <p>
      {{ systemDisplayName }} could not find available branches.
      Double-check your repository permissions.
    </p>
    <p v-if="hasBranchScanningError">
      Scanning returned the error: {{ branchScanningError }}
    </p>
    <p v-if="!hasBranchScanningError">
      Scanning is taking too long.
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'BranchScanFailure',
  props: {
    branchScanningError: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapState({
      systemDisplayName: state => state.server.settings.systemDisplayName,
    })
  },
  methods: {
    hasBranchScanningError() {
      return !!this.branchScanningError;
    },
  },
};
</script>

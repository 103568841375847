// Copyright (C) 2022 by Posit Software, PBC.
import { utcToLocalTimezone } from '@/utils/timezone';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(calendar);
dayjs.extend(isBetween);

function iconCSSClass({ type, hasParameters, contentCategory }) {
  switch (type) {
    case 'rmd-static':
    case 'quarto-static':
      if (['site', 'book'].includes(contentCategory)) {
        // if site
        return hasParameters ? 'typeSiteParamsScheduled' : 'typeSiteScheduled';
      }
      return hasParameters ? 'typeDocParamsScheduled' : 'typeDocScheduled';
    case 'jupyter-static':
      return hasParameters
        ? 'typeJupyterParamsScheduled'
        : 'typeJupyterScheduled';
    default:
      // error: unknown doc-type (will be an empty icon)
      return '';
  }
}

function documentType({ type, contentCategory }) {
  switch (type) {
    case 'rmd-static': {
      switch (contentCategory) {
        case 'site':
          return 'R Markdown website';
        case 'book':
          return 'R Markdown book';
        default:
          return 'R Markdown document';
      }
    }
    case 'quarto-static': {
      switch (contentCategory) {
        case 'site':
          return 'Quarto website';
        case 'book':
          return 'Quarto book';
        default:
          return 'Quarto document';
      }
    }
    case 'jupyter-static':
      return 'Jupyter notebook';
    default:
      return 'Scheduled document';
  }
}

function generateRuns(item, end) {
  let nextRun = dayjs(item.nextRun);
  const mend = dayjs(end);
  const everyN = JSON.parse(item.schedule).N;
  const runs = [];
  const minuteRun = { start: null, end: null, multiple: true };

  if (item.type === 'minute') {
    // this means that when item.type is 'minute' that the runs will be a
    // single entry that is an object with start and end timestamps, and in
    // all other cases it will be an array of timestamps
    minuteRun.start = nextRun.toISOString();
    minuteRun.end = mend.toISOString();
    runs.push(minuteRun);
  } else {
    // in this 'hour'-specific loop, we fill in the array of runs given that
    // the server side excludes these runs by default for response size
    // economy
    while (nextRun.isBefore(mend)) {
      const nextRunString = nextRun.toISOString();
      if (item.type === 'hour') {
        runs.push(nextRunString);
      }
      nextRun = nextRun.add(everyN, item.type);
    }
  }
  return runs;
}

// The API returns a `description` field that contains a string
// with a time of day in UTC. This function replaces the time of day
// with the local time of day at which the content will be rerun.
const toLocalTimezone = ({ description, timeOfDay }) =>
  description?.replace(
    /(\d{1,2}:\d{2}[am|pm]*)/,
    utcToLocalTimezone(timeOfDay, 'h:mma')
  );

// ScheduledContent is a view-model that wraps a Schedule returned from the API
// to provide useful methods to the dashboard.
export class ScheduledContent {
  constructor(item, end) {
    this.item = item;
    this.id = item.id;

    // link to content's schedule in dashboard
    this.url = `#/apps/${item.appId}/output/${item.variantId}`;

    // link to user profile in dashboard
    this.authorUrl = `#/people/users/${encodeURIComponent(
      item.extra.owner.guid
    )}`;
    this.authorGuid = item.extra.owner.guid;

    const { firstName, lastName, username } = item.extra.owner;
    this.authorName = `${firstName} ${lastName}`.trim() || username;

    this.iconCssClass = iconCSSClass(item.extra.content);
    this.documentType = documentType(item.extra.content);
    this.frequencyDescription = toLocalTimezone({
      description: item.extra.description,
      timeOfDay: item.nextRun,
    });
    this.scheduleType = item.type;
    this.sendsEmailDescription = item.email ? 'Yes' : 'No';
    this.sendsEmailGlyph = item.email ? '✔' : '';
    this.email = item.email;

    this.title = (function() {
      if (item.extra.content.defaultVariant) {
        // content is default variant
        return item.extra.content.title;
      }
      // content is variant
      return `${item.extra.content.title}, ${item.extra.content.variantName}`;
    })();

    this.numberOfRuns = item.extra.runCount;
    this.nextRun = item.nextRun;
    this.nextRunDescription = dayjs(item.nextRun).calendar(null, {
      lastDay: '[Yesterday at] h:mma',
      lastWeek: 'Last dddd [at] h:mma',
      sameDay: '[Today] [at] h:mma',
      nextDay: '[Tomorrow] [at] h:mma',
      nextWeek: 'dddd [at] h:mma',
      sameElse: 'MMM D, YYYY [at] h:mma',
    });

    if (end && ['hour', 'minute'].includes(item.type)) {
      this.runs = generateRuns(item, end);
    } else {
      this.runs = item.extra.runs;
    }
  }

  // whether this scheduled content runs on the specified day
  runsOn(day) {
    if (this.scheduleType === 'minute') {
      return this.runs.some(
        t =>
          dayjs(t.start).isSame(day, 'day') ||
          dayjs(day).isBetween(t.start, t.end)
      );
    }
    return this.runs.some(t => dayjs(t).isSame(day, 'day'));
  }

  // scheduled runs on the day specified
  runsFor(day) {
    if (this.scheduleType === 'minute') {
      return this.runs.filter(
        t =>
          dayjs(t.start).isSame(day, 'day') ||
          dayjs(day).isBetween(t.start, t.end)
      );
    }
    return this.runs.filter(t => dayjs(t).isSame(day, 'day'));
  }
}

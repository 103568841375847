<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { PARAMETERIZATION_REFRESH_REPORT } from '@/store/modules/parameterization';
import isEmpty from 'lodash/isEmpty';
import { computed } from 'vue';
import { useStore } from 'vuex';

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const isBusy = computed(() => store.state.parameterization.isBusy);
const isInitialized = computed(
  () => !isEmpty(store.state.parameterization.currentVariant)
);
// refresh is against the current variant not the adhoc that is being
// edited. prohibit refresh during edit.
const isEditing = computed(
  () => !isEmpty(store.state.legacyParams.adhocVariant)
);
</script>

<template>
  <button
    type="button"
    aria-label="Refresh Report"
    :disabled="isBusy || !isInitialized || isEditing || disabled"
    title="Refresh Report"
    class="action update content-actions__refresh-button"
    data-automation="menuitem-refresh-report"
    @click="store.dispatch(PARAMETERIZATION_REFRESH_REPORT)"
  />
</template>

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { docsPath } from '@/utils/paths';
const store = useStore();
const currentUser = computed(() => store.state.currentUser.user);
const isAdmin = computed(() => currentUser.value.isAdmin());
const adminUrl = docsPath('admin/');
</script>

<template>
  <div
    data-automation="empty-auth-integration"
    class="empty-auth-integration"
  >
    <p
      v-if="isAdmin"
    >
      Get started by adding integrations. Refer to the 
      <a
        class="empty-auth-integration-link"
        :href="adminUrl"
        target="_blank"
        data-automation="admin-guide-link"
      >
        Admin Guide
      </a>
      for detailed instructions on creating your first OAuth integration.
    </p>
    <p
      v-else
    >
      Your Connect administrator can set up integrations for your content by following the instructions in the 
      <a
        class="empty-auth-integration-link"
        :href="adminUrl"
        target="_blank"
        data-automation="admin-guide-link"
      >
        Admin Guide
      </a>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.empty-auth-integration {
  margin: 100px auto 0 auto;
  max-width: 450px;
  text-align: center;

  a {
    font-weight: bold;
  }
}
</style>

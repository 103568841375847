// Copyright (C) 2024 by Posit Software, PBC.

/**
 * removeMultipleSpaces returns a string with multiple spaces to a string single spaces only
 * @param {String} str
 * @returns {String}
 */

const removeMultipleSpaces = str => str.replace(/ {2,}/g, ' ');

export default removeMultipleSpaces;

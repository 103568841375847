// Copyright (C) 2023 by Posit Software, PBC.

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import UserRoles from '@/api/dto/userRole';

dayjs.extend(calendar);
export class ApiKey {
  constructor({
    id,
    name: keyName,
    userRole,
    key,
    createdTime,
    activeTime,
  }) {
    this.id = id;
    this.name = keyName;
    this.key = key;
    this.userRole = UserRoles.of(userRole);
    this.created = dayjs(createdTime).calendar();
    this.active = dayjs(activeTime).calendar();
  }
}

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <OutroPanel
    learn-more-title="Introduction to Python APIs"
    learn-more-text="Learn more in the User Guide"
    learn-more-target="user/fastapi"
  >
    <template #body>
      <p>
        Once your Python FastAPI is published, your code can be accessed from
        other systems. You control who can access it and more via its
        settings panels.
      </p>
    </template>
  </OutroPanel>
</template>

<script>
import OutroPanel from '../OutroPanel';

export default {
  name: 'PythonFastAPIOutro',
  components: { OutroPanel }
};
</script>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    :data-automation="dataAutomation"
    class="rs-field"
  >
    <div
      v-if="showLabel"
      :class="{small}"
      class="rs-field__help helpLabel"
    >
      <label
        :for="name"
        :class="{small}"
        class="rs-field__help-label"
      >{{ label }}</label>
      <i
        v-if="hasHelp"
        :class="{active: helpShown}"
        class="rs-field__help-icon"
        role="button"
        tabindex="0"
        aria-label="More Information"
        @click="toggleHelp"
        @keypress="toggleHelp"
      />
    </div>

    <!-- help text -->
    <div
      v-if="helpShown"
      class="rs-field__text"
    >
      <div
        v-if="helpType === 'string'"
        class="helpParagraphs"
      >
        {{ help }}
      </div>
      <div v-if="helpType === 'array'">
        <div
          v-for="item in help"
          :key="item"
          class="helpParagraphs"
        >
          {{ item }}
        </div>
      </div>
      <div v-if="$slots.help">
        <slot name="help" />
      </div>
      <div v-if="helpLinkObj">
        <div class="helpSlot">
          Learn more about this setting in the Posit Connect
          <a
            :href="helpLinkObj.anchor.href"
            target="_blank"
          >
            {{ helpLinkObj.anchor.text }}
          </a>.
        </div>
      </div>
    </div>
    <div class="rs-field__control">
      <div class="rowFlex">
        <div
          class="defaultBox"
        >
          <span :class="{defaultBoxStrikethrough: overridingDefault}">
            <span
              :class="overridingDefault ? 'defaultBoxValueOverridden' : 'defaultBoxValueActive'"
            >
              {{ defaultValue }}
              <span v-if="padDefaultValue">&nbsp;</span>
            </span>
            <span class="defaultBoxZeroMeaning">{{ defaultValueZeroMeaning }}</span>
          </span>
        </div>
        <div class="overrideBox">
          <span class="deleteicon">
            <input
              v-bind="$attrs"
              :id="name"
              :aria-label="label"
              :class="['overrideInput', hasError ? 'overrideInputInError' : '']"
              :name="name"
              :disabled="disabled"
              :data-automation="`${name}-input`"
              placeholder="enter override"
              type="text"
              :value="modelValue"
              @input="handleInput"
            >
            <span
              v-if="overridingDefault && !disabled"
              tabindex="0"
              role="button"
              :data-automation="`${name}-clear`"
              @click="handleClear"
              @keyup.enter="handleClear"
            >
              x
            </span>
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="hasMessage"
      :class="{ 'rs-field__error': hasError, 'rs-field__warning': hasWarning, 'rs-field__info': hasInfo }"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
import { debounce } from '@/utils/debounce';

const ErrorMessage = 'error';
const WarningMessage = 'warning';
const InfoMessage = 'info';

const DebounceMS = 500;

export default {
  name: 'RSInputNumberOverrideDefault',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: null
    },
    dataAutomation: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    help: {
      type: [String, Array],
      default: null,
      validator: prop => ((typeof prop === 'string') || (Array.isArray(prop) && prop.length > 0)),
    },
    message: {
      type: String,
      default: null,
    },
    messageType: {
      type: String,
      default: ErrorMessage,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    defaultValue: {
      type: String,
      default: '',
    },
    allowDecimal: {
      type: Boolean,
      default: false,
    },
    suppressRegEx: {
      type: String,
      default: '',
    },
    helpLinkObj: {
      type: Object,
      default: null,
    },
    zeroDescription: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      helpShown: false,
      cachedValue: '',
      mounted: false,
    };
  },
  computed: {
    helpType() {
      if (typeof this.help === 'string') {
        return 'string';
      } else if (Array.isArray(this.help)) {
        return 'array';
      }
      return 'unknown';
    },
    padDefaultValue() {
      return this.defaultValue.length === 1;
    },
    overridingDefault() {
      return this.mounted && this.modelValue !== '' && this.modelValue !== null;
    },
    hasMessage() {
      return Boolean(this.message);
    },
    hasHelp() {
      return Boolean(this.help) || Boolean(this.$slots.help);
    },
    hasError() {
      return this.hasMessage && this.messageType === ErrorMessage;
    },
    hasWarning() {
      return this.hasMessage && this.messageType === WarningMessage;
    },
    hasInfo() {
      return this.hasMessage && this.messageType === InfoMessage;
    },
    defaultValueZeroMeaning() {
      return this.defaultValue === '0' ? `${this.zeroDescription}` : '';
    },
  },
  watch: {
    modelValue(newValue) {
      this.cachedValue = newValue;
    }
  },
  mounted() {
    this.mounted = true;
    this.init();
  },
  methods: {
    init() {
      this.cachedValue = this.modelValue;
    },
    toggleHelp() {
      this.helpShown = !this.helpShown;
    },
    handleInput(inputEvent) {
      let regExStr = this.allowDecimal ? '[^0-9.]' : '[^0-9]';
      if (this.suppressRegEx !== '') {
        regExStr = this.suppressRegEx;
      }
      const re = new RegExp(regExStr);
      // restrict the input to the regEx w/ only one decimal possible
      inputEvent.target.value = inputEvent.target.value.replace(re, '').replace(/(\..*)\./g, '$1');

      // if it hasn't changed, then do not emit the event - parent doesn't need to know.
      if ((inputEvent.target.value !== this.cachedValue) && !(inputEvent.target.value === '' && this.cachedValue === null)) {
        this.debouncedEmitInput(inputEvent.target.value);
        this.cachedValue = inputEvent.target.value;
      } else {
        inputEvent.stopPropagation();
      }
    },
    debouncedEmitInput: debounce(DebounceMS, function(targetValue) {
      this.$emit('change', targetValue);
      this.$emit('update:modelValue', targetValue);
    }),
    handleClear() {
      this.$emit('change', '');
      this.$emit('update:modelValue', '');
      this.cachedValue = '';
    },
  }
};
</script>
<style lang="scss" scoped>
@import 'Styles/shared/_colors';

  .rowFlex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .defaultBox {
    width: 50%;
    height: 1.5rem;
    color: $color-dark-grey-3;
    border: rgb(201, 200, 200) solid 1px;
    margin-right: 20px;
    text-align: left;
    justify-content: center;
    line-height: 1.5rem;
    box-sizing: border-box;
    padding: 0px 10px;
    font-family: 'Lato', 'sans-serif';
    font-size: 14px;
    font-stretch: 100%;
    font-weight: 400;
  }
  .defaultBoxStrikethrough {
    text-decoration: line-through;
    color: $color-dark-grey;
  }
  .defaultBoxValueActive {
    color: $color-dark-grey-3;
  }
  .defaultBoxValueOverridden {
    color: $color-dark-grey;
  }
  .defaultBoxZeroMeaning {
    font-style: italic;
    font-weight: 200;
  }
  .overrideBox {
    width: 50%;
  }
  .overrideInput {
    width: 100%;
    color: $color-dark-grey-3;
    height: 1.5rem
  }
  .overrideInputInError {
    border-color: $color-error;
  }
  span.deleteicon {
      /* width: 50%; */
      position: relative;
      display: inline-flex;
      align-items: center;
  }
  span.deleteicon span {
      position: absolute;
      display: block;
      right: 3px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      color: $color-white;
      background-color: $color-medium-grey;
      font: 13px monospace;
      text-align: center;
      line-height: 1em;
      cursor: pointer;
  }
  span.deleteicon input {
      padding: 5px 0 5px 10px;
      box-sizing: border-box;
  }

  .helpLabel {
    margin: .5rem 0 .3rem;
  }

  .helpParagraphs {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .helpSlot {
    padding-top: 0.5rem;
  }
</style>

// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiPath } from '@/utils/paths';

/**
 * API calls for connect privileges endpoints
 *
 * Makes the request to the endpoint and returns the result.
 */

const requestPrivilege = privilege => {
  const path = apiPath('privileges/request');
  return axios.post(path, { privilege });
};

export { requestPrivilege };

// Copyright (C) 2022 by Posit Software, PBC.

/**
 * limitedPrecisionDuration
 *
 * Utility method to produce the special format desired when
 * displaying a duration with limited duration.
 *
 * We will split time into whole units of Days, Hours, Minutes and Seconds
 * but display only the two most significant non-zero units and drop the lesser
 * significant units.
 *
 *
 * Examples:
 * zero time
 *   0 seconds => '0m 0s'
 * small - seconds only
 *   1 second => '0m 1s'
 * minutes and seconds only
 *   1 minute + 1 second => '1m 1s'
 *   9 minutes + 15 seconds => '9m 15s'
 *   15 minutes => '15m 0s'
 * hours and minutes only
 *   7 hours + 15 minutes => '7h 15m'
 *   3 hours => '3h 0m'
 * hours and minutes, dropping seconds
 *   8 hours + 31 minutes + 17 seconds => '8h 31m'
 * hours and minutes, rounding up seconds
 * days only
 *   1 day => '1d 0h'
 * days and hours only
 *   1 day + 4 hours => '5d 4h'
 * days and hours, dropping minutes and seconds
 *   1 day + 5 minutes + 49 seconds => '1d 0h'
 * days and hours, dropping minutes
 *   7 days + 11 hours + 55 minutes => '7d 11h'
 * days and hours, drop minutes and seconds
 *   7 days + 30 minutes + 45 seconds => '7d 0h'
 *
 * @param {number} numberOfSeconds
 *
 * @returns {string} - formatted string
 */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const limitedPrecisionDuration = numberOfSeconds => {
  if (numberOfSeconds < 0) {
    return 'invalid';
  }

  const dur = dayjs.duration(numberOfSeconds, 'seconds');

  let timeStr = '';

  if (dur.days() > 0) {
    timeStr += `${dur.days()}d ${dur.hours()}h`;
  } else if (dur.hours() > 0) {
    timeStr += `${dur.hours()}h ${dur.minutes()}m`;
  } else {
    timeStr += `${dur.minutes()}m ${dur.seconds()}s`;
  }

  return timeStr;
};

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div class="rs-help-toggler">
    <div
      class="rs-help-toggler__help"
      :class="{'rs-help-toggler__help--left': leftAlign}"
    >
      <div class="rs-help-toggler__label">
        <slot name="title" />
      </div>
      <div class="rs-help-toggler__controls">
        <slot name="leftSideControls" />
        <i
          v-bind="$attrs"
          :class="{ active: helpShown }"
          aria-label="More Information"
          class="rs-help-toggler__help-icon"
          data-automation="help-toggler"
          role="button"
          tabindex="0"
          @click="toggleHelp"
          @keypress="toggleHelp"
        />
        <slot name="rightSideControls" />
      </div>
    </div>

    <!-- help text -->
    <div
      v-if="helpShown"
      class="rs-help-toggler__text"
      data-automation="help-text"
    >
      <slot name="help" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RSInformationToggle',
  props: {
    leftAlign: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      helpShown: false
    };
  },
  methods: {
    toggleHelp() {
      this.helpShown = !this.helpShown;
    }
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-help-toggler {
  position: relative;

  &__help {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--left {
      justify-content: unset;

      & .rs-help-toggler__controls {
        margin-left: 4px;
      }
    }
  }

  &__label {
    color: $color-secondary-inverse;
    line-height: 1.5rem;
  }

  &__help-icon {
    width: $rs-icon-size-smaller;
    height: $rs-icon-size-smaller;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    background-repeat: no-repeat;
    background-size: $rs-icon-size-smaller $rs-icon-size-smaller*2;
    background-position: 0 0;
    background-image: url(/images/elements/actionToggleInfo.svg);
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background-color: $color-light-grey-2;
    }

    &:focus-visible {
      @include control-visible-focus(50%);
    }
  }

  &__controls {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &__text {
    font-size: $rs-font-size-small;
    border: 1px solid $color-light-grey-3;
    background-color: $color-light-grey-2;
    margin: 3px 0 5px 0;
    padding: 0.5rem;
    line-height: 1.5;
  }
}
</style>

// Copyright (C) 2024 by Posit Software, PBC.

import { camelCase } from 'lodash';

/**
 * Utility for calculating the visibility status of a given form field in 
 * an OAuth integration template.
 * 
 * visibilityExpression is expected to be a boolean expression represented as an expression tree.
 * The evaluateVisibility function performs a DFS to navigate the tree and compute a final boolean
 * result that represents the visibility of a given field.
 * 
 * Each node in the tree can take one of the following forms: 
 * 
 * 1. A boolean assertion against the state of the oauth integration config (EQUALS)
 * 2. A boolean operation (AND/OR/NOT) and operands
 * 
 * Any other forms are malformed.
 */
export const evaluateVisibility = (visibilityExpression, config) => {
  // leaf node, represents an equals/not_equals assertion 
  if ('name' in visibilityExpression  && 'value' in visibilityExpression) {
    const configValue = config[camelCase(visibilityExpression.name)];
    if (visibilityExpression.notEquals) {
      return configValue !== visibilityExpression.value;
    }
    return configValue === visibilityExpression.value;
  }

  // if not a leaf node, assume the node represents an operation / operands.
  
  if ('operator' in visibilityExpression && 'operands' in visibilityExpression) {
    // edge case - return 'false' if there isn't an operand to 
    // evaluate.
    if (visibilityExpression.operands.length === 0) {
      return false;
    }

    // recursively evaluate AND operation
    if (visibilityExpression.operator === 'and') {
      for (let i = 0; i < visibilityExpression.operands.length; i++) {
        if (!evaluateVisibility(visibilityExpression.operands[i], config)) {
          return false;
        }
      }
      return true;
    }
   
    // recursively evaluate OR operation
    if (visibilityExpression.operator === 'or') {
      for (let i = 0; i < visibilityExpression.operands.length; i++) {
        if (evaluateVisibility(visibilityExpression.operands[i], config)) {
          return true;
        }
      }  
      return false;
    }

    // recursively evaluate NOT operation
    // ignore all operands but the first
    if (visibilityExpression.operator === 'not') {
      return !evaluateVisibility(visibilityExpression.operands[0], config);
    }
  } 

  // malformed node
  return false;
};

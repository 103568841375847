// Copyright (C) 2022 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import PythonDependencies from './panels/PythonDependencies';
import PythonDeploy from './panels/PythonDeploy';
import PythonRsconnect from './panels/PythonRsconnect';
import PythonFastAPIOutro from './panels/PythonFastAPIOutro';

export default {
  copyFrom: [
    DownloadExample,
    PythonDependencies,
    PythonRsconnect,
    PythonDeploy,
    PythonFastAPIOutro,
  ],
};

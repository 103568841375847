<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<!--
  SupportExpiration

  Renders the support expiration view, which indicates end-of-support for the
  current product version.

-->
<template>
  <div
    v-if="display"
    class="broadcastMessage"
  >
    <span data-automation="end-of-support-message">{{ message }}</span>

    <span>
      Please update Posit Connect or contact <a :href="supportEmailLink">{{ supportEmail }}</a> for assistance.
    </span>

    <button
      aria-label="Close message"
      class="messageClose"
      @click="dismiss"
    />
  </div>
</template>

<script>
import { SUPPORT_EMAIL } from '@/constants/contacts';
import {
  SERVER_NOTICES_SUPPORT_CLOSE
} from '@/store/modules/serverNotices';
import { normalizedVersion, endOfSupport } from '@/utils/version';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { mapMutations, mapState } from 'vuex';

dayjs.extend(localizedFormat);

export default {
  name: 'SupportExpiration',
  props: {
    server: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      supportEmail: SUPPORT_EMAIL,
    };
  },
  computed: {
    ...mapState({
      active: state => state.serverNotices.support,
    }),
    supportEmailLink() {
      return `mailto:${this.supportEmail}`;
    },
    enabled() {
      // Indicate end-of-support only when the server shares its version and
      // the feature is enabled. The feature is only reported as enabled for
      // administrators.
      return this.server.version !== '' && this.server.endOfSupportUiWarning === true;
    },
    normalizedVersion() {
      // Returns the product version YYYY.MM.V without unreleased or
      // development suffixes.
      return normalizedVersion(this.server.version);
    },
    endOfSupport() {
      // Returns the date when the product is out-of-support.
      return endOfSupport(this.normalizedVersion);
    },
    expired() {
      // Returns true when the product is out-of-support (product support has
      // expired).
      const now = dayjs();
      const eos = this.endOfSupport;
      return eos.isBefore(now);
    },
    display() {
      return this.enabled && this.active && this.expired;
    },
    message() {
      const eos = this.endOfSupport;
      const formatted = eos.format('LL');
      return `Posit Connect v${this.server.version} is out-of-support since ${formatted}.`;
    }
  },
  methods: {
    ...mapMutations({
      dismiss: SERVER_NOTICES_SUPPORT_CLOSE,
    }),
  },
};
</script>

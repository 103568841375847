<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Server notices

  Renders the server notices view.
-->
<template>
  <div v-if="ready">
    <LicenseExpiration
      :server="server"
    />
    <SupportExpiration
      :server="server"
    />
    <DeprecatedSettings
      :server="server"
    />
  </div>
</template>

<script>
import { keysToCamel } from '@/api/transform';

import LicenseExpiration from './LicenseExpiration.vue';
import SupportExpiration from './SupportExpiration.vue';
import DeprecatedSettings from './DeprecatedSettings.vue';

export default {
  name: 'ServerNotices',
  components: {
    LicenseExpiration,
    SupportExpiration,
    DeprecatedSettings,
  },
  props: {
    ngServer: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ready() {
      return this.server !== undefined;
    },
    // Convert the incoming Angular model (ngServer) from snake_case to
    // camelCase for our child components.
    server() {
      return keysToCamel(this.ngServer);
    },
  },
};
</script>

<style lang="scss">
@import 'Styles/shared/_mixins';
@import 'Styles/shared/_colors';
  /* adjustment allowing multiple broadcast messages, each with their own X. */
  .broadcastMessage {
    position: relative;
    width: 100%;
    padding: 2px 40px;
    line-height: 20px;
    background-color: $color-light-grey;
    text-align: center;
    border-bottom: 1px dotted $color-alternate;
  }
  /* Adjustment needed to make the X not be all, well, you know, "buttony". */
  .broadcastMessage button.messageClose {
    background-color: inherit;
    padding: 0;
    flex-shrink: 0;
  
    border-radius: 15px;
    @include control-visible-focus(50%);
  
    &:hover {
      background-color: rgba(0, 0, 0, .05);
    }

    position: absolute;
    right: 20px;
    top: 0px;
    width: 24px;
    height: 24px;
    background-image: url(Images/close.svg);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  };
</style>

// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';

import { Host } from './dto/hosts';
import { keysToCamel } from '@/api/transform';
import { apiV1Path } from '@/utils/paths';

export function getHosts() {
  return axios
    .get(apiV1Path('system/hosts'))
    .then(({ data }) => {
      return data.map(h => new Host(keysToCamel(h)));
    });
}

export function getActiveHosts() {
  return axios
    .get(apiV1Path('system/hosts'), { params: { active: true } })
    .then(({ data }) => {
      return data.map(h => new Host(keysToCamel(h)));
    });
}

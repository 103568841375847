// Copyright (C) 2022 by Posit Software, PBC.

// BuildState reflects the backend `BuildStatus` type.  It is used to denote
// the current build state of an app and its related bundles.
export class BuildState {
  constructor(value) {
    this._value = value;
  }

  // Ensures serialization via `JSON.stringify` returns the raw value.
  toJSON() {
    return this._value;
  }

  toString() {
    let description;
    switch (this._value) {
      case 0:
        description = 'Empty';
        break;
      case 1:
        description = 'Pending';
        break;
      case 2:
        description = 'Complete';
        break;
    }
    return `BuildState(${description})`;
  }
}

// BuildStatus holds singleton values and a construction function for raw app role
// values.
const BuildStatus = {
  Empty: new BuildState(0),
  Pending: new BuildState(1),
  Complete: new BuildState(2),

  of(value) {
    switch (value) {
      case 0:
        return BuildStatus.Empty;
      case 1:
        return BuildStatus.Pending;
      case 2:
        return BuildStatus.Complete;
      default:
        // default to Empty as we're unsure
        return BuildStatus.Empty;
    }
  },
};

export default BuildStatus;

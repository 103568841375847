<!-- Copyright (C) 2024 by Posit Software, PBC. -->
<script setup>
const { label, disabled } = defineProps({
  label: { type: String, required: true },
  color: { type: String, default: 'limegreen' },
  hideLabel: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
});
const model = defineModel({ type: Boolean, required: true });
defineOptions({ inheritAttrs: false });

const toggleId = `toggle-${Math.random().toString(36).substring(2)}`;
</script>

<template>
  <div class="container">
    <div
      :class="['switch', { disabled }]"
      :title="label"
    >
      <input
        :id="toggleId"
        v-model="model"
        :aria-label="label"
        :data-automation="$attrs['data-automation']"
        :disabled="disabled"
        class="toggle"
        :class="{ checked: model }"
        :style="model ? { backgroundColor: color } : {}"
        type="checkbox"
      >
      <span
        class="slider"
        data-automation="toggle-slider"
      />
    </div>

    <label
      v-if="!hideLabel"
      :for="toggleId"
      :class="{ disabled }"
    >
      {{ label }}
    </label>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.container {
  display: flex;
  align-items: center;

  label {
    font-size: 0.85rem;
  }

  * {
    margin-right: 0.5rem;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.switch {
  line-height: 2.1;
  position: relative;

  &.disabled {
    opacity: 0.5;
  }

  &:has(.checked) {
    .slider {
      left: 21px;
    }
  }

  .toggle {
    appearance: none;
    background: $color-dark-grey;
    border-radius: 25px;
    border: 1px solid gray;
    height: 22px;
    margin: 2px;
    outline: none;
    width: 40px;

    &:focus-visible {
      @include control-visible-focus(25px);
    }

    &.checked {
      outline: none;

      &:after {
        content: '';
        display: none;
      }
    }
  }

  .slider {
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    left: 3px;
    position: absolute;
    top: 3px;
    transition: left 0.2s ease-out;
    width: 20px;
    // Having pointer-events: none
    // allows the input to be the main target
    // beingthe .slider mainly presentational
    pointer-events: none;
  }
}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

// AccessTypes holds singleton values and a construction function for raw
// accessType values.
const AccessTypes = {
  All: 'all',
  LoggedIn: 'logged_in',
  Acl: 'acl',

  of(value) {
    switch (value) {
      case 'all':
        return AccessTypes.All;
      case 'logged_in':
        return AccessTypes.LoggedIn;
      case 'acl':
        return AccessTypes.Acl;
      default:
        // default to Acl as it's the most restrictive
        return AccessTypes.Acl;
    }
  },
};

export default Object.freeze(AccessTypes);

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    class="access"
    data-automation="app-settings__access"
  >
    <div
      v-if="loadingError"
      class="formSection"
      data-automation="loading-error"
    >
      <p>An error occurred while loading the access settings.</p>
    </div>

    <ConfirmationPanel
      :enabled="enableConfirmation"
      :visible="showConfirmation"
      @save="save"
      @discard="discard"
    />
    <MessageBox
      v-if="requestByToken"
      small
      :closeable="requestMsgCanBeClosed"
      @close="clearPermissionRequest"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="requestByTokenMsg" />
    </MessageBox>

    <div>
      <!-- Sharing --> 
      <RSInformationToggle
        class="spaceAfter"
      >
        <template #title>
          <span class="groupHeadings">
            Sharing
          </span>
        </template>
        <template #help>
          <div class="spaceBefore spaceAfter">
            <h4>Who can view or change this content.</h4>

            <ul class="access-help">
              <li>
                Give specific people and groups access to your content by adding them as
                viewers or collaborators.
              </li>
              <li>
                Search by their name and use the drop-down
                to specify what privileges they should receive.
              </li>
              <li>
                Adjust viewer and collaborator permissions after adding people by using the
                drop-down next to their name.
              </li>
            </ul>
            <p
              v-if="anonymousServersUnlicensedHelp"
              class="anonymous-servers-unlicensed-help"
            >
              The product license prohibits access to interactive content without login.
              <a
                :href="licensingDocumentation"
                target="_blank"
              >
                Learn more.
              </a>
            </p>

            <p v-if="anonymousServersVerificationHelp">
              The product license allows interactive content to be hosted
              publicly online without login. Posit Connect periodically checks
              that public access content is available online. Content that
              cannot be verified will be restricted to logged-in users.
              <a
                :href="licensingDocumentation"
                target="_blank"
              >
                Learn more.
              </a>
            </p>

            <p v-if="anonymousBrandingHelp">
              The product license displays a "Powered by Posit Connect" badge on content that
              permits access without log in.
              <a
                :href="licensingDocumentation"
                target="_blank"
              >
                Learn more.
              </a>
            </p>
          </div>
        </template>
      </RSInformationToggle>

      <div class="formSection">
        <AccessType
          v-if="showAccessType && type"
          :read-only="!canEditSettings"
          :visible-types="visibleAccessTypes"
          :is-worker-app="isWorkerApp"
          :anonymous-branding="anonymousBranding"
        />
        <div
          v-if="showAuthWarning"
          class="rsc-alert warning"
          data-automation="oauth-warning"
        >
          Auth integrations are not available for public-access content.
          Any associated integrations will be removed.
        </div>

        <p
          v-if="showPublicContentInfoLink"
          class="access-type-admin-guide-link"
        >
          See the
          <a :href="publicAccessAdminGuideURL">
            Posit Connect Admin Guide
          </a>
          for more information about public-access content verification.
        </p>

        <p
          v-if="showPublicContentProblemLink"
          class="access-type-admin-guide-link"
        >
          Resolving the validation problem may require a change in this server's
          network configuration. See the
          <a :href="publicAccessAdminGuideURL">
            Posit Connect Admin Guide
          </a>
          for more information.
        </p>

        <SearchBox
          v-if="showSearchBox"
          label="Share with"
          :server-settings="serverSettings"
          :exclusion-set="new Set([ownerGuid])"
          :remote-lookup="false"
          data-automation="as-search"
          name="as-add-access"
          type="all"
          @select="addPrincipal"
        >
          <template #help>
            <strong>Can't find something?</strong>

            <p v-if="cantFindSomethingMsg === 'forAdmin'">
              Users will show up here as they log in. You can also add
              <RouterLink
                class="add-links"
                :to="{ name: 'people.users' }"
              >
                users
              </RouterLink>
              and
              <RouterLink
                class="add-links"
                :to="{ name: 'people.groups' }"
              >
                groups
              </RouterLink>
              yourself.
            </p>

            <p v-if="cantFindSomethingMsg === 'forUsersPermissions'">
              Users will show up here as they log in. Otherwise users or groups need to be added to Connect first.
              You can also add
              <RouterLink
                class="add-links"
                :to="{ name: 'people.users' }"
              >
                users
              </RouterLink>
              yourself.
            </p>

            <p v-if="cantFindSomethingMsg === 'forGroupsPermissions'">
              Users will show up here as they log in. Otherwise users or groups need to be added to Connect first.
              You can also add
              <RouterLink
                class="add-links"
                :to="{ name: 'people.groups' }"
              >
                groups
              </RouterLink>
              yourself.
            </p>

            <p v-if="cantFindSomethingMsg === 'forNoPermissions'">
              Users will show up here as they log in.
              Otherwise users or groups need to be added to Connect by an administrator first.
            </p>
          </template>
        </SearchBox>

        <PrincipalList
          v-if="showPrincipalList && owner"
          :principals="activePrincipals"
          :can-edit-permissions="canEditPermissions"
          :current-user-guid="currentUser.guid"
          :owner="owner"
          :content-type="contentType"
          @update-principal="updatePrincipal"
          @remove-principal="removePrincipal"
        />

        <!-- Sharing URL -->
        <ShareLink
          v-if="showShareLink"
          :app="app"
          data-automation="share-link"
        />
      </div>
    </div>

    <!-- OAuth -->
    <div v-if="showAuthSection">
      <div
        class="formSection spaceAfter"
        data-automation="oauth-integrations"
      >
        <RSInformationToggle
          v-if="currentUser.canEditAppSettings(app)"
          class="spaceAfter"
        >
          <template #title>
            <!-- override contentPanel styling -->
            <span class="groupHeadings">
              Integrations
            </span>
          </template>
          <template #help>
            <div style="padding-top:0.5rem; padding-bottom: 0.5rem;">
              <p>
                Adding an integration to your content indicates that 
                every content viewer must explicitly allow the content 
                to access their personal credentials. After viewers log
                in to the integration, their credentials are used when 
                making requests to the integration's external resource.
              </p>

              <p>
                Learn how to author content for integrations and view code examples in the
                <a :href="authIntegrationsGuideURL">User Guide</a>.
              </p>
            </div>
          </template>
        </RSInformationToggle>
        <div
          v-else
          class="groupHeadings"
        >
          Integrations
        </div>
        <button
          v-if="!loading &&
            currentUser.canEditAppSettings(app) &&
            associations.active.length === 0"
          class="add-integration-button"
          data-automation="add-integration-button"
          @click="toggleAuthModal"
        >
          Add integration
        </button>
        <AuthIntegrationsListModal
          v-if="showAuthIntegrationModal"
          :available-integrations="associations.available"
          :active="associations.active"
          @integration-change="onAuthIntegrationChange"
          @close="toggleAuthModal"
        />
        <AuthActiveIntegration
          v-if="associations.active.length !== 0"
          :active-auth="oauthSessionAuth"
          @open-modal="toggleAuthModal"
          @integration-change="onAuthIntegrationChange"
        />
      </div>
    </div>

    <!-- Vanity URL -->
    <div>
      <RSInformationToggle>
        <template #title>
          <span class="groupHeadings">
            Content URL
          </span>
        </template>
        <template #help>
          <p>
            A custom URL can be created to access this content.
          </p>
          <p>
            Your custom path will be appended to the URL of your Posit Connect server
            to form a complete URL for this content. The
            <a
              :href="contentUrlDocumentation"
              target="_blank"
            >
              User Guide
            </a>
            explains what custom URL
            paths are permitted.
          </p>
          <p>
            Note that Posit Connect may be configured to restrict
            this option only to Administrators.
          </p>
        </template>
      </RSInformationToggle>

      <ContentUrl
        v-if="showContentUrl"
        v-model="activeCustomUrl"
        :app-guid="app.guid"
        :can-customize="canAddCustomUrl"
        :can-edit-settings="canEditSettings"
        :disable-copy="customUrlIsChanging"
      />
    </div>

    <LockContent
      v-if="doneLoading && lockedContentEnabled"
      v-model:locked="activeLocked"
      v-model:locked-message="activeLockedMessage"
      :app="app"
    />

    <!-- Supportive popups and messages -->
    <EmbeddedStatusMessage
      v-if="loading"
      message="Loading access settings..."
      :show-close="false"
      type="activity"
      data-automation="loading"
    />
    <BecomeViewerWarning
      v-if="showBecomeViewerWarning"
      :is-admin="isAdmin"
      @close="closeBecomeViewerWarning"
      @confirm="confirmBecomeViewer"
    />
    <RemovePrincipalWarning
      v-if="showRemovePrincipalWarning"
      :is-admin="isAdmin"
      @close="closeRemovePrincipalWarning"
      @confirm="confirmRemovePrincipal"
    />

    <LockConfirmationModal
      v-if="showLockConfirmationModal"
      :subject="`Locking ${app.displayName}`"
      confirmation="Lock Content"
      @cancel="showLockConfirmationModal = false"
      @confirm="saveConfirmation"
    >
      <div class="confirm-message">
        <p>
          By locking this content, <strong>{{ app.displayName }}</strong>, all active processes will immediately stop.
          The content will no longer appear in search results. 
        </p>
        <p>
          Do you want to proceed with locking this content or cancel?
        </p>
      </div>
    </LockConfirmationModal>
  </div>
</template>

<script>
import {
  addGroup,
  addUser,
  removeGroup,
  removeUser,
  updateContent,
} from '@/api/app';
import { deleteCustomUrl, setCustomUrl } from '@/api/customUrl';
import AccessTypes from '@/api/dto/accessType';
import AppRoles from '@/api/dto/appRole';
import PublicContentStatuses from '@/api/dto/publicContentStatus';
import { User } from '@/api/dto/user';
import { addNewRemoteGroup } from '@/api/groups';
import { getAvailableIntegrations, getContentAssociations, getUserSessions, setContentAssociations } from '@/api/oauth';
import { getRequestAccessByToken } from '@/api/permissions';
import { getApplicationsSettings } from '@/api/serverSettings';
import { addNewRemoteUser, getUser } from '@/api/users';
import LockConfirmationModal from '@/components/ConfirmModal';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage.vue';
import MessageBox from '@/components/MessageBox';
import RSInformationToggle from '@/elements/RSInformationToggle';
import {
  ACCESS_SETTINGS_UPDATE_MODE,
  ACCESS_SETTINGS_UPDATE_TYPE,
  ModeType,
} from '@/store/modules/accessSettings';
import {
  LOAD_CONTENT_VIEW,
  SET_CONTENT_FRAME_RELOADING,
} from '@/store/modules/contentView';
import {
  CLEAR_STATUS_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
} from '@/store/modules/messages';
import { docsPath, getHashQueryParameter, joinPaths, oauthLoginPath, oauthLogoutPath } from '@/utils/paths';
import ConfirmationPanel from '@/views/content/settings/ConfirmationPanel';
import AuthIntegrationsListModal from '@/views/content/settings/access/AuthIntegrationsListModal.vue';
import AuthActiveIntegration from '@/views/content/settings/access/AuthActiveIntegration.vue';
import LockContent from '@/views/content/settings/LockContent.vue';
import { isEqual, upperFirst } from 'lodash';
import { RouterLink } from 'vue-router';
import { mapActions, mapMutations, mapState } from 'vuex';
import AccessType from './AccessType';
import BecomeViewerWarning from './BecomeViewerWarning';
import ContentUrl from './ContentUrl';
import ShareLink from './ShareLink';
import PrincipalList from './PrincipalList';
import RemovePrincipalWarning from './RemovePrincipalWarning';
import SearchBox from './SearchBox';

export default {
  name: 'AccessSettings',
  components: {
    AccessType,
    AuthIntegrationsListModal,
    AuthActiveIntegration,
    BecomeViewerWarning,
    ConfirmationPanel,
    ContentUrl,
    EmbeddedStatusMessage,
    LockContent,
    LockConfirmationModal,
    MessageBox,
    PrincipalList,
    RSInformationToggle,
    RemovePrincipalWarning,
    RouterLink,
    SearchBox,
    ShareLink,
  },
  data() {
    return {
      loading: true,
      loadingError: false,
      canAddCustomUrl: false,
      canEditSettings: false,
      canEditPermissions: false,
      contentType: '',
      owner: null,
      isAdmin: false,
      isPublisher: false,
      isEditor: false,
      isWorkerApp: false,
      anonymousBranding: false,
      anonymousServersUnlicensedHelp: false,
      anonymousServersVerificationHelp: false,
      anonymousBrandingHelp: false,
      showPublicContentInfoLink: false,
      showPublicContentProblemLink: false,
      visibleAccessTypes: [],
      ownerGuid: null,
      isExecutable: false,
      initial: {
        customUrl: null,
        locked: false,
        lockedMessage: '',
        mode: null,
        principals: [],
        type: null,
      },
      activePrincipals: [],
      activeCustomUrl: null,
      activeLocked: false,
      activeLockedMessage: '',
      initialized: new Promise(() => {}),
      isSaving: false,
      showBecomeViewerWarning: false,
      showRemovePrincipalWarning: false,
      pendingBecomeViewerPrincipal: null,
      pendingRemovePrincipal: null,
      requestByToken: null,
      oauthSessions: [],
      associations: {
        available: [],
        initial: [],
        active: [],
        activeKeys: [],
      },
      showLockConfirmationModal: false,
      showAuthIntegrationModal: false,
    };
  },
  computed: {
    ...mapState({
      activeState: state => state.accessSettings,
      app: state => state.contentView.app,
      currentUser: state => state.currentUser.user,
      locked: state => state.contentView.app.locked,
      lockedContentEnabled: state => state.server.settings.lockedContentEnabled,
      lockedMessage: state => state.contentView.app.lockedMessage,
      serverSettings: state => state.server.settings,
      type: state => state.accessSettings.type,
    }),
    doneLoading() {
      return !this.loading && !this.loadingError;
    },
    showAccessType() {
      return this.doneLoading;
    },
    showSearchBox() {
      return this.doneLoading && this.canEditPermissions;
    },
    showPrincipalList() {
      return this.doneLoading;
    },
    showContentUrl() {
      return this.doneLoading && this.app;
    },
    typeIsChanging() {
      return this.initial.type !== this.activeState.type;
    },
    customUrlIsChanging() {
      return this.initial.customUrl?.path !== this.activeCustomUrl;
    },
    listIsChanging() {
      return this.activePrincipals.some(this.isChanged);
    },
    lockedStateIsChanging() {
      return this.initial.locked !== this.activeLocked ||
        this.initial.lockedMessage !== this.activeLockedMessage;
    },
    authIntegrationIsChanging() {
      return !isEqual(this.associations.active, this.associations.initial);
    },
    showAuthSection() {
      if (!this.app?.hasWorker() || // static content can't have integrations
        !this.serverSettings.oauthIntegrationsEnabled ||
        // if there's nothing for a viewer to sign into, no need to show
        (!this.currentUser.canEditAppSettings(this.app) && this.associations.active.length === 0) ||
        // publishers don't need to see the section if no integrations are set up
        (this.currentUser.canEditAppSettings(this.app) &&
          this.associations.available.length === 0) ||
        // public content can't have integrations either
        this.activeState.type === AccessTypes.All
      ) {
        return false;
      }
      return true;
    },
    showAuthWarning() {
      return (
        this.app?.hasWorker() // don't show for static content
        && this.serverSettings.oauthIntegrationsEnabled // or if not enabled
        && this.associations.initial.length !== 0 // or if not active on this content
        && this.typeIsChanging // are we changing who can access the content?
        && this.activeState.type === AccessTypes.All // are we changing to 'no login required'?
      );
    },
    showConfirmation() {
      return this.doneLoading && (
        this.typeIsChanging ||
        this.listIsChanging ||
        this.lockedStateIsChanging ||
        this.customUrlIsChanging ||
        this.authIntegrationIsChanging
      );
    },
    enableConfirmation() {
      return this.showConfirmation && !this.isSaving;
    },
    cantFindSomethingMsg() {
      const canAddGroups =
        this.currentUser.canCreateGroup(this.serverSettings) ||
        this.currentUser.canAddRemoteGroup(this.serverSettings);
      const canAddUsers =
        this.currentUser.canAddNewUser(this.serverSettings) ||
        this.currentUser.canAddRemoteUser(this.serverSettings);

      if (this.isAdmin || (canAddGroups && canAddUsers)) {
        return 'forAdmin';
      } else if (canAddUsers) {
        return 'forUsersPermissions';
      } else if (canAddGroups) {
        return 'forGroupsPermissions';
      }
      return 'forNoPermissions';
    },
    requestByTokenMsg() {
      if (!this.requestByToken) {
        return null;
      }

      if (this.requestByToken.approved) {
        return `<strong>${
          this.requestByToken.user.displayName
        }</strong> was already added as a <strong>${this.getRequestRole()}</strong>`;
      }
      return `<strong>${
        this.requestByToken.user.displayName
      }</strong> has been added as a <strong>${
        this.getRequestRole()
      }</strong>. You must Save for the changes to take effect.`;
    },
    requestMsgCanBeClosed() {
      return this.requestByToken && this.requestByToken.approved;
    },
    licensingDocumentation() {
      return docsPath('admin/licensing/');
    },
    contentUrlDocumentation() {
      return docsPath('user/content-settings/#custom-url');
    },
    publicAccessAdminGuideURL() {
      return docsPath('admin/licensing/#public-access-content-verification');
    },
    authIntegrationsGuideURL() {
      return docsPath('user/oauth-integrations');
    },
    oauthSessionAuth() {
      const simplifiedSessions = {};
      let activeAuthObj = {};

      for (const session of this.oauthSessions) {
        simplifiedSessions[session.oauthIntegrationGuid] = session.hasRefreshToken;
      }

      for (const association of this.associations.active) {
        // Currently, we only support only one active association per content item
        // If or when we start to support multiple, this will need to be addressed.
        activeAuthObj = {
          label: association.label,
          sub: association.sub,
          template: association.template,
          value: association.value,
        };

        if (!Object.keys(simplifiedSessions).includes(association.value) ||
          simplifiedSessions[association.value] === false)
        {
          activeAuthObj.href = oauthLoginPath(
            { guid: association.value, redirect: window.location.href }
          );
          activeAuthObj.linkName = 'Login';
        }
        else {
          activeAuthObj.href = oauthLogoutPath(association.value);
          activeAuthObj.linkName = 'Logout';
        }
      }

      return activeAuthObj;
    },
    // Only show share links if:
    //  - enabled in early access (server setting also handles license check)
    //  - user is an editor or admin
    showShareLink() {
      const { earlyAccess } = this.serverSettings;
      return earlyAccess.shareLink && (this.isAdmin || this.isEditor);
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      updateType: ACCESS_SETTINGS_UPDATE_TYPE,
      updateMode: ACCESS_SETTINGS_UPDATE_MODE,
      reloadFrame: SET_CONTENT_FRAME_RELOADING,
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      resetApp: LOAD_CONTENT_VIEW,
    }),
    init() {
      // When admin self-grants access via embedded content
      // Re-fetch this component's data to update the ACL.
      this.$onAdminSelfGrantAccessDo(this.reloadAccessData);
      this.loading = true;
      this.loadingError = false;
      this.clearStatusMessage();
      this.initialized = this.getData()
        .then(this.prefillPermissionRequestIfAny)
        .catch(e => {
          this.loadingError = true;
          this.setErrorMessageFromAPI(e);
        })
        .finally(() => (this.loading = false));
    },
    getData() {
      // TODO: getting applications settings can be done in
      // within the store action resolving the content data -> LOAD_CONTENT_VIEW
      return getApplicationsSettings()
        .then(appSettings => {
          const { app, currentUser, serverSettings } = this;
          if (!app) {
            return;
          }

          this.ownerGuid = app.ownerGuid;
          this.isExecutable = app.isExecutable();
          this.contentType = app.contentType();
          this.canEditSettings = currentUser.canEditAppSettings(app);
          this.canEditPermissions = currentUser.canEditAppPermissions(app);
          this.canAddCustomUrl = currentUser.canAddVanities();
          this.isAdmin = currentUser.isAdmin();
          this.isPublisher = currentUser.isPublisher();
          this.isEditor = currentUser.isAppEditor(app);

          this.visibleAccessTypes = appSettings.accessTypes;

          this.isWorkerApp = this.app.hasWorker();
          this.anonymousBranding = serverSettings.license.anonymousBranding;
          this.anonymousBrandingHelp = !this.isWorkerApp && this.anonymousBranding;

          // Compute help values for worker apps (static and rendered content
          // will have PublicContentStatuses.None)
          if (this.isWorkerApp) {
            this.anonymousServersUnlicensedHelp = (
              app.publicContentStatus === PublicContentStatuses.Unlicensed
            );
            this.anonymousServersVerificationHelp = (
              app.publicContentStatus === PublicContentStatuses.Restricted ||
              app.publicContentStatus === PublicContentStatuses.Warning ||
              app.publicContentStatus === PublicContentStatuses.Ok
            );
            this.showPublicContentInfoLink = app.publicContentStatus === PublicContentStatuses.Ok &&
              app.accessType === AccessTypes.All;
            this.showPublicContentProblemLink = (
              app.publicContentStatus === PublicContentStatuses.Restricted ||
              app.publicContentStatus === PublicContentStatuses.Warning
            ) && app.accessType === AccessTypes.All;
          }

          // Get app owner. Viewers are not allowed to call getUser, and we only need fields
          // that we already have available on `app`, so just create a User DTO from those.
          // If ViewersCanOnlySeeThemselves is true then owner information has been stripped;
          // a placeholder name is set in the DTO.
          this.owner = new User({
            firstName: app.ownerFirstName,
            lastName: app.ownerLastName,
            username: app.ownerUsername,
            guid: app.ownerGuid,
            email: app.ownerEmail,
            locked: app.ownerLocked,
          });

          this.initial.mode =
            app.accessType === AccessTypes.Acl
              ? ModeType.VIEWER
              : ModeType.OWNER;

          this.initial.type = app.accessType;

          // Sort existing principals by name. Newly added principals should appear
          // at the top of the list, so sorting should not be repeated.
          this.initial.principals = [...app.users, ...app.groups].sort((a, b) => {
            const aname = a.displayName ? a.displayName : a.name;
            const bname = b.displayName ? b.displayName : b.name;
            if (aname < bname) {
              return -1;
            }
            if (aname > bname) {
              return 1;
            }
            return 0;
          });

          if (app.vanities && app.vanities.length > 0) {
            // Rename the GET /applications/{id} vanities field to mirror what
            // is returned by GET /v1/content/{guid}/vanity. The initial
            // customUrl does not need the entire vanity response object...
            this.initial.customUrl = {
              path: app.vanities[0].pathPrefix,
            };
          } else {
            // it's necessary to reset this here because we may be reloading
            // after deleting an existing custom url
            this.initial.customUrl = {
              path: '',
            };
          }
        })
        .then(this.oauthInfo)
        .then(this.lockedState)
        .then(this.resetActive);
    },
    async oauthInfo() {
      if (this.serverSettings.oauthIntegrationsEnabled) {
        this.oauthSessions = await getUserSessions();
        const active = await getContentAssociations(this.app.guid);
        this.associations.initial = [];
        for (const each of active) {
          this.associations.initial.push(
            {
              label: each.oauthIntegrationName,
              value: each.oauthIntegrationGuid,
              sub: each.oauthIntegrationDescription,
              template: each.oauthIntegrationTemplate
            }
          );
          this.associations.activeKeys.push(each.oauthIntegrationGuid);
        };
        this.associations.active = this.associations.initial;
        if (!this.currentUser.isViewer()) {
          this.associations.available = await getAvailableIntegrations();
        };
      }
    },
    lockedState() {
      this.initial.locked = this.locked;
      this.initial.lockedMessage = this.lockedMessage;
    },
    async prefillPermissionRequestIfAny() {
      const queryFound = getHashQueryParameter('permission_request');
      if (!queryFound) {
        return;
      }
      try {
        const [requestToken] = queryFound;
        const request = await getRequestAccessByToken({
          contentGUID: this.app.guid,
          requestToken,
        });
        const requester = await getUser(request.requesterGuid);
        this.requestByToken = { ...request, user: requester };
        if (request.approved) {
          return;
        }
        this.addPrincipal({
          principal: requester,
          mode: request.requestedRole,
          highlight: true,
        });
      } catch (err) {
        if (err.response && err.response.status !== 404) {
          this.setErrorMessageFromAPI(err);
        }
      }
    },
    onAuthIntegrationChange(value) {
      this.associations.active = value;
      // this will need to be fixed when we allow multiple auths per content
      value.length > 0 ?
        this.associations.activeKeys = [value[0].value] :
        this.associations.activeKeys = [];
      if (!isEqual(this.associations.active, this.associations.initial)) {
        this.confirmationVisible = true;
      }
    },
    resetActive() {
      this.updateType(this.initial.type);
      this.updateMode(this.initial.mode);

      this.activePrincipals = this.initial.principals.map(principal => ({
        ...principal,
        type: principal instanceof User ? 'user' : 'group',
        added: false,
        changed: false,
        deleted: false,
      }));

      this.activeCustomUrl = this.initial.customUrl?.path;

      this.activeLocked = this.initial.locked;
      this.activeLockedMessage = this.initial.lockedMessage;

      this.requestByToken = null;
      this.onAuthIntegrationChange(this.associations.initial);
    },
    isChanged(principal) {
      return principal.deleted || principal.changed;
    },
    containsCurrentUser(principal) {
      // Returns true if principal is the current user, or is a group containing the current user.
      return principal.guid === this.currentUser.guid ||
        principal.members && principal.members.some(m => m.guid === this.currentUser.guid);
    },
    resolvePrincipal(principal) {
      // If the principal does not have a guid, it is a remote user that hasn't been
      // created in Connect yet, so try to add it. The returned object will have a guid.
      if (principal.guid) {
        return Promise.resolve(principal);
      } else if (principal instanceof User) {
        return addNewRemoteUser(principal.tempTicket);
      }
      return addNewRemoteGroup(principal.tempTicket);
    },
    getRequestRole() {
      let role = this.requestByToken.requestedRole;
      if (this.requestByToken.approved) {
        // Because the app role requested could be different than the role applied
        const approvedPrincipal = this.activePrincipals.find(
          p => p.guid === this.requestByToken.requesterGuid
        );
        role = AppRoles.stringOf(approvedPrincipal.appRole);
      }
      if (role === ModeType.OWNER) {
        role = 'collaborator';
      }
      return upperFirst(role);
    },
    addPrincipal({ principal, mode, highlight = false }) {
      const role = AppRoles.of(mode);

      // Ensure the principal exists in Connect before adding
      return this.resolvePrincipal(principal)
        .then(resolvedPrincipal => {
          const existingPrincipal = this.activePrincipals
            .find(({ guid }) => guid === resolvedPrincipal.guid);

          if (existingPrincipal) {
            if (existingPrincipal.appRole !== role) {
              // role is changing
              this.updatePrincipal({ principal: existingPrincipal, mode });
            } else {
              // maybe they deleted and then changed their mind
              existingPrincipal.deleted = false;
            }
          } else {
            this.activePrincipals.unshift({
              ...resolvedPrincipal,
              type: resolvedPrincipal instanceof User ? 'user' : 'group',
              appRole: role,
              added: true,
              changed: true,
              deleted: false,
              highlight,
            });
          }
        })
        .catch(this.setErrorMessageFromAPI);
    },
    updatePrincipal({ principal, mode }) {
      const role = AppRoles.of(mode);
      // If a collaborator is becoming a viewer, ask for confirmation
      if (
        this.containsCurrentUser(principal) &&
        AppRoles.isOwner(principal.appRole) &&
        AppRoles.isViewer(role)
      ) {
        this.pendingBecomeViewerPrincipal = principal;
        this.showBecomeViewerWarning = true;
      } else {
        principal.changed = true;
        principal.deleted = false;
        principal.appRole = role;
      }
    },
    closeBecomeViewerWarning() {
      this.showBecomeViewerWarning = false;
      this.pendingBecomeViewerPrincipal = null;
    },
    confirmBecomeViewer() {
      this.showBecomeViewerWarning = false;
      const principal = this.pendingBecomeViewerPrincipal;
      principal.changed = true;
      principal.deleted = false;
      principal.appRole = AppRoles.Viewer;
      this.pendingBecomeViewerPrincipal = null;
    },
    removePrincipal(principal) {
      if (this.containsCurrentUser(principal)) {
        // If a user is removing themself, ask for confirmation
        this.pendingRemovePrincipal = principal;
        this.showRemovePrincipalWarning = true;
      } else if (principal.added) {
        // Deleting a principal that was never saved will throw an error.
        // Pull them from the list instead.
        const idx = this.activePrincipals.findIndex(({ guid }) => guid === principal.guid);
        this.activePrincipals.splice(idx, 1);
      } else {
        principal.deleted = true;
      }
    },
    closeRemovePrincipalWarning() {
      this.showRemovePrincipalWarning = false;
      this.pendingRemovePrincipal = null;
    },
    confirmRemovePrincipal() {
      this.showRemovePrincipalWarning = false;
      // This looks weird, but this.pendingRemovePrincipal is a reference to a principal in
      // this.activePrincipals. So we change it, and then remove the temporary reference.
      this.pendingRemovePrincipal.deleted = true;
      this.pendingRemovePrincipal = null;
    },
    saveCustomUrl() {
      if (this.customUrlIsChanging) {
        if (this.activeCustomUrl === '') {
          return deleteCustomUrl(this.app.guid);
        }

        // normalize new path---make sure it starts and ends with a single `/`
        const path = joinPaths([`/${ this.activeCustomUrl }/`]);
        return setCustomUrl(this.app.guid, path);
      }
      return Promise.resolve();
    },
    saveAccessTypeAndEnvironment() {
      const attributes = {};
      if (this.typeIsChanging) {
        attributes.accessType = this.activeState.type;
      }
      if (Object.keys(attributes).length > 0) {
        return updateContent(this.app.guid, attributes);
      }
      return Promise.resolve();
    },
    savePrincipals() {
      if (this.listIsChanging) {
        return Promise.all(
          this.activePrincipals.filter(this.isChanged).map(principal => {
            if (principal.deleted) {
              if (principal.type === 'user') {
                return removeUser(this.app.id, principal.guid);
              }
              return removeGroup(this.app.id, principal.guid);
            } else if (principal.type === 'user') {
              return addUser(this.app.id, principal.guid, AppRoles.stringOf(principal.appRole));
            }
            return addGroup(this.app.id, principal.guid, AppRoles.stringOf(principal.appRole));
          })
        );
      }
      return Promise.resolve();
    },
    saveLockedState() {
      if (this.lockedStateIsChanging) {
        return updateContent(this.app.guid, {
          locked: this.activeLocked,
          lockedMessage: this.activeLockedMessage,
        }).then(() => {
          this.reloadFrame(true);
        });
      }
      return Promise.resolve();
    },
    saveAuthIntegrations() {
      if (this.authIntegrationIsChanging) {
        const apiFormat = [];
        for (const each of this.associations.active) {
          apiFormat.push({ oauthIntegrationGuid: each.value });
        }
        return setContentAssociations(this.app.guid, apiFormat);
      }
      return Promise.resolve();
    },
    save() {
      if (this.lockedStateIsChanging) {
        this.showLockConfirmationModal = this.activeLocked && !this.initial.locked;

        if (this.showLockConfirmationModal) {
          return;
        }
      } 

      return this.saveConfirmation();
    },
    saveConfirmation() {
      this.showLockConfirmationModal = false;

      const savingTimeout = setTimeout(() => {
        this.isSaving = true;
      }, 300);

      this.clearStatusMessage();

      // Return the user to the content listing if they would no longer be able to view this:
      // * access type is ACL
      // * current user is not the owner or an admin
      // * no remaining active principals include the user
      const closeRequired = this.activeState.type === AccessTypes.Acl &&
        this.currentUser.guid !== this.ownerGuid &&
        !this.isAdmin &&
        !this.activePrincipals
          .filter(p => !p.deleted)
          .some(this.containsCurrentUser);

      // Reload the whole page after saving if current user is changing roles
      const reloadRequired =
        this.activePrincipals.some(p => this.containsCurrentUser(p) && p.changed);

      // What appears to the user as a single action is really (potentially) a batch of updates:
      // * POST, PUT, or DELETE custom url
      // * update app accessType
      // * POST or DELETE users and groups, one at a time
      //
      // We will attempt them all at once and report the first error, if any---unfortunately
      // without a batch API available on the server we cannot avoid the possibility of getting
      // in a half-updated state.
      return Promise.all([
        this.saveCustomUrl(),
        this.saveAccessTypeAndEnvironment(),
        this.savePrincipals(),
        this.saveAuthIntegrations(),
        this.saveLockedState(),
      ])
        .then(() => {
          if (closeRequired) {
            this.$router.push({ name: 'contentList' });
            return null;
          }

          if (reloadRequired) {
            window.location.reload();
            return null;
          }

          // clear the permission request, if any
          this.clearPermissionRequest();
          return this.reloadAccessData();
        })
        .catch(e => {
          this.setErrorMessageFromAPI(e);
          // try to make sure the panel data is up-to-date, but if there are
          // additional errors don't hide the primary one we just displayed
          return this.reloadAccessData(true);
        })
        .finally(() => {
          clearTimeout(savingTimeout);
          this.isSaving = false;
        });
    },
    async reloadAccessData(keepErr = false) {
      await this.resetApp({
        appIdOrGuid: this.app.guid,
        onlyAppData: true,
      });
      try {
        await this.getData();
      } catch (err) {
        if (!keepErr) {
          this.setErrorMessageFromAPI(err);
        }
      }
    },
    discard() {
      this.resetActive();
    },
    clearPermissionRequest() {
      this.requestByToken = null;
    },
    toggleAuthModal() {
      this.showAuthIntegrationModal = !this.showAuthIntegrationModal;
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

// Make different section titles all match (several different elements are used
// which have slightly different default styles.)
.access {
  margin-bottom: 15px;
  padding-bottom: 15px;

  .rs-radiogroup__title {
    color: $color-secondary-inverse;
  }

  .rs-help-toggler__label {
    font-size: 0.9rem;
  }

  &-help {
    list-style:outside;
  }
}
.groupHeadings {
  color: $color-heading;
  letter-spacing: .1em;
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}
.spaceBefore {
  margin-top: 0.5rem;
}
.spaceAfter {
  margin-bottom: 0.5rem;
}
.add-links {
  text-decoration: underline;
}
.access-type-admin-guide-link {
  margin-bottom: 1.2rem;
}
.rs-radiogroup {
  margin-bottom: 0.2rem;
}
.session-name {
  font-size: 0.85rem;
}
.session-desc {
  margin-top: -0.5rem;
  font-weight: 300;
}
.login-button {
  min-width: 8.75rem;
  margin: 0.5em 0 1.5em;
  padding: 0.5rem 0.8rem 1rem;
  font-size: $rs-font-size-normal;
  line-height: 0.75rem;
  overflow: visible;
  border: 1px solid $color-primary;
  border-radius: 3px;
  cursor: pointer;
  transition-duration: 250ms;
  text-align: center;
  background-color: $color-white;
  color: $color-primary;
  display: inline-block;
  font-weight: normal;
  text-decoration: none;

  &:hover {
    background-color: #e7eef5;
    text-decoration: none;
  }
  .integration-logo {
    height: 20px;
    top: 3px;
    position: relative;
    margin-right: 4px;
  }
}
.rsc-alert.warning {
  line-height: 18px;
}
.confirm-message {
  p {
    margin-top: 1rem;
  }
}
.active-subhead {
  font-weight: bold;
  font-size: 14px;
  margin-top: 1.5rem;
}

.add-integration-button {
  background-color: $color-white;
  border: 1px solid $color-primary;
  border-radius: 6px;
  color: $color-primary;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  min-width: 9rem;
  margin-bottom: 0.5rem;
  padding: 0.375rem;
  text-align: center;
  text-decoration: none;

  &::before {
    content: '+';
    font-size: 1rem;
    padding-right: 0.25rem;
  }

  &:hover {
    background-color: $color-options-focus-background;
    text-decoration: none;
  }
}
</style>

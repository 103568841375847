<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    subject="User Changes"
    @close="$emit('close')"
  >
    <template #content>
      <div>
        <p>
          Changing the user responsible for executing this {{ ContentTypeLabel[contentType] }}
          will terminate active processes associated with this {{ ContentTypeLabel[contentType] }}.
          Are you sure you want to continue?
        </p>
      </div>
    </template>

    <template #controls>
      <RSButton
        label="Save"
        data-automation="runas-warning-dialog-submit"
        @click="$emit('save')"
      />
    </template>
  </RSModal>
</template>

<script>
import { ContentTypeLabel } from '@/api/dto/app';
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';

export default {
  name: 'RunAsWarning',
  components: { RSButton, RSModal },
  props: {
    contentType: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'save'],
  data: () => ({ ContentTypeLabel })
};
</script>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="datetime-input">
    <DateInput
      :date="date"
      :disabled="disabled"
      @change="updateDate"
      @focus="setFocus"
    />
    <TimeInput
      :date="date"
      :focus="focus"
      :disabled="disabled"
      @change="updateTime"
      @focus="setFocus"
    />
  </div>
</template>

<script>
import DateInput from './DateInput';
import TimeInput from './TimeInput';

export default {
  name: 'DateTimeInput',
  components: {
    DateInput,
    TimeInput,
  },
  props: {
    // date is expected to be a `moment` wrapped Date object (optional)
    date: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      // focus should be one of: null, 'date', 'hour', 'minute', 'ampm'
      focus: null
    };
  },
  methods: {
    setFocus(value) {
      this.focus = value;
    },
    updateDate(newDate) {
      // Emit the new date with the existing time components
      const updatedDate = newDate
        .set('hour', this.date.get('hour'))
        .set('minute', this.date.get('minute'))
        .set('second', this.date.get('second'))
        .set('millisecond', this.date.get('millisecond'));
      this.update(updatedDate);

      // Move focus to first time component
      this.setFocus('hour');
    },
    updateTime(newDate, attr) {
      // Emit the new time with the existing date components
      const updatedDate = newDate
        .set('year', this.date.get('year'))
        .set('month', this.date.get('month'))
        .set('date', this.date.get('date'));
      this.update(updatedDate);

      // Move focus to next time component for hour or minute
      if (attr === 'hour') {
        this.setFocus('minute');
      }
      if (attr === 'minute') {
        this.setFocus('ampm');
      }
    },
    update(newDate) {
      this.$emit('change', newDate);
    }
  }
};

export { DateInput, TimeInput };
</script>

<style scoped lang="scss">
.datetime-input {
  display: flex;
  justify-content: space-between;
}
</style>

<!-- Copyright (C) 2024 by Posit Software, PBC. -->
<script setup>
import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import RSInputCheckbox from '@/elements/RSInputCheckbox';
import GitUpdateNow from './GitUpdateNow';

const props = defineProps({
  appGuid: {
    type: String,
    required: true,
  },
  repositoryEnabled: {
    type: Boolean,
    required: true,
  },
  // whether user is allowed to make changes
  userCanMakeChanges: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['input']);

const store = useStore();

const localState = reactive({
  enabled: props.repositoryEnabled,
});

const git = computed(() => store.state.infoSettings.app.git);

const hasGitInfo = computed(() => git.value !== null);

const repositoryUpdateToggled = (e) => {
  localState.enabled = e;
  emit('input', localState.enabled);
};

watch(() => props.repositoryEnabled, (newValue) => {
  if (newValue !== localState.enabled) {
    localState.enabled = newValue;
  }
});
</script>

<template>
  <div v-if="hasGitInfo">
    <div class="itemInfo">
      <p>This content was generated from Git</p>
      <dl class="sameLine">
        <div class="item">
          <dt>Repo: </dt>
          <dd>
            <a
              :href="git.repositoryUrl"
              target="_blank"
            >{{ git.repositoryUrl }}</a>
          </dd>
        </div>
        <div class="item">
          <dt>Branch: </dt>
          <dd>{{ git.branch }}</dd>
        </div>
        <div class="item">
          <dt>Directory: </dt>
          <dd>/{{ git.contentPath }}</dd>
        </div>
      </dl>
    </div>

    <div
      v-if="userCanMakeChanges"
      class="formSection"
    >
      <RSInputCheckbox
        v-model="localState.enabled"
        label="Check for updates periodically"
        name="gitAutoUpdate"
        @change="repositoryUpdateToggled"
      />
      <div
        v-if="git.lastError"
        class="error"
      >
        {{ git.lastError }}
      </div>
      <GitUpdateNow
        :app-guid="appGuid"
        :git="git"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.itemInfo {
  font-size: 12px;
  color: $color-dark-grey-2;
  font-style: italic;
  word-break: break-all;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0.5rem;
  }
}

dl.sameLine {
  line-height: 18px;
  .item {
    margin-bottom: 0.5rem;
  }
  dt, dd {
    display: inline;
  }
  dt {
    font-weight: 700;
  }
}

.error {
  color: $color-error;
  line-height: 18px;
}
</style>

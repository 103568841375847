<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div
        v-if="serverSettings.mailConfigured"
        data-automation="confirm-user-mail-configured"
        class="container"
      >
        <h1 class="title">
          Awaiting Confirmation
        </h1>

        <p class="rsc-lead">
          You need to confirm your account before proceeding.
          You should have received an email with a link that will allow you to confirm your account.
        </p>

        <p class="subtext">
          It may take a few minutes for the email to arrive; you might also need to check your Spam or Junk folder.
        </p>

        <RSButton
          :disabled="isSendingConfirmationEmail"
          label="Resend Confirmation Email"
          data-automation="confirm-user__submit-button"
          class="resend-button"
          @click="resendConfirmationEmail"
        />
      </div>
      <div
        v-else
        data-automation="confirm-user-no-mail-configured"
        class="container"
      >
        <h1 class="title">
          Requires Confirmation
        </h1>

        <p class="rsc-lead">
          You need to confirm your account before proceeding.
        </p>

        <p class="subtext">
          Ask your {{ serverSettings.systemDisplayName }} administrator to visit your user profile
          and obtain an <span class="emphasize">account confirmation link</span> for you.
        </p>
        <p class="subtext">
          You can
          <a
            :href="userProfile"
            data-automation="ask-your-admin-link"
            @click.prevent="copyLink"
          >copy this link</a>
          to your user profile and pass it to an administrator.,
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { sendOrGetAccountConfirmationLink } from '@/api/authentication';
import { getCurrentUser } from '@/api/users';
import RSButton from '@/elements/RSButton';
import {
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { copyToClipboard } from '@/utils/clipboard';
import { getHashQueryParameter, groupPath, userPath } from '@/utils/paths';
import { mapActions, mapMutations, mapState } from 'vuex';

const NOTIFICATION_TIMEOUT = 5000;
const CONFIRMATION_CHECK_INTERVAL = 3000;

export default {
  name: 'ConfirmUser',
  components: {
    RSButton,
  },
  data() {
    return {
      intervalId: null,
      currentUser: {},
      isSendingConfirmationEmail: false,
    };
  },
  computed: {
    userProfile() {
      if (!this.currentUser.guid) {
        return '';
      }

      if (this.currentUser.type && this.currentUser.type === 'group') {
        return `${window.location.protocol}//${window.location.host}${groupPath(this.currentUser.guid)}`;
      }

      return `${window.location.protocol}//${window.location.host}${userPath(this.currentUser.guid)}`;
    },
    ...mapState({
      serverSettings: state => state.server.settings
    })
  },
  created() {
    this.getUser().then(this.redirectIfConfirmed);
  },
  beforeUnmount() {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
    }
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    getUser() {
      return new Promise((resolve, reject) => {
        getCurrentUser()
          .then(userInfo => {
            this.currentUser = userInfo;
            resolve(userInfo);
          })
          .catch(err => {
            this.setErrorMessageFromAPI(err);
            reject(err);
          });
      });
    },
    redirectIfConfirmed() {
      if (this.currentUser.confirmed) {
        // We are already confirmed. Why are we even here?
        this.handleRedirect();
      } else {
        // We poll to check if the confirmation has happened in another tab/window
        // and then proceed to their original destination. This polling happens
        // when we first land on this page because the email has likely been sent
        // and we are just waiting for the user to open/click.
        this.intervalId = setInterval(() => {
          this.getUser().then(newUser => {
            if (newUser.confirmed) {
              this.handleRedirect();
            }
          });
        }, CONFIRMATION_CHECK_INTERVAL);
      }
    },
    handleRedirect() {
      const [url] = getHashQueryParameter('url') || [];
      const [target] = getHashQueryParameter('target') || [];
      if (url) {
        // Someone gave us an absolute URL.
        window.location.href = url;
      } else if (target) {
        window.location.hash = `#${target}`;
      } else {
        // Visit a dashboard page
        window.location.href = '/';
      }
    },
    resendConfirmationEmail() {
      this.isSendingConfirmationEmail = true;
      sendOrGetAccountConfirmationLink(this.currentUser.guid)
        .then(() => {
          this.setInfoMessage({
            message: 'Confirmation email resent.',
            timeout: NOTIFICATION_TIMEOUT,
          });
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          this.isSendingConfirmationEmail = false;
        });
    },
    async copyLink() {
      await copyToClipboard({ value: this.userProfile });
      this.setInfoMessage({
        message: `A link to your user profile has been copied to the clipboard.
        Be sure to pass it along to an administrator without visiting it.`,
        timeout: NOTIFICATION_TIMEOUT,
      });
    }
  }
};
</script>

<style scoped lang='scss'>
.mainPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items:flex-start;

    .title {
      margin-top: 2rem;
      margin-bottom: 2rem;
      align-self: center;
    }

    .subtext {
      font-size: 1.1rem;
      margin-bottom: 2rem;
    }

    .resend-button {
      align-self: center;
    }

    @media (min-width: 768px) {
      width: 80vw;
    }

    @media (min-width: 1024px) {
      width: 70vw;
    }

    @media (min-width: 1200px) {
      width: 50vw;
    }
  }
}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

/*
 * checkedTagsSearch:
 * Aims to do a recursive search for all selected tags in a given tags tree.
 * Returns the appropriate filter prefix by considering the nested selections.
 *
 * 'tagtree':
 * When there are tags selected that have children but none of them is selected.
 *
 * 'tag':
 * When the selection (or all selections) doesn't have children.
 */
function checkedTagsSearch(tagBranch) {
  const filter = {
    prefix: 'tag',
    tagIds: [],
  };
  // Look for checked/selected tags
  tagBranch.children.forEach(tag => {
    const { id, checked, children } = tag;
    if (!checked) {
      return;
    }
    let idsChecked = [id];
    // Trigger recursive lookup if tags have children
    if (children.length) {
      const { prefix, tagIds } = checkedTagsSearch(tag);
      if (tagIds.length && filter.prefix !== 'tagtree') {
        // If children come with selected ids and a conclusive prefix, use those
        filter.prefix = prefix;
        idsChecked = tagIds;
      } else {
        // otherwise "tagtree" must be used
        filter.prefix = 'tagtree';
      }
    }
    filter.tagIds.push(...idsChecked);
  });
  return filter;
}

function buildTagPaths({
  id,
  label,
  tagsBranchPaths,
  branchLevel,
  checked,
  hasChildren,
  paths = [],
}) {
  const basePath = {
    id,
    path: '',
    label,
    hasChildren,
  };
  if (branchLevel >= 1 && checked) {
    if (paths.length) {
      paths.forEach(record => {
        tagsBranchPaths.push({
          ...record,
          path: `${label}/${record.path}`,
        });
      });
    } else {
      tagsBranchPaths.push(basePath);
    }
  } else if (paths.length) {
    tagsBranchPaths.push(...paths);
  }
}

/**
 * Looks for a tag id in a given registry and updates the registry reference
 * to prevent double checks on the same record.
 * @param {number} tagId The tag ID.
 * @param {Set} selectedIdsRegistry The registry of ids to look for the tag.
 * @returns {boolean} Whether or not the tag exists in the registry.
 */
export function isTagSelected(tagId, selectedIdsRegistry) {
  // Looks if tag is selected for first render
  if (selectedIdsRegistry.size > 0) {
    for (const item of selectedIdsRegistry) {
      // If tag exists in selectedIdsRegistry, we return true
      // and remove the tag from the registry, no need to iterate again over it
      if (item === tagId) {
        selectedIdsRegistry.delete(item);
        return true;
      }
    }
  }
  return false;
}

/**
 * Builds the tags query filter for a given tags category tree.
 * @param {Object} category The tags category tree to be walked.
 * @returns {string | null} A filter string ready to be used as query param.
 */
export function tagsCategoryTreeToQueryFilter(category) {
  const { prefix, tagIds } = checkedTagsSearch(category);
  return tagIds.length ? `${prefix}:${tagIds.join(':')}` : null;
}

/**
 * Builds the label paths for each selected tag in a given tree
 * @param {Array<{id, label, children}>} selectionTree Collection containing the branches & leaves of the selected tags
 * @returns {Array} The reduced collection containing the only paths needed to display the selected tags
 */
export function buildCategoryTreePaths(selectionTree) {
  const buildPaths = tag => {
    const results = [];
    if (tag.children && tag.children.length) {
      const branchedResults = tag.children.flatMap(buildPaths);
      branchedResults.forEach(result => {
        const leaf = { ...result };
        leaf.path = `${tag.label}/${leaf.path}`;
        results.push(leaf);
      });
    }
    return results.length
      ? results
      : [
        {
          path: '',
          id: tag.id,
          label: tag.label,
          hasChildren: tag.hasChildren,
        },
      ];
  };

  return selectionTree.flatMap(buildPaths);
}

/**
 * Builds a selectable tree in a format ready to be used by RSCheckboxGroup,
 * and the selected tags paths to display to the user the selected tags.
 * @param {Array} tags The complete tags tree
 * @param {Set} selectedIdsRegistry A Set that contains the ids of the selected tags
 * @param {number} branchLevel Used only in recursive calls, not inteded to be provided by the user
 * @returns {Object} Returns the selectableTree and selectedTagsPaths to be used by TagsCatalogSelector
 */
export function buildSelectableTreeAndPaths(
  tags,
  selectedIdsRegistry,
  branchLevel = 0
) {
  const branchIsNotRoot = branchLevel > 1;
  const selectedTagsPaths = [];
  const tagsBranchPaths = [];
  let pathsLengthRegistered = 0;
  let parentMustBeChecked = false;

  // eslint-disable-next-line no-shadow
  const selectableTree = tags.map(({ id, name, children }) => {
    const {
      selectableTree: selectableChildren,
      tagsBranchPaths: paths,
      parentMustBeChecked: checkParent,
    } = buildSelectableTreeAndPaths(
      children,
      selectedIdsRegistry,
      branchLevel + 1
    );
    const checked = isTagSelected(id, selectedIdsRegistry) || checkParent;
    if (branchIsNotRoot && checked) {
      // Parent should be checked
      parentMustBeChecked = true;
    }
    buildTagPaths({
      id,
      tagsBranchPaths,
      branchLevel,
      checked,
      label: name,
      hasChildren: Boolean(children && children.length),
      paths,
    });
    if (branchLevel === 0 && pathsLengthRegistered !== tagsBranchPaths.length) {
      // At the categories level, build the tags paths tree
      selectedTagsPaths.push({
        categoryId: id,
        categoryName: name,
        paths: tagsBranchPaths.slice(pathsLengthRegistered),
      });
      pathsLengthRegistered = tagsBranchPaths.length;
    }
    return {
      id,
      label: name,
      children: selectableChildren,
      checked,
    };
  });

  const result = { selectableTree };
  if (branchLevel === 0) {
    // At first level, set the categories tags paths
    result.selectedTagsPaths = selectedTagsPaths;
  } else {
    // Else, return the needed data to keep building the tree
    result.tagsBranchPaths = tagsBranchPaths;
    result.parentMustBeChecked = parentMustBeChecked;
  }

  return result;
}

// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiV1Path } from '@/utils/paths';
import { User } from './dto/user';
import { Group } from './dto/group';
import { keysToCamel, keysToSnake } from './transform';

export function getGroup(guid) {
  return axios
    .get(apiV1Path(`groups/${encodeURIComponent(guid)}`))
    .then(({ data }) => new Group(keysToCamel(data)));
}

export function createGroup(groupName) {
  return axios
    .post(apiV1Path(`groups`), { name: groupName })
    .then(({ data }) => new Group(keysToCamel(data)));
}

/**
 * Rename a group.
 *
 * @param {string} guid - The guid of the group
 * @param {string} newName - The new name to be assigned to the group
 * @returns {Promise<Group>} - updated group
 */
export function renameGroup(guid, newName) {
  return axios
    .post(apiV1Path(`groups/${encodeURIComponent(guid)}`), { name: newName })
    .then(({ data }) => new Group(keysToCamel(data)));
}

/**
 * Adds a remote group to the system.
 *
 * @param {string} tempTicket - temporary code received when searching for groups
 * @returns {Promise<Object>} - created group
 */
export function addNewRemoteGroup(tempTicket) {
  return axios
    .put(apiV1Path('groups'), keysToSnake({ tempTicket }))
    .then(({ data }) => new Group(keysToCamel(data)));
}

/**
 * Get group members
 *
 * @param {string} guid - the guid of the group
 * @param {number} [pageSize=10] - number of search results to return
 * @param {number} [pageNumber=1] - the page of search results to return
 * @returns {Promise<{totalPages: number, currentPage: number, results: Array<User>}>}
 */
export function getGroupMembers(guid, {
  pageSize = 10, pageNumber = 1
} = {}) {
  return axios
    .get(
      apiV1Path(`groups/${encodeURIComponent(guid)}/members`),
      {
        params: keysToSnake({ pageSize, pageNumber }),
      }
    )
    .then(({ data }) => {
      const { results, currentPage, total } = keysToCamel(data);
      return {
        results: results.map(u => new User(u)),
        currentPage,
        totalPages: Math.ceil(total / pageSize),
      };
    });
}

export function deleteGroup(guid) {
  return axios.delete(apiV1Path(`groups/${encodeURIComponent(guid)}`));
}

export function deleteGroupMember(groupGuid, memberGuid) {
  return axios.delete(
    apiV1Path(
      `groups/${encodeURIComponent(groupGuid)}/members/${encodeURIComponent(
        memberGuid
      )}`
    )
  );
}

export function addGroupMember(groupGuid, memberGuid) {
  return axios.post(
    apiV1Path(`groups/${encodeURIComponent(groupGuid)}/members`),
    keysToSnake({ userGuid: memberGuid })
  );
}

/**
 * Searches the system for groups.
 *
 * @param {Object} serverSettings - server settings object
 * @param {string} [prefix] - restricts search results to groups containing the specified prefix
 * @param {number} [pageSize=10] - number of search results to return
 * @param {number} [pageNumber=1] - the page of search results to return
 * @param {boolean} [includeRemote] - include remote groups
 * @returns {Promise<{totalPages: number, currentPage: number, results: Array<Group>}>}
 */
export function searchGroups(
  serverSettings,
  {
    prefix,
    pageSize = 10,
    pageNumber = 1,
    includeRemote = false,
  } = {}
) {
  // if groups are disabled, return an empty list.
  if (!serverSettings.authentication.groupsEnabled) {
    return Promise.resolve({
      results: [],
      currentPage: 0,
      totalPages: 0,
    });
  }
  const path =
    serverSettings.authentication.externalGroupSearch && includeRemote
      ? apiV1Path('groups/remote')
      : apiV1Path('groups');

  return axios
    .get(path, {
      params: keysToSnake({ prefix, pageSize, pageNumber }),
    })
    .then(({ data }) => {
      const { results, currentPage, total } = keysToCamel(data);
      return {
        results: results.map(g => new Group(g)),
        currentPage,
        totalPages: Math.ceil(total / pageSize),
      };
    });
}

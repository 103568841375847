// Copyright (C) 2022 by Posit Software, PBC.

import AppRoles from './appRole';
import { User } from './user';

// Group reflects the backend struct `store.GroupRecord`
export class Group {
  /* eslint-disable no-shadow */
  constructor({
    guid,
    members,
    name,
    owner,
    ownerGuid,
    tempTicket,
    appRole,
  } = {}) {
    /* eslint-enable no-shadow */
    this.guid = guid;
    this.members = members ? members.map(m => new User(m)) : null;
    this.name = name;
    this.owner = owner ? new User(owner) : null;
    this.ownerGuid = ownerGuid;
    // tempTicket comes from `groups.GroupRecordPayload`
    this.tempTicket = tempTicket;
    // appRole comes from `store.GroupAppPrincipal`
    if (appRole) {
      this.appRole = AppRoles.of(appRole);
    }

    // calculated fields
    this.displayName = name || `GUID: ${guid}`;
  }

  toJSON() {
    const data = {
      guid: this.guid,
      members: this.members,
      name: this.name,
      owner: this.owner,
      ownerGuid: this.ownerGuid,
      tempTicket: this.tempTicket,
    };
    if (this.appRole) {
      data.appRole = AppRoles.stringOf(this.appRole);
    }
    return data;
  }

  toString() {
    return `Group(${this.name})`;
  }
}

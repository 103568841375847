// Copyright (C) 2022 by Posit Software, PBC.

import DownloadExample from './panels/DownloadExample';
import PythonDependencies from './panels/PythonDependencies';
import JupyterNotebookOpen from './panels/JupyterNotebookOpen';
import PythonRsconnect from './panels/PythonRsconnect';
import PythonDeploy from './panels/PythonDeploy';
import JupyterNotebookOutro from './panels/JupyterNotebookOutro';

export default {
  copyFrom: [
    DownloadExample,
    PythonDependencies,
    JupyterNotebookOpen,
    PythonRsconnect,
    PythonDeploy,
    JupyterNotebookOutro,
  ],
};

// Copyright (C) 2023 by Posit Software, PBC.

//  functions are designed to return true when invalid, false when valid.
export function isEmptyValue(valueStr) {
  return Boolean((valueStr === '' || valueStr === null || valueStr === undefined));
}

export function containsControlWhitespace(valueStr, allowNewLines) {
  if (isEmptyValue(valueStr)) {
    return false;
  }
  if (allowNewLines) {
    return /[\t\b\f\r]/.test(valueStr);
  }
  return /[\t\n\b\f\r]/.test(valueStr);
}

export function containsSpaceCharacter(valueStr) {
  if (isEmptyValue(valueStr)) {
    return false;
  }
  return / /.test(valueStr);
}

export function invalidAbsoluteUnixFilepath(valueStr) {
  if (isEmptyValue(valueStr)) {
    return false;
  }
  return (
    valueStr.charAt(0) !== '/' ||
    valueStr.charAt(valueStr.length - 1) === '/'
  );
}

export function invalidSemanticVersionString(valueStr) {
  const re = /^(\d+)\.(\d+)\.(\d+)$/.exec(valueStr);
  if (re === null || re.length !== 4) {
    return true; // invalid
  }
  return false;
}

const passwordValidation = {
  required: 'This field cannot be blank.',
  minLength: 'Password is too short. Must be at least 6 characters.',
  same: 'Passwords must match exactly.',
  sameAsPassword: 'Passwords must match exactly.',
};

export const ValidationMessages = {
  username: {
    required: 'Username is required.',
    blank: passwordValidation.required,
    prohibited: 'Username is prohibited by policy.',
    minLength: 'Username is too short. Must be at least 3 characters.',
    maxLength: 'Username is too long. Must be at most 64 characters.',
    firstLetter: 'The first character in a username must be a letter.',
    validCharacters:
        'Invalid character used. Can only use letters, numbers, periods, and underscores (_).',
  },
  email: {
    required: 'Email is required.',
    email: 'Not a valid email address.',
  },
  password: passwordValidation,
  currentPassword: passwordValidation,
  newPassword: passwordValidation,
  repeatNewPassword: passwordValidation,
};

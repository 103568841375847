// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiPath } from '@/utils/paths';
import { taskToPromise } from './tasks';
import { keysToCamel } from './transform';

/**
 * getBranches gets the valid branches for a given git URL
 * @param {String} repoUrl URL of a valid git repository
 * @return {AxiosPromise<any>} result of the call
 */
export function getBranches(repoUrl) {
  return axios.get(apiPath('repo/branches'), { params: { url: repoUrl } });
}

export function getBranchesResult(repoUrl, onPoll = () => {}) {
  return getBranches(repoUrl)
    .then(task => taskToPromise(task.data.id, onPoll))
    .then(task => {
      if (task.error) {
        return Promise.reject(task.error);
      }
      return task.result;
    });
}

/**
 * getSubdirectories gets all subdirectories with valid `manifest.json` files
 * @param {String} repoUrl URL of a valid git repository
 * @param {String} branch name of a valid git branch in the given repository
 * @returns {AxiosPromise<any>} result of the call
 */
export function getSubdirectories(repoUrl, branch) {
  const params = {
    url: repoUrl.trim(),
    branch: branch.trim(),
  };
  return axios.get(apiPath('repo/manifest-dirs'), {
    params,
  });
}

export function getSubdirectoriesResult(repoUrl, branch, onPoll = () => {}) {
  return getSubdirectories(repoUrl, branch)
    .then(task => taskToPromise(task.data.id, onPoll))
    .then(task => {
      if (task.error) {
        return Promise.reject(task.error);
      }
      return task.result;
    });
}

/**
 * getRepoUsername gets the service account username for a given git URL
 * @param {String} repoUrl URL of a valid git repository
 * @return {AxiosPromise<GetGitRepoAccountDTO>} result of the call
 */
export function getRepoUsername(repoUrl) {
  return axios.get(apiPath('repo/account'), { params: { url: repoUrl } }).then(({ data }) => keysToCamel(data));
}

/**
 * udpateGitRepository updates the git repository associated with an application.
 * @param {number} appId ID of the application whose git repository will be updated
 * @returns {AxiosPromise<GitRecord>}
 */
export function updateGitRepository(appId, { enabled }) {
  return axios
    .put(apiPath(`applications/${encodeURIComponent(appId)}/repo`), {
      enabled,
    })
    .then(({ data }) => keysToCamel(data));
}

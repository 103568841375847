// Copyright (C) 2022 by Posit Software, PBC.

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

// localOffset returns the UTC offset for the browser's local timezone in `GMT-hh:mm` format
export const localOffset = () => {
  const offset = new Date().getTimezoneOffset();
  const plusOrMinus = offset > 0 ? '-' : '+';
  const trucatedHours = offset > 0 ? Math.floor(offset / 60) : Math.ceil(offset / 60);
  const hour = Math.abs(trucatedHours).toString()
    .padStart(2, '0');
  const minute = Math.abs(offset % 60).toString()
    .padStart(2, '0');

  return `GMT${plusOrMinus}${hour}:${minute}`;
};

// Get the browser's timezone
export const browserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

// Accepts a UTC time and a format string and returns the time in the local timezone
export const utcToLocalTimezone = (utcTime, format) =>
  dayjs.utc(utcTime).local().format(format);

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div
    class="notification-badge"
    data-automation="notification-badge"
  />
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.notification-badge {
  background: $color-error;
  border-radius: 100%;
  height: 12px;
  text-align: center;
  width: 12px;
}
</style>

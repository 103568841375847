<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<script setup>
import ConnectLogoAnchor from '@/components/ConnectLogoAnchor.vue';
import { useSkipToMainContent } from '@/composables/skipToMainContent';
import About from '@/views/documentation/About';
import DocumentationViewItems from '@/views/documentation/DocumentationViewItems';
import { ref } from 'vue';

const initialFocus = ref(null);

useSkipToMainContent(() => {
  initialFocus.value.focusElement();
});
</script>

<!-- Renders the routable Documentation view container -->
<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div class="flex">
        <h1 class="sectionTitle focusedTitle">
          Documentation
        </h1>
      </div>
      <div class="section">
        Posit Connect is a publishing platform for the work your teams create in
        R and Python. Share Shiny applications, R Markdown reports, Plumber and
        Flask APIs, Dash, and Streamlit applications, Quarto projects, dashboards,
        plots, Jupyter Notebooks, and more in one convenient place. Use
        push-button publishing from the RStudio IDE, scheduled execution of
        reports, and flexible security policies to bring the power of data science
        to your entire enterprise.
      </div>

      <DocumentationViewItems ref="initialFocus" />

      <ConnectLogoAnchor />

      <About />
    </div>
  </div>
</template>

// Copyright (C) 2023 by Posit Software, PBC.
import { keysToCamel } from '../transform';
import { VISIBILITY_ADHOC } from '@/constants/variants';

export class Variant {
  /* eslint-disable no-shadow */
  constructor({
    id = null,
    appId,
    key,
    bundleId,
    ownerId,
    renderingId = null,
    isDefault,
    name,
    emailCollaborators,
    emailViewers,
    emailAll,
    visibility,
    renderDuration,
    renderTime,
    createdTime,
  } = {}) {
    this.id = id;
    this.appId = appId;
    this.key = key;
    this.bundleId = bundleId;
    this.ownerId = ownerId;
    this.renderingId = renderingId;
    this.isDefault = isDefault;
    this.name = name;
    this.emailCollaborators = emailCollaborators;
    this.emailViewers = emailViewers;
    this.emailAll = emailAll;
    this.visibility = visibility;
    this.renderDuration = renderDuration;
    this.renderTime = new Date(renderTime);
    this.createdTime = new Date(createdTime);
  }
  /* eslint-enable no-shadow */

  static fromRedux(reduxVariant) {
    return new Variant(keysToCamel(reduxVariant));
  }

  setEmailSettings({
    all = false,
    viewers = false,
    collabs = false,
  } = {}) {
    this.emailAll = all;
    this.emailCollaborators = collabs;
    // if mailing all, viewers cannot be true
    this.emailViewers = all ? false : viewers;
  }

  toJSON() {
    return {
      id: this.id,
      appId: this.appId,
      key: this.key,
      bundleId: this.bundleId,
      ownerId: this.ownerId,
      renderingId: this.renderingId,
      isDefault: this.isDefault,
      name: this.name,
      emailCollaborators: this.emailCollaborators,
      emailViewers: this.emailViewers,
      emailAll: this.emailAll,
      visibility: this.visibility,
      renderTime: this.renderTime,
      renderDuration: this.renderDuration,
      createdTime: this.createdTime,
    };
  }

  toString() {
    return `Variant(${this.id})`;
  }

  isAdHoc() {
    return this.visibility === VISIBILITY_ADHOC;
  }

  isUntitled() {
    return this.name === 'Untitled' || this.name === '';
  }
}

export class VariantRendering {
  constructor({
    id,
    appId,
    variantId,
    bundleId,
    jobKey,
    renderTime,
    renderDuration,
    active,
  } = {}) {
    this.id = id;
    this.appId = appId;
    this.variantId = variantId;
    this.bundleId = bundleId;
    this.jobKey = jobKey;
    this.renderDuration = renderDuration;
    this.renderTime = new Date(renderTime);
    this.active = active;
  }

  toJSON() {
    return {
      id: this.id,
      appId: this.appId,
      variantId: this.variantId,
      bundleId: this.bundleId,
      jobKey: this.jobKey,
      renderDuration: this.renderDuration,
      renderTime: this.renderTime,
      active: this.active,
    };
  }

  toString() {
    return `VariantRendering(${this.id})`;
  }
}

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!-- Renders the runtime tab -->
<template>
  <div data-automation="app-settings__runtime">
    <ConfirmationPanel
      :enabled="formIsValid"
      :visible="doneLoading && (confirmationVisible || runAsIsChanging)"
      @save="preSaveConfirmation"
      @discard="discard"
    />
    <EmbeddedStatusMessage
      v-if="loading"
      message="Runtime settings saved successfully."
      :show-close="false"
      type="activity"
      data-automation="loading"
    />
    <RunAsWarning
      v-if="runAs.showWarning"
      :content-type="app.contentType()"
      @close="closeRunAsWarning"
      @save="save"
    />
    <div
      v-if="loadingError"
      class="formSection"
      data-automation="loading-error"
    >
      <p>An error occurred while loading the runtime settings.</p>
    </div>
    <div
      v-if="showNoRuntimeSettings"
      class="formSection"
      data-automation="no-settings"
    >
      <p>This content is not executed or rendered by the server. There are no runtime settings to configure.</p>
    </div>
    <div
      v-if="showRuntimeSettings"
      data-automation="has-settings"
    >
      <MessageBox
        v-if="app.locked"
        alert
        small
        data-automation="content-locked-runtime-warning"
      >
        This content is locked. Locked content cannot start processes.
        Any changes made to the Runtime settings will have no effect.
      </MessageBox>
      <div
        v-else-if="disabled"
        class="rs-field"
      >
        <p>You do not have permissions to change the settings for this content.</p>
      </div>

      <!-- Min/Max Process Settings -->
      <div v-if="showProcessSettings">
        <RSInformationToggle
          class="spaceAfter"
        >
          <template #title>
            <!-- override contentPanel styling -->
            <span class="groupHeadings">
              Process Settings
            </span>
          </template>
          <template #help>
            <div style="padding-top:0.5rem; padding-bottom: 0.5rem;">
              Changes to runtime settings for executable content
              are applied immediately to running instances of this content.
              New connections use updated values. Existing connections
              are not modified or disconnected.
            </div>
          </template>
        </RSInformationToggle>
        <RSInputNumberOverrideDefault
          v-model="form.minProcesses"
          :disabled="disabled"
          :message="errorForMinProcesses.message"
          :message-type="errorForMinProcesses.type"
          :help="[
            `The minimum number of processes that will be kept running
              for this content per Posit Connect node, regardless of load.`,
            `A value of 0 indicates that no minimum is to be imposed.`,
            `Value must be <= Max Processes as well as the system limit set in
              the server config file.`
          ]"
          label="Min processes"
          :small="true"
          data-automation="min-processes"
          name="minProcesses"
          units="processes"
          :default-value="defaultsAndLimits.minProcesses"
          :help-link-obj="helpLinkForProcesses"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          zero-description="no minimum"
          :readonly="disabled"
          @change="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-model="form.maxProcesses"
          :disabled="disabled"
          :message="errorForMaxProcesses"
          :help="[
            `The maximum number of processes that will be simultaneously
              running for this content per Posit Connect node, regardless of
              load.`,
            `A value of 0 will prevent the content from running at all.`,
            `Value must be >= Min Processes as well as the system limit set in the
              server config file.`
          ]"
          label="Max processes"
          :small="true"
          data-automation="max-processes"
          name="maxProcesses"
          units="processes"
          :default-value="defaultsAndLimits.maxProcesses"
          :help-link-obj="helpLinkForProcesses"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :readonly="disabled"
          @change="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-model="form.maxConnsPerProcess"
          :disabled="disabled"
          :message="errorForMaxConnsPerProcess"
          :help="[
            `The maximum number of client connections allowed to an
              individual process. Incoming connections which will exceed
              this limit are routed to a new process or rejected.`
          ]"
          label="Max connections per process"
          :small="true"
          data-automation="max-conns-per-process"
          name="maxConnsPerProcess"
          units="connections"
          :default-value="defaultsAndLimits.maxConnsPerProcess"
          :help-link-obj="helpLinkForProcesses"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :readonly="disabled"
          @change="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-model="form.loadFactor"
          :disabled="disabled"
          :message="errorForLoadFactor"
          :help="[
            `A value between 0 and 1 which determines how lazily additional
              processes will be spawned to handle incoming load for this
              process.`,
            `At the highest setting, Connect will only spawn
              additional processes when existing processes are not allowed
              to accept an additional connection. At the lowest setting,
              Connect will create many new processes as new users arrive to
              handle the load.`
          ]"
          label="Load factor"
          :small="true"
          data-automation="load-factor"
          name="loadFactor"
          :default-value="defaultsAndLimits.loadFactor"
          :allow-decimal="true"
          :help-link-obj="helpLinkForProcesses"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :readonly="disabled"
          @change="changeHandler"
        />

        <!-- Timeout Settings -->
        <hr class="rs-divider">
        <RSInformationToggle
          class="spaceAfter"
        >
          <template #title>
            <!-- override contentPanel styling -->
            <span class="groupHeadings">
              Timeout Settings
            </span>
          </template>
          <template #help>
            <div style="padding-top:0.5rem; padding-bottom: 0.5rem;">
              Changes to runtime settings for executable content
              are applied immediately to running instances of this content.
              New connections use updated values. Existing connections
              are not modified or disconnected.
            </div>
          </template>
        </RSInformationToggle>
        <RSInputNumberOverrideDefault
          v-model="form.initTimeout"
          :disabled="disabled"
          :message="errorForInitTimeout"
          help="Maximum number of seconds to wait for an app to start."
          label="Initial timeout (seconds)"
          :small="true"
          data-automation="init-timeout"
          name="initTimeout"
          :default-value="defaultsAndLimits.initTimeout"
          units="seconds"
          :help-link-obj="helpLinkForTimeouts"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :readonly="disabled"
          @change="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-model="form.idleTimeout"
          :disabled="disabled"
          :message="errorForIdleTimeout"
          :help="[
            `Minimum number of seconds a worker process will remain alive
              after it becomes idle - i.e. no active connections.`
          ]"
          label="Idle Timeout per process (seconds)"
          :small="true"
          data-automation="idle-timeout"
          name="idleTimeout"
          :default-value="defaultsAndLimits.idleTimeout"
          units="seconds"
          :help-link-obj="helpLinkForTimeouts"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :readonly="disabled"
          @change="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-model="form.connectionTimeout"
          :disabled="disabled"
          :message="errorForConnectionTimeout"
          :help="[
            `Maximum number of seconds allowed without data sent or
              received across a client connection. Connections reaching this
              idle threshold are closed.`,
            `A value of 0 means connections will never time-out
              (not recommended).`
          ]"
          label="Connection timeout (seconds)"
          :small="true"
          data-automation="connection-timeout"
          name="connectionTimeout"
          :default-value="defaultsAndLimits.connectionTimeout"
          units="seconds"
          :help-link-obj="helpLinkForTimeouts"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          zero-description="no timeout"
          :readonly="disabled"
          @change="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-model="form.readTimeout"
          :disabled="disabled"
          :message="errorForReadTimeout"
          :help="[
            `Maximum number of seconds allowed without data received from a
              client connection. Connections reaching this idle read
              threshold are closed.`,
            `A value of 0 means a lack of browser interaction will never
              cause the connection to close. This is useful when deploying
              dashboard applications which send regular updates but have
              no need for interactivity.`
          ]"
          label="Read timeout (seconds)"
          :small="true"
          data-automation="read-timeout"
          name="readTimeout"
          :default-value="defaultsAndLimits.readTimeout"
          units="seconds"
          :help-link-obj="helpLinkForTimeouts"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          zero-description="no timeout"
          :readonly="disabled"
          @change="changeHandler"
        />
        <hr class="rs-divider">
      </div>

      <!-- RunAs & Service Account -->
      <div v-if="showExecutableSettings">
        <RSInformationToggle
          class="spaceAfter"
        >
          <template #title>
            <span class="groupHeadings">
              Process Execution
            </span>
          </template>
          <template #help>
            <div class="spaceAfter">
              Content is initially configured to run on the server as a Unix user
              configured by your Posit Connect administrator
            </div>
            <div class="spaceAfter">
              These settings allow your content to run using different credentials if needed.
            </div>
          </template>
        </RSInformationToggle>

        <RunAs
          :is-admin="currentUser.isAdmin()"
          :default-run-as-user="runAs.defaultUser"
          :run-as-current-allowed="runAs.currentUserAllowed"
        />

        <RSInputSelect
          v-if="serviceAccount.loaded"
          v-model="serviceAccount.active"
          name="as-service-accounts"
          data-automation="content-service-account"
          label="Service account used to execute this content"
          :disabled="readonlyServiceAccount"
          :options="serviceAccount.all"
          @change="onServiceAccountSelectionChange"
        >
          <template #help>
            <p>
              The name of the Kubernetes service account used to run this piece of content.
            </p>
            <p>
              If no service account is explicitly set, the configured global default service account will be used to execute this content.
              If a global default service account is not configured, the Kubernetes namespace default service account is used.`,
            </p>
            <p v-if="showServiceAccountSelectionDisabledMsg">
              The system is configured to disable content service account selection.
            </p>
          </template>
        </RSInputSelect>
        <MessageBox
          v-if="!serviceAccount.recognized && currentUser.isAdmin()"
          alert
          small
        >
          <span>
            Kubernetes service account <strong>{{ serviceAccount.initial }}</strong> is not recognized
            as one of the service accounts found at system startup.
            Content execution problems might occur. See the
            <a
              :href="troubleshootingDocumentation"
              target="_blank"
            >
              troubleshooting documentation
            </a>
            for more details.
          </span>
        </MessageBox>
        <MessageBox
          v-if="!serviceAccount.recognized && !currentUser.isAdmin()"
          alert
          small
        >
          <span>
            Kubernetes service account <strong>{{ serviceAccount.initial }}</strong> is not recognized
            as one of the service accounts found at system startup.
            Content execution problems might occur. Contact your Administrator for more details.
          </span>
        </MessageBox>
        <hr class="rs-divider">
      </div>

      <!-- Execution Environment -->
      <div v-if="showExecutionEnvironment">
        <RSInformationToggle
          class="spaceAfter"
          data-automation="executionEnvironment-help"
        >
          <template #title>
            <span class="groupHeadings">
              Execution Environment
            </span>
          </template>
          <template #help>
            <div class="spaceBefore spaceAfter">
              <div class="spaceAfter">
                When first deployed, Posit Connect uses an image (from the configured
                execution environments) to create a build container to install R and Python
                packages required by your content. Subsequently, each time your content
                needs to run, that same image is used to create a container for content execution.
              </div>
              <div class="spaceAfter">
                This image can be selected in three ways.
              </div>
              <div class="spaceAfter">
                1) A manifest can specify an image by setting the environment.image field.
                Requires Application.ManifestImageSelectionEnabled to be enabled.
                See the {adminGuide}
                <a
                  :href="applicationSettingsDocumentation"
                >
                  Admin Guide
                </a>
                for further details.
              </div>
              <div class="spaceAfter">
                2) Choose a default image here.
              </div>
              <div class="spaceAfter">
                3) Let Posit Connect determine which execution environment is most appropriate
                for your content.
              </div>
            </div>
          </template>
        </RSInformationToggle>

        <DefaultExecutionEnvironment
          ref="defaultExecutionEnvironment"
          :image-name="executionEnvironment.imageName"
          :valid="executionEnvironment.valid"
          :image-last-time="executionEnvironment.last"
          :show-error-message="!executionEnvironment.valid"
          :allow-selection="executionEnvironmentSelectionAllowed"
          :read-only="disabled"
          @change-name="onDefaultImageNameChange"
          @change-option="changeHandler"
          @valid="onDefaultImageNameValidChange"
        />
        <hr class="rs-divider">
      </div>

      <!-- CPU & RAM Settings -->
      <div
        v-if="showNewRuntimeSettings"
        class="rs-field"
      >
        <RSInformationToggle
          class="spaceAfter"
        >
          <template #title>
            <span
              v-if="showOffHostSpecificResourceSettings && (appHasWorker || appIsRendered)"
              class="groupHeadings"
            >
              CPU & RAM Settings
            </span>
            <span
              v-if="!showOffHostSpecificResourceSettings && (appHasWorker || appIsRendered)"
              class="groupHeadings"
            >
              RAM Settings
            </span>
          </template>
          <template #help>
            <!-- have to use style here or will need to extend scoped styles within RSInformationToggle -->
            <div style="padding-top:0.5rem; padding-bottom: 0.5rem;">
              <span v-if="appHasWorker">
                Changes to runtime settings for executable content are applied immediately to running instances of this content.
                New connections use updated values. Existing connections are not modified or disconnected.
              </span>
              <span v-if="appIsRendered">
                Changes to runtime settings for rendered content
                will be used the next time this content is built.
              </span>
            </div>
          </template>
        </RSInformationToggle>
        <RSInputNumberOverrideDefault
          v-if="showOffHostSpecificResourceSettings"
          v-model="form.cpuRequest"
          :disabled="disabled"
          :message="errorForCPURequest"
          :help="[
            `Initial numbers of CPUs to be requested when the process is launched.`,
            `A value of 0 indicates that no initial number of CPUs is to be requested.`,
            `Value must be <= Max Number of CPUs as well as the system limit set
              in the server config file.`
          ]"
          label="Initial Number of CPUs"
          :small="true"
          data-automation="initial-num-cpus"
          name="cpuRequest"
          units="CPUs"
          :default-value="defaultsAndLimits.cpuRequest"
          :allow-decimal="true"
          :help-link-obj="helpLinkforScheduler"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          zero-description="none requested"
          :readonly="disabled"
          @change="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-if="showOffHostSpecificResourceSettings"
          v-model="form.cpuLimit"
          :disabled="disabled"
          :message="errorForCPULimit"
          :help="[
            `Maximum limit of CPUs to be allocated to the process at runtime.`,
            `A value of 0 indicates no limit will be specified at runtime.`,
            `Value must be >= Initial Number of CPUs and the system limit set
              in the server config file.`
          ]"
          label="Max Number of CPUs"
          :small="true"
          data-automation="max-num-cpus"
          name="cpuLimit"
          units="CPUs"
          :default-value="defaultsAndLimits.cpuLimit"
          :allow-decimal="true"
          :help-link-obj="helpLinkforScheduler"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          zero-description="no limit"
          :readonly="disabled"
          @change="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-if="showOffHostSpecificResourceSettings"
          v-model="form.memoryRequest"
          :disabled="disabled"
          :message="errorForMemoryRequest"
          :help="[
            `Initial amount of RAM in GiB to be requested when the process is launched.`,
            `A value of 0 indicates that no initial amount of RAM is to be requested.`,
            `Value must be <= Max RAM as well as the system limit set in the server
              config file.`
          ]"
          label="Initial RAM Requested (GiB)"
          :small="true"
          data-automation="initial-ram-gib-per-process"
          name="memoryRequest"
          :default-value="defaultsAndLimits.memoryRequest"
          :allow-decimal="true"
          units="GiBs"
          :help-link-obj="helpLinkforScheduler"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          zero-description="none requested"
          :readonly="disabled"
          @change="changeHandler"
        />
        <!-- Always shown, if RAM is shown -->
        <RSInputNumberOverrideDefault
          v-model="form.memoryLimit"
          :disabled="disabled"
          :message="errorForMemoryLimit"
          :help="[
            `Maximum limit of RAM (in GiB) to be allocated to the process at runtime.`,
            `A value of 0 indicates no limit.`,
            `Value must be >= the system limit set in the server config file.`
          ]"
          label="Max RAM (GiB)"
          :small="true"
          data-automation="max-ram-gib-per-process"
          name="memoryLimit"
          :default-value="defaultsAndLimits.memoryLimit"
          :allow-decimal="true"
          units="GiBs"
          :help-link-obj="helpLinkforScheduler"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          zero-description="no limit"
          :readonly="disabled"
          @change="changeHandler"
        />
        <hr class="rs-divider">
      </div>

      <!-- AMD and NVIDIA GPUs -->
      <div v-if="(showAMDGPUSettings || showNvidiaGPUSettings) && (appHasWorker || appIsRendered)">
        <RSInformationToggle
          class="spaceAfter"
        >
          <template #title>
            <!-- override contentPanel styling -->
            <span class="groupHeadings">
              GPU Settings
            </span>
          </template>
          <template #help>
            <div style="padding-top:0.5rem; padding-bottom: 0.5rem;">
              <span v-if="appHasWorker">
                Changes to these runtime settings for executable
                content will not be applied to running instances of this
                content. New connections use updated values. Existing
                connections are not modified or disconnected.
              </span>
              <span v-if="appIsRendered">
                Changes to runtime settings for rendered content
                will be used the next time this content is built.
              </span>
            </div>
          </template>
        </RSInformationToggle>
        <RSInputNumberOverrideDefault
          v-if="showAMDGPUSettings"
          v-model="form.amdGpuLimit"
          :disabled="disabled"
          :message="errorForAMDGPULimit"
          :help="[
            `The number of GPUs to be requested when the process is launched.`,
            `A value of 0 indicates that no GPUs are to be requested.`,
            `Value must be <= Max Number of GPUs as defined by the system limit set
              in the server config file.`
          ]"
          label="AMD GPUs Requested"
          :small="true"
          data-automation="amd-gpu-limit"
          name="amdGpu"
          units="GPUs"
          :default-value="defaultsAndLimits.amdGpuLimit"
          :help-link-obj="helpLinkForGPUs"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          zero-description="none requested"
          :readonly="disabled"
          @change="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-if="showNvidiaGPUSettings"
          v-model="form.nvidiaGpuLimit"
          :disabled="disabled"
          :message="errorForNvidiaGPULimit"
          :help="[
            `The number of GPUs to be requested when the process is launched.`,
            `A value of 0 indicates that no GPUs are to be requested.`,
            `Value must be <= Max Number of GPUs as defined by the system limit set
              in the server config file.`
          ]"
          label="NVIDIA GPUs Requested"
          :small="true"
          data-automation="nvidia-gpu-limit"
          name="nvidiaGpu"
          units="GPUs"
          :default-value="defaultsAndLimits.nvidiaGpuLimit"
          :help-link-obj="helpLinkForGPUs"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          zero-description="none requested"
          :readonly="disabled"
          @change="changeHandler"
        />
        <hr class="rs-divider">
      </div>

      <!-- Environment Management -->
      <div v-if="showNewRuntimeSettings">
        <DefaultEnvironmentManagement
          ref="defaultEnvironmentManagementPy"
          v-model="envManagement.python.appDefault"
          data-automation="python-env-management"
          app-settings-key="python"
          :initial-value="envManagement.python.initialValue"
          :server-default="envManagement.python.serverDefault"
          :last-restore="envManagement.python.last"
          :show-selection="showEnvManagementSelection"
          :read-only="disabled"
          @change="changeHandler"
        />
        <hr class="rs-divider">
        <DefaultEnvironmentManagement
          ref="defaultEnvironmentManagementR"
          v-model="envManagement.r.appDefault"
          data-automation="r-env-management"
          app-settings-key="r"
          :initial-value="envManagement.r.initialValue"
          :server-default="envManagement.r.serverDefault"
          :last-restore="envManagement.r.last"
          :show-selection="showEnvManagementSelection"
          :read-only="disabled"
          @change="changeHandler"
        />
        <hr class="rs-divider">
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/MessageBox.vue';
import RSInputNumberOverrideDefault from '@/components/RSInputNumberOverrideDefault.vue';
import RSInformationToggle from '@/elements/RSInformationToggle.vue';
import RSInputSelect from '@/elements/RSInputSelect.vue';
import DefaultEnvironmentManagement from './DefaultEnvironmentManagement.vue';
import DefaultExecutionEnvironment from './DefaultExecutionEnvironment.vue';
import RunAs from './RunAs.vue';
import RunAsWarning from './RunAsWarning.vue';

import { updateContent, updateRunAs } from '@/api/app';
import {
  ExecutionTypeK8S,
  getApplicationsSettings,
  getRuntimeDefaultsAndLimits,
} from '@/api/serverSettings';
import { getServiceAccounts } from '@/api/system';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage.vue';
import { docsPath } from '@/utils/paths';
import ConfirmationPanel from '@/views/content/settings/ConfirmationPanel';

import {
  errorForMinValueZero,
  errorForTimeoutSettings,
  exportAppData,
  importAppData,
  importDefaultsAndLimits,
  internalError,
  isEmptyValue,
  maxGibibytes,
  validateMaxProcesses,
  validateMinProcesses,
  validateResourceLimit,
  validateResourceRequest,
} from './runtime';

import {
  ACCESS_SETTINGS_UPDATE_ALTERNATE_USER,
  ACCESS_SETTINGS_UPDATE_PRIMARY_MODE,
  ACCESS_SETTINGS_UPDATE_SECONDARY_MODE,
  RunAsModes,
} from '@/store/modules/accessSettings';
import {
  LOAD_CONTENT_VIEW,
  SET_CONTENT_FRAME_RELOADING,
} from '@/store/modules/contentView';
import {
  CLEAR_STATUS_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_ERROR_MESSAGE,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'RuntimeSettingsWithRAMCPU',
  components: {
    EmbeddedStatusMessage,
    ConfirmationPanel,
    DefaultEnvironmentManagement,
    DefaultExecutionEnvironment,
    MessageBox,
    RSInformationToggle,
    RSInputNumberOverrideDefault,
    RSInputSelect,
    RunAs,
    RunAsWarning,
  },
  data() {
    return {
      loading: true,
      loadingError: false,
      disabled: true,
      appSettings: {},
      apiValues: {}, // app defaults (scheduler overrides); string values and converted to seconds
      defaultsAndLimits: {}, // scheduler defaults; string values and converted to seconds
      form: {
        // string values, required by input component
        cpuRequest: null,
        cpuLimit: null,
        memoryRequest: null,
        memoryLimit: null,
        amdGpuLimit: null,
        nvidiaGpuLimit: null,
        minProcesses: null,
        maxProcesses: null,
        maxConnsPerProcess: null,
        loadFactor: null,
        initTimeout: null,
        connectionTimeout: null,
        idleTimeout: null,
        readTimeout: null,
      },
      envManagement: {
        r: {
          appDefault: null,
        },
        python: {
          appDefault: null,
        },
      },
      executionEnvironment: {
        imageName: null,
        valid: true,
      },
      serviceAccount: {
        apiResponse: null,
        enabled: true,
        all: [],
        loaded: false,
        recognized: true,
        default: 'default',
        initial: '',
        active: '',
      },
      runAs: {
        initial: {
          primaryMode: null,
          secondaryMode: null,
          alternateUser: null,
        },
        defaultUser: '',
        currentUserAllowed: false,
        showWarning: false,
      },
      confirmationVisible: false,
      initPromise: new Promise(() => {}),
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
      runAsActiveState: state => state.accessSettings.runAs
    }),
    helpLinkforScheduler() {
      return {
        anchor: {
          href: this.schedulerDocumentation,
          text: 'Admin Guide',
        }
      };
    },
    helpLinkForProcesses() {
      return {
        anchor: {
          href: this.userProcessDocumentation,
          text: 'User Guide',
        },
      };
    },
    helpLinkForTimeouts() {
      return {
        anchor: {
          href: this.userTimeoutDocumentation,
          text: 'User Guide',
        },
      };
    },
    helpLinkForGPUs() {
      return {
        anchor: {
          href: this.gpuDocumentation,
          text: 'User Guide',
        },
      };
    },
    applicationSettingsDocumentation() {
      return docsPath('admin/appendix/configuration/#Applications.Settings');
    },
    schedulerDocumentation() {
      return docsPath('admin/appendix/configuration/#Scheduler.Settings');
    },
    userTimeoutDocumentation() {
      return docsPath('user/content-settings/#timeout-configurations');
    },
    userProcessDocumentation() {
      return docsPath('user/content-settings/#process-configurations');
    },
    gpuDocumentation() {
      return docsPath('user/content-settings/#gpu-configurations');
    },
    troubleshootingDocumentation() {
      return docsPath('admin/appendix/off-host/service-accounts/#troubleshooting');
    },
    doneLoading() {
      return !this.loading && !this.loadingError;
    },
    showNoRuntimeSettings() {
      if (this.doneLoading) {
        return !this.app?.hasRuntimeSettings();
      }
      return false;
    },
    showRuntimeSettings() {
      return this.doneLoading && this.app?.hasRuntimeSettings();
    },
    showNewRuntimeSettings() {
      return this.doneLoading;
    },
    showOffHostSpecificResourceSettings() {
      return (
        this.doneLoading
        && this.serverSettings.executionType === ExecutionTypeK8S
      );
    },
    showProcessSettings() {
      return this.doneLoading && this.appHasWorker;
    },
    showExecutableSettings() {
      return this.doneLoading && this.app.isExecutable();
    },
    showServiceAccountSelectionDisabledMsg() {
      return this.isAdmin && !this.serviceAccount.enabled;
    },
    showEnvManagementSelection() {
      return (
        this.doneLoading
        && this.serverSettings.defaultEnvironmentManagementSelection
      );
    },
    showExecutionEnvironment() {
      return (
        this.doneLoading
        && this.app.isExecutable()
        && (this.currentUser.isAdmin() || this.currentUser.isPublisher())
        && this.serverSettings.executionType === ExecutionTypeK8S
      );
    },
    executionEnvironmentSelectionAllowed() {
      return (
        (this.currentUser.isAdmin() || this.currentUser.isPublisher())
        && this.serverSettings.defaultImageSelectionEnabled
      );
    },
    readonlyServiceAccount() {
      return !this.currentUser.isAdmin() || !this.serviceAccount.enabled;
    },
    showAMDGPUSettings() {
      return (
        this.showOffHostSpecificResourceSettings
        && (this.defaultsAndLimits.maxAmdGpuLimit > 0 || this.app.amdGpuLimit !== null)
      );
    },
    showNvidiaGPUSettings() {
      return (
        this.showOffHostSpecificResourceSettings
        && (this.defaultsAndLimits.maxNvidiaGpuLimit > 0 || this.app.nvidiaGpuLimit !== null)
      );
    },
    appHasWorker() {
      return this.doneLoading && this.app?.hasWorker();
    },
    appIsRendered() {
      return this.doneLoading && this.app?.isRenderable();
    },
    errorForCPULimit() {
      return validateResourceLimit({
        input: {
          form: this.form.cpuLimit,
          limit: this.defaultsAndLimits.maxCpuLimit,
        },
        minimum: {
          form: this.form.cpuRequest,
          default: this.defaultsAndLimits.cpuRequest,
          errorMsg: 'Max Number of CPUs >= Initial Number of CPUs'
        }
      });
    },
    errorForCPURequest() {
      return validateResourceRequest({
        input: {
          form: this.form.cpuRequest,
          limit: this.defaultsAndLimits.maxCpuRequest,
        },
        maximum: {
          form: this.form.cpuLimit,
          default: this.defaultsAndLimits.cpuLimit,
          errorMsg: 'Initial Number of CPUs <= Max Number of CPUs'
        }
      });
    },
    errorForMemoryLimit() {
      // when Kubernetes is enabled, validate in terms of memoryRequest.
      // otherwise, don't compare to memoryRequest as that field is hidden and
      // can't be changed. The easiest way to do this is just declare
      // memoryRequest to be unlimited.
      let minimum = {
        form: this.form.memoryRequest,
        default: this.defaultsAndLimits.memoryRequest,
        errorMsg: 'Max RAM >= Initial RAM requested'
      };
      if (!this.showOffHostSpecificResourceSettings) {
        minimum = {
          form: 0, // unlimited
        };
      }

      return validateResourceLimit({
        input: {
          form: this.form.memoryLimit,
          limit: this.defaultsAndLimits.maxMemoryLimit,
          safeLimit: maxGibibytes,
          unit: 'GiB',
        },
        minimum,
      });
    },
    errorForMemoryRequest() {
      return validateResourceRequest({
        input: {
          form: this.form.memoryRequest,
          limit: this.defaultsAndLimits.maxMemoryRequest,
          safeLimit: maxGibibytes,
          unit: 'GiB',
        },
        maximum: {
          form: this.form.memoryLimit,
          default: this.defaultsAndLimits.memoryLimit,
          errorMsg: 'Initial RAM requested <= Max RAM'
        }
      });
    },
    errorForAMDGPULimit() {
      // Note: we are using validateResourceRequest here even though we are validating gpu_limit
      // because GPU resource limits behave more like a resource requests than a limits
      // see also: https://kubernetes.io/docs/tasks/manage-gpus/scheduling-gpus/#using-device-plugins
      return validateResourceRequest({
        input: {
          form: this.form.amdGpuLimit,
          limit: this.defaultsAndLimits.maxAmdGpuLimit,
          // safeLimit is required, otherwise 0 is interpreted as unlimited
          safeLimit: this.defaultsAndLimits.maxAmdGpuLimit,
          unit: 'GPUs',
        },
        maximum: {
          form: this.defaultsAndLimits.maxAmdGpuLimit,
        }
      });
    },
    errorForNvidiaGPULimit() {
      // Note: we are using validateResourceRequest here even though we are validating gpu_limit
      // because GPU resource limits behave more like a resource requests than a limits
      // see also: https://kubernetes.io/docs/tasks/manage-gpus/scheduling-gpus/#using-device-plugins
      return validateResourceRequest({
        input: {
          form: this.form.nvidiaGpuLimit,
          limit: this.defaultsAndLimits.maxNvidiaGpuLimit,
          // safeLimit is required, otherwise 0 is interpreted as unlimited
          safeLimit: this.defaultsAndLimits.maxNvidiaGpuLimit,
          unit: 'GPUs',
        },
        maximum: {
          form: this.defaultsAndLimits.maxNvidiaGpuLimit,
        }
      });
    },
    errorForMinProcesses() {
      const errStr = validateMinProcesses(
        this.form.minProcesses,
        this.defaultsAndLimits.minProcessesLimit,
        this.form.maxProcesses,
        this.defaultsAndLimits.maxProcesses
      );
      if (errStr !== null) {
        return {
          message: errStr,
          type: 'error',
        };
      }
      // special guidance, if no error already
      if (this.form.minProcesses > 5) {
        return {
          message: 'Warning: Large values significantly increase memory usage.',
          type: 'warning',
        };
      }
      return {
        message: null,
        type: null,
      };
    },
    errorForMaxProcesses() {
      return validateMaxProcesses(
        this.form.maxProcesses,
        this.defaultsAndLimits.maxProcessesLimit,
        this.form.minProcesses,
        this.defaultsAndLimits.minProcesses
      );
    },
    errorForMaxConnsPerProcess() {
      return errorForMinValueZero(this.form.maxConnsPerProcess);
    },
    errorForLoadFactor() {
      if (isEmptyValue(this.form.loadFactor)) {
        return null;
      }
      const numValue = Number(this.form.loadFactor);
      if (isNaN(numValue)) {
        return internalError(`errorForLoadFactor: this.form.loadFactor=${this.form.loadFactor} isNaN`);
      }
      if (numValue < 0 || numValue > 1) {
        return 'Must be between 0 and 1';
      }
      return null;
    },
    errorForIdleTimeout() {
      return errorForTimeoutSettings(this.form.idleTimeout);
    },
    errorForInitTimeout() {
      return errorForTimeoutSettings(this.form.initTimeout);
    },
    errorForConnectionTimeout() {
      return errorForTimeoutSettings(this.form.connectionTimeout);
    },
    errorForReadTimeout() {
      return errorForTimeoutSettings(this.form.readTimeout);
    },
    runAsIsChanging() {
      return this.runAs.initial.primaryMode !== this.runAsActiveState.primaryMode
        || this.runAs.initial.secondaryMode !== this.runAsActiveState.secondaryMode
        || this.runAs.initial.alternateUser !== this.runAsActiveState.alternateUser;
    },
    // eslint-disable-next-line complexity
    formIsValid() {
      // This list must be kept up with all fields within this panel
      // if they have the opportunity to be invalid.

      // If a setting is hidden, we should not prevent saving even if it has an
      // error because the user will not be able to see what is wrong or correct
      // it. Consider: an allowed CPU limit is set; later the admin lowers the max
      // CPU limit; later still they disable Kubernetes support. Now the apps
      // runtime settings can never be updated, because the existing CPU limit is
      // illegal but is also hidden. So we ignore hidden settings when deciding
      // whether saving is allowed.
      return (
        (!this.showOffHostSpecificResourceSettings || this.errorForCPULimit === null) &&
        (!this.showOffHostSpecificResourceSettings || this.errorForCPURequest === null) &&
        (!this.showNewRuntimeSettings || this.errorForMemoryLimit === null) &&
        (!this.showOffHostSpecificResourceSettings || this.errorForMemoryRequest === null) &&
        (!this.showAMDGPUSettings || this.errorForAMDGPULimit === null) &&
        (!this.showNvidiaGPUSettings || this.errorForNvidiaGPULimit === null) &&
        (this.errorForMinProcesses.type !== 'error') &&
        (this.errorForMaxProcesses === null) &&
        (this.errorForMaxConnsPerProcess === null) &&
        (this.errorForLoadFactor === null) &&
        (this.errorForIdleTimeout === null) &&
        (this.errorForInitTimeout === null) &&
        (this.errorForConnectionTimeout === null) &&
        (this.errorForReadTimeout === null) &&
        (this.executionEnvironment.valid)
      );
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      reloadContent: LOAD_CONTENT_VIEW,
      setInfoMessage: SHOW_INFO_MESSAGE,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    ...mapMutations({
      runAsUpdatePrimary: ACCESS_SETTINGS_UPDATE_PRIMARY_MODE,
      runAsUpdateSecondary: ACCESS_SETTINGS_UPDATE_SECONDARY_MODE,
      runAsUpdateAlternateUser: ACCESS_SETTINGS_UPDATE_ALTERNATE_USER,
      reloadFrame: SET_CONTENT_FRAME_RELOADING,
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    init() {
      this.loading = true;
      this.loadingError = false;
      this.clearStatusMessage();
      this.initPromise = this.getData()
        .then(this.populate)
        .catch(e => {
          this.loadingError = true;
          this.setErrorMessageFromAPI(e);
        })
        .finally(() => (this.loading = false));
    },
    getData() {
      return getApplicationsSettings()
        .then(appSettings => {
          this.appSettings = appSettings;
          this.apiValues = importAppData(this.app);
          this.disabled = !this.currentUser.canEditAppSettings(this.app);

          if (this.app?.hasWorker() || this.app?.isRenderable()) {
            const mode = this.app.appMode;
            return getRuntimeDefaultsAndLimits(mode)
              .then(runtimeDefaultsAndLimits => {
                this.defaultsAndLimits = importDefaultsAndLimits(
                  runtimeDefaultsAndLimits
                );
              });
          }
        })
        .then(() => {
          const isAdminOrPublisher = this.currentUser.isAdmin() || this.currentUser.isPublisher();
          const inK8S = this.serverSettings.executionType === ExecutionTypeK8S;
          if (isAdminOrPublisher && inK8S) {
            return getServiceAccounts()
              .then(serviceAccounts => {
                this.serviceAccount.apiResponse = serviceAccounts;
              });
          }
        });
    },
    populate() {
      if (!this.app) {
        return;
      }

      this.populateForm();
      this.populateEnvManagement();
      this.populateExecutionEnvironment();
      this.populateServiceAccount();
      this.populateRunAs();
    },
    populateForm() {
      this.form = {
        ...this.apiValues,
      };
    },
    populateEnvManagement() {
      this.envManagement.r.appDefault = this.app.defaultREnvironmentManagement;
      this.envManagement.r.initialValue = this.app.defaultREnvironmentManagement;
      this.envManagement.r.serverDefault = this.serverSettings.defaultREnvironmentManagement;
      this.envManagement.r.last = this.app.rEnvironmentManagement;

      this.envManagement.python.appDefault = this.app.defaultPyEnvironmentManagement;
      this.envManagement.python.initialValue = this.app.defaultPyEnvironmentManagement;
      this.envManagement.python.serverDefault = this.serverSettings.defaultPyEnvironmentManagement;
      this.envManagement.python.last = this.app.pyEnvironmentManagement;
    },
    populateExecutionEnvironment() {
      this.executionEnvironment.imageName = this.app.defaultImageName;
      this.executionEnvironment.last = this.app.imageName;
      this.executionEnvironment.valid = true;
    },
    populateServiceAccount() {
      if (this.serviceAccount.apiResponse) {
        this.serviceAccount.enabled = this.serviceAccount.apiResponse.enabled;
        this.serviceAccount.default = this.serviceAccount.apiResponse.default;
        this.serviceAccount.all = this.serviceAccount.apiResponse.accounts;

        let initAccount = this.serviceAccount.default;
        const contentAccount = this.app.serviceAccountName;
        // If service accounts feature not enabled. Finish the required assignments.
        if (this.serviceAccount.enabled && contentAccount) {
          initAccount = contentAccount;
          // If content has an unrecognized service account label it and include it within
          // the options for users to view the actual value, regardless of validity,
          // let's be transparent with what we have in the DB record.
          const accountExists = this.serviceAccount.all
            .find(ac => ac.value === initAccount);
          if (!accountExists) {
            const label = this.serviceAccount.apiResponse.notRecognizedLabel(initAccount);
            this.serviceAccount.recognized = false;
            this.serviceAccount.all = [
              ...this.serviceAccount.all,
              { label, value: initAccount }
            ];
          } else {
            this.serviceAccount.recognized = true;
          }
        }
        this.serviceAccount.initial = initAccount;
        this.serviceAccount.active = initAccount;
        this.serviceAccount.loaded = true;
      }
    },
    populateRunAs() {
      this.runAs.defaultUser = this.appSettings.runAs;
      this.runAs.currentUserAllowed = (this.appSettings.runAsCurrentUser
        && this.app.isRunnableAsCurrentUser());
      this.runAs.initial.primaryMode = RunAsModes.DEFAULT;
      this.runAs.initial.secondaryMode = RunAsModes.DEFAULT;
      this.runAs.initial.alternateUser = '';
      if (this.app.runAs) {
        this.runAs.initial.primaryMode = RunAsModes.ALTERNATE;
        this.runAs.initial.secondaryMode = RunAsModes.ALTERNATE;
        this.runAs.initial.alternateUser = this.app.runAs;
      }
      if (this.runAs.currentUserAllowed && this.app.runAsCurrentUser) {
        this.runAs.initial.primaryMode = RunAsModes.CURRENT;
      }
      this.runAsUpdatePrimary(this.runAs.initial.primaryMode);
      this.runAsUpdateSecondary(this.runAs.initial.secondaryMode);
      this.runAsUpdateAlternateUser(this.runAs.initial.alternateUser);
    },
    changeHandler() {
      this.confirmationVisible = true;
    },
    onDefaultImageNameChange(value) {
      if (this.executionEnvironment.imageName !== value) {
        this.executionEnvironment.imageName = value;
        this.confirmationVisible = true;
      }
    },
    onDefaultImageNameValidChange(value) {
      this.executionEnvironment.valid = value;
    },
    onServiceAccountSelectionChange() {
      this.confirmationVisible = true;
    },
    closeRunAsWarning() {
      this.runAs.showWarning = false;
    },
    preSaveConfirmation() {
      if (this.runAsIsChanging) {
        this.runAs.showWarning = true;
      } else {
        return this.save();
      }
    },
    save() {
      // bail if form is not valid
      if (!this.formIsValid) {
        return Promise.resolve();
      }
      const result = exportAppData(this.form);
      if (result.error !== null) {
        this.setErrorMessage({ mesage: result.error });
        return Promise.reject(result.error);
      }
      this.runAs.showWarning = false;
      this.clearStatusMessage();

      // update environment management fields if they were modified
      if (this.app.defaultREnvironmentManagement !== this.envManagement.r.appDefault) {
        result.data.defaultREnvironmentManagement = this.envManagement.r.appDefault;
      }
      if (this.app.defaultPyEnvironmentManagement !== this.envManagement.python.appDefault) {
        result.data.defaultPyEnvironmentManagement = this.envManagement.python.appDefault;
      }

      // update default execution environment selection if it was modified
      if (this.app.defaultImageName !== this.executionEnvironment.imageName) {
        result.data.defaultImageName = this.executionEnvironment.imageName;
      }

      // update service account selection if it was modified
      if (this.serviceAccount.initial !== this.serviceAccount.active) {
        // Submit "null" as service account when the configured default service account is selected.
        // This, for content to be able to use any configured default service account if it changes.
        //   ( Content items DB records with an existing service_account_name will always use that one,
        //   for this use case we want to make sure there is nothing preventing content to pick up
        //   new service accounts defined within Connect's config )
        result.data.serviceAccountName = null;
        if (this.serviceAccount.active !== this.serviceAccount.default) {
          result.data.serviceAccountName = this.serviceAccount.active;
        }
      }

      return this.saveRunAs()
        .then(() => {
          return updateContent(this.app.guid, result.data);
        })
        .then(() => {
          return this.reloadContent({
            appIdOrGuid: this.app.guid,
          });
        })
        .then(() => {
          // updating the raw data
          this.apiValues = importAppData(this.app);
          this.confirmationVisible = false;
          this.setInfoMessage({ message: 'Runtime settings saved successfully.' });
          this.populate();
          if (this.appHasWorker) {
            // we need to cause app to restart. If static, no need.
            this.reloadFrame(true);
          }
        })
        .catch(this.setErrorMessageFromAPI);
    },
    saveRunAs() {
      if (this.runAsIsChanging) {
        const { primaryMode, secondaryMode, alternateUser } = this.runAsActiveState;
        const data = {
          runAs: primaryMode === RunAsModes.ALTERNATE ||
            (primaryMode === RunAsModes.CURRENT && secondaryMode === RunAsModes.ALTERNATE)
            ? alternateUser
            : null,
          runAsCurrentUser: primaryMode === RunAsModes.CURRENT,
        };
        return updateRunAs(this.app.id, data);
      }
      return Promise.resolve();
    },

    discard() {
      this.populate();
      Object.keys(this.$refs)
        .forEach(ref => {
          if (this.$refs[ref].resetState) {
            this.$refs[ref].resetState();
          }
        });
      this.confirmationVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'Styles/shared/_colors';

  .lightText {
    color: $color-dark-grey !important;
  }
  .letterSpacing {
  letter-spacing: .1em;
  }
  .smallerFont {
  font-size: .9em;
  }
  .largerFont {
  font-size: 1.2em;
  }
  .uppercase {
  text-transform: uppercase;
  }
  .groupHeadings {
    color: $color-heading;
    letter-spacing: .1em;
    font-size: 1em;
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }
  .spaceAfter {
    margin-bottom: 0.5rem;
  }
</style>

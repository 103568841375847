<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div
    ref="logContents"
    :class="{
      logOutput: true,
      inDialog: embedded,
      short: embedded
    }"
    tabindex="0"
    @scroll="onScroll"
  >
    <div
      v-for="(entry, index) in filteredEntries"
      :key="index"
    >
      <div
        class="logTimestamp"
      >
        {{ formattedTimestamp(entry) }}
      </div>
      <div
        data-automation="log-message"
        :class="{
          logMessage: true,
          error: isError(entry)
        }"
      >
        {{ logLine(entry) }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default {
  name: 'LogViewer',
  props: {
    entries: {
      type: Array,
      default() {
        return [];
      },
    },
    embedded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      follow: true,
    };
  },
  computed: {
    filteredEntries() {
      return this.entries.filter(entry => entry.timestamp || entry.line);
    },
  },
  updated() {
    if (this.follow) {
      this.$el.scrollTop = this.$el.scrollHeight;
    }
  },
  mounted() {
    this.$refs.logContents.focus();
  },
  methods: {
    formattedTimestamp(entry) {
      if (!entry.timestamp) {
        return '';
      }
      return `${dayjs.utc(entry.getDayjsTimestamp()).format('MM/DD HH:mm:ss.SSS')} (GMT)`;
    },
    logLine(entry) {
      return entry.getLine();
    },
    isError(entry) {
      return entry.Error();
    },
    onScroll() {
      const maxScroll = this.$el.scrollHeight - this.$el.clientHeight;
      this.follow = (this.$el.scrollTop >= maxScroll - 1);
    },
  }
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.logOutput {
  font-family: 'Courier New', monospace;
  overflow-y: auto;

  &.inDialog {
    height: 300px;
    overflow-y: auto;
    background-color: $color-white;
    @include control-border();
    padding: 0 15px 10px 15px;

    &.short {
      height: 200px;
    }
  }

  &:focus {
    @include control-focus;
  }
}

.logTimestamp {
  font-size: 11px;
  font-style: italic;
  line-height: 13px;
  padding-top: 10px;
}

.logMessage {
  line-height: 18px;
  word-wrap: break-word;

  &.warning {
    color: #800000;
  }

  &.error {
    color: $color-error;
  }
}

.logSummary {
  line-height: 16px;
  padding-left: 20px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: left top;

  &.error {
    background-image: url(/images/elements/error.svg);
  }

  &.warning {
    background-image: url(/images/elements/warning.svg);
  }
}
</style>

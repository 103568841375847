// Copyright (C) 2023 by Posit Software, PBC.

import {
  getVariantOverrides,
  duplicateVariant,
  renderVariant,
  deleteVariant,
  promoteVariant,
} from '@/api/parameterization';
import {
  PARAMETERIZATION_FETCH_VARIANTS,
  PARAMETERIZATION_SET_INITIAL_VARIANT,
  PARAMETERIZATION_SELECT_VARIANT,
  PARAMETERIZATION_SELECT_DEFAULT_VARIANT,
} from '@/store/modules/parameterization';
import { SHOW_ERROR_MESSAGE } from '@/store/modules/messages';
import { taskToPromise } from '@/api/tasks';
import { VISIBILITY_ADHOC } from '@/constants/variants';
import { v4 as uuidv4 } from 'uuid';

export const LEGACY_PARAMS_SET_BUSY = 'LEGACY_PARAMS_SET_BUSY';
export const LEGACY_PARAMS_SET_OVERRIDES = 'LEGACY_PARAMS_SET_OVERRIDES';
export const LEGACY_PARAMS_SET_ADHOC_VARIANT = 'LEGACY_PARAMS_SET_ADHOC_VARIANT';
export const LEGACY_PARAMS_SET_RENAMING_VARIANT = 'LEGACY_PARAMS_SET_RENAMING_VARIANT';
export const LEGACY_PARAMS_SET_SAVING_VARIANT = 'LEGACY_PARAMS_SET_SAVING_VARIANT';
export const LEGACY_PARAMS_SET_RENDERED = 'LEGACY_PARAMS_SET_RENDERED';
export const LEGACY_PARAMS_CREATE_SESSION = 'LEGACY_PARAMS_CREATE_SESSION';
export const LEGACY_PARAMS_UPDATE_FORM = 'LEGACY_PARAMS_UPDATE_FORM';
export const LEGACY_PARAMS_SET_UNSAVED_MODAL = 'LEGACY_PARAMS_SET_UNSAVED_MODAL';
export const LEGACY_PARAMS_CLEAR = 'LEGACY_PARAMS_CLEAR';
export const LEGACY_PARAMS_LOAD = 'LEGACY_PARAMS_LOAD';
export const LEGACY_PARAMS_START = 'LEGACY_PARAMS_START';
export const LEGACY_PARAMS_SAVE_PARAMS = 'LEGACY_PARAMS_SAVE_PARAMS';
export const LEGACY_PARAMS_RUN_REPORT = 'LEGACY_PARAMS_RUN_REPORT';
export const LEGACY_PARAMS_REVERT_CHANGES = 'LEGACY_PARAMS_REVERT_CHANGES';
export const LEGACY_PARAMS_CONFIRM_IGNORE_CHANGES = 'LEGACY_PARAMS_CONFIRM_IGNORE_CHANGES';
export const LEGACY_PARAMS_SAVE_VARIANT = 'LEGACY_PARAMS_SAVE_VARIANT';
export const LEGACY_PARAMS_SAVE_AS_VARIANT = 'LEGACY_PARAMS_SAVE_AS_VARIANT';
export const LEGACY_PARAMS_DELETE_VARIANT = 'LEGACY_PARAMS_DELETE_VARIANT';

export const defaultState = () => ({
  isBusy: false,
  renamingVariant: false,
  savingVariant: false,
  // adhocVariant is the temporary variant that owns in-progress edits and
  // renders. Populated when editing is permitted.
  adhocVariant: {},
  editingSession: undefined,
  // isRendered tracks if the current ad-hoc variant has been rendered while
  // managing parameters.
  isRendered: false,
  form: {
    loaded: false,
    dirty: false,
  },
  // overrides contains the parameter override values associated with the
  // adhocVariant (when editing is permitted) or the current variant (when
  // editing is prohibited).
  overrides: {},
  unsavedChanges: {
    showModal: false,
    onIgnore: () => {},
  },
});

export default {
  state: defaultState(),
  mutations: {
    [LEGACY_PARAMS_SET_BUSY]: (state, busy) => {
      state.isBusy = busy;
    },
    [LEGACY_PARAMS_SET_OVERRIDES]: (state, overrides) => {
      state.overrides = overrides;
    },
    [LEGACY_PARAMS_SET_ADHOC_VARIANT]: (state, adhocVariant) => {
      state.adhocVariant = adhocVariant;
    },
    [LEGACY_PARAMS_SET_RENAMING_VARIANT]: (state, flag) => {
      state.renamingVariant = flag;
    },
    [LEGACY_PARAMS_SET_SAVING_VARIANT]: (state, flag) => {
      state.savingVariant = flag;
    },
    [LEGACY_PARAMS_SET_RENDERED]: (state, rendered) => {
      state.isRendered = rendered;
    },
    [LEGACY_PARAMS_CREATE_SESSION]: (state) => {
      state.editingSession = uuidv4();
    },
    [LEGACY_PARAMS_UPDATE_FORM]: (state, updates) => {
      state.form = {
        ...state.form,
        ...updates,
      };
    },
    [LEGACY_PARAMS_SET_UNSAVED_MODAL]: (state, { show, ignoreCallback = () => {} }) => {
      state.unsavedChanges.showModal = show;
      state.unsavedChanges.onIgnore = ignoreCallback;
    },
    [LEGACY_PARAMS_CLEAR]: state => {
      Object.assign(state, defaultState());
    },
  },
  actions: {
    [LEGACY_PARAMS_LOAD]: ({ commit }, variantId) => {
      commit(LEGACY_PARAMS_CLEAR);
      return getVariantOverrides(variantId)
        .then(overrides => {
          commit(LEGACY_PARAMS_SET_OVERRIDES, overrides);
          return overrides;
        });
    },

    [LEGACY_PARAMS_START]: async({ commit }, variantId) => {
      commit(LEGACY_PARAMS_CLEAR);
      commit(LEGACY_PARAMS_CREATE_SESSION);
      const adhocVariant = await duplicateVariant(variantId, 'Untitled', VISIBILITY_ADHOC);
      commit(LEGACY_PARAMS_SET_ADHOC_VARIANT, adhocVariant);
      return getVariantOverrides(adhocVariant.id)
        .then(overrides => {
          commit(LEGACY_PARAMS_SET_OVERRIDES, overrides);
          return overrides;
        });
    },

    [LEGACY_PARAMS_SAVE_PARAMS]: ({ commit, state }) => {
      const iframe = document.getElementById('parameters-iframe');
      if (!iframe) {
        return Promise.reject({ message: 'parameters UI has not loaded.' });
      }

      const { adhocVariant } = state;

      return new Promise((resolve, reject) => {
        const get = ({ retries, delay }) => {
          getVariantOverrides(adhocVariant.id).then(overrides => {
            if (overrides.version > state.overrides.version) {
              commit(LEGACY_PARAMS_SET_OVERRIDES, overrides);
              resolve(overrides);
            } else if (retries > 0) {
              setTimeout(() => get({ retries: retries - 1, delay }), delay);
            } else {
              reject(new Error('Unable to save variant parameters'));
            }
          });
        };

        // this is the actual save command. It's done via the shiny form
        iframe.contentWindow.postMessage({ type: 'customshiny:save' }, '*');

        // We know that Shiny has issued its "disconnected" event. Fetch the
        // overrides until we see a new version, which means we have the
        // output from the shiny customization application.
        get({ retries: 20, delay: 500 });
      });
    },

    [LEGACY_PARAMS_RUN_REPORT]: async({ commit, dispatch, state }) => {
      commit(LEGACY_PARAMS_SET_BUSY, true);

      if (state.form.dirty) {
        // Setting frame form as un-loaded because it'll disconnect,
        // when run finishes and frame form is ready, it'll get back as loaded.
        commit(LEGACY_PARAMS_UPDATE_FORM, { loaded: false });

        await dispatch(LEGACY_PARAMS_SAVE_PARAMS);
        commit(LEGACY_PARAMS_CREATE_SESSION);
      }

      const { adhocVariant } = state;
      const task = await renderVariant(adhocVariant.id);

      return taskToPromise(task.id, data => {
        if (!data.finished) {
          return data;
        }

        if (data.code !== 0) {
          // Report failed
          dispatch(SHOW_ERROR_MESSAGE, { message: data.error });
        } else {
          commit(LEGACY_PARAMS_SET_RENDERED, true);
        }

        commit(LEGACY_PARAMS_SET_BUSY, false);
        return data;
      });
    },

    [LEGACY_PARAMS_REVERT_CHANGES]: async({ dispatch, rootState }) => {
      // Revert restarts the editing session (creating a new ad-hoc variant).
      const { id: variantId } = rootState.parameterization.currentVariant;
      return dispatch(LEGACY_PARAMS_START, variantId);
    },

    [LEGACY_PARAMS_CONFIRM_IGNORE_CHANGES]({ commit, dispatch, state, rootState }) {
      const { id: appId } = rootState.contentView.app;
      const { currentVariant } = rootState.parameterization;
      const callback = state.unsavedChanges.onIgnore;

      commit(LEGACY_PARAMS_CLEAR);

      // We run the original method (mutation, action or navigation)
      // attempted to run before confirming to ignore changes.
      callback();

      commit(PARAMETERIZATION_SET_INITIAL_VARIANT, currentVariant.id);
      return dispatch(PARAMETERIZATION_FETCH_VARIANTS, appId);
    },

    [LEGACY_PARAMS_SAVE_VARIANT]: async({ commit, dispatch, state, rootState }) => {
      const { id: variantId } = rootState.parameterization.currentVariant;
      const { adhocVariant } = state;

      // Setting frame form as un-loaded because it'll disconnect
      commit(LEGACY_PARAMS_UPDATE_FORM, { loaded: false });

      if (state.form.dirty) {
        await dispatch(LEGACY_PARAMS_SAVE_PARAMS);
        commit(LEGACY_PARAMS_CREATE_SESSION);
      }

      await promoteVariant(variantId, adhocVariant.key);

      return dispatch(LEGACY_PARAMS_START, variantId);
    },

    [LEGACY_PARAMS_SAVE_AS_VARIANT]: async(
      { commit, dispatch, state },
      { name, visibility }, // eslint-disable-line no-shadow
    ) => {
      const { adhocVariant } = state;

      // Setting frame form as un-loaded because it'll disconnect,
      commit(LEGACY_PARAMS_UPDATE_FORM, { loaded: false });

      if (state.form.dirty) {
        await dispatch(LEGACY_PARAMS_SAVE_PARAMS);
        commit(LEGACY_PARAMS_CREATE_SESSION);
      }

      const newVariant = await duplicateVariant(adhocVariant.id, name, visibility);

      await dispatch(PARAMETERIZATION_FETCH_VARIANTS, newVariant.appId);
      await dispatch(PARAMETERIZATION_SELECT_VARIANT, newVariant.id);
      // SAVE_AS does not directly dispatch START. The caller routes to the
      // newly created variant, which eventually dispatches START.
      return newVariant;
    },

    [LEGACY_PARAMS_DELETE_VARIANT]: async({ dispatch, rootState }) => {
      const { id: variantId, appId } = rootState.parameterization.currentVariant;

      // The caller closes the parameter panel upon delete. This isn't
      // necessary; we could dispatch LEGACY_PARAMS_START after selecting the
      // default variant.
      //
      // The state is left dirty after delete ...

      await deleteVariant(variantId);
      await dispatch(PARAMETERIZATION_FETCH_VARIANTS, appId);
      return dispatch(PARAMETERIZATION_SELECT_DEFAULT_VARIANT);
    },
  },
};

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div
    class="indeterminate-progress"
    role="status"
    aria-busy="true"
    aria-label="Adding extension"
  >
    <div class="indeterminate-progress__bar">
      <div class="indeterminate-progress__bar-inner" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.indeterminate-progress {
  height: 1rem;
  width: 4rem;

  &__bar {
    position: relative;
    background-color: $color-posit-blue;
    border-radius: 3px;
    height: 100%;
    width: 100%;

    &-inner {
      animation: slider 1.5s infinite linear;
      background-color: $color-light-blue;
      border-radius: 3px;
      height: .9rem;
      position: absolute;
      top: 1px;
      width: 1rem;
    }
  }

  @keyframes slider {
    0% {
      left: 0%;
    }
    50% {
      left: calc(100% - 1rem);
    }
    100% {
      left: 0%;
    }
  }
}
</style>

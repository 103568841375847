<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="pageContainer">
    <div id="contentContainer">
      <iframe
        id="contentIFrame"
        :src="url"
        :title="`${systemDisplayName} Landing Page`"
        data-test="content-iframe"
      />
    </div>
  </div>
</template>

<script>
import { landingPath } from '@/utils/paths';
import { mapState } from 'vuex';

export default {
  name: 'LandingPage',
  data() {
    return {
      url: landingPath(),
    };
  },
  computed: {
    ...mapState({
      systemDisplayName: state => state.server.settings.systemDisplayName,
    }),
  },
};
</script>

<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <fieldset :disabled="disableInputs">
    <div class="rs-field">
      <p>
        Create a content item in {{ systemDisplayName }} that automatically
        receives updates from its backing Git repository.
      </p>
      <p>
        Learn more about
        <a
          target="_blank"
          :href="learnMoreLink"
        >
          Git-backed content
        </a>
        in the Posit Connect User Guide.
      </p>
    </div>
    <div class="rs-divider" />
    <GitRepositoryURLInput
      :value="repositoryUrl"
      @input="newValue => $emit('update:repositoryUrl', newValue)"
      @valid="isValid => $emit('valid', isValid)"
    />
  </fieldset>
</template>

<script>
import { mapState } from 'vuex';
import GitRepositoryURLInput from './GitRepositoryURLInput';

export default {
  name: 'SelectRepository',
  components: {
    GitRepositoryURLInput,
  },
  props: {
    repositoryUrl: {
      type: String,
      required: true,
    },
    disableInputs: {
      type: Boolean,
      required: true,
    },
    learnMoreLink: {
      type: String,
      required: true,
    },
  },
  emits: ['valid', 'update:repositoryUrl'],
  computed: {
    ...mapState({
      systemDisplayName: state => state.server.settings.systemDisplayName,
    }),
  },
  mounted() {
    this.$nextTick().then(this.focusModalTitle);
  },
  methods: {
    focusModalTitle() {
      const title = document.querySelector('.rs-modal__title');
      if (title) { title.focus(); }
    },
  },
};
</script>

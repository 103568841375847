<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div data-automation="variant-selector">
    <SearchSelect
      v-if="current"
      data-automation="variant-select-input"
      label="Variant"
      :selected="selectedOption"
      :options="options"
      :disabled="disabled || disabledLegacy"
      :title="title"
      :default-value="defaultOption"
      class="variant-select__select"
      @select="onSelect"
    />

    <ConfirmModal
      v-if="confirmSwitchVariant"
      subject="Unsaved Parameter Changes"
      discard-label="Switch Variant"
      cancel-label="Return to Current Variant"
      warning="You have unsaved changes that will be lost if you select a different variant."
      @discard="onConfirmSwitch"
      @cancel="confirmSwitchVariant = null"
    />
  </div>
</template>

<script>
import ConfirmModal from '@/components/ParametersPanel/ConfirmModal';
import { PARAMETERIZATION_SELECT_VARIANT } from '@/store/modules/parameterization';
import { cloneDeep } from 'lodash';
import { mapActions, mapState } from 'vuex';
import {
  VISIBILITY_ADHOC,
  VISIBILITY_PRIVATE,
} from '../constants/variants';
import SearchSelect from './SearchSelect';

export default {
  name: 'ParameterizedVariantSelect',
  components: {
    ConfirmModal,
    SearchSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['change'],
  data: () => ({
    confirmSwitchVariant: null,
  }),
  computed: {
    ...mapState({
      variants: state => state.parameterization.variants,
      current: state => state.parameterization.currentVariant,
      isVariantDirty: state => state.parameterization.isVariantDirty,
      legacyIsDirty: state => state.legacyParams.form.dirty,
      newParameterizationEnabled: state => state.server.settings.newParameterizationEnabled,
    }),
    title() {
      return this.disabled
        ? 'Disabled while editing settings - save or discard your changes'
        : 'Select a variant';
    },
    disabledLegacy() {
      return this.legacyIsDirty || this.current.isAdHoc();
    },
    selectedOption() {
      return this.toOption(this.current);
    },
    defaultOption() {
      const defaultVariant = this.variants.find(
        variant => variant.isDefault
      );
      return defaultVariant && defaultVariant.id;
    },
    options() {
      const dropdownOptions = cloneDeep(this.variants);
      if (!this.newParameterizationEnabled) {
        dropdownOptions.sort((a, b) => b.createdTime.getTime() - a.createdTime.getTime());
      }
      return dropdownOptions.map(v => this.toOption(v));
    },
  },
  methods: {
    toOption(item) {
      const opt = { value: item.id, text: item.name };
      // For legacy params compatibility.
      // New p14n does not have the concept of Shared and Personal variants.
      // We group variants only when new parameterization is not enabled.
      if (!this.newParameterizationEnabled) {
        opt.group = this.groupName(item);
      }
      return opt;
    },
    groupName(variant) {
      const visibility = variant.visibility;

      if (visibility === VISIBILITY_PRIVATE || visibility === VISIBILITY_ADHOC) {
        return 'Personal';
      }

      return 'Shared';
    },
    onConfirmSwitch() {
      this.switchVariant(this.confirmSwitchVariant);
      this.confirmSwitchVariant = null;
    },
    onSelect(id) {
      if (!this.isVariantDirty) {
        this.switchVariant(id);
        return;
      }

      this.confirmSwitchVariant = id;
    },
    switchVariant(id) {
      if (id !== this.current.id) {
        this.$emit('change', id);
        this.selectVariant(id);
      }
    },
    ...mapActions({
      selectVariant: PARAMETERIZATION_SELECT_VARIANT,
    }),
  },
};
</script>

<style lang="scss">
.search-select.variant-select__select {
  .search-select__input {
    margin-left: 1rem;
    width: 275px;
  }
}
</style>

// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiV1Path } from '../utils/paths';

export const getShinyUsage = params => axios.get(apiV1Path('instrumentation/shiny/usage'), { params });

export const getContentVisits = params => axios.get(apiV1Path('instrumentation/content/visits'), { params });

export async function drainPaging(callback, params, totalLimit) {
  let results = [];
  let response = await callback(params);
  results = results.concat(response.data.results);
  while (response.data.paging.cursors.next && results.length < totalLimit) {
    // copy, don't modify in place
    const nextParams = { ...params, next: response.data.paging.cursors.next };
    response = await callback(nextParams);
    results = results.concat(response.data.results);
  }
  return results;
}

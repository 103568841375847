<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<!--
  RSModalForm extends RSModal with a few changes:
  1. the content and control slots live inside a form tag
  2. the form emits a submit event
-->
<template>
  <div
    :class="componentClass"
    :data-automation="dataAutomation"
  >
    <FocusLock>
      <div
        :class="dialogClasses"
        role="dialog"
        :aria-label="subject"
        @click.stop
        @keydown.stop
      >
        <div class="rs-modal__header">
          <h1
            class="rs-modal__title"
            tabindex="-1"
          >
            {{ subject }}
          </h1>
          <button
            v-if="closeable"
            :aria-label="closeButtonLabel"
            :tabindex="active ? 0 : -1"
            class="rs-modal__close"
            @click="$emit('close')"
          />
        </div>
        <form @submit.prevent="$emit('submit')">
          <section class="rs-modal__content">
            <slot name="content" />
          </section>
          <section class="rs-modal__actions">
            <slot name="controls" />
          </section>
        </form>
      </div>
    </FocusLock>
  </div>
</template>

<script>
import RSModal from './RSModal';
import FocusLock from 'vue-focus-lock';

export default {
  name: 'RSModalForm',
  components: {
    FocusLock,
  },
  extends: RSModal,
  emits: ['close', 'submit'],
};
</script>

// Copyright (C) 2023 by Posit Software, PBC.

import axios from 'axios';

import { Environment } from './dto/environment';
import { keysToCamel, keysToSnake } from '@/api/transform';
import { apiV1Path } from '@/utils/paths';

export function getEnvironments() {
  return axios
    .get(apiV1Path('environments'))
    .then(({ data }) => {
      const result = data.map(h => {
        return new Environment(keysToCamel(h));
      });
      return result;
    });
}

export function addEnvironment(environment) {
  // there are some fixed, required fields
  environment.clusterName = 'Kubernetes';
  return axios.post(
    apiV1Path(`environments`),
    keysToSnake(environment.toJSON(true)),
  ).then(response => {
    return new Environment(keysToCamel(response.data));
  });
}

export function deleteEnvironment(guid) {
  return axios
    .delete(apiV1Path(`environments/${guid}`));
}

export function updateEnvironment(environment) {
  return axios.put(
    apiV1Path(`environments/${environment.guid}`),
    keysToSnake(environment.toJSON()),
  ).then(response => {
    return new Environment(keysToCamel(response.data));
  });
}

// Copyright (C) 2022 by Posit Software, PBC.

import { JUMP_START_OPEN } from '@/store/modules/jumpstart';

export const PUBLISH_WIZARD_OPEN = 'PUBLISH_WIZARD_OPEN';
export const PUBLISH_WIZARD_CLOSE = 'PUBLISH_WIZARD_CLOSE';
export const PUBLISH_WIZARD_NEXT = 'PUBLISH_WIZARD_NEXT';
export const PUBLISH_WIZARD_BACK = 'PUBLISH_WIZARD_BACK';
export const PUBLISH_WIZARD_SET_STEP = 'PUBLISH_WIZARD_SET_STEP';

export const ADD_EXTENSION_MODAL_OPEN = 'ADD_EXTENSION_MODAL_OPEN';
export const ADD_EXTENSION_MODAL_CLOSE = 'ADD_EXTENSION_MODAL_CLOSE';

export default {
  state: {
    active: false,
    stepNum: 0,
    contentType: null,
    copyFrom: null,
    showAddExtensionModal: false,
  },
  mutations: {
    [PUBLISH_WIZARD_OPEN](state, payload) {
      state.active = true;
      state.stepNum = 0;
      state.contentType = payload.contentType;
      state.copyFrom = payload.copyFrom;
    },

    [PUBLISH_WIZARD_CLOSE](state) {
      state.active = false;
      state.contentType = null;
      state.copyFrom = null;
    },

    [PUBLISH_WIZARD_NEXT](state) {
      state.stepNum++;
    },

    [PUBLISH_WIZARD_SET_STEP](state, stepNum) {
      state.stepNum = stepNum;
    },

    [ADD_EXTENSION_MODAL_OPEN](state) {
      state.showAddExtensionModal = true;
    },
    [ADD_EXTENSION_MODAL_CLOSE](state) {
      state.showAddExtensionModal = false;
    }
  },
  actions: {
    [PUBLISH_WIZARD_BACK]({ commit, state }) {
      const newStep = state.stepNum - 1;

      commit(PUBLISH_WIZARD_SET_STEP, newStep < 0 ? 0 : newStep);

      if (newStep >= 0) {
        return;
      }
      commit(PUBLISH_WIZARD_CLOSE);
      commit(JUMP_START_OPEN, { backNavigation: true }, { root: true });
    }
  }
};

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <div
      class="flex"
      data-automation="scheduled-title"
    >
      <h1 class="sectionTitle focusedTitle">
        Scheduled Content
      </h1>
    </div>

    <EmbeddedStatusMessage
      v-if="!api.loaded"
      :show-close="false"
      message="Getting schedules..."
      data-automation="scheduled-content__message"
      type="activity"
    />

    <div
      v-if="api.loaded"
      class="rsc-schedule__filters"
      data-automation="scheduled-content__filters"
    >
      <RSInputRadio
        v-model="selectedView"
        label="Calendar"
        :value="options.view.calendar"
        data-automation="scheduled-content__filters__calendar"
        name="sc-selected-view"
      />
      <RSInputRadio
        v-model="selectedView"
        label="List"
        :value="options.view.list"
        data-automation="scheduled-content__filters__list"
        name="sc-selected-view"
      />
      <!-- eslint-disable vuejs-accesibility/no-onchange -->
      <select
        v-if="listView"
        v-model="selectedFrequency"
        aria-label="Content Schedule Frequency"
        data-automation="scheduled-content__list__schedule-frequency"
        @blur="changeFrequency"
        @change="changeFrequency"
      >
        <option
          v-for="opt in options.frequency"
          :key="opt.value"
          :value="opt.value"
        >
          {{ opt.label }}
        </option>
      </select>
      <div>{{ `All times are displayed in local time (${offset})` }}</div>
    </div>

    <CalendarWeekView
      v-if="api.loaded && calendarView"
      :week-view="api.weekView"
      data-automation="scheduled-content__calendar__container"
    />

    <ListView
      v-if="api.loaded && listView"
      :schedules="filteredSchedules"
      :frequency="selectedFrequency"
      data-automation="scheduled-content__list__container"
      @sort="updateSelection"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';

import RSInputRadio from '@/elements/RSInputRadio';

import { getSchedules } from '@/api/scheduledContent';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { localOffset } from '@/utils/timezone';

import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { mapMutations } from 'vuex';
import CalendarWeekView from './CalendarWeekView';
import ListView from './ListView';
import { filterSort } from './filter';
import { ScheduledContent } from './scheduledContent';
import { WeekView } from './weekView';

const scheduleView = {
  list: 'list',
  calendar: 'calendar',
};

const sortColumn = {
  unselected: null,
  name: 'name',
  frequency: 'frequency',
  nextRun: 'nextRun',
  author: 'author',
};

const scheduleFrequency = {
  all: 'all',
  minute: 'minute',
  hour: 'hour',
  day: 'day',
  week: 'week',
  semiMonth: 'semi-month',
  month: 'month',
  year: 'year',
};

const frequencyLabels = {
  all: 'All Scheduled Content',
  minute: 'Content by the Minute',
  hour: 'Hourly Content',
  day: 'Daily Content',
  week: 'Weekly Content',
  semiMonth: 'Semi-monthly Content',
  month: 'Monthly Content',
  year: 'Yearly Content',
};

export default {
  name: 'ScheduledContent',
  components: {
    EmbeddedStatusMessage,
    ListView,
    CalendarWeekView,
    RSInputRadio,
  },
  data() {
    return {
      options: {
        view: scheduleView,
        frequency: Object.entries(scheduleFrequency).map(([freq, value]) => ({
          value,
          label: frequencyLabels[freq],
        })),
      },

      selectedView: scheduleView.calendar,
      selectedFrequency: scheduleFrequency.all,
      sortDescending: true,
      sortColumn: sortColumn.unselected,
      // subset of schedules based on user selected filters
      filteredSchedules: [],
      offset: localOffset(),

      api: {
        loaded: false,

        // scheduled content list for 1 year - [ScheduledContent]
        yearSchedules: null,

        // scheduled content list for 1 week - WeekView
        weekView: null,
      },
    };
  },
  computed: {
    listView() {
      return this.selectedView === scheduleView.list;
    },
    calendarView() {
      return this.selectedView === scheduleView.calendar;
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    init() {
      const now = dayjs();
      const week = dayjs(now).add(6, 'days');
      const year = dayjs(now).add(365, 'days');

      // get 1 week of scheduled content with details (calendar view)
      const weekRequest = getSchedules({
        detailed: true,
        // call toISOString with `true` to force inclusion of local time offset, if any
        start: now.toISOString(true),
        end: week.toISOString(true),
      });

      // get 1 year of schedule content without details (list view)
      const yearRequest = getSchedules({
        detailed: false,
        // call toISOString with `true` to force inclusion of local time offset, if any
        start: now.toISOString(true),
        end: year.toISOString(true),
      });

      return Promise.all([weekRequest, yearRequest])
        .then(([weekResponse, yearResponse]) => {
          this.api.weekView = new WeekView(
            now,
            weekResponse.schedules.map(s => new ScheduledContent(s, week))
          );
          this.api.yearSchedules = yearResponse.schedules.map(
            s => new ScheduledContent(s)
          );
          this.updateSelection(sortColumn.frequency, true);
          this.api.loaded = true;
        })
        .catch(this.setErrorMessageFromAPI);
    },
    updateSelection(column, descending) {
      this.sortColumn = column;
      this.sortDescending = descending;

      this.filteredSchedules = filterSort(
        this.api.yearSchedules,
        this.selectedFrequency,
        column,
        descending
      );
    },
    changeFrequency() {
      this.updateSelection(this.sortColumn, this.sortDescending);
    }
  },
};
</script>

<style lang="scss" scoped>
  .rsc-schedule__filters {
    display: flex;
    align-items: baseline;
    margin: 0.5rem auto;

    & > *:not(:last-child) {
      margin-right: 1rem;
    }

    & >:last-child {
      margin-left: auto;
    }
  }
</style>

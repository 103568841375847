<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    class="pageContainer"
    :class="{ showingHistoryBar: isHistoryVisible }"
  >
    <LogsOverlay
      v-if="userCanViewLogs"
      :visible="showingLogs"
      :app-id="app.id"
      :job-key="jobKey"
      @hide="hideLogs"
      @show="openLogs"
    />

    <LegacyParametersPanel
      v-if="includeParamsPanel && !newParameterizationEnabled && !app?.locked"
    />
    <ParametersPanel v-if="includeParamsPanel && newParameterizationEnabled && !app?.locked" />
    <EmbedApp />
    <ContentSettingsPane v-if="!isUnauthorizedViewer" />
    <SubscriptionsModal v-if="showSubscriptionsModal" />
    <OAuthLoginModal v-if="showOAuthModal" />
  </div>
</template>

<script>
import AppModes from '@/api/dto/appMode';
import LegacyParametersPanel from '@/components/LegacyParametersPanel';
import OAuthLoginModal from '@/components/OAuthLoginModal';
import ParametersPanel from '@/components/ParametersPanel';
import SubscriptionsModal from '@/components/SubscriptionsModal';
import { resolveContentFilter, variantRedirectFilter } from '@/router/helpers';
import { SET_LOGS_PANEL_VISIBILITY } from '@/store/modules/contentView';
import LogsOverlay from '@/views/content/LogsOverlay';
import EmbedApp from '@/views/content/appFrame/EmbedApp';
import ContentSettingsPane from '@/views/content/settings/ContentSettingsPane';
import { isEmpty } from 'lodash';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'ContentView',
  components: {
    SubscriptionsModal,
    LegacyParametersPanel,
    ContentSettingsPane,
    LogsOverlay,
    EmbedApp,
    ParametersPanel,
    OAuthLoginModal,
  },
  beforeRouteEnter(to, from, next) {
    resolveContentFilter(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    variantRedirectFilter(to, from, next);
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      showingLogs: state => state.contentView.showLogs,
      isUnauthorized: state => state.contentView.isUnauthorized,
      isSettingsOpen: state => state.contentView.showSettingsPanel,
      currentUser: state => state.currentUser.user,
      isAuthenticated: state => state.currentUser.isAuthenticated,
      appHistoryOpen: state => state.bundles.showHistoryPane,
      renderingHistoryOpen: state => state.contentView.renderingHistory.showHistoryPane,
      newParameterizationEnabled: state => state.server.settings.newParameterizationEnabled,
      oauthIntegrationsEnabled: state => state.server.settings.oauthIntegrationsEnabled,
      variants: state => state.parameterization.variants,
      legacyParamsIsDirty: state => state.legacyParams.form.dirty,
    }),
    isHistoryVisible() {
      return this.appHistoryOpen || this.renderingHistoryOpen;
    },
    userCanViewLogs() {
      return this.app && this.isAuthenticated && this.currentUser.canViewAppSettings(this.app);
    },
    includeParamsPanel() {
      return this.app &&
        this.app.hasParameters &&
        this.app.appMode === AppModes.StaticRmd &&
        this.currentUser.canViewApp(this.app);
    },
    isUnauthorizedViewer() {
      return !this.app && this.isUnauthorized;
    },
    jobKey() {
      return this.$route.query.logKey || '';
    },
    showSubscriptionsModal() {
      return (
        this.app &&
        this.app.isRenderable() &&
        // if there aren't any variants, nothing's been published, so there's nothing to subscribe to
        !isEmpty(this.variants) &&
        this.$route.name === 'apps.subscriptions'
      );
    },
    showOAuthModal() {
      return (
        this.oauthIntegrationsEnabled &&
        !this.isSettingsOpen &&
        !this.isUnauthorizedViewer &&
        !this.showingLogs
      );
    }
  },
  mounted() {
    if (this.includeParamsPanel) {
      // prompt before leaving page if we have unsaved params changes
      window.addEventListener('beforeunload', this.paramsBrowserLeaveCheck.bind(this));
    }

    if (this.jobKey) {
      this.openLogs();
    }
  },
  beforeUnmount() {
    if (this.includeParamsPanel) {
      window.removeEventListener('beforeunload', this.paramsBrowserLeaveCheck.bind(this));
    }
  },
  methods: {
    ...mapMutations({
      logsVisible: SET_LOGS_PANEL_VISIBILITY,
    }),
    paramsBrowserLeaveCheck(ev) {
      // Some browsers want the text to be returned via e.returnValue, and others
      // just want the text returned, so we do both.
      // Some browsers ignore the text and use their own text. Chrome seems to do this.
      const text = 'You have unsaved changes.';
      if (this.legacyParamsIsDirty) {
        ev.returnValue = text;
        return text;
      }
    },
    hideLogs() {
      this.logsVisible(false);
    },
    openLogs() {
      this.logsVisible(true);
    },
  },
};
</script>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__outro wizard-panel--blue">
    <div class="wizard-panel__content">
      <StepHeading step="done">
        <span>
          That's It!
        </span>
      </StepHeading>
      <span v-show="hasBody">
        <slot name="body" />
      </span>

      <p>
        <a
          :title="learnMoreTitle"
          :href="learnMoreLink"
          class="wizard-panel__outro-link"
          target="_blank"
        >{{ learnMoreText }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import { docsPath } from '@/utils/paths';
import StepHeading from '@/views/content/PublishWizard/StepHeading';

export default {
  name: 'OutroPanel',
  components: { StepHeading },
  props: {
    learnMoreTitle: {
      type: String,
      required: true,
    },
    learnMoreText: {
      type: String,
      required: true,
    },
    learnMoreTarget: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasBody() {
      return Boolean(this.$slots.body);
    },
    learnMoreLink() {
      return this.targetStringToLink(this.learnMoreTarget);
    },
  },
  methods: {
    targetStringToLink(targetString) {
      // If the target URL is already an absolute URL (i.e. has an http[s])
      // prefix), return it as-is. Otherwise, treat it as a docs path.
      if (targetString.indexOf('http://') === 0 || targetString.indexOf('https://') === 0) {
        return targetString;
      }
      return docsPath(targetString);
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.wizard-panel {
  &__content {
    a {
      color: $color-light-grey-4;
    }
  }

  &__outro {
    background-image: url('./background.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>


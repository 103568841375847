<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Button with an on/off state. It emits a "toggled" event when its state changes.
  It receives a toggleClass for styling which will have a qualifiying
  "current" class to apply different styling for the on vs off state.
-->
<template>
  <BaseButton
    v-bind="{ label, buttonClass }"
    :aria-label="ariaLabel"
    @clicked.stop.prevent="handler"
  />
</template>

<script>
import BaseButton from '@/components/BaseButton';

export default {
  name: 'ToggleButton',
  components: { BaseButton },
  props: {
    label: {
      type: String,
      default: null,
    },
    toggleClass: {
      type: String,
      required: true,
    },
    isOn: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: null,
    }
  },
  emits: ['toggled'],
  computed: {
    buttonClass() {
      return this.isOn ? `${this.toggleClass} current` : this.toggleClass;
    },
  },
  methods: {
    handler() {
      this.$emit('toggled');
    },
  },
};
</script>

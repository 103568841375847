// Copyright (C) 2022 by Posit Software, PBC.

/**
 * Utility to build paths for connect API calls.
 *
 * All base paths need to be calculated only once.
 * Our dashboard is rooted at http://SERVER:PORT/PREFIX/{Server.DashboardPath}/
 * Our API is rooted at http://SERVER:PORT/PREFIX/__api__
 */

const setupPaths = () => {
  const script = document.currentScript;
  const scriptAttr = attr => script.getAttribute(attr);
  const dashboardPath = scriptAttr('data-dashboardpath');

  // It is unauthorized app
  if (script.src.includes('__unauthorized__')) {
    // Only provided to unauthorized template
    const dashboardUrl = scriptAttr('data-dashboardurl');
    return [
      dashboardUrl.replace(location.origin, '').replace(dashboardPath, ''),
      dashboardUrl.replace(location.origin, ''),
    ];
  }

  // Else, it is the main dashboard.
  // We are sure that document.currentScript.src is in the format:
  // https://any.host.net/{optional-server-prefix}/{DashboardPath}/out/{application}.bundle.js
  // Then we can infer:
  //  - The fullDashboardPath, (/{optional-server-prefix}/{DashboardPath})
  //  - The server base path with (the optional) server prefix
  //    E.g: get "/optional/prefix"
  //    from "https://any.host.com/optional/prefix/connect"
  const [serverPrefixURL] = script.src.split(`${dashboardPath}/out`);
  const basePath = serverPrefixURL.replace(location.origin, '');
  return [basePath, basePath + dashboardPath];
};

export const [serverBasePath, fullDashboardPath] = setupPaths();

// joinPaths only handles well paths,
// the outcome is not what you expect when a protocol is present (e.g: http://, https://)
// Use urlWithPaths when dealing with a well formed URL.
export const joinPaths = parts => {
  // Replace repeated separator i.e. replace '////' with '/'
  return parts.join('/').replace(/\/{1,}/g, '/');
};

// Same as joinPaths but expecting a URL with protocol as the first argument.
export const urlWithPaths = (url, paths) => {
  const urlObj = new URL(url);
  return urlObj.origin + joinPaths([urlObj.pathname, ...paths]);
};

export const serverURL = path => {
  return `${location.protocol}//${location.host}${joinPaths([serverBasePath, path])}`;
};
export const serverPath = suffix => joinPaths([serverBasePath, suffix]);
export const registerPath = ({ redirect = '' } = {}) => {
  let url = joinPaths([fullDashboardPath, '/#/register']);
  if (redirect) {
    url += `?url=${encodeURIComponent(redirect)}`;
  }
  return url;
};
export const loginPath = ({ redirect = '' } = {}) => {
  let url = joinPaths([serverBasePath, '__login__']);
  if (redirect) {
    url += `?url=${encodeURIComponent(redirect)}`;
  }
  return url;
};
export const setPasswordPath = ({ resettoken = '' } = {}) => {
  let url = joinPaths([fullDashboardPath, '#/setpassword']);
  if (resettoken) {
    url += `?resettoken=${encodeURIComponent(resettoken)}`;
  }
  return url;
};
export const resetPasswordPath = ({ username = '' } = {}) => {
  let url = joinPaths([fullDashboardPath, '#/resetpassword']);
  if (username) {
    url += `?u=${encodeURIComponent(username)}`;
  }
  return url;
};
export const oauthLoginPath = ({ guid, redirect = '' } = {}) => {
  let url = joinPaths([serverBasePath, `__oauth__/integrations/${guid}/login`]);
  if (redirect) {
    url += `?redirect_url=${encodeURIComponent(redirect)}`;
  }
  return url;
};

export const oauthLogoutPath = (guid) => joinPaths([serverBasePath, `__oauth__/integrations/${guid}/logout`]);

export const apiPath = suffix => joinPaths([serverBasePath, '__api__', suffix]);
export const apiV1Path = suffix => joinPaths([serverBasePath, '__api__', 'v1', suffix]);
export const apiExperimentalPath = suffix => joinPaths([serverBasePath, '__api__', 'v1', 'experimental', suffix]);

export const contentListPath = () => joinPaths([fullDashboardPath, '/#/content/listing']);
export const appPath = id => joinPaths([fullDashboardPath, '/#/apps/', encodeURIComponent(id)]);
export const jobPath = (id, jobKey) => `${appPath(id)}/logs?logKey=${encodeURIComponent(jobKey)}`;
export const userPath = id => joinPaths([fullDashboardPath, '/#/people/users/', encodeURIComponent(id)]);
export const groupPath = id => joinPaths([fullDashboardPath, '/#/people/groups/', encodeURIComponent(id)]);
export const userPreferencesPath = id => joinPaths([apiV1Path('users'), encodeURIComponent(id), 'preferences']);

export const docsPath = suffix => joinPaths([serverBasePath, '__docs__', suffix]);
export const contentPath = guid => joinPaths([serverPath('content'), encodeURIComponent(guid)]);
export const contentURL = guid => `${location.protocol}//${location.host}${contentPath(guid)}`;
export const landingPath = suffix => joinPaths([serverBasePath, '__landing__', suffix]);

export const adminPath = suffix => joinPaths([fullDashboardPath, '/#/admin/', encodeURIComponent(suffix)]);
export const unpublishedAppsSearchPath = () => joinPaths([fullDashboardPath, '/#/content/listing?q=published:false']);

// Retrieves the value of a query parameter contained in window.location.hash.
// To be used until we get vue-router in place so we don't have to talk to Angular.
// Returns null if the query parameter isn't found, otherwise and array with the param values
export function getHashQueryParameter(parameterName) {
  const hash = window.location.hash;
  const qIndex = hash.indexOf('?');
  if (qIndex < 0 || qIndex === hash.length - 1) {
    // no query params found
    return null;
  }
  const hashQueryString = hash.substring(qIndex + 1);

  if ('URLSearchParams' in window) {
    // all browsers except IE 11 (returns an empty array if not found)
    const param = new URLSearchParams(hashQueryString).getAll(parameterName);
    return param && param.length ? param : null;
  }

  const params = {};
  for (const pair of hashQueryString.split('&')) {
    // pair will be something like 'foo=bar', 'foo=', 'foo'
    let parts = pair.split('=');
    if (parts.length === 1) {
      // assign empty value in case there is no value for the key
      parts = parts.concat(['']);
    }
    const [key, value] = parts;
    if (params[key]) {
      params[key].push(decodeURIComponent(value));
    } else {
      params[key] = [decodeURIComponent(value)];
    }
  }
  const value = params[parameterName];
  // ensure same behavior as URLSearchParams
  return value === undefined ? null : value;
}

export const IllegalRedirectErr = new Error(
  'Illegal redirect to unsafe host. Please contact the Server Administrator.'
);

export const BadRedirectErr = new Error(
  'Illegal redirect to unsafe host. Cannot parse url argument.'
);

/**
 * Check a redirect URL to be sure it is safe to use.
 * @param {String} dest The URL to evaluate as safe to use for redirects.
 * @returns {Error|null} If the redirect URL is not considered safe to use, returns one error of IllegalRedirectErr or BadRedirectErr.
 */
export function policeRedirects(dest) {
  try {
    const destUrlObj = new URL(dest);
    const { protocol, hostname, port } = destUrlObj;
    const protocolMatch = protocol === window.location.protocol;
    const hostnameMatch = hostname === window.location.hostname;
    const portMatch = port === window.location.port;

    // Protocol and Hostname must match
    if (!protocolMatch || !hostnameMatch) {
      return IllegalRedirectErr;
    }

    // Port should match or at least be a default port
    // If destination URL.port is not present,
    // it is using a default port ['80', '443'], which is ok
    if (port && !portMatch) {
      return IllegalRedirectErr;
    }
    // eslint-disable-next-line no-unused-vars
  } catch (e) {
    return BadRedirectErr;
  }
  return null;
}

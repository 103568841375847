<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="errorMessages">
    <p>
      The selected branch does not contain deployable content.
      No directory with a <code>manifest.json</code> was found in that branch.
      Click "Back" to use a different branch.
    </p>
    <p>
      You can make a directory deployable by creating a <code>manifest.json</code> using
      the R function <code>rsconnect::writeManifest()</code> or the
      Python CLI <code>rsconnect write-manifest</code>
      See the
      <a
        :href="userGuideLocation"
        target="_blank"
      >
        Posit Connect User Guide
      </a>
      for more information.
    </p>
    <p v-if="!hasDirectoryScanningError">
      Scanning is taking too long.
    </p>
  </div>
</template>

<script>
export default {
  name: 'DirectoryScanFailure',
  props: {
    hasDirectoryScanningError: {
      type: Boolean,
      required: true,
    },
    userGuideLocation: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.errorMessages {
  p {
    margin-bottom: .75rem;
  }
}
</style>

<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    :data-automation="dataAutomation"
    class="rs-field"
  >
    <RSInformationToggle
      class="spaceAfter"
      data-automation="environment-management-help"
    >
      <template #title>
        <span class="groupHeadings">
          {{ settings().name }} Environment Management
        </span>
      </template>
      <template #help>
        <div class="spaceBefore spaceAfter">
          <div class="spaceAfter">
            When enabled, Posit Connect uses
            <code class="code">
              {{ settings().packageManager }}
            </code>
            to install packages required by the content.
            When environment management is disabled, Connect does not perform any package installation
            and Administrators must ensure that the required packages are available in the runtime environment.
            See the
            <a
              :href="adminGuide"
              target="_blank"
            >
              Admin Guide
            </a>
            for further details.
          </div>
          <div class="spaceAfter">
            The environment management strategy can be selected by Publishers in two ways:
          </div>
          <div class="spaceAfter">
            1) A bundle can select the environment management strategy in the manifest.
            This applies only to the bundle. See the
            <a
              :href="userGuideCli"
              target="_blank"
            >
              User Guide
            </a>
            for further details.
          </div>
          <div class="spaceAfter">
            2) Choose a default environment management strategy for this application
            through the dashboard. This applies to all bundles for this content.
            See the
            <a
              :href="userGuideUi"
              target="_blank"
            >
              User Guide
            </a>
            for further details.
          </div>
        </div>
      </template>
    </RSInformationToggle>
    <div
      data-automation="form-section"
    >
      <div class="sub-section-label">
        Last time
      </div>
      <div
        class="detail"
        data-automation="last-restore-value"
      >
        {{ computedLastRestore }}
      </div>
      <div
        v-if="showSelection"
        class="mt-small"
      >
        <RSInputSelect
          v-model="selectValue"
          label="Next time"
          :options="options"
          :disabled="readOnly"
          :name="`${appSettingsKey}EnvManagementSelect`"
          :data-automation="`${dataAutomation}-select`"
          @change="onSelectionChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RSInformationToggle from '@/elements/RSInformationToggle';
import RSInputSelect from '@/elements/RSInputSelect';
import { docsPath } from '@/utils/paths';

export const OptionsValues = {
  enabled: 'enabled',
  disabled: 'disabled',
  unset: 'unset',
};

const EnvironmentManagement = {
  python: {
    name: 'Python',
    packageManager: 'pip/venv',
    adminGuide: 'admin/python/package-management/#environment-management',
    userGuideCli: 'user/publishing-cli/#environment-management',
    userGuideUi: 'user/content-settings/#environment-management',
  },
  r: {
    name: 'R',
    packageManager: 'packrat',
    adminGuide: 'admin/r/package-management/#environment-management',
    userGuideCli: 'user/publishing-r/#environment-management',
    userGuideUi: 'user/content-settings/#environment-management',
  },
};

export default {
  name: 'DefaultEnvironmentManagement',
  components: {
    RSInputSelect,
    RSInformationToggle,
  },
  props: {
    dataAutomation: {
      type: String,
      required: true,
    },
    appSettingsKey: {
      type: String,
      required: true,
    },
    serverDefault: {
      type: Boolean,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: null,
    },
    initialValue: {
      type: Boolean,
      default: null,
    },
    lastRestore: {
      type: Boolean,
      default: null,
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      selectValue: this.boolToString(this.modelValue),
      options: [
        {
          value: OptionsValues.enabled,
          label: 'Enabled',
        },
        {
          value: OptionsValues.disabled,
          label: 'Disabled',
        },
        {
          value: OptionsValues.unset,
          label: `Use server default (${this.boolToString(this.serverDefault)})`,
        },
      ],
      EnvironmentManagement,
    };
  },
  computed: {
    adminGuide() {
      return docsPath(this.settings().adminGuide);
    },
    userGuideCli() {
      return docsPath(this.settings().userGuideCli);
    },
    userGuideUi() {
      return docsPath(this.settings().userGuideUi);
    },
    computedLastRestore() {
      return this.boolToTemplateString(this.lastRestore);
    },
  },
  methods: {
    // called from parent
    resetState() {
      this.$emit('change', this.initialValue);
      this.$emit('update:modelValue', this.initialValue);
    },
    settings() {
      return EnvironmentManagement[this.appSettingsKey];
    },
    // converts a nullable-boolean into the correct template string value
    boolToTemplateString(b) {
      const value = b ? 'Enabled' : 'Disabled';
      return b !== null ? value : 'Unknown';
    },
    // converts the string value 'enabled' or 'disabled' to the correct boolean
    stringToBool(s) {
      if (s === 'enabled') {
        return true;
      } else if (s === 'disabled') {
        return false;
      }
      return null;
    },
    // converts the boolean value to the correct string ('enabled', 'disabled', or 'unset')
    boolToString(b) {
      if (b !== null) {
        return b
          ? 'enabled'
          : 'disabled';
      }
      return 'unset';
    },
    onSelectionChange(value) {
      this.$emit('change', this.stringToBool(value));
      this.$emit('update:modelValue', this.stringToBool(value));
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.code {
  background-color: $color-light-grey-2;
}
.sub-section-label {
  font-size: 0.9rem;
  line-height: 1.5rem;
  color: $color-secondary-inverse;
  margin-top: 0.4rem;
  margin-bottom: 0.25rem;
}
.detail {
  border: 1px solid $color-medium-grey;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0.4rem 0.6rem;
  background-color: #fff;
  color: rgb(89, 88, 88);
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 16px;
}
.mt-small {
  margin-top: 0.4rem;
}
.groupHeadings {
  color: $color-heading;
  letter-spacing: .1em;
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}
.spaceBefore {
  margin-top: 0.5rem;
}
.spaceAfter {
  margin-bottom: 0.5rem;
}
</style>

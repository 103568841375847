<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { debounce } from '@/utils/debounce';
import cloneDeep from 'lodash/cloneDeep';
import {
  computed,
  nextTick,
  onMounted,
  ref,
  watch
} from 'vue';
import CheckboxFieldset from './CheckboxFieldset.vue';

// Props
const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: undefined,
  },
  icon: {
    type: String,
    default: undefined,
  },
  searchLabel: {
    type: String,
    default: '',
  },
  options: {
    type: Array, // [{ label, value, sub (optional), hidden (optional), children (optional) }]
    required: true,
  },
  preSelected: {
    type: Array, // [{ label, value, sub (optional) }]
    default: () => [],
  },
  aliases: {
    type: Object,
    default: null,
  },
  search: {
    type: Function,
    default: null,
  },
  popupDirection: {
    type: String,
    default: 'right',
  },
  preserveTree: {
    type: Boolean,
    default: false,
  },
  filterable: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  }
});

// Emits
const emit = defineEmits(['update:modelValue', 'selectionChange', 'treeCheckSpecifics']);

const dropdown = ref(null);
const dropdownButton = ref(null);
const searchInput = ref(null);
const isOpen = ref(false);
const searchPrefix = ref('');
const localSelections = ref([]);

const selectionsLabel = computed(() => {
  if (localSelections.value.length) {
    return localSelections.value[0].label;
  }

  // Show nothing if nothing is selected
  return '';
});

const selectionsCount = computed(() => {
  const valuesLen = localSelections.value.length;

  if (valuesLen > 1) {
    return `, +${valuesLen - 1}`;
  }

  // Show nothing if selections count < 1
  return '';
});

const visibleOptions = computed(() => {
  return props.options.filter(op => !op.hidden);
});

/**
 * Handler of this component's v-model. Emitting update:modelValue.
 * Triggered by the CheckboxFieldset's check event.
 * @param {Object} opt The option selected.
 */
const selectOption = ({ option }) => {
  const newModelValue = [...props.modelValue, option.value];
  localSelections.value.push({ ...option });
  emit('update:modelValue', newModelValue);
  emit('selectionChange', searchPrefix.value);
};

/**
 * Selection removal handler for this component's v-model. Emitting update:modelValue.
 * Triggered by the CheckboxFieldset's uncheck event.
 * @param {Object} opt The option payload being removed.
 * @param {Number} index The index of the item being removed.
 */
const removeSelection = ({ option }) => {
  const newModelValue = props.modelValue.filter(v => {
    const shouldKeep = v !== option.value;
    if (!props.aliases) {
      return shouldKeep;
    }
    const aliasMatch = props.aliases[v] && props.aliases[v] === option.value;
    return !aliasMatch && shouldKeep;
  });
  localSelections.value = localSelections.value.filter(item => item.value !== option.value);

  // If nothing got removed, it means that there is a current broad selection with multiple matches
  // and the current "uncheck" should notify the parent to update selections accordingly.
  const treeCheck = props.preserveTree && newModelValue.length === props.modelValue.length;
  if (treeCheck) {
    emit('treeCheckSpecifics', option);
    return;
  }

  emit('update:modelValue', newModelValue);
  emit('selectionChange', searchPrefix.value);
};

/**
 * Clear up all selections from model value
 */
const clearSelections = () => {
  emit('update:modelValue', []);
};

/**
 * Emit search event with search prefix value after debounce settles.
 */
const searchOptions = debounce(300, () => {
  props.search(searchPrefix.value);
});

/**
 * Close the dropdown and clear the search prefix.
 */
const closeDropdown = () => {
  if (props.search) {
    searchPrefix.value = '';
    searchOptions(); // Clear search results explicitly
  }
  isOpen.value = false;
};

/**
 * Open the dropdown and focus on the search input or the first option.
 */
const openDropdown = () => {
  isOpen.value = true;
  nextTick(() => {
    if (props.search) {
      searchInput.value.focus();
    }
  });
};

/**
 * Toggle the dropdown visibility.
 * Automatically triggers focus on the first option, or the input search when applicable.
 */
const toggleDropdown = () => {
  if (isOpen.value) {
    closeDropdown();
  } else {
    openDropdown();
  }
};

/**
 * Keyboard "esc" press handler.
 * Closes the dropdown and gives focus to the main toggle button.
 * @param {ClickEvent} ev
 */
const handleEsc = () => {
  dropdownButton.value.focus();
  closeDropdown();
};

const onDropdownClick = e => {
  if (e?.target && !dropdown.value.contains(e.target)) {
    closeDropdown();
  }
};

const onFocusIn = ({ target }) => {
  // if focus is on the dropdown button, ignore it.
  // The 'click' event will take care of it.
  if (target !== dropdownButton.value) {
    closeDropdown();
  }
};

/**
 * Pre-selected options are likely handled in an async manner at the parent,
 * thus we also need to watch and pick incoming pre-selections.
 */
watch(() => props.preSelected, () => {
  localSelections.value = cloneDeep(props.preSelected);
});

watch(isOpen, () => {
  if (isOpen.value) {
    window.addEventListener('click', onDropdownClick);
    window.addEventListener('focusin', onFocusIn);
    return; 
  }

  window.removeEventListener('click', onDropdownClick);
  window.removeEventListener('focusin', onFocusIn);
});

onMounted(() => {
  localSelections.value = cloneDeep(props.preSelected);
});
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
  <div
    ref="dropdown"
    class="dropdown"
    @keydown.esc.prevent="handleEsc"
  >
    <button
      ref="dropdownButton"
      class="dropdown__button"
      :class="{ 'dropdown__button--active': isOpen }"
      aria-haspopup="menu"
      :aria-expanded="isOpen"
      :data-automation="`${name}-dropdown`"
      :disabled="disabled"
      @click.prevent="toggleDropdown"
      @keypress.enter.prevent="toggleDropdown"
      @keypress.space.prevent="toggleDropdown"
    >
      <span
        v-if="icon"
        class="dropdown__icon"
        :class="icon"
      />
      {{ label }}
      <span 
        v-if="selectionsLabel"
        class="dropdown__selections-label"
      >
        <span v-if="label">:</span>
        {{ selectionsLabel }}
      </span>
      <span
        v-if="selectionsCount"
        class="dropdown__selections-count"
      >
        {{ selectionsCount }}
      </span>
      <span class="dropdown__label-icon" />
    </button>
    <menu
      v-if="isOpen"
      class="dropdown__menu"
      :class="{ 'lean-left': popupDirection === 'left' }"
      :data-automation="`${name}-dropdown-menu`"
      @click.stop
      @focusin.stop
    >
      <div
        v-if="search"
        class="dropdown__menu-search"
      >
        <label
          class="dropdown__menu-subtitle"
          :for="name"
        >
          {{ searchLabel }}
        </label>
        <input
          :id="name"
          ref="searchInput"
          v-model="searchPrefix"
          type="text"
          :data-automation="`${name}-dropdown-search`"
          @input="searchOptions"
          @keypress.enter.prevent.stop
        >
      </div>

      <CheckboxFieldset
        :name="name"
        :selected="localSelections"
        :options="visibleOptions"
        :preserve-tree="preserveTree"
        @check="selectOption"
        @uncheck="removeSelection"
      />

      <button
        v-if="filterable"
        class="dropdown__menu-clear-btn"
        :data-automation="`dropdown-menu-${name}-clear`"
        @click="clearSelections"
      >
        Clear filter
      </button>
    </menu>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

// Colors according to the latest design
$clear-filters-border: #D9D9D9;
$color-default-border: #DEDEDE; // borders in general, unselected checkbox

.dropdown {
  color: $color-heading;
  position: relative;

  &__button {
    background: $color-light-grey;
    color: $color-dark-grey;
    border-radius: 0.2rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    list-style: none;
    line-height: 1.44rem;
    padding: 0 0.6rem;

    &--active,
    &:hover {
      cursor: pointer;
      color: $color-posit-blue;
      background: $color-primary-light;

      .dropdown__label-icon {
        background-image: url('/images/caret-down-alt2.svg');
      }
    }

    &:disabled {
      color: $color-dark-grey;
      @include control-disabled-input;
      cursor: not-allowed;

      .dropdown__label-icon {
        background-image: url('/images/caret-down-alt.svg');
      }
    }

    &::-webkit-details-marker {
      display:none;
    }
  }

  &__selections-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 10rem;
  }

  &__icon {
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 0.5rem;
    height: 1rem;
    width: 1rem;

    &.sort {
      background-image: url('/images/sort-icon.svg');
    }
  }

  &__label-icon {
    background-position: center center;
    background-image: url('/images/caret-down-alt.svg');
    background-repeat: no-repeat;
    display: inline-block;
    margin-left: 0.2rem;
    height: 1rem;
    width: 1rem;
  }

  &__menu {
    background: #fff;
    border-radius: 0.3rem;
    position: absolute;
    top: 1.6rem;
    left: 0;
    width: 18rem;
    overflow: hidden;
    z-index: 1; // Required due to this being a pop-over

    &.lean-left {
      left: unset;
      right: 0;
    }

    &-subtitle {
      border: 1px solid $color-default-border;
      border-top: none;
      color: $color-dark-grey;
      font-size: 0.8rem;
      width: 100%;
    }

    &-search {
      border: 1px solid $color-default-border;
      border-bottom: none;
      border-radius: 0.3rem 0.3rem 0 0;
      padding: 0.8rem;

      .dropdown__menu-subtitle {
        border: none;
      }

      input {
        border: 1px solid $color-default-border;
        color: $color-dark-grey-3;
        display: block;
        width: 100%;
      }
    }

    &-clear-btn {
      all: unset;
      cursor: pointer;
      background: $color-light-grey-2;
      border: 1px solid $clear-filters-border;
      border-radius: 0 0 0.3rem 0.3rem;
      padding: 0.8rem;
      box-sizing: border-box;
      width: 100%;

      &:hover,
      &:focus {
        cursor: pointer;
        color: $color-posit-blue;
        background: $color-primary-light;
      }
    }
  }
}
</style>

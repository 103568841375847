<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
/**
 * CheckboxFieldset is pretty much a dumb component that's meant to reflect and render what's passed in via props.
 * Stateless, only emits selections change intents via "check" and "uncheck" events and updates only props have changes.
 */

import {
  ref,
  unref,
  computed,
} from 'vue';

// Props
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  selected: {
    type: Array,
    required: true,
  },
  options: {
    type: Array, // [{ label, value, sub (optional) }]
    required: true,
  },
  // By default, current selections are displayed at the top of the list
  // to preserve a tree structure, this prop needs to be enabled.
  preserveTree: {
    type: Boolean,
    default: false,
  },
  // Prop meant to be used internally for nesting specific styling.
  nestingLevel: {
    type: Number,
    default: 0,
  },
});

// Emits
const emit = defineEmits(['check', 'uncheck']);

const checkboxDomRefs = ref([]);
const allCheckboxItems = computed(() => {
  if (props.preserveTree) {
    return [...props.options];
  }
  return [...props.selected, ...props.options];
});

/**
 * Check whether the given option is currently selected or not.
 * It also accounts for broad term selections on nested options (trees).
 * @param {Object} option Option to check.
 * @returns {Boolean}
 */
const isOptionSelected = option => {
  const valueMatch = !!props.selected.find(sel => sel.value === option.value);
  if (!props.preserveTree) {
    return valueMatch;
  }
  // When a deep tree is in use, there can be broad matches that must be accounted for
  const broadMatch = !!props.selected.find(sel =>
    sel.value.toLowerCase() === option.label.toLowerCase());
  return valueMatch || broadMatch;
};

/**
 * Method to emit the proper event when an option is being changed.
 * @param {Object} option The target of the check/uncheck action. { label, value, sub (optional) }
 * @param {Number} index The index of the option changing
 */
const onChange = (option, index) => {
  const ev = isOptionSelected(option) ? 'uncheck' : 'check';
  emit(ev, {
    index,
    option: { ...unref(option) },
  });
};

/**
 * Method to focus on the given option index. Mainly for keyboard navigation purposes.
 * @param {Number} optIndex Option index to focus on.
 * @param {Boolean} isOption Is this an available option item, not a selected option.
 */
const handleKeyboardFocus = (optIndex = 0) => {
  if (checkboxDomRefs.value[optIndex]) {
    checkboxDomRefs.value[optIndex].focus();
  }
};

// Since options can change order via search filtering or when selecting options,
// it is required to re-generate the options refs that are used for keyboard navigation.
const checkboxGenRef = (el, index) => {
  checkboxDomRefs.value[index] = el;
};
</script>

<template>
  <div
    class="checkbox-fieldset"
    :class="`checkbox-fieldset--level-${nestingLevel}`"
    role="listbox"
    tabindex="0"
    :data-automation="`${name}-options`"
    @keydown.down.prevent="handleKeyboardFocus(0)"
  >
    <template
      v-for="(opt, index) in allCheckboxItems"
      :key="`checkbox-${opt.value}-${index}`"
    >
      <label
        :ref="el => checkboxGenRef(el, index)"
        :for="`checkbox-${opt.value}`"
        role="option"
        tabindex="-1"
        class="checkbox-fieldset__option"
        :class="{ selected: isOptionSelected(opt) }"
        :aria-selected="isOptionSelected(opt)"
        :data-automation="`checkbox-${opt.value}`"
        @blur="handleOutsideEv"
        @keypress.enter.prevent.stop="onChange(opt, index)"
        @keypress.space.prevent.stop="onChange(opt, index)"
        @keydown.up.prevent.stop="handleKeyboardFocus(index - 1)"
        @keydown.down.prevent.stop="handleKeyboardFocus(index + 1)"
      >
        <input
          :id="`checkbox-${opt.value}`"
          class="checkbox-fieldset__input"
          type="checkbox"
          tabindex="-1"
          :checked="isOptionSelected(opt)"
          @change="onChange(opt, index)"
        >
        {{ opt.label }}
        <span
          v-if="opt.sub"
          class="checkbox-fieldset__option-sub"
        >
          {{ opt.sub }}
        </span>
      </label>
      <CheckboxFieldset
        v-if="opt.children && opt.children.length"
        :name="`${name}-children`"
        :nesting-level="nestingLevel + 1"
        :selected="selected"
        :options="opt.children"
        :preserve-tree="preserveTree"
        @check="ev => emit('check', ev)"
        @uncheck="ev => emit('uncheck', ev)"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
$entry-height: 2.07rem;
$checkbox-blue: #345d84;

.checkbox-fieldset {

  &--level-0 {
    max-height: calc(($entry-height * 8) + (0.8rem *2));
    overflow-y: auto;
    overscroll-behavior: contain;
  }

  &--level-1 {
    border: 1px solid $color-default-border;
    border-top: none;
    border-bottom: none;
  }

  & > .checkbox-fieldset {
    max-height: unset;
    padding-left: 1rem;

    .checkbox-fieldset__option {
      border: none;
    }
  }

  &__option {
    border: 1px solid $color-default-border;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    padding: 0 0.8rem;
    line-height: $entry-height;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    & + .checkbox-fieldset__option {
      border-top-width: 0;
    }

    &.selected {
      border-color: $checkbox-blue;
    }

    &:hover {
      cursor: pointer;
      background: $color-options-focus-background;
    }

    &:focus-within {
      background: $color-options-focus-background;
    }

    &-sub{
      color: $color-dark-grey;
      margin-left: 0.3rem;
    }
  }

  &__input[type="checkbox"] {
    position: relative;
    margin-right: 0.6rem;
    outline: 0.08rem solid $color-default-border;

    &:checked {
      background: white;
      outline-color: $checkbox-blue;

      &::after {
        border-color: $checkbox-blue;
        left: 0.2rem;
        top: 0;
      }
    }
  }
}
</style>

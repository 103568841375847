// Copyright (C) 2023 by Posit Software, PBC.

function valueOrDefault(value, defaultValue) {
  return value === undefined || value === null ? defaultValue : value;
}

// Environment reflects the backend struct `Environment` in the environment API
export class Environment {
  constructor(obj = {}) {
    // NOTE: using this format to stay away from 'name' violating 'no-shadow' lint rule vs. decomposition
    this.id = valueOrDefault(obj.id, ''); // read-only string
    this.guid = valueOrDefault(obj.guid, ''); // read-only string
    this.createdTime = valueOrDefault(obj.createdTime, ''); // read-only time string
    this.updatedTime = valueOrDefault(obj.updatedTime, ''); // read-only time string
    this.title = valueOrDefault(obj.title, ''); // nullable string
    this.description = valueOrDefault(obj.description, ''); // nullable string
    this.clusterName = valueOrDefault(obj.clusterName, 'Kubernetes'); // read-only fixed string - always 'Kubernetes'
    this.name = valueOrDefault(obj.name, ''); // read-only string
    this.environmentType = valueOrDefault(obj.environmentType, 'Kubernetes'); // read-only fixed string - always 'Kubernetes'
    this.matching = valueOrDefault(obj.matching, 'any'); // string enum
    // `any` (the default) indicates that the image can be selected by Connect automatically,
    // _or_ targeted in the bundle's manifest.
    //
    // `exact` indicates the image must be explicitly asked for in the bundle's manifest.
    //
    // `none` indicates that the image should never be selected by Posit Connect.
    this.supervisor = valueOrDefault(obj.supervisor, ''); // nullable string

    // This constructor may be used via the UX or as part of the API flow.
    // When used via the UX, we'll have installations already assembled.
    if (obj.installations) {
      this.installations = obj.installations;
    } else {
      this.installations = [];
    }
    this.installationStrings = {
      Python: {
        asc: '',
        desc: '',
      },
      Quarto: {
        asc: '',
        desc: '',
      },
      R: {
        asc: '',
        desc: '',
      },
      TensorFlow: {
        asc: '',
        desc: '',
      },
    };

    // When used as part of the API flow, we'll have to assemble installations
    // from the parts.
    // Convert python, quarto, r, and tensorflow installations into one single bucket,
    // with some additional conversions for the version strings into granular values
    if (obj.python && obj.python.installations) {
      obj.python.installations.forEach(apiInstallObj => {
        const installation = new Installation({
          type: 'Python',
          path: apiInstallObj.path,
          versionStr: apiInstallObj.version,
        });
        this.installations.push(installation);
      });
    }
    if (obj.quarto && obj.quarto.installations) {
      obj.quarto.installations.forEach(apiInstallObj => {
        const installation = new Installation({
          type: 'Quarto',
          path: apiInstallObj.path,
          versionStr: apiInstallObj.version,
        });
        this.installations.push(installation);
      });
    }
    if (obj.r && obj.r.installations) {
      obj.r.installations.forEach(apiInstallObj => {
        const installation = new Installation({
          type: 'R',
          path: apiInstallObj.path,
          versionStr: apiInstallObj.version,
        });
        this.installations.push(installation);
      });
    }
    if (obj.tensorflow && obj.tensorflow.installations) {
      obj.tensorflow.installations.forEach(apiInstallObj => {
        const installation = new Installation({
          type: 'TensorFlow',
          path: apiInstallObj.path,
          versionStr: apiInstallObj.version,
        });
        this.installations.push(installation);
      });
    }
  }

  toJSON(create = false) {
    // split single installation pool into separate ones as required by API
    const pythonInstalls = this.installations.filter(installation => installation.type === 'Python');
    const quartoInstalls = this.installations.filter(installation => installation.type === 'Quarto');
    const rInstalls = this.installations.filter(installation => installation.type === 'R');
    const tensorflowInstalls = this.installations.filter(installation => installation.type === 'TensorFlow');

    const jsonObj = {
      // only includes attributes which can be sent to API
      title: this.title,
      description: this.description,
      matching: this.matching,
      supervisor: this.supervisor,
      python: {
        installations: pythonInstalls.map(element => element.toJSON()),
      },
      quarto: {
        installations: quartoInstalls.map(element => element.toJSON()),
      },
      r: {
        installations: rInstalls.map(element => element.toJSON()),
      },
      tensorflow: {
        installations: tensorflowInstalls.map(element => element.toJSON()),
      },
    };
    // additional attributes only allowed on create operations.
    if (create) {
      jsonObj.name = this.name;
      jsonObj.clusterName = this.clusterName;
    }
    return jsonObj;
  }
}

// Installation reflects the backend struct `Installation` in the environment API
export class Installation {
  constructor({
    type = '', // string enum: 'Python', 'Quarto', 'R', 'TensorFlow'
    path = '', // string
    id = -1,
    versionStr = '', // string
    version = { // object
      major: 0,
      minor: 0,
      patch: 0,
    },
  }) {
    this.type = type;
    this.path = path;
    this.id = id;
    // This constructor may be used via the UX or as part of the API flow.
    // When used via the API, we'll have to extract version from the string.
    if (versionStr !== '') {
      this.version = extractVersionObj(versionStr);
    } else {
      // when used via the UX, we'll already be dealing with a version object
      this.version = version;
    }
  }

  toJSON() {
    return {
      path: this.path,
      version: versionObjToString(this.version)
    };
  }
}

export function versionObjToString(version) {
  return `${version.major}.${version.minor}.${version.patch}`;
}

export function extractVersionObj(versionString) {
  const parts = versionString.split('.');
  const versionObj = {
    major: 0,
    minor: 0,
    patch: 0,
  };
  let result;
  if (parts[0] !== undefined) {
    result = parseInt(parts[0], 10);
    if (!isNaN(result)) {
      versionObj.major = result;
    }
  }
  if (parts[1] !== undefined) {
    result = parseInt(parts[1], 10);
    if (!isNaN(result)) {
      versionObj.minor = result;
    }
  }
  if (parts[2] !== undefined) {
    result = parseInt(parts[2], 10);
    if (!isNaN(result)) {
      versionObj.patch = result;
    }
  }
  return versionObj;
}

<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div
    role="status"
    class="spinner"
    data-automation="loading-spinner"
    aria-busy="true"
    aria-label="Loading..."
  >
    <div class="dot one" />
    <div class="dot two" />
    <div class="dot three" />
    <div class="dot four" />
  </div>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.dot {
  width: 40%;
  height: 40%;
  border-radius: 50%;
  position: absolute;
  -webkit-animation: progressPulse 2s infinite ease-in-out;
  animation: progressPulse 2s infinite ease-in-out;

  &.one {
    top: 0;
    left: 30%;
    background-color: $color-primary;
  }

  &.two {
    top: 30%;
    left: 0;
    -webkit-animation-delay: -1.5s;
    animation-delay: -1.5s;
    background-color: $color-primary;
  }

  &.three {
    bottom: 0;
    left: 30%;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
    background-color: $color-primary;
  }

  &.four {
    top: 30%;
    right: 0;
    -webkit-animation-delay: -.5s;
    animation-delay: -.5s;
    background-color: $color-primary;
  }
}

.spinner {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;

  -webkit-animation: rotate 2s infinite linear;
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); }
}

@keyframes progressPulse {
  0%, 100% {
    -webkit-transform: scale(.1);
    transform: scale(.1);
    opacity: .1;
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
</style>

// Copyright (C) 2023 by Posit Software, PBC.

import axios from 'axios';
import { User } from '@/api/dto/user';
import { keysToCamel } from '@/api/transform';
import { apiPath } from '@/utils/paths';

/**
 * Get the list of email recipients (subscribers) for a given variant.
 * @param {Number} variantId - The variant id
 * @returns {Array} The list of subscribers.
 */
export function getSubscribers(variantId) {
  const url = apiPath(`variants/${variantId}/subscribers`);
  return axios.get(url).then(({ data }) => {
    const prepData = keysToCamel(data);
    prepData.subscriptions = prepData.subscriptions.map(u => new User(u));
    return prepData;
  });
}

/**
 * Subscribe a user as an email recipient for a given variant.
 * @param {Number} variantId - The variant id
 * @param {String} guid - The user's guid to be subscribed
 * @returns {Promise} The resolution of the request
 */
export function subscribeUser(variantId, guid) {
  const url = apiPath(`variants/${variantId}/subscribers`);
  return axios.post(url, { guid })
    .then(({ data }) => keysToCamel(data));
}

/**
 * Unsubscribe a user for a given variant, to not be an email recipient.
 * @param {Number} variantId - The variant id
 * @param {String} guid - The user's guid to be unsubscribed
 * @returns {Promise} The resolution of the request
 */
export function unsubscribeUser(variantId, guid) {
  const url = apiPath(`variants/${variantId}/unsubscribe`);
  return axios.post(url, { guid })
    .then(({ data }) => keysToCamel(data));
}

/**
 * Remove a user as subscriber for a given variant, to not be an email recipient.
 * Pretty much the same as unsubscribeUser(). But with two substantial differences.
 * - This endpoint allows non-editor users to remove themselves.
 * - Prevents leaking opt-out information on errors.
 * @param {Number} variantId - The variant id
 * @param {String} guid - The user's guid to be unsubscribed
 * @returns {Promise} The resolution of the request
 */
export function removeSubscriber(variantId, guid) {
  const url = apiPath(`variants/${variantId}/subscribers/${guid}`);
  return axios.delete(url)
    .then(({ data }) => keysToCamel(data));
}

// Copyright (C) 2024 by Posit Software, PBC.

// PublicContentValidationStatus holds singleton values and a construction function for raw
// publicContentStatus values.
const PublicContentStatuses = {
  Unrestricted: 'unrestricted',
  Ok: 'ok',
  Warning: 'warning',
  Restricted: 'restricted',
  Unlicensed: 'unlicensed',
  None: 'none',
  Err: null,

  of(value) {
    switch (value) {
      case 'ok':
        return PublicContentStatuses.Ok;
      case 'warning':
        return PublicContentStatuses.Warning;
      case 'restricted':
        return PublicContentStatuses.Restricted;
      case 'unrestricted':
        return PublicContentStatuses.Unrestricted;
      case 'unlicensed':
        return PublicContentStatuses.Unlicensed;
      case 'none':
        return PublicContentStatuses.None;
      default:
        return PublicContentStatuses.Err;
    }
  },
};

export default Object.freeze(PublicContentStatuses);

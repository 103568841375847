<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    class="band"
  >
    <div class="bandContent mainPage">
      <h1>
        <!-- eslint-disable vuejs-accessibility/no-autofocus -->
        <span
          class="autofocus-header"
          tabindex="-1"
          autofocus
        >
          Connect to R
        </span>
      </h1>

      <ActivateToken
        v-if="canPublish"
        :token="$route.params.token"
        :username="currentUser.username"
      />
      <CannotPublish
        v-else
        :username="currentUser.username"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ActivateToken from './ActivateToken';
import CannotPublish from './CannotPublish';

export default {
  name: 'TokenSync',
  components: {
    ActivateToken,
    CannotPublish,
  },
  data() {
    return {
      tokenStatus: false,
    };
  },
  computed: {
    canPublish() {
      return this.currentUser.canPublish();
    },
    ...mapState({
      currentUser: state => state.currentUser.user,

    })
  },
};
</script>

<style lang="scss" scoped>
.autofocus-header:focus-visible {
  outline: 2px solid #419599;
  border-radius: 2px;
}
</style>

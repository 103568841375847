<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div class="log-line">
    <span 
      v-for="(part, i) in logParts"
      :key="i"
      :class="{ 'highlighted': part.highlighted }"
    >
      {{ part.text }}
    </span>
  </div>
</template>

<script>
import stripansi from 'strip-ansi';
const escapeRegEx = str => str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');

export default {
  name: 'Logline',
  props: {
    searchTerm: {
      type: String,
      default: null,
    },
    data: {
      type: String,
      required: true,
    },
  },
  emits: ['count'],
  data() {
    return {
      matchCount: 0,
      logParts: [],
    };
  },
  computed: {
  },
  watch: {
    searchTerm() {
      this.searchedLogParts();
    }
  },
  mounted() {
    this.searchedLogParts();
  },
  methods: {
    searchedLogParts() {
      let cleanedData = this.data.replaceAll('\r', ' ');
      cleanedData = stripansi(cleanedData);

      const parts = [];
      this.matchCount = 0;

      if (this.searchTerm) {
        const regex = new RegExp(escapeRegEx(this.searchTerm), 'gi');
        const matches = [...cleanedData.matchAll(regex)];
        this.matchCount = matches.length;

        if (matches.length > 0) {
          // The startIndex marks where the parts are taken apart
          let startIndex = 0;

          matches.forEach(match => {
            // This part only separates beginning point of the search term
            parts.push({
              text: cleanedData.substring(startIndex, match.index),
              highlighted: false,
            });
            // This part consists of the actual matching search term
            parts.push({
              text: match[0],
              highlighted: true,
            });

            startIndex = match.index + match[0].length;
          });

          // This part only separates end point of the search term
          parts.push({
            text: cleanedData.substring(startIndex),
            highlighted: false,
          });
        } else {
          // This part is the remaining portion of the text which does not match,
          // but is part of the text
          parts.push({
            text: cleanedData,
            highlighted: false,
          });
        }
      } else {
        // If there is nothing in the search input, the individual text is pushed into parts[]
        parts.push({
          text: cleanedData,
          highlighted: false,
        });

        this.matchCount = 0;
      }

      this.logParts = parts;
      this.$emit('count', this.matchCount);
    },
  },
};
</script>

<style lang="scss">
@import 'Styles/shared/_colors';

.highlighted {
  background-color: $color-highlight-inactive;
  border-bottom: 1px solid $color-dark-grey;
  border-radius: 2px;

  &.current {
    background-color:$color-highlight-active;
    border-bottom: 2px solid $color-dark-grey-3;
  }
}
</style>

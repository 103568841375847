<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Install rsconnect-python
      </StepHeading>

      <p>
        To publish {{ primaryKind }} content to {{ displayName }}, first install
        the <code>rsconnect-python</code> package in your Python environment.
      </p>

      <div class="wizard-panel__section">
        <h3 class="wizard-panel__subtitle">
          Install directly from pip:
        </h3>

        <div class="python-rsconnect__install">
          <code class="wizard-panel__copy-code">{{ connectCode }}</code>

          <CopyButton
            class="python-rsconnect__install-copy"
            :copy-text="connectCode"
          />
        </div>
      </div>

      <ul class="wizard-panel__links">
        <li>
          <a
            href="https://pypi.org/project/rsconnect-python/"
            target="_blank"
          >
            Learn more about the rsconnect-python package in PyPI
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CopyButton from '@/components/CopyButton';
import {
  QUARTO,
  QUARTO_PYTHON,
  QUARTO_PYTHON_SCRIPT,
  QUARTO_R,
  QUARTO_R_SCRIPT,
  QUARTO_WEBSITE,
} from '@/constants/contentTypes';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import { mapState } from 'vuex';
import PanelMixin from '../PanelMixin';

export default {
  name: 'PythonRsconnect',
  components: {
    CopyButton,
    StepHeading,
  },
  mixins: [PanelMixin],
  computed: {
    primaryKind() {
      switch (this.copyFrom.type) {
        case QUARTO:
        case QUARTO_R:
        case QUARTO_R_SCRIPT:
        case QUARTO_PYTHON:
        case QUARTO_PYTHON_SCRIPT:
        case QUARTO_WEBSITE:
          return 'Quarto';
        default:
          return 'Python';
      }
    },
    connectCode() {
      return 'pip install rsconnect-python';
    },
    ...mapState({
      displayName: state => state.server.settings.systemDisplayName,
    })
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
.python-rsconnect {
  &__install {
    display: flex;
    margin-top: 0.5rem;

    &-copy {
      margin-left: 0.25rem;
    }
  }
}
</style>

// Copyright (C) 2023 by Posit Software, PBC.

import axios from 'axios';

import { apiV1Path } from '@/utils/paths';
import { keysToCamel } from '@/api/transform';
import { ServiceAccounts } from '@/api/dto/system';

export const getServiceAccounts = () => {
  const url = apiV1Path(`system/offhost/service-accounts`);
  return axios
    .get(url)
    .then(({ data }) => new ServiceAccounts(keysToCamel(data)));
};

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModalForm
    :active="true"
    :closeable="false"
    :subject="`For security purposes, please re-enter the password associated with your ${authenticationName} credentials.`"
    data-automation="cpd-dialog"
    @submit="onSubmit"
  >
    <template #content>
      <fieldset :disabled="processing">
        <div
          v-if="status.show"
          class="rs-field"
        >
          <EmbeddedStatusMessage
            :message="status.message"
            :type="status.type"
            data-automation="cpd-status-message"
            @close="hideStatusMessage"
          />
        </div>
        <RSInputPassword
          ref="passwordInput"
          v-model="form.password"
          label="Password"
          name="cpd-password"
          data-automation="cpd-password"
          autocomplete="off"
        />
        <RSInputText
          v-if="challengeRequired"
          v-model="form.challengeResponse"
          label="Captcha Code"
          name="cpd-challenge-response"
          data-automation="cpd-challenge-response"
          autocomplete="off"
        />
        <div
          v-if="challenge.image.mimeType"
          class="rs-field center"
        >
          <img
            alt="Captcha Image"
            :src="imageData"
            class="rsc-full-width"
          >
        </div>
        <div
          v-if="challenge.audio.mimeType"
          class="rs-field center"
        >
          <!-- eslint-disable vuejs-accessibility/media-has-caption -->
          <audio
            controls
            class="rsc-full-width"
          >
            Captcha audio is unsupported on this browser.
            <source
              :src="audioData"
              :type="challenge.audio.mimeType"
            >
          </audio>
          <!-- eslint-enable vuejs-accessibility/media-has-caption -->
        </div>
      </fieldset>
    </template>
    <template #controls>
      <RSButton
        id="cpd-submit"
        label="Confirm Password"
        :disabled="processing"
        data-automation="cpd-submit"
      />
    </template>
  </RSModalForm>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSInputPassword from '@/elements/RSInputPassword';
import RSInputText from '@/elements/RSInputText';
import RSModalForm from '@/elements/RSModalForm';

import { getCaptcha, login } from '@/api/authentication';
import { safeAPIErrorMessage } from '@/api/error';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';

export default {
  name: 'ConfirmPasswordDialog',
  components: {
    EmbeddedStatusMessage,
    RSButton,
    RSInputText,
    RSInputPassword,
    RSModalForm,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    authenticationName: {
      type: String,
      required: true,
    },
    challengeRequired: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['done'],
  data() {
    return {
      processing: false,
      status: {
        show: false,
        message: null,
        type: null,
      },
      form: {
        password: '',
        challengeResponse: '',
      },
      challenge: {
        challengeId: null,
        audio: {
          mimeType: null,
          data: null,
        },
        image: {
          mimeType: null,
          data: null,
        },
      },
    };
  },
  computed: {
    imageData() {
      const { mimeType, data } = this.challenge.image;
      return `data:${mimeType};base64,${data}`;
    },
    audioData() {
      const { mimeType, data } = this.challenge.audio;
      return `data:${mimeType};base64,${data}`;
    },
  },
  created() {
    if (!this.challengeRequired) {
      return Promise.resolve();
    }

    const timeoutId = setTimeout(() => { this.showStatusMessage('activity', 'Loading captcha...'); }, 300);

    return this.loadCaptchaData()
      .catch(err => {
        this.showStatusMessage('error', safeAPIErrorMessage(err));
      })
      .finally(() => {
        clearTimeout(timeoutId);
        if (this.status.type === 'activity') {
          // only hide if status is no longer an activity message - i.e. error might be shown
          this.hideStatusMessage();
        }
      });
  },
  mounted() {
    this.focusPassword();
  },
  methods: {
    focusPassword() {
      this.$nextTick().then(() => this.$refs.passwordInput?.focusElement());
    },
    onSubmit() {
      this.processing = true;
      this.showStatusMessage('activity', 'Confirming Password...');

      return login({
        username: this.username,
        password: this.form.password,
        challengeId: this.challenge.challengeId,
        challengeResponse: this.form.challengeResponse,
      })
        .then(() => {
          this.$emit('done');
        })
        .catch(err => {
          this.form.password = '';
          this.showStatusMessage('error', safeAPIErrorMessage(err));
          if (this.challengeRequired) {
            this.form.challengeResponse = '';
            return this.loadCaptchaData();
          }
        })
        .finally(() => {
          this.processing = false;
          this.focusPassword();
        });
    },
    loadCaptchaData() {
      return getCaptcha()
        .then(({ challengeId, challengeData = [] }) => {
          this.challenge.challengeId = challengeId;
          challengeData.forEach(({ mimeType, payload }) => {
            if (mimeType.startsWith('image')) {
              this.challenge.image.mimeType = mimeType;
              this.challenge.image.data = payload;
            }

            if (mimeType.startsWith('audio')) {
              this.challenge.audio.mimeType = mimeType;
              this.challenge.audio.data = payload;
            }
          });
        })
        .catch(err => {
          this.showStatusMessage('error', safeAPIErrorMessage(err));
        });
    },
    showStatusMessage(type, message) {
      this.status.message = message;
      this.status.type = type;
      this.status.show = true;
    },
    hideStatusMessage() {
      this.status.show = false;
      this.status.message = null;
      this.status.type = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.rsc-full-width {
  width: 100%;
}
</style>

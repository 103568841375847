<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <LandingPage
    v-if="serverSettings.customizedLanding"
    data-test="custom-landing-page"
  />
  <div
    v-else
    id="homeContent"
    class="limitBandContentWidth"
    data-test="home-content"
  >
    <div class="band first rightText">
      <div class="bandContent vCenter">
        <div class="blurb">
          <h1
            class="tagline"
            data-automation="welcome-tagline"
          >
            Welcome to Posit Connect
          </h1>
          <div>
            Distribute data science across your organization.
          </div>
          <div
            v-if="serverSettings.authentication.handlesLogin"
            class="actions"
          >
            <!-- eslint-disable vuejs-accessibility/no-autofocus -->
            <RouterLink
              :to="{ name: 'login_view' }"
              class="bigButton"
              data-test="get-started-button"
              autofocus
            >
              Get Started
            </RouterLink>
          </div>
        </div>
      </div>
    </div>

    <div class="band second rightText">
      <div class="bandContent vCenter">
        <div class="blurb">
          <div>
            One button deployment of:
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://shiny.posit.co/"
                >Shiny applications</a>
              </li>
              <li>
                Python applications built with
                <a
                  target="_blank"
                  href="https://flask.palletsprojects.com/"
                >Flask</a>, <a
                  target="_blank"
                  href="https://dash.plotly.com/"
                >Dash</a>,
                <a
                  target="_blank"
                  href="https://bokeh.org/"
                >Bokeh</a>, or
                <a
                  target="_blank"
                  href="https://streamlit.io/"
                >Streamlit</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://rmarkdown.rstudio.com/"
                >R Markdown documents</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://jupyter.org/"
                >Jupyter Notebooks</a>
              </li>
              <li>Static plots and graphs</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="band third rightText">
      <div class="bandContent vCenter">
        <div class="blurb">
          <div>
            Manage and control access to the work you've shared with others -
            and easily see the work they've shared with you
          </div>
        </div>
      </div>
    </div>

    <div class="band fourth rightText">
      <div class="bandContent vCenter">
        <div class="blurb">
          <div>
            "Hands free" scheduling of updates to your documents and automatic email distribution
          </div>
        </div>
      </div>
    </div>

    <div class="band fifth">
      <div class="bandContent">
        <div class="blurb">
          <div>
            For more information on running Posit Connect in your organization please visit
            <a
              target="_blank"
              href="https://posit.co/products/enterprise/connect/"
            >https://posit.co/products/enterprise/connect/</a>.
          </div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter';
import LandingPage from '@/components/Landing/LandingPage';
import {
  SHOW_ERROR_MESSAGE,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { SHRINK_HEADER } from '@/store/modules/navigation';
import { mapActions, mapMutations, mapState } from 'vuex';
import { RouterLink } from 'vue-router';

export default {
  name: 'WelcomeView',
  components: {
    AppFooter,
    LandingPage,
    RouterLink,
  },
  computed: {
    messages() {
      return {
        loginError: 'There was a problem logging you in. Please try again. ' +
          `If the problem persists, please ask your ${this.serverSettings.systemDisplayName}'s administrator for assistance.`,
        policyError: 'Your username is not allowed because it is prohibited by policy. ' +
          `Please ask your ${this.serverSettings.systemDisplayName} administrator for assistance.`,
      };
    },
    ...mapState({
      serverSettings: state => state.server.settings,
    })
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions({
      statusInfoMessage: SHOW_INFO_MESSAGE,
      statusErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    ...mapMutations({
      shrinkMainHeader: SHRINK_HEADER
    }),
    init() {
      this.setErrorMessage();
      this.shrinkMainHeader(this.serverSettings.customizedLanding);

      if (this.serverSettings.publicWarning) {
        this.statusInfoMessage({ message: this.serverSettings.publicWarning, autoHide: false });
      }
    },
    setErrorMessage() {
      const urlParams = window.location.hash;
      if (urlParams.includes('msg')) {
        const defaultErrorMessage = 'An unexpected error has occurred';
        const errorMessage =
          this.messages[urlParams.split('=')[1]] || defaultErrorMessage;
        this.statusErrorMessage({ message: errorMessage });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.vCenter {
  position: relative;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#homeContent {
  padding-top: 140px;
  background-color: $color-white;

  .band {

    background-repeat: no-repeat;
    background-position: center top;

    &.leftText {
      text-align: left;
    }

    &.rightText {
      text-align: right;

      .vCenter {
        align-items: flex-end;
      }
    }

    &.first {
      height: 600px;
      background-color: $color-white;
      background-image: url(Images/elements/connectHome_01.jpg);
      background-size: 1800px 600px;
    }

    &.second {
      height: 480px;

      overflow: hidden;

      background-color: $color-light-grey;

      background-image: url(Images/elements/connectHome_02.jpg);
      background-size: 1800px 480px;

    }

    &.third {
      height: 480px;
      background-image: url(Images/elements/connectHome_03.jpg);
      background-size: 1800px 480px;
    }

    &.fourth {
      height: 340px;
      background-color: $color-light-grey;
      background-image: url(Images/elements/connectHome_04.jpg);
      background-size: 1800px 340px;
    }

    &.fifth {
      padding-top: 60px;
      padding-bottom: 60px;

      .blurb {
        width: auto;
        min-height: 0;
        @include force-breaks();
      }
    }

    .tagline {
      padding-top: 100px;
      font-size: 36px;
      font-weight: 400;
      margin-bottom: 16px;
    }

    .blurb {
      display: inline-block;
      text-align: left;
      width: 490px;
      min-height: 160px;
      font-size: 24px;
      line-height: 40px;
    }

    .actions {
      margin-top: 40px;
    }

    .bigButton {
      padding: 15px 50px;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 1px;
      border: none;

      border-radius: $medium-border-radius;
      background-color: $color-alternate;
      color: $color-white;

      @include small-drop-shadow();
      @include transition-property(background-color);
      @include normal-transition-duration();

      text-decoration: none;
      cursor: pointer;

      &:hover {
        background-color: $color-alternate-dark;
      }

      &:focus {
        @include control-focus();
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  ul {
    list-style: disc outside none;
    margin-left: 30px;
    text-align: left;
  }
}

@media screen and (max-width: 1023px) {

  .vCenter {
    position: relative;
    height: auto;
    min-height: 0;
    display: block;
    padding: 100px 0px;
  }

  #homeContent {
    overflow-x: hidden;

    .band {

      &.first,
      &.second,
      &.third,
      &.fourth,
      &.fifth {
        height: auto;
        background-image: none;
      }

      &.rightText,
      &.leftText {
        text-align: center;
      }

      .tagline {
        padding-top: 0;
      }

      .blurb {
        width: auto;
        text-align: center;
        min-height: 0;
      }
    }
  }
}
</style>

<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
  <div
    ref="languageSelector"
    class="language-selector"
    tabindex="0"
    @keyup="onKeyUp"
  >
    <div
      v-for="language in languages"
      :key="language"
      class="radioButton"
      :data-automation="`language-${language}`"
    >
      <input
        :id="`language-${language}`"
        :checked="selectedLanguage === language"
        :value="language"
        name="language-selector"
        tabindex="-1"
        type="radio"
        @change="selectLanguage(language)"
      >
      <label
        :for="`language-${language}`"
        tabindex="-1"
        @focus="focusContainer"
      >
        {{ languageLabel(language) }}
      </label>
    </div>
  </div>
</template>

<script>
import startCase from 'lodash/startCase';

export const R = 'r';
export const PYTHON = 'python';
export const QUARTO = 'quarto';

export default {
  name: 'LanguageSelector',
  props: {
    selectedLanguage: {
      type: String,
      default: PYTHON,
    },
  },
  emits: ['selected'],
  data() {
    return {
      languages: [
        PYTHON,
        R,
        QUARTO,
      ],
    };
  },
  mounted() {
    this.focusContainer();
  },
  methods: {
    focusContainer() {
      this.$refs.languageSelector.focus();
    },
    selectLanguage(languageId) {
      this.$emit('selected', languageId);
    },
    languageLabel(language) {
      return startCase(language);
    },
    changeLanguage(language, step) {
      const cur = this.languages.findIndex(each => (each === this.selectedLanguage));
      // Adding this.languages.length compensates for negative steps and the
      // chance of cur==-1. Assumes we have at least two languages.
      const pos = (cur + this.languages.length + step) % this.languages.length;
      return this.languages[pos];
    },
    nextLanguage(language) {
      return this.changeLanguage(language, 1);
    },
    prevLanguage(language) {
      return this.changeLanguage(language, -1);
    },
    onKeyUp(keyEvent) {
      const nextKeys = ['ArrowRight', 'ArrowDown'];
      const prevKeys = ['ArrowLeft', 'ArrowUp'];
      if (nextKeys.includes(keyEvent.key)) {
        this.selectLanguage(this.nextLanguage(this.selectedLanguage));
      } else if (prevKeys.includes(keyEvent.key)) {
        this.selectLanguage(this.prevLanguage(this.selectedLanguage));
      }
    },
  },
};
</script>

<style lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

  .language-selector {
    border-radius: .4rem;
    display: flex;
    margin-top: -.6rem;
    outline: none;

    &:focus {
      @include control-focus;
    }

    .radioButton {
      &:first-of-type {
        label {
          border-radius: .3rem 0 0 .3rem;
          border-right: none;
        }
      }

      &:last-of-type {
        label {
          border-radius: 0 .3rem .3rem 0;
          border-left: none;
        }
      }

      input[type=radio] {
        display: none;

        &:checked+label {
          background-color: $color-light-blue;
        }
      }

      label {
        display: block;
        font-size: 14px;
        padding: .75em 1em;
        background-color: $color-white;
        border: 1px solid $color-dialog-border;
        outline: none;
      }
    }
  }
</style>

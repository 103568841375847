// Copyright (C) 2023 by Posit Software, PBC.

const DEFAULT_SYSTEM_NAME = 'Posit Connect';

/**
 * Check if a value is the default system name.
 * @param {String} sysname The system name to verify.
 * @returns {Boolean} Whether the name passed is the default system name.
 */
export const isDefaultSystemName = sysname => (sysname === DEFAULT_SYSTEM_NAME);

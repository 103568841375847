// Copyright (C) 2022 by Posit Software, PBC.

export function toCamelCase(str) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_match, chr) => chr.toUpperCase());
}

export function timeElapsedSince(date) {
  const now = new Date();
  return {
    inHours: Math.trunc((now - date) / (1000 * 60 * 60)),
    inMinutes: Math.trunc((now - date) / (1000 * 60)),
  };
}

export function makeReportDocumentHTML(report) {
  const doc = `
<head>
  <meta charset="UTF-8">
  <style type="text/css">
    body {
      font-family: "Lato", sans-serif;
      font-size: 14px;
      margin: 20px;
    }

    code, pre {
      font-family: "Source Code Pro", monospace;
    }

    h1 {
      font-size: 2.4em;
      margin: .75em 0 .5em 0;
    }

    h2 {
      font-size: 2.0em;
      margin: 2em 0 .5em 0;
    }

    h3 {
      font-size: 1.6em;
      margin: .75em 0 .5em 0;
    }

    h4 {
      font-size: 1.2em;
      margin: .75em 0 .5em 0;
    }

    h5 {
      font-size: 1.0em;
      margin: .75em 0 .5em 0;
    }

    p {
      line-height: 0.5em;
      margin-bottom: 1em;
    }

    ul, ol {
      line-height: 1.6em;
      padding-left: 2em;
      margin-bottom: 1em;
    }

    ul {
      list-style: disc outside none;
    }

    ol {
      list-style: decimal outside none; }
      ol.groovyBaby {
        list-style: none;
        counter-reset: list-counter; }
        ol.groovyBaby li {
          counter-increment: list-counter; }
          ol.groovyBaby li:before {
            content: counter(list-counter);
            width: 16px;
            height: 16px;
            text-align: center;
            margin-right: 10px;
            color: #fff;
            background-color: #808080;
            display: inline-block;
            border-radius: 8px;
            font-size: 9px;
            line-height: 16px;
            vertical-align: text-bottom; }

    pre, code {
      font-family: "Source Code Pro", monospace;
      background-color: #f8f8f8; }

    pre {
      padding: 10px;
      line-height: 2em;
      margin-bottom: 1em;
      word-wrap: normal;
      word-break: normal;
      border-radius: 3px;
      border: 1px solid #e4e4e4;
      overflow-x: auto; }
      pre code {
        display: inline;
        padding: 0;
        margin: 0;
        background-color: inherit;
        border-radius: 0;
        border: none; }

    code {
      color: #606060;
      display: inline-block;
      margin: 1px 3px;
      padding: 1px 2px;
      background-color: #f8f8f8;
      border-radius: 3px;
    }
    
    /* Here through "End replicated styles" should be present in
    makeReportDocumentHTML() and SystemCheckRunReport.vue */
    ul {
      padding-left: 20px;
    }

    li {
      margin: 0px !important;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 1rem !important;
      margin-bottom: 0.5rem !important;
      font-weight: 500;
    }

    pre {
      line-height: 1.5rem;
    }

    .passed {
      color: hsl(115, 73%, 31%);
      font-weight: 700;
    }

    .failed {
      color: hsl(40, 79%, 34%);
      font-weight: 700;
    }

    .result-header {
      display: flex;
      align-items: baseline;
      justify-content: start;
      gap: 1rem;
      margin-top: 20px;
    }

    .result-link {
      cursor: pointer;
    }

    .result-link:hover {
        text-decoration: underline;
    }

    .result-error-text {
      font-weight: 700;
    }

    .back-to-top {
      position: fixed;
      right: 32px;
      bottom: 20px;
      background: white;
      border-radius:13px;
      height: 26px;
      line-height: 26px;
      display: inline-block;
      text-align: center;
      padding-right: 8px;
    }

    .back-to-top:hover {
      cursor: pointer;
    }

    .back-to-top [role=img] {
      font-weight: 700;
      background:#333333;
      color: white;
      border-radius:50%;
      height: 26px;
      width: 26px;
      line-height: 26px;
      display: inline-block;
      text-align: center;
      margin-right: 0px;
    }

    .args {
      font-size: 0.9rem;
      color: #555;
    }
      /* End replicated styles */
    </style>
</head>
<body>
${report}
</body>`;
  return doc;
}

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    subject="Remove viewer or collaborator"
    @close="$emit('close')"
  >
    <template #content>
      <div>
        <p
          v-if="isAdmin"
        >
          Are you sure you want to remove yourself? You may no longer be able to
          access the content, but will still be able to change application settings.
        </p>
        <p
          v-else
        >
          Are you sure you want to remove yourself?
          You may no longer be able to access the content.
        </p>
      </div>
    </template>

    <template #controls>
      <RSButton
        class="cancel-button"
        data-automation="remove-principal-warning-dialog-cancel"
        label="Cancel"
        type="secondary"
        @click="$emit('close')"
      />

      <RSButton
        data-automation="remove-principal-warning-dialog-submit"
        label="OK"
        @click="$emit('confirm')"
      />
    </template>
  </RSModal>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';

export default {
  name: 'RemovePrincipalWarning',
  components: { RSButton, RSModal },
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'confirm']
};
</script>

<style scoped lang='scss'>
.cancel-button {
  margin-right: 1rem;
}
</style>

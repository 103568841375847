<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    v-if="showModal"
    data-automation="reqperm-dialog"
    :subject="modalSubject"
    close-button-label="Close Modal"
    :active="showModal"
    @close="$emit('close')"
  >
    <template #content>
      <p>
        Are you sure you want to request "{{ privilege }}"
        Permissions on this server? This will contact the
        {{ systemDisplayName }} administrator on your
        behalf to request these additional privileges.
      </p>
    </template>
    <template #controls>
      <RSButton
        data-automation="reqperm-cancel-btn"
        label="Cancel"
        type="secondary"
        @click="$emit('close')"
      />
      <RSButton
        class="request-button"
        data-automation="reqperm-submit-btn"
        label="Request"
        @click="requestPrivilege"
      />
    </template>
  </RSModal>
</template>

<script>
import { requestPrivilege } from '@/api/privileges';
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';
import {
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'RequestPermissionsModal',
  components: {
    RSModal,
    RSButton,
  },
  props: {
    privilege: {
      type: String,
      required: true,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  computed: {
    modalSubject() {
      return `Request \"${this.privilege}\" Permissions`;
    },
    ...mapState({
      systemDisplayName: state => state.server.settings.systemDisplayName,
    }),
  },
  methods: {
    ...mapMutations({
      safeAPIErrorMessage: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    async requestPrivilege() {
      try {
        await requestPrivilege(this.privilege);
        this.setInfoMessage({ message: 'Permissions requested successfully.' });
      } catch (err) {
        this.safeAPIErrorMessage(err);
      }
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang='scss'>
.request-button {
  margin-left: 1rem;
}
</style>

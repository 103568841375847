<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <RestrictedAccessWrapper
      v-slot="{ executeRestrictedApi }"
      :eager="false"
      @loaded="focus"
    >
      <RSModalForm
        data-automation="new-api-key-form"
        :active="true"
        subject="API Keys"
        @close="$emit('close')"
        @submit="() => createKey(executeRestrictedApi)"
      >
        <template #content>
          <EmbeddedStatusMessage
            v-if="errMessage"
            type="error"
            data-automation="create-key-error"
            :message="errMessage"
            @close="clearErr"
          />
          <RSInputText
            ref="input"
            v-model.trim="keyName"
            name="api-key-name"
            data-automation="api-key-name"
            label="Enter a name for the new API key"
            :message="errorMsg"
            @change="checkInputLen"
          />
          <RSRadioGroup
            v-if="userRole === 'administrator' || userRole === 'publisher'"
            v-model="selectedRole"
            title="Set the role permissions for this API key"
            name="api_key_role"
            data-automation="api-key-role"
            :options="radioValues"
          />
        </template>
        <template #controls>
          <RSButton
            data-automation="api-key-create"
            label="Create Key"
          />
        </template>
      </RSModalForm>
    </RestrictedAccessWrapper>
  </div>
</template>

<script>
import { safeAPIErrorMessage } from '@/api/error';
import { createAPIKey } from '@/api/users';

import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import RestrictedAccessWrapper, {
  ReauthenticationInProgressError,
} from '@/components/RestrictedAccessWrapper';
import RSButton from '@/elements/RSButton';
import RSInputText from '@/elements/RSInputText';
import RSModalForm from '@/elements/RSModalForm';
import RSRadioGroup from '@/elements/RSRadioGroup';

const MAX_KEY_LENGTH = 50;

export default {
  name: 'CreateKey',
  components: {
    RestrictedAccessWrapper,
    RSButton,
    RSInputText,
    RSModalForm,
    RSRadioGroup,
    EmbeddedStatusMessage,
  },
  props: {
    guid: {
      type: String,
      required: true,
    },
    userRole: {
      type: String,
      required: true,
    }
  },
  emits: ['close', 'created'],
  data() {
    return {
      keyName: '',
      showInputErr: false,
      errMessage: '',
      selectedRole: this.userRole,
    };
  },
  computed: {
    errorMsg() {
      return this.showInputErr ? 'The name is required' : '';
    },
    radioValues() {
      const vals = [
        { label: 'Publisher', value: 'publisher' },
        { label: 'Viewer', value: 'viewer' }
      ];
      if (this.userRole === 'administrator') {
        vals.unshift({ label: 'Administrator', value: 'administrator' });
      }
      return vals;
    },
  },
  methods: {
    keyNameCheck() {
      // Check if key name is valid
      this.showInputErr = this.keyName === '';
    },
    async checkInputLen() {
      // Wait on model to update before length check
      await this.$nextTick();
      this.keyNameCheck();
      if (this.keyName.length > MAX_KEY_LENGTH) {
        this.keyName = this.keyName.slice(0, MAX_KEY_LENGTH);
      }
    },
    createKey(executeRestrictedApi) {
      // Halt if key name not valid
      this.keyNameCheck();
      if (this.showInputErr) {
        return;
      }

      executeRestrictedApi(createAPIKey(this.guid, this.keyName, this.selectedRole))
        .then(newKey => {
          return this.$emit('created', newKey);
        })
        .catch(err => {
          if (!(err instanceof ReauthenticationInProgressError)) {
            this.errMessage = safeAPIErrorMessage(err);
          }
        });
    },
    clearErr() {
      this.errMessage = '';
    },
    focus() {
      this.$nextTick().then(() => this.$refs.input.$el.querySelector('input').focus());
    }
  },
};
</script>

<style lang="scss" scoped>
.statusMessage {
  margin-bottom: 0.9rem;
}
</style>

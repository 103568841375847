<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSRadioGroup
    v-model="radioValue"
    name="schedule-semimonthly"
    data-automation="schedule-semimonthly"
    :options="options"
    @change="onChange"
  />
</template>

<script>
import { SemiMonthOptions } from '@/api/dto/schedule';
import RSRadioGroup from '@/elements/RSRadioGroup';

export default {
  name: 'SemimonthlySchedule',
  components: {
    RSRadioGroup,
  },
  props: {
    value: {
      type: String,
      default: SemiMonthOptions.First,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      radioValue: this.value,
      options: [
        {
          value: SemiMonthOptions.First,
          label: 'Run on the 1st and 15th days of each month.',
          disabled: this.disabled,
        },
        {
          value: SemiMonthOptions.Last,
          label: 'Run on the 14th and last days of each month.',
          disabled: this.disabled,
        },
      ],
    };
  },
  methods: {
    onChange(val) {
      this.$emit('change', val);
    },
  },
};
</script>

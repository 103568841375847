<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import BaseButton from '@/components/BaseButton';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import ToggleButton from '@/components/ToggleButton';
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';
import {
  CREATE_NEW_TAG,
  DELETE_TAG,
  FETCH_TAGS_TREE,
  RESET_DELETE_REQUEST,
} from '@/store/modules/adminTags';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { computed, nextTick, onBeforeMount, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import TagsCategory from './tags/TagsCategory';
import TagsGettingStarted from './tags/TagsGettingStarted';
import TagsHelpInfo from './tags/TagsHelpInfo';

const store = useStore();

const newCategoryInput = ref(null);

const localState = reactive({
  isAddingCategory: false,
  lockSaveCategory: false,
  newCategoryName: '',
  schemaFetched: false,
  showHelp: false,
});

const tagsTree = computed(() => store.state.adminTags.tagsTree);
const deletingTag = computed(() => store.state.adminTags.deletingTag);
const currentRequest = computed(() => store.state.adminTags.currentRequest);
const deleteTagLabel = computed(() => `Delete tag "${deletingTagName.value}"`);
const deletingTagName = computed(() => deletingTag.value.name);
const isDeletingTag = computed(() => !!deletingTag.value.name);
const hasTags = computed(() => !!tagsTree.value.length);
const showGettingStarted = computed(
  () =>
    localState.schemaFetched && !hasTags.value && !localState.isAddingCategory
);
const showHelpIcon = computed(
  () => hasTags.value || localState.isAddingCategory
);
const isProcessing = computed(() => currentRequest.value.active);
const processingMessage = computed(() => currentRequest.value.message);
const exampleCategories = computed(() =>
  tagsTree.value.slice(0, 2).map(c => c.name));

const setErrorMessageFromAPI = error =>
  store.commit(SET_ERROR_MESSAGE_FROM_API, error);

watch(hasTags, () => {
  if (hasTags.value) {
    localState.showHelp = false;
  }
});

onBeforeMount(() => {
  init();
});

const init = async() => {
  try {
    await store.dispatch(FETCH_TAGS_TREE);
    localState.schemaFetched = true;
  } catch (error) {
    setErrorMessageFromAPI(error);
  }
};

const showNewCategoryForm = () => {
  localState.isAddingCategory = true;
  // Wait for input to display before trying to focus on it
  nextTick().then(() => {
    newCategoryInput.value.focus();
  });
};
const submitCategoryCheck = ev => {
  if (localState.newCategoryName !== '') {
    submitNewCategory(ev, true);
  }
};
const submitNewCategory = async(ev, onBlur = false) => {
  // Lock when a submission is already set, when @keypress resolution triggers @blur
  if (localState.lockSaveCategory) {
    return;
  }
  localState.lockSaveCategory = true;
  try {
    await store.dispatch(CREATE_NEW_TAG, {
      name: localState.newCategoryName,
      discardFocus: onBlur,
    });
    localState.newCategoryName = '';
  } catch (e) {
    setErrorMessageFromAPI(e);
  }
  localState.isAddingCategory = false;
  localState.lockSaveCategory = false;
};
const toggleShowHelp = () => {
  localState.showHelp = !localState.showHelp;
};
</script>

<template>
  <div>
    <div
      class="tagEditor majorColumn"
      data-automation="admin-tag-viewer"
    >
      <!-- Delete confirmation modal -->
      <RSModal
        v-if="isDeletingTag"
        :active="true"
        :subject="deleteTagLabel"
        :close-button-label="`No, do not delete tag ${deletingTagName}`"
        @close="store.commit(RESET_DELETE_REQUEST)"
      >
        <template #content>
          {{
            `Are you sure that you want to delete ${deletingTagName}? You can't undo this action.`
          }}
        </template>
        <template #controls>
          <RSButton
            :label="`Yes, delete tag ${deletingTagName}`"
            :tabindex="isDeletingTag ? 0 : -1"
            type="primary"
            data-automation="tag-confirm-delete"
            @click="store.dispatch(DELETE_TAG, deletingTag)"
          />
        </template>
      </RSModal>

      <div class="flex">
        <div class="actionBar">
          <h1 class="sectionTitle focusedTitle">
            Tags Schema
          </h1>
          <ToggleButton
            v-show="showHelpIcon"
            :is-on="localState.showHelp"
            toggle-class="toggleInfo"
            @toggled="toggleShowHelp"
          />
        </div>
        <div class="tagEditor actionBar inline showTitles">
          <BaseButton
            v-if="showHelpIcon"
            label="New Category"
            :disabled="isProcessing"
            button-class="action newCategory"
            data-automation="tags-create-category-btn"
            @clicked="showNewCategoryForm"
          />
          <RSButton
            v-else
            label="Create Your Schema"
            :disabled="isProcessing"
            type="primary"
            data-automation="tags-start-schema-btn"
            @click="showNewCategoryForm"
          />
        </div>
      </div>

      <EmbeddedStatusMessage
        v-show="isProcessing"
        :show-close="false"
        :message="processingMessage"
        type="activity"
      />

      <TagsHelpInfo
        v-show="localState.showHelp"
        :example-categories="exampleCategories"
      />

      <TagsGettingStarted v-if="showGettingStarted" />

      <div class="tag-categories-container">
        <div class="tag-categories-container__tree">
          <TagsCategory
            v-for="category in tagsTree"
            :key="'category-' + category.id"
            :category="category"
          />
          <div
            v-show="localState.isAddingCategory"
            class="tag-categories-container__form"
          >
            <input
              ref="newCategoryInput"
              v-model.trim="localState.newCategoryName"
              placeholder="New Category"
              aria-label="New Category"
              class="tag-categories-container__form-input"
              type="text"
              data-automation="tags-new-category-input"
              @keypress.enter="submitNewCategory"
              @blur="submitCategoryCheck($event)"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tagEditor {
  &.actionBar {
    font-size: 13px;
  }

  &.majorColumn {
    position: relative;
  }
}

.actionBar {
  display: flex;
  align-items: center;
  .toggleInfo {
    margin: 0 0 7px 4px;
  }
}
</style>

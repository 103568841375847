<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__open-ide wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Open the RStudio IDE
      </StepHeading>
      <p>
        Use your desktop version, or Posit Workbench if your organization
        supports it.
      </p>
      <a
        href="https://www.posit.co/products/open-source/rstudio/"
        target="_blank"
      >Learn more about the RStudio IDE</a>
    </div>
  </div>
</template>

<script>
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'OpenIDE',
  components: { StepHeading },
  mixins: [PanelMixin],
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__open-ide {
    background-image: url('./background.png');
    background-position: right bottom;
    background-repeat: no-repeat;
  }
}
</style>

// Copyright (C) 2024 by Posit Software, PBC.

export default function pairsToQueryParams(...pairs) {
  const encoded = [];
  for (const pair of pairs) {
    const [key, value] = pair;
    if (typeof value !== 'undefined') {
      encoded.push(`${key}=${encodeURIComponent(value)}`);
    }
  }
  return encoded.join('&');
}

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import CollapsiblePanel from '@/components/CollapsiblePanel.vue';
import SlidingToggle from '@/components/SlidingToggle.vue';
import RSButton from '@/elements/RSButton.vue';
import RSInformationToggle from '@/elements/RSInformationToggle.vue';
import RSInputText from '@/elements/RSInputText.vue';
import {
  HIDE_LOCKED_MESSAGE_PREVIEW,
  PREVIEW_LOCKED_MESSAGE
} from '@/store/modules/contentView';
import _unescape from 'lodash/unescape';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  app: { type: Object, required: true },
});

const MAX_LOCKED_MESSAGE_LENGTH = 4_096;

const locked = defineModel('locked', { type: Boolean, default: false });
const lockedMessage = defineModel('lockedMessage', {
  type: String,
  default: '',
  get: value => _unescape(value),
});

const isDirty = ref(false);
const invalidMessage = computed(() =>
  isDirty.value &&
  locked.value &&
  lockedMessage.value?.length > MAX_LOCKED_MESSAGE_LENGTH
    ? `Locked message is limited to ${Number(
      MAX_LOCKED_MESSAGE_LENGTH
    ).toLocaleString()} characters`
    : null);

const store = useStore();
const currentUser = computed(() => store.state.currentUser.user);
const canLock = computed(
  () => currentUser.value.isAdmin() || currentUser.value.isAppEditor(props.app)
);

watch(locked, isLocked => {
  if (!isLocked) {
    isDirty.value = false;
    store.commit(HIDE_LOCKED_MESSAGE_PREVIEW);
  }
});

watch(lockedMessage, () => {
  isDirty.value = true;
});

const onPreview = () => {
  store.commit(PREVIEW_LOCKED_MESSAGE, lockedMessage.value);
};
</script>

<template>
  <div
    v-if="canLock"
    class="formSection"
  >
    <RSInformationToggle>
      <template #title>
        <div class="groupHeadings">
          Locking
        </div>
      </template>
      <template #help>
        <p>
          Locking content prevents visitors from viewing it and blocks scheduled runs.
        </p>

        <p>
          Add an optional custom message for visitors who attempt to view the locked content. 
          This message can include a URL to other content and can be formatted using 
          <a
            target="_blank"
            href="https://commonmark.org/help/"
          >Markdown</a>.
        </p>

        <h3>Effects of Locking Content:</h3>
        <ul>
          <li>
            <strong>Search:</strong> Locked content only appears in searches using
            <code class="locked-text">is:locked</code>.
          </li>
          <li>
            <strong>Processes:</strong> All processes associated with the content immediately stop.
          </li>
          <li>
            <strong>Schedules:</strong> All associated schedules are paused.
          </li>
          <li>
            <strong>Publishing:</strong> New versions cannot be uploaded.
          </li>
        </ul>
      </template>
    </RSInformationToggle>

    <CollapsiblePanel
      :hide-icon="true"
      :is-expanded="locked"
    >
      <SlidingToggle
        v-model="locked"
        color="#dd0000"
        :label="locked ? 'Locked' : 'Unlocked'"
        data-automation="settings-lock-content"
      />

      <template #content>
        <div class="message-container">
          <div
            class="rsc-row"
            :class="{ hasError: !!invalidMessage }"
          >
            <RSInputText
              v-model="lockedMessage"
              name="lockMessage"
              :lines="6"
              :message="invalidMessage"
              label="Locked content message"
              data-automation="settings-lock-message"
            />
          </div>
          <div class="button-row">
            <!-- TODO: re-enable when we have a final Preview design -->
            <RSButton
              v-if="false"
              :use-label-width="true"
              data-automation="settings-lock-preview"
              label="Preview"
              size="small"
              type="secondary"
              @click.stop="onPreview"
            />
          </div>
        </div>
      </template>
    </CollapsiblePanel>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.formSection {
  .message-container {
    margin-top: 1rem;

    .rsc-row {
      padding: 2px;
    }
  }
}

.groupHeadings {
  color: $color-heading;
  letter-spacing: 0.1em;
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.collapsible-container {
  margin-top: 1rem;
}

.lock-content-list {
  list-style: disc;
}

.button-row {
  display: flex;
  justify-content: flex-end;
  margin: 0 2px 2px 0;

  button {
    padding: 0.5rem 1rem;
  }
}

.rs-help-toggler__text {
  @include normalize-css;

  h3 {
    font-size: 1.17em;
  }

  .locked-text {
    font-weight: 600;
  }
}
</style>

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <fieldset
    :data-automation="dataAutomation"
    class="rs-radiogroup"
    role="radiogroup"
  >
    <legend
      v-if="title"
      :id="name"
      class="rs-radiogroup__title"
    >
      {{ title }}
    </legend>
    <div>
      <RSInputRadio
        v-for="option in options"
        :key="option.label"
        v-model="groupValue"
        :aria-labelledby="name"
        :label="option.label"
        :title="option.title"
        :message="option.message"
        :message-type="option.messageType"
        :name="name"
        :value="option.value"
        :disabled="option.disabled"
        @change="handleChange(option.value)"
      />
    </div>
  </fieldset>
</template>

<script>
import RSInputRadio from './RSInputRadio';

export default {
  name: 'RSRadioGroup',
  components: {
    RSInputRadio,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    dataAutomation: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      groupValue: this.modelValue,
    };
  },
  watch: {
    modelValue(newVal) {
      // Update with incoming values from outside
      this.groupValue = newVal;
    },
  },
  methods: {
    handleChange(optValue) {
      this.groupValue = optValue;
      this.$emit('change', optValue);
      this.$emit('update:modelValue', optValue);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-radiogroup {
   margin-bottom: 1.2rem;

  .rs-field {
    margin-bottom: 0;
  }

  &__title {
    @include message;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: .2rem;
  }
}
</style>

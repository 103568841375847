<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <label
      v-if="isDateType"
      :for="parameter.name"
      class="label"
    >
      {{ parameter.label }}
    </label>
    <component
      v-bind="parameter"
      :is="componentForType()"
      v-model="propValue"
    />
  </div>
</template>

<script>
import DateTimePicker from './DateTimePicker';
import DatePicker from './DatePicker';
import RSInputCheckbox from '@/elements/RSInputCheckbox';
import RSInputNumber from '@/elements/RSInputNumber';
import RSInputPassword from '@/elements/RSInputPassword';
import RSInputRange from '@/elements/RSInputRange';
import RSInputSelect from '@/elements/RSInputSelect';
import RSInputText from '@/elements/RSInputText';
import RSRadioGroup from '@/elements/RSRadioGroup';
import isEmpty from 'lodash/isEmpty';
import { cloneDeep } from 'lodash';

const MAX_CHOICES_FOR_RADIO = 4;

const COMPONENT_CHECKBOX = 'checkbox';
const COMPONENT_NUMERIC = 'numeric';
const COMPONENT_RADIO = 'radio';
const COMPONENT_SELECT = 'select';
const COMPONENT_TEXT = 'text';
const COMPONENT_PASSWORD = 'password';
const COMPONENT_DATETIMEPICKER = 'datetimepicker';
const COMPONENT_DATEPICKER = 'datepicker';
const COMPONENT_SLIDER = 'slider';

const VALUE_BOOL = 'boolean';
const VALUE_NUMBER = 'number';
const VALUE_STRING = 'string';

const VALUE_TYPES = {
  [VALUE_BOOL]: COMPONENT_CHECKBOX,
  [VALUE_NUMBER]: COMPONENT_NUMERIC,
  [VALUE_STRING]: COMPONENT_TEXT,
};

const COMPONENT_TYPES = {
  [COMPONENT_CHECKBOX]: 'RSInputCheckbox',
  [COMPONENT_DATETIMEPICKER]: 'DateTimePicker',
  [COMPONENT_DATEPICKER]: 'DatePicker',
  [COMPONENT_NUMERIC]: 'RSInputNumber',
  [COMPONENT_PASSWORD]: 'RSInputPassword',
  [COMPONENT_RADIO]: 'RSRadioGroup',
  [COMPONENT_SELECT]: 'RSInputSelect',
  [COMPONENT_SLIDER]: 'RSInputRange',
  [COMPONENT_TEXT]: 'RSInputText',
};

// FIXME: remove property mutation for parameter prop.

export default {
  name: 'ParameterComponent',
  components: {
    DatePicker,
    DateTimePicker,
    RSInputCheckbox,
    RSInputNumber,
    RSInputPassword,
    RSInputRange,
    RSInputSelect,
    RSInputText,
    RSRadioGroup,
  },
  props: {
    parameter: {
      type: Object,
      required: true,
    },
    value: {
      type: [Boolean, Number, String, Date, Object],
      default: null,
    },
  },
  emits: ['valueUpdated'],
  data() {
    return {
      param: cloneDeep(this.parameter)
    };
  },
  computed: {
    propValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('valueUpdated', { parameter: this.param.name, value });
      },
    },
    isDateType() {
      return (
        this.param.type === 'datetimepicker' ||
        this.param.type === 'datepicker'
      );
    }
  },
  created() {
    if (!isEmpty(this.param.choices)) {
      this.generateOptionsFromChoices(this.param.choices);
      return;
    }

    if (['select', 'radio'].includes(this.param.type)) {
      this.param.type = COMPONENT_TEXT;
    }
  },
  methods: {
    componentForType() {
      const validatedType = this.getTypeForParameter();
      if (validatedType === COMPONENT_RADIO) {
        this.param.title = this.param.label;
      }
      if (validatedType === COMPONENT_SLIDER) {
        this.param.showValue = true;
      }

      const component = COMPONENT_TYPES[validatedType];
      if (component) {
        return component;
      }

      // TODO: remove this when all component types are available.
      console.error(`No component found for: ${this.param.type}`);
    },
    getTypeForParameter() {
      if (this.param.type) {
        if (
          [COMPONENT_RADIO, COMPONENT_SELECT].includes(this.param.type) &&
          isEmpty(this.param.choices)
        ) {
          return COMPONENT_TEXT;
        }
        return this.param.type;
      }

      if (!isEmpty(this.param.choices)) {
        return this.param.choices.length > MAX_CHOICES_FOR_RADIO
          ? COMPONENT_SELECT
          : COMPONENT_RADIO;
      }

      const valueType = VALUE_TYPES[typeof this.value];
      if (valueType) {
        return valueType;
      }

      return VALUE_STRING;
    },
    generateOptionsFromChoices() {
      this.param.options = this.param.choices.map(choice => ({
        label: choice,
        value: choice,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_mixins';

.label {
  @include label;
  display: block;
}
</style>

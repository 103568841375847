<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div
    :title="tooltip"
    class="rs-principal"
  >
    <div
      :class="userIconClass"
      class="rs-principal__icon"
    >
      <div
        v-if="initials"
        class="rs-principal__initials"
      >
        {{ initials }}
      </div>
    </div>
    <div
      v-if="name || title || fullStatus"
      class="rs-principal__info"
    >
      <h1
        v-if="title"
        class="rs-principal__info--title"
      >
        {{ title }}
      </h1>
      <div
        v-if="!title && name"
        class="rs-principal__info--primary"
      >
        {{ name }}
      </div>
      <div
        v-if="!title && fullStatus"
        class="rs-principal__info--secondary"
      >
        {{ fullStatus }}
      </div>
    </div>
    <div :class="additionalIconClass" />
  </div>
</template>

<script>
export default {
  name: 'RSPrincipalInfo',
  props: {
    initials: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: null
    },
    additionalStatus: {
      type: String,
      default: null
    },
    isGroup: {
      type: Boolean,
      default: false
    },
    additionalIcon: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    userIconClass() {
      return this.isGroup
        ? 'rs-principal__icon--group'
        : 'rs-principal__icon--initials';
    },
    fullStatus() {
      let statuses = '';
      if (this.additionalStatus) {
        statuses += this.additionalStatus;
      }
      if (statuses && this.status) {
        statuses += `, ${this.status}`;
      } else if (this.status) {
        statuses += this.status;
      }
      return statuses;
    },
    additionalIconClass() {
      return this.additionalIcon
        ? `rs-principal__icon rs-principal__icon--${this.additionalIcon}`
        : '';
    },
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

.rs-principal {
  display: flex;
  align-items: center;
  vertical-align: middle;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;

  &__icon {
    display: flex;
    align-items: center;
    width: $rs-icon-size;
    height: $rs-icon-size;
    min-width: $rs-icon-size;
    border-radius: calc($rs-icon-size / 2);
    overflow: hidden;

    &--initials {
      background-color: $color-secondary;
    }

    &--group {
      background-image: url(/images/elements/iconGroup.svg);
      background-repeat: no-repeat;
      background-size: $rs-icon-size $rs-icon-size;
    }

    &--viewer {
      background-image: url(/images/elements/actionView.svg);
      background-repeat: no-repeat;
      background-size: ($rs-icon-size + 4px) ($rs-icon-size + 4px);
      background-position: center center;
    }
  }

  &__initials {
    margin: auto;
    font-size: 1rem;
    font-weight: 300;
    padding-top: 2px;
    transform: rotate(-15deg);
    color: $color-secondary-inverse;
  }

  &__info {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 0.5rem;

    &--primary {
      font-size: $rs-font-size-small;
    }

    &--secondary {
      color: $color-dark-grey;
      font-size: $rs-font-size-smallest;
      margin-top: 3px;
    }

    &--title {
      font-size: $rs-font-size-large;
      font-weight: 300;
    }
  }
}
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--

  Presents a radio group that allows selection of the content access type.

  * Anyone
  * Login required
  * Specific users/groups

  Access types prohibited by the Applications.MostPermissiveAccessType or
  Applications.AdminMostPermissiveAccessType settings are removed from the set
  of available selections (not displayed). When the current access type is
  prohibited by these configuration, that value is displayed until a different
  access type is chosen.

  Access types prohibited by the anonymous-servers license entitlement are
  presented as disabled, but included in the radio group.

  The entire radio group is presented as disabled when the viewer does not have
  write permissions.

-->

<template>
  <RSRadioGroup
    :options="options"
    :model-value="type"
    name="as-types"
    data-automation="access-type"
    @change="onChange"
  />
</template>

<script>
import AccessTypes from '@/api/dto/accessType';
import PublicContentStatuses from '@/api/dto/publicContentStatus';
import RSRadioGroup from '@/elements/RSRadioGroup';
import {
  ACCESS_SETTINGS_UPDATE_MODE,
  ACCESS_SETTINGS_UPDATE_TYPE,
  ModeType,
} from '@/store/modules/accessSettings';
import { mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export default {
  name: 'AccessType',
  components: {
    RSRadioGroup,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    visibleTypes: {
      type: Array,
      required: true,
    },
    isWorkerApp: {
      type: Boolean,
      default: true,
    },
    anonymousBranding: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['change'],
  computed: {
    ...mapState({
      type: state => state.accessSettings.type,
      app: state => state.contentView.app,
    }),
    options() {
      // The product license may force the "all" choice to be read-only and
      // include additional messages.
      const allChoice = {
        value: AccessTypes.All,
        label: 'Anyone - no login required',
        disabled: this.readOnly,
      };

      // Worker apps receive messaging that depends on the public content status.
      if (this.isWorkerApp) {
        if (this.app.publicContentStatus === PublicContentStatuses.Unlicensed) {
          allChoice.disabled = true;
          if (this.type === AccessTypes.All) {
            allChoice.message = 'Not available for interactive content. Access to this content requires login.';
            allChoice.messageType = 'error';
          } else {
            allChoice.message = 'Not available for interactive content.';
            allChoice.messageType = 'info';
          }
        } else if (
          this.app.publicContentStatus === PublicContentStatuses.Restricted &&
          this.type === AccessTypes.All
        ) {
          allChoice.message = 'This content is restricted to logged-in users. Posit Connect could not verify that it is publicly available online.';
          allChoice.messageType = 'error';
        } else if (
          this.app.publicContentStatus === PublicContentStatuses.Warning &&
          this.type === AccessTypes.All
        ) {
          allChoice.message = 'Posit Connect has not verified that this content is publicly available online. Unless successfully verified, this content will be restricted to logged-in users only.';
          allChoice.messageType = 'warning';
        } else if (
          this.app.publicContentStatus === PublicContentStatuses.Ok &&
          this.type === AccessTypes.All
        ) {
          allChoice.message = 'Posit Connect periodically checks that this content is publicly available online. Content that cannot be verified will be restricted to logged-in users only.';
          allChoice.messageType = 'info';
        }

      // Static and rendered apps receive messaging about anonymous branding.
      // Viewers will not see this, as it depends on license status from the serversettings endpoint.
      } else if (this.anonymousBranding) {
        allChoice.message = 'Adds a "Powered by Posit Connect" badge to the content.';
        allChoice.messageType = 'info';
      }

      // An access type is visible when permitted by configuration.
      const choices = [
        allChoice,
        {
          value: AccessTypes.LoggedIn,
          label: 'All users - login required',
          disabled: this.readOnly,
        },
        {
          value: AccessTypes.Acl,
          label: 'Specific users or groups',
          disabled: this.readOnly,
        },
      ];
      return choices.filter(({ value }) => {
        return (this.type === value ||
          this.visibleTypes.includes(value));
      });
    },
  },
  methods: {
    ...mapMutations({
      updateType: ACCESS_SETTINGS_UPDATE_TYPE,
      updateMode: ACCESS_SETTINGS_UPDATE_MODE,
    }),
    onChange(value) {
      const type = AccessTypes.of(value);
      const mode =
        type === AccessTypes.Acl ? ModeType.VIEWER : ModeType.OWNER;
      this.updateType(type);
      this.updateMode(mode);
      this.$emit('change');
    },
  },
};
</script>

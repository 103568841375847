// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiV1Path } from '@/utils/paths';

/**
 * * @typedef Task
 * * @property {string} id
 * * @property {number} user_id
 * * @property {any} output
 * * @property {any} result
 * * @property {boolean} finished
 * * @property {any} code
 * * @property {string} error
 * * @property {number} last
 */

/**
 * getTask returns the status of a server-side task
 * @param {string} taskId unique id of the task
 * @param {number} first offset of the first status message to return
 * @param {number} wait number of seconds to wait for the task to complete before responding
 * @return {AxiosPromise<any>} result of the call
 */
export function getTask(taskId, first = 0, wait = 0) {
  return axios.get(apiV1Path(`tasks/${encodeURIComponent(taskId)}`), {
    params: {
      first: first,
      wait: wait,
    },
  });
}

/**
 * taskToPromise converts a taskId into a promise
 * and additionally fires off a callback every time
 * the task is polled.
 * Finally, the `last` is followed, meaning only
 * new output lines are fetched on each poll.
 * @param {string} taskId unique id of the task
 * @param {function} onPoll function that accepts a task object.
 * Has no return value.
 * @returns {Promise<Task>} the finished task object
 * @throws when any of the underlying task calls fail
 * but not if the task itself fails.
 */
export async function taskToPromise(taskId, onPoll = () => {}) {
  let task = await getTask(taskId, 0, 1);
  onPoll(task.data);
  while (!task.data.finished) {
    task = await getTask(taskId, task.data.last, 1);
    onPoll(task.data);
  }
  return task.data;
}

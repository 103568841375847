<!-- Copyright (C) 2024 by Posit Software, PBC. -->
<script setup>
import RSButton from '@/elements/RSButton.vue';
import { SKIP_TO_MAIN_CONTENT } from '@/store/modules/navigation';
import { useStore } from 'vuex';

const store = useStore();

const onClick = () => {
  store.dispatch(SKIP_TO_MAIN_CONTENT);
};
</script>

<template>
  <RSButton
    class="skip-to-main"
    data-automation="skip-to-main-button"
    label="Skip to main content"
    size="small"
    @click="onClick"
  />
</template>

<style lang='scss' scoped>
@import 'Styles/shared/_colors.scss';

.skip-to-main {
  font-size: 1.1rem;
  left: 0;
  margin: 0.5rem;
  overflow: hidden;
  padding: 1rem;
  position: absolute;
  top: -100px;
  z-index: 100;

  &:focus {
    position: fixed;
    top: 0;
  }
}
</style>

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { deleteIntegration, getAvailableIntegrations, getTemplatesList } from '@/api/oauth';
import { useSkipToMainContent } from '@/composables/skipToMainContent';
import RSButton from '@/elements/RSButton';
import RSInformationToggle from '@/elements/RSInformationToggle';
import RSModalForm from '@/elements/RSModalForm';
import RSTable from '@/elements/RSTable';
import RSTableCell from '@/elements/RSTableCell';
import RSTableRow from '@/elements/RSTableRow';
import { SET_ERROR_MESSAGE_FROM_API, SHOW_INFO_MESSAGE, SHOW_WARNING_MESSAGE } from '@/store/modules/messages';
import { docsPath, getHashQueryParameter, oauthLoginPath, serverURL } from '@/utils/paths';
import dayjs from 'dayjs';
import { computed, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import IntegrationModal from './IntegrationModal.vue';

const store = useStore();

const localState = reactive({
  integrations: [],
  templates: [],
  openModal: false,
  guid: '',
  readOnly: true,
  toDelete: null,
  headers: []
});

const isAdmin = computed(() => store.state.currentUser.user.isAdmin());

const getTemplateName = (templateId) => {
  const template = localState.templates.find(({ id }) => id === templateId);
  return template?.name;
};

const addIntegrationButtonAdminRef = ref(null);
const addIntegrationButtonRef = ref(null);

useSkipToMainContent(() => {
  addIntegrationButtonRef.value?.focusElement();
  addIntegrationButtonAdminRef.value?.focusElement();
});

onBeforeMount(async() => {
  try {
    localState.integrations = await getAvailableIntegrations();
    localState.templates = await getTemplatesList();
  } catch (e) {
    store.commit(SET_ERROR_MESSAGE_FROM_API, e);
  }
  
  const headers = [
    { label: 'Title', sortable: true },
    { label: 'Integration Type', sortable: true, width: '250px' },
    { label: 'Created Date', sortable: true, width: '160px' },
    { label: 'Last Modified Date', sortable: true, width: '160px', direction: 'asc' }
  ];
  if (isAdmin.value) { headers.push({ label: 'Actions', width: '50px' });};
  localState.headers = headers;

  const param = getHashQueryParameter('integration');
  if (param) {
    store.dispatch(SHOW_INFO_MESSAGE, {
      message: `${param[0]} integration test successful.`, timeout: 5000
    });
  }
});

const onDelete = async(guid) => {
  try {
    await deleteIntegration(guid);
    store.dispatch(SHOW_INFO_MESSAGE, { message: 'Integration successfully deleted.' });
  } catch (e) {
    store.commit(SET_ERROR_MESSAGE_FROM_API, e);
  } finally {
    localState.toDelete = null;
    localState.integrations = await getAvailableIntegrations();
  }
};

const onCreate = async(integrationName) => {
  store.dispatch(
    SHOW_INFO_MESSAGE,
    { message: `Integration ${integrationName} successfully created.` }
  ).then(async() => {
    localState.integrations = await getAvailableIntegrations();
  });
};

const onUpdate = async(integrationName) => {
  store.dispatch(
    SHOW_INFO_MESSAGE,
    { message: `Integration ${integrationName} successfully updated.` }
  ).then(async() => {
    localState.integrations = await getAvailableIntegrations();
  });
};

const onTest = (integration) => {
  toggleActions(integration.guid);
  store.dispatch(
    SHOW_WARNING_MESSAGE,
    { message: `You will be redirected to test ${integration.name}.`, timeout: 4000 }
  );
  setTimeout(() => { window.location.href = oauthLogin(integration); }, 4000);
};

const onSortChange = ({ index, direction }) => {
  const activeColumn = localState.headers[index];
  localState.headers.forEach(c => {
    c.direction = null;
  });
  const descending = direction === 'desc';
  activeColumn.direction = descending ? 'desc' : 'asc';

  const compare = (first, second) => {
    const invert = descending ? -1 : 1;
    switch(activeColumn.label) {
      case 'Created Date':
        return invert * (dayjs(first.createdTime) - dayjs(second.createdTime));
      case 'Last Modified Date':
        return invert * (dayjs(first.updatedTime) - dayjs(second.updatedTime));
      case 'Title':
        return invert * (first.name.localeCompare(second.name));
      case 'Integration Type':
        return invert * (
          getTemplateName(first.template).localeCompare(getTemplateName(second.template))
        );
    }
  };
  return localState.integrations.sort(compare);
};

const reset = (() => {
  localState.guid = '';
  localState.readOnly = true;
  localState.openModal = false;
});

const addIntegration = (() => {
  localState.readOnly = false;
  localState.openModal = true;
});

const viewIntegration = ((guid) => {
  localState.readOnly = true;
  localState.guid = guid;
  localState.openModal = true;
});

const editIntegration = ((guid) => {
  localState.readOnly = false;
  localState.guid = guid;
  localState.openModal = true;
});

const oauthLogin = ((integration) => {
  const guid = integration.guid;
  const redirect = serverURL('/#/integrations');
  return oauthLoginPath({ guid, redirect: `${redirect}?integration=${integration.name}` });
});

const actions = ref({});
const toggleActions = ((guid) => {
  actions.value[guid].classList.toggle('open');
});

const closeActions = ((e, guid) => {
  if (e.relatedTarget?.className.includes('menu-button')) { return; };
  actions.value[guid].classList.remove('open');
});
</script>

<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div class="flex header">
        <h1
          v-if="localState.integrations.length === 0"
          class="sectionTitle"
        >
          Integrations
        </h1>
        <RSInformationToggle
          v-else
          :left-align="true"
        >
          <template #title>
            <h1 class="sectionTitle">
              Integrations
            </h1>
          </template>
          <template #help>
            <div v-if="isAdmin">
              Add or manage integrations with external OAuth applications.
              For more details on creating integrations, see the
              <a
                :href="docsPath('admin/integrations/oauth-integrations')"
                target="_blank"
              >
                Admin Guide</a>.
            </div>
            <div v-if="!isAdmin">
              The integrations listed here can be used to develop content that can request
              a viewer's OAuth access tokens to access third-party protected resources. 
              This pattern helps ensure that data access in Connect is aligned with your 
              data governance protocols.
              For more details on creating interactive content using integrations, see the
              <a
                :href="docsPath('user/oauth-integrations')"
                target="_blank"
              >
                User Guide</a>.
            </div>
          </template>
        </RSInformationToggle>
        
        <RSButton
          v-if="isAdmin && localState.integrations.length > 0"
          ref="addIntegrationButtonAdminRef"
          data-automation="integration__add-button"
          class="add-button"
          label="Add Integration"
          @click="addIntegration"
        />
      </div>
      <IntegrationModal
        v-if="localState.openModal"
        :read-only="localState.readOnly"
        :guid="localState.guid"
        :templates="localState.templates"
        @close="reset"
        @update-success="onUpdate"
        @create-success="onCreate"
        @error="store.commit(SET_ERROR_MESSAGE_FROM_API, e)"
      />
      <RSTable
        v-if="localState.integrations.length > 0"
        :columns="localState.headers"
        data-automation="integrations-table"
        table-name="Integrations"
        @sort="onSortChange"
      >
        <RSTableRow
          v-for="integration of localState.integrations"
          :key="integration.guid"
          :row-id="integration.guid"
          :row-label="integration.name"
          :data-automation="`integrations-table__row-${integration.name}`"
        >
          <RSTableCell
            style="padding:0"
          >
            <button
              class="integration-view-edit"
              @click="isAdmin ?
                editIntegration(integration.guid) :
                viewIntegration(integration.guid)"
            >
              <div
                class="integration-name"
                data-automation="integrations-table__row__name"
              >
                {{ integration.name }}
              </div>
              <div
                class="integration-description"
                data-automation="integrations-table__row__description"
              >
                {{ integration.description }}
              </div>
            </button>
          </RSTableCell>
          <RSTableCell
            class="flexAlignCenter template"
          >
            <img
              :src="`images/oauthintegrations/${integration.template}.png`"
              alt=""
              class="template-image"
            >
            {{ getTemplateName(integration.template) }}
          </RSTableCell>
          <RSTableCell>
            {{ dayjs(integration.createdTime).format('MM/DD/YY') }}
          </RSTableCell>
          <RSTableCell>
            {{ dayjs(integration.updatedTime).format('MM/DD/YY') }}
          </RSTableCell>
          <RSTableCell v-if="isAdmin">
            <button
              data-automation="integrations-table__row__actions-button"
              class="action-button"
              aria-label="actions"
              @click="toggleActions(integration.guid)"
              @blur="e => closeActions(e, integration.guid)"
            />
            <div
              :ref="(el) => (actions[integration.guid] = el)"
              data-automation="integrations-table__row__actions-menu"
              class="action-menu"
            >
              <button
                class="menu-button"
                @click="onTest(integration)"
              >
                Test integration
              </button>
              <button
                class="menu-button"
                @click="() => {
                  editIntegration(integration.guid);
                  toggleActions(integration.guid)
                }"
              >
                Edit
              </button>
              <button
                class="menu-button"
                @click="() => {
                  localState.toDelete = integration;
                  toggleActions(integration.guid);
                }"
              >
                Delete
              </button>
            </div>
          </RSTableCell>
        </RSTableRow>
      </RSTable>
      <div
        v-if="isAdmin && localState.integrations.length === 0"
        class="blurb"
      >
        <RSInformationToggle :left-align="true">
          <template #title>
            <img
              src="/images/oauthintegrations/integration_icon.svg"
              alt=""
            >
            <h2>Integrations</h2>
          </template>
          <template #help>
            <div class="info-heading">
              Why set up OAuth integrations?
            </div>
            <p>
              OAuth integrations can be used by publishers to develop content that can request
              a viewer's OAuth access tokens to access third-party protected resources. This pattern
              helps ensure that data access in Connect is aligned with your data governance protocols.
            </p>
            <p>
              Refer to the
              <a
                :href="docsPath('admin/integrations/oauth-integrations/security.html')"
                target="_blank"
              >
                OAuth Integrations Security section of the Admin Guide
              </a>
              for more information on viewer identity delegation.
            </p>
            <div class="info-heading">
              Add OAuth integrations:
            </div>
            <p>
              Configuration templates are available for Microsoft Entra ID, Databricks, GitHub,
              Google, and Snowflake.
              You can also specify custom OAuth integrations.
            </p>
          </template>
        </RSInformationToggle>
        <p
          class="get-started"
          data-automation="integration__get-started__admin"
        >
          Get started by adding integrations. Refer to the
          <a
            :href="docsPath('admin/integrations/oauth-integrations')"
            target="_blank"
          >
            Admin Guide
          </a>
          for detailed instructions on creating your first OAuth integration.
        </p>
        <RSButton
          ref="addIntegrationButtonRef"
          data-automation="integration__add-button"
          class="add-button"
          label="Add Integration"
          @click="addIntegration"
        />
      </div>
      <div
        v-if="!isAdmin && localState.integrations.length === 0"
        class="blurb"
      >
        <RSInformationToggle :left-align="true">
          <template #title>
            <img
              src="/images/oauthintegrations/integration_icon.svg"
              alt=""
            >
            <h2>No integrations are configured</h2>
          </template>
          <template #help>
            <div class="info-heading">
              Why set up OAuth integrations?
            </div>
            <p>
              OAuth integrations can be used to develop content that can request a viewer's
              OAuth access tokens to access third-party protected resources. This pattern helps
              ensure that data access in Connect is aligned with your data governance protocols.
              For more details on creating interactive content using integrations, see the
              <a
                :href="docsPath('user/oauth-integrations')"
                target="_blank"
              >
                User Guide</a>.
            </p>
            <div class="info-heading">
              Adding an OAuth integration:
            </div>
            <p>
              Your Connect administrator can follow the
              <a
                :href="docsPath('admin/integrations/oauth-integrations')"
                target="_blank"
              >
                Admin Guide
              </a> 
              to configure integrations with external services such as Microsoft Entra ID, Databricks,
              and Snowflake.
            </p>
          </template>
        </RSInformationToggle>
        <p
          class="get-started"
          data-automation="integration__get-started__publisher"
        >
          Your Connect administrator can set up integrations by following the instructions in the
          <a
            :href="docsPath('admin/integrations/oauth-integrations')"
            target="_blank"
          >
            Admin Guide</a>.
        </p>
      </div>
      <RSModalForm
        v-if="localState.toDelete"
        :active="true"
        subject="Delete Integration"
        @close="localState.toDelete = null"
        @submit="onDelete(localState.toDelete.guid)"
      >
        <template #content>
          <p>
            Are you sure you want to delete 
            <span class="emphasize">
              {{ localState.toDelete.name }}
            </span>? This will remove this integration completely, and cannot be undone.
          </p>
        </template>
        <template #controls>
          <RSButton
            type="secondary"
            label="Cancel"
            title="Cancel"
            @click="localState.toDelete = null"
          />
          <RSButton
            data-automation="integration-delete-modal__delete-button"
            class="delete-confirm"
            type="primary"
            label="Delete"
            title="Delete"
          />
        </template>
      </RSModalForm>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
  .header .add-button, .blurb .add-button {
    padding: 4px 0;
    font-size: 14px;
    width: 140px;
    border: 1px solid $color-primary;
    background: $color-white;
    display: flex;
    color: $color-primary;
    &:hover {
      color: $color-white;
      background: $color-primary;
    }
    &::before {
        content: '+';
        font-size: 24px;
        position: relative;
        padding: 0 8px;
    }
  }
  .header :deep(.rs-help-toggler) {
    .rs-help-toggler__help-icon {
      position: relative;
      top: -2px;
    }
    .rs-help-toggler__text {
      max-width: 620px;
      padding: 1rem 1.5rem;
    }
    a {
      font-weight: 600;
    }
  }
  .action-button {
    background-color: transparent;
    background-image: url(/images/elements/inputDialogButton.svg);
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
    padding: 20px;
    transform: rotate(90deg);
    &:hover {
      background-color: $color-light-grey-3;
    }
    &:has(+ .open) {
      background-color: $color-light-grey-2;
    }
  }
  .integration-view-edit {
    width: 100%;
    max-width: 50vw;
    text-align: left;
    background-color: transparent;
  }
  .integration-name {
    color: $color-primary;
    text-wrap: wrap;
  }
  .integration-description {
    font-size: 0.8rem;
    padding-top: 0.25rem;
    text-wrap: wrap;
    max-height: 4em;
    position: relative;
    overflow: hidden;
    &::after {
      content: '';
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30%;
      height: 1.2em;
      background: linear-gradient(to right, #fff0, #fff 50%);
    }
  }
  .template {
    position: relative;
    top: -5px;
  }
  .template-image {
    height: 20px;
    width: 20px;
    margin-right: 0.25rem;
    position: relative;
    top: 6px;
  }
  .blurb {
    margin: 3rem auto auto 10%;
    text-align: center;
    max-width: 80%;
  }
  .action-menu {
    position: absolute;
    z-index: 1;
    right: 118px;
    width: 200px;
    display: none;
    &.open {
      display: flex;
      flex-direction: column;
    }
    .menu-button {
      height: 36px;
      background-color: $color-white;
      text-align: left;
      border: 1px solid $color-medium-grey-2;
      border-top: none;
      border-radius: 0;
      border-collapse: collapse;
      line-height: 12px;
      &:first-child {
        border-top: 1px solid $color-medium-grey-2;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
      &:last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
      &:hover {
        background-color: $color-primary-light;
        color: $color-primary;
        border-top: 1px solid $color-primary;
        border-color: $color-primary;
      }
    }
  }
  :deep(.rs-modal__actions) {
    justify-content: flex-start;

    button {
      min-width: 100px;
      &.delete-confirm {
        background-color: $color-error;
        margin-left: 1rem;
        &:hover {
          background-color: darken($color-error, 10%)!important;
        }
      }
    }
  }
  .blurb {
    margin: 5rem auto;
    text-align: center;
    max-width: 900px;
    :deep(.rs-help-toggler) {
      .rs-help-toggler__help {
        justify-content: center;
      }
      .rs-help-toggler__label {
        display: flex;
        h2 {
          font-size: 24px;
          font-weight: 600;
        }
        img {
          height: 24px;
          padding-right: 8px;
        }
      }
      .rs-help-toggler__text {
        text-align: left;
        margin: 1rem 6rem;
        padding: 1rem 2rem;
      }
    }
    .info-heading {
      font-size: 1rem;
      font-weight: 700;
      padding: 0.5rem 0;
    }
    .get-started {
      font-size: 1rem;
      padding-top: 1rem;
    }
    a {
      font-weight: 600;
    }
    .add-button {
      width: auto;
      padding: 1rem 1.6rem 1rem 1.2rem;
      margin: 1rem auto;
    }
  }
</style>

<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  This component responsibility is to show
  to non publisher users the blurb about not having permissions,
  and handle the request permissions modal for that case.
-->

<template>
  <div
    data-automation="cannot-activate-token"
  >
    <h2 class="rsc-lead">
      Insufficient Privileges
    </h2>

    <p>
      Your user account (<span class="emphasize">{{ username }}</span>) has insufficient
      privileges to publish content to Connect.
      <a
        href="#"
        data-automation="activate-token-req-perm"
        @click.prevent="togglePermissionsModal"
      >
        Request permission to publish your own content
      </a>
    </p>
    <RequestPermissionsModal
      privilege="publisher"
      :show-modal="showPermissionsModal"
      @close="togglePermissionsModal"
    />
  </div>
</template>

<script>
import RequestPermissionsModal from '@/views/content/RequestPermissionsModal';

export default {
  name: 'TokenCannotPublish',
  components: {
    RequestPermissionsModal,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showPermissionsModal: false,
    };
  },
  methods: {
    togglePermissionsModal() {
      this.showPermissionsModal = !this.showPermissionsModal;
    },
  },
};
</script>

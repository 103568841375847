// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import dayjs from 'dayjs';
import { keysToCamel } from './transform';

/**
 * API calls for connect V1 auditLogs endpoints
 *
 * Makes the request to the endpoint, transforms and returns the response
 */

const getAuditLogs = url => {
  return axios.get(url).then(response => {
    const paging = keysToCamel(response.data.paging);
    const auditLogs = keysToCamel(response.data.results);
    auditLogs.forEach(log => {
      log.time = dayjs(log.time).format('MMM D, YYYY h:mma');
    });

    return {
      auditLogs,
      paging,
    };
  });
};

export { getAuditLogs };

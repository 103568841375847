// Copyright (C) 2023 by Posit Software, PBC.

import ContentHeader from '@/components/ContentHeader';
import ContentView from '@/views/content/ContentView';
import AppTags from '@/views/content/settings/AppTags';
import AccessSettings from '@/views/content/settings/access/AccessSettings';
import InfoSettings from '@/views/content/settings/InfoSettings';
import RuntimeSettings from '@/views/content/settings/runtime/RuntimeSettings';
import ScheduleSettings from '@/views/content/settings/schedule/ScheduleSettings';
import EnvironmentSettings from '@/views/content/settings/EnvironmentSettings';

const RESOLVE_CONTENT_META = { resolveContent: true };
const VARIANT_CHECK_META = { resolveContent: true, variantRedirectCheck: true };

// NOTE: App routes do not require auth at the route resolving level,
// because apps can have access as "no login required".
// That is the reason why only /subscriptions route "meta.requireAuth: true"
// Each content view component handles user roles as needed,
// if login is required that will be handled at the resolution of
// resolveContentFilter helper.
export default [
  {
    path: '/apps/:idOrGuid',
    components: {
      header: ContentHeader,
      default: ContentView,
    },
    children: [
      {
        path: '',
        name: 'apps',
        meta: VARIANT_CHECK_META,
        components: { },
      },
      {
        path: 'info',
        name: 'apps.info',
        meta: VARIANT_CHECK_META,
        components: {
          settings: InfoSettings,
        },
      },
      {
        path: 'info/:id',
        name: 'apps.info.variant',
        meta: RESOLVE_CONTENT_META,
        components: {
          settings: InfoSettings,
        },
      },
      {
        path: 'access',
        name: 'apps.access',
        meta: VARIANT_CHECK_META,
        components: {
          settings: AccessSettings,
        },
      },
      {
        path: 'access/:id',
        name: 'apps.access.variant',
        meta: RESOLVE_CONTENT_META,
        components: {
          settings: AccessSettings,
        },
      },
      {
        path: 'output',
        name: 'apps.output',
        meta: VARIANT_CHECK_META,
        components: {
          settings: ScheduleSettings,
        },
      },
      {
        path: 'output/:id',
        name: 'apps.output.variant',
        meta: RESOLVE_CONTENT_META,
        components: {
          settings: ScheduleSettings,
        },
      },
      {
        path: 'subscriptions',
        name: 'apps.subscriptions',
        meta: {
          ...RESOLVE_CONTENT_META,
          requireAuth: true,
        },
        components: {
          settings: ScheduleSettings,
        },
      },
      {
        path: 'performance', // Legacy route, redirects to runtime settings
        name: 'apps.performance',
        redirect: { name: 'apps.runtime' },
      },
      {
        path: 'runtime',
        name: 'apps.runtime',
        meta: RESOLVE_CONTENT_META,
        components: {
          settings: RuntimeSettings,
        },
      },
      {
        path: 'tags',
        name: 'apps.tags',
        meta: VARIANT_CHECK_META,
        components: {
          settings: AppTags,
        },
      },
      {
        path: 'tags/:id',
        name: 'apps.tags.variant',
        meta: RESOLVE_CONTENT_META,
        components: {
          settings: AppTags,
        },
      },
      {
        path: 'logs',
        name: 'apps.logs',
        meta: VARIANT_CHECK_META,
        components: {
          settings: AccessSettings,
        },
      },
      {
        path: 'logs/:id',
        name: 'apps.logs.variant',
        meta: RESOLVE_CONTENT_META,
        components: {
          settings: AccessSettings,
        },
      },
      {
        path: 'environment',
        name: 'apps.environment',
        meta: RESOLVE_CONTENT_META,
        components: {
          settings: EnvironmentSettings,
        },
      },
      {
        path: ':id',
        name: 'apps.variant',
        meta: RESOLVE_CONTENT_META,
        components: { },
      },
    ],
  },
];
